import { createContext, useContext } from 'react';

export interface MenuContextProps {
    component?: () => JSX.Element;
    openMenu?: (args: MenuContextProps) => void;
    closeMenu?: () => void;
    isOpened?: boolean;
    props?: any;
}

export const MenuContext = createContext<MenuContextProps>({
    component: undefined,
    openMenu: () => {},
    closeMenu: () => {},
    isOpened: false,
    props: {},
});

export const useMenu = () => useContext(MenuContext);




