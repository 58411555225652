/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import styled, { css } from 'styled-components';

interface IProps {
    active?: boolean;
    puzzle?: boolean;
    error?: boolean;
}

export const Container = styled.div<IProps>`
    ${({ active, puzzle, error, theme }) => css`
        height: auto;
        align-items: center;
        border: solid 1px ${theme.colors.kobaltblue};
        color: ${puzzle ? theme.colors.kobaltblue : '#FFF'};
        background-color: ${puzzle
            ? theme.colors.white
            : error
            ? theme.colors.darkorange
            : active
            ? theme.colors.active
            : theme.colors.empty};
        cursor: pointer;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        font-size: 20px;
        font-weight: ${puzzle ? 'normal' : 'bold'};
        justify-content: center;
        transition: ${theme.transition};
        user-select: none;

        &:before {
            padding-top: 100%;
            content: '';
            float: left;
        }

        &:hover {
            background-color: ${theme.colors.lightBlue};
        }
    `}
`;
