import Trophee from './assets/trophee.svg?react';
import Banner from './assets/banner.svg?react';
import RightArrow from './assets/rightArrow.svg?react';

import styles from './styles.module.css';
import Heading from '../../components/Heading';
import Action from '../../components/Action';
import Clock from '../../components/Clock';

export const CongratsWindow = ({ onClose, time, colors, congratsFrom, displayCongratulation }: {
    onClose: () => void,
    time: number,
    colors: { primary: string, secondary: string },
    congratsFrom?: string,
    displayCongratulation: () => void,
}) => {
    return (
        <div className={styles.root}>
            <div className={styles.tropheeHalo} />
            <Trophee className={styles.trophee} />
            <div className={styles.bannerContainer}>
                <Banner className={styles.banner} style={{
                    '--banner-color': colors.primary,
                    '--banner-secondary-color': colors.secondary,
                } as React.CSSProperties} />
                <Heading center className={styles.heading}>Bravo !</Heading>
            </div>
            {time !== 0 && <Clock className={styles.clock} time={time} color={colors.secondary} />}
            {congratsFrom ?
                <>
                    <Heading center className={styles.congratsText}>{`${congratsFrom} à une petite surprise\npour vous !`}</Heading>
                    <div className={styles.actions} onClick={displayCongratulation}>
                        <div className={styles.cta} style={{ backgroundColor: colors.secondary }}>
                            <Heading center className={styles.label}>Découvrir ma surprise !</Heading>
                        </div>
                    </div>
                </> :
                <Action className={styles.button} action={onClose}>
                    <RightArrow className={styles.rightArrow} />
                </Action>
            }
        </div>
    );
}