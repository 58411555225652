/* eslint-disable no-param-reassign */
import { useState } from 'react';
import { AnyAction } from 'redux';
import { GRID } from '../typings';
import { compareArrays, copyGrid, createFullGrid, removeNumbers } from '../utils';

import { IReducer } from './interfaces';
import * as types from './types';
import theme from '../styles/theme';
import global from '../global';
// import { settVar } from '../sudoku';

const initialState: IReducer = {};

function reducer(state = initialState, action: AnyAction): IReducer {
    switch (action.type) {
        case types.CREATE_GRID: {
            if (!action.payload || !action.payload.gridSize) {
                return state;
            }

            const { gridSize } = action.payload;
            const solvedGrid = createFullGrid(gridSize);
            const gridCopy = copyGrid(solvedGrid);
            const challengeGrid = removeNumbers(
                gridCopy
                // , 5
                // set a number to increase difficulty
            );
            const workingGrid = copyGrid(challengeGrid);
            const errorCount = 0;

            for (let r = 0; r < global.gridSize; r++) {
                for (let c = 0; c < global.gridSize; c++) {
                    const block = document.querySelector(`[data-cy="block-${r}-${c}"]`);
                    if (block instanceof HTMLElement) {
                        if (workingGrid[r][c] !== 0) {
                            block.style.backgroundColor = theme.colors.white;
                        } else {
                            block.style.backgroundColor = theme.colors.empty;
                        }
                    }
                }
            }

            return {
                ...state,
                challengeGrid,
                solvedGrid,
                workingGrid,
                errorCount,
                isError: false
            };
        }

        case types.FILL_BLOCK: {
            if (state.workingGrid && state.solvedGrid) {
                if (state.solvedGrid[action.coords[0]][action.coords[1]] !== action.value) {
                    const block = document.querySelector(`[data-cy="block-${action.coords[0]}-${action.coords[1]}"]`);
                    if (block instanceof HTMLElement) {
                        block.style.backgroundColor = theme.colors.darkorange;
                    }
                    return {
                        ...state,
                        errorCount: state.errorCount ? state.errorCount + 1 : 1,
                        isError: true
                    };
                }

                state.workingGrid[action.coords[0]][action.coords[1]] = action.value;
                // add green background to correct value
                const block = document.querySelector(`[data-cy="block-${action.coords[0]}-${action.coords[1]}"]`);
                if (block instanceof HTMLElement) {
                    block.style.backgroundColor = theme.colors.corectValue;
                }

                // if (compareArrays(state.workingGrid, state.solvedGrid)) alert('Yes, you did it!');
                return { ...state, workingGrid: [...state.workingGrid] as GRID, isError: false };
            }
            return state;
        }

        case types.SELECT_BLOCK:
            const block = document.querySelector(`[data-cy="block-${action.coords[0]}-${action.coords[1]}"]`);
            // Reset background color of previously selected block
            if (state.selectedBlock && state.workingGrid[state.selectedBlock[0]][state.selectedBlock[1]] === 0) {
                const prevBlock = document.querySelector(`[data-cy="block-${state.selectedBlock[0]}-${state.selectedBlock[1]}"]`);
                if (prevBlock instanceof HTMLElement) {
                    prevBlock.style.backgroundColor = theme.colors.empty;
                }
            }
            if (block instanceof HTMLElement && state.workingGrid[action.coords[0]][action.coords[1]] === 0) {
                block.style.backgroundColor = theme.colors.active;
            }
            return {
                ...state,
                selectedBlock: action.coords,
                isError: false
            };

        case types.RESET_STATE: {
            return initialState;
        }

        default:
            return state;
    }
}

// eslint-disable-next-line import/no-default-export
export default reducer;
