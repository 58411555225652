import React from 'react';

import LevelCircle from './levelCircle.svg?react';
import styles from './styles.module.css';

function hexToRgba(hex: string, alpha: number): string {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    if (!result) {
        throw new Error('La couleur hexadécimale fournie à LevelBar n\'est pas valide.');
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}


interface LevelBarProps {
    level: number;
    color: string;
}

const Circle = ({ colorLeft, colorRight }: { colorLeft: string; colorRight: string }) => (
    <LevelCircle
        className={styles.circle}
        style={{ '--circle-color-left': colorLeft, '--circle-color-right': colorRight } as React.CSSProperties}
    />
);

const LevelBar: React.FC<LevelBarProps> = ({ level, color }) => {
    if (level < 1 || level > 10) {
        throw new Error('Level must be a number between 1 and 10');
    }

    const circles = Array.from({ length: 5 }, (_, index) => {
        const circleOpacityRight = index < level / 2 ? 1 : 0.1;
        const circleOpacityLeft = index < (level - 1) / 2 ? 1 : 0.1;
        return <div
            className={styles.circle}
            key={index}
            style={{
                '--circle-color-left': hexToRgba(color, circleOpacityLeft),
                '--circle-color-right': hexToRgba(color, circleOpacityRight)
            } as React.CSSProperties}
        />
    });

    return <div className={styles.root}>{circles}</div>;
};

export default LevelBar;