import { Link as ReactLink } from 'react-router-dom';

import style from './style.module.css';

interface ActionProps {
    action?: string | (() => void);
    target?: string;
    className?: string;
    children?: React.ReactNode;
    style?: React.CSSProperties;
}

const Action: React.FC<ActionProps> = ({ action , target = '_blank', className = '', ...props }) => {
    const dest = action;
    const actionType = dest && typeof dest;

    if (actionType === 'function') {
        return <span className={`${style.action} ${className}`} onClick={dest as () => void} {...props} />;
    }

    if (actionType === 'string' && typeof dest === 'string' && dest[0] === '/') {
        return <ReactLink className={`${style.action} ${className}`} to={dest} {...props} />;
    }

    if (actionType === 'string' && typeof dest === 'string' && dest[0] === '#') {
        return <a className={`${style.action} ${className}`} href={dest} {...props} />;
    }

    if (actionType === 'string' && typeof dest === 'string' && dest[0] !== '/') {
        return (
            <a
                className={`${style.action} ${className}`}
                href={dest}
                rel="noopener noreferrer"
                {...(target ? { target } : {})}
                {...props}
            />
        );
    }

    return <span className={`${style.action} ${style.noaction} ${className}`} {...props} />;
};

export default Action;
