import React, { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { useAuth0 } from '@auth0/auth0-react';
import { returnTo } from '../main';
import { Browser } from '@capacitor/browser';
import { useHistory } from 'react-router';
import { isPlatform } from '@ionic/react';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';

const classes: { [key: string]: CSSProperties } = {
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '95%',
        textAlign: 'center',
        alignSelf: 'center'
    }
};

export function LogoutButton(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);

    return (
            <Button
                key="logout"
                sx={{ ...classes['logout-button'] }}
                pink
                onClick={() => {
                    localStorage.removeItem('access_token');
                    userDataProp.updateUser ? userDataProp.updateUser({token: '', user: null }) : '';
                    history.replace('/login');
                }}>
                {t('LogOut')}
            </Button>
    );
}
