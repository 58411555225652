import React from 'react';

import style from './style.module.css';

type HeadingProps = {
    h?: number;
    seo?: number;
    center?: boolean;
    className?: string;
    children: React.ReactNode;
};

type Headings = {
    [key: number]: React.FC<HeadingProps>;
    fallback: React.FC<HeadingProps>;
};

const headings: Headings = {
    1: ({ children, ...props }) => <h1 {...props}>{children}</h1>,
    2: ({ children, ...props }) => <h2 {...props}>{children}</h2>,
    3: ({ children, ...props }) => <h3 {...props}>{children}</h3>,
    4: ({ children, ...props }) => <h4 {...props}>{children}</h4>,
    5: ({ children, ...props }) => <h5 {...props}>{children}</h5>,
    6: ({ children, ...props }) => <h6 {...props}>{children}</h6>,
    fallback: ({ children, ...props }) => <span {...props}>{children}</span>,
};

const Heading = ({ h = 1, seo = h, center = false, className = '', children, ...props }: HeadingProps) => {
    const Head: React.FC<HeadingProps> = headings[seo] || headings.fallback;
    return <Head className={`${center ? style.center : ''} ${className}`} {...props}>
        {children}
    </Head>
};

export default React.memo(Heading);
