import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import clueIcon from '../../assets/clueIcon.svg';
import ErrorIcon from '../../assets/ErrorIcon.svg';
import littleArrow from '../../assets/littleArrow.svg';
import { Select } from '../Select';

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    addData,
    calcEvol,
    changetimeLine,
    classes,
    getLabelFromDate
} from '../ProfileStatisticsStyle';
import { lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import { getNumberOfExercises } from '../../services/cloudFirestore';
import { Button } from '../Button';
// import '../../utilities/utils.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Patient } from '../../models/Patient';
import { getDateFromTimestamp2 } from '../../utilities/utils';
import { StimbotInfo } from './StimbotInfo';
import { StatEvol } from './StatEvol';
import { labelsMonth } from '../ProfileStatisticsStyle';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { getStat } from '../../stores/Stats';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

interface Props {
    userDataList: Patient[];
}

export function StatsTimePlay({ userDataList }: Props): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const [typeDataView, setTypeDataView] = useState<ExerciseType | 'all'>('all');
    const [timePlaingEvol, setTimePlaingEvol] = useState<string>('');
    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [totalTimePlay, setTotalTimePlay] = useState<string>('');
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem: any) => {
                        return (
                            tooltipItem.dataset.label +
                            ' : ' +
                            Math.floor(tooltipItem.dataset.data[tooltipItem.dataIndex]) +
                            ' min ' +
                            Math.round(
                                (tooltipItem.dataset.data[tooltipItem.dataIndex] -
                                    Math.floor(tooltipItem.dataset.data[tooltipItem.dataIndex])) *
                                    60
                            ) +
                            ' sec'
                        );
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
                // max: 10,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const [data, setData] = useState<any | undefined>({
        labels: labelsMonth,
        datasets: [
            {
                fill: true,
                label: 'Durée de jeu',
                data: [0, 0, 0, 0],
                borderColor: '#00665F',
                borderWidth: 2,
                pointBackgroundColor: '#00665F',
                pointRadius: 6,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#00665F',
                pointHoverBorderColor: '#FFFFFF',
                pointHoverBorderWidth: 2,
                pointHitRadius: 10,
                pointBorderWidth: 2,
                pointBorderColor: '#FFFFFF',
                pointStyle: 'circle',
                backgroundColor: ({ chart: { ctx } }: any) => {
                    const bg = ctx.createLinearGradient(0, 0, 0, 250);
                    bg.addColorStop(0, 'rgba(0, 102, 95, 0.40)');
                    bg.addColorStop(1, 'rgba(0, 102, 95, 0.00)');
                    return bg;
                }
            }
        ]
    });

    const calcData = async () => {
        const res = await getStat(
            userDataProp?.token || '',
            'game/time',
            {
                startDate,
                endDate,
                idList: userDataList.map((user) => user.id ?? ''),
                timeScale,
                type: typeDataView
            }
        ).then((res) => res.data);
        
        setTimePlaingEvol(calcEvol(res.data, timeScale));

        const totalTimePlay = res.totalTime / 60;
        const totalTimePlayMinutes = Math.floor(totalTimePlay);
        const totalTimePlaySeconds = Math.round((totalTimePlay - totalTimePlayMinutes) * 60);

        setTotalTimePlay(totalTimePlayMinutes + ' min ' + totalTimePlaySeconds + ' sec');

        setData({
            ...data,
            labels: getLabelFromDate(startDate, endDate, timeScale),
            datasets: [
                {
                    ...data.datasets[0],
                    data: res.data.map((d: any) => d ? d/60 : 0)
                }
            ]
        });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, typeDataView, timeScale, endDate, startDate]);

    return (
        <div style={{ ...classes['stat-card'] }}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%'
                }}>
                <Typography variant="p" sx={{ ...classes['stat-card-subtitle'] }}>
                    {t('profile.statistics.TimePlaingSubtitle')}
                </Typography>
                <StatToolkit
                    value={totalTimePlay}
                    tooltipText={t('profile.statistics.TimePlaingTooltip', { time: totalTimePlay })}
                />
            </div>
            <StimbotInfo text={t('profile.statistics.TimePlaingInfo')} />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '1rem'
                }}>
                <Select
                    sxRoot={{ ...classes['select-white-root'] }}
                    sx={{
                        border: '1px solid #E8E8E8',
                        borderRadius: '1.5rem'
                    }}
                    defaultValue="all"
                    value={typeDataView}
                    opts={[
                        { value: 'all', label: t('all') },
                        ...lstGames.map((game, index) => {
                            return { value: game.type, label: t(game.name) };
                        })
                    ]}
                    onChange={(e) => setTypeDataView(e.target.value as ExerciseType)}
                />
                <SelectTimeline
                    onChange={(data: {
                        val: string;
                        startDate: Date;
                        endDate: Date;
                        timeScale: 'day' | 'week' | 'month' | 'year';
                    }) => {
                        setEndDate(data.endDate);
                        setStartDate(data.startDate);
                        setTimeScale(data.timeScale);
                    }}
                />
            </div>
            {data && <Line options={options} data={data} />}
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1.5rem',
                    width: '100%'
                }}>
                <Button
                    sx={{ ...classes['white-btn'] }}
                    onClick={() => {
                        changetimeLine(
                            'prev',
                            timeScale,
                            endDate,
                            setEndDate,
                            startDate,
                            setStartDate
                        );
                    }}>
                    <img src={littleArrow} alt="littleArrow" />
                    {t('prev')}
                </Button>
                <Button
                    sx={{ ...classes['white-btn'] }}
                    onClick={() => {
                        changetimeLine(
                            'next',
                            timeScale,
                            endDate,
                            setEndDate,
                            startDate,
                            setStartDate
                        );
                    }}>
                    {t('next')}
                    <img
                        src={littleArrow}
                        alt="littleArrow"
                        style={{ transform: 'rotate(180deg)' }}
                    />
                </Button>
            </div>
            <StatEvol evolNb={timePlaingEvol} reverse />
        </div>
    );
}
