// eslint-disable-next-line import/no-extraneous-dependencies
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';
import { Roles } from '../models/Roles';
import { User } from '../models/User';
import { ExerciseType } from '../models/ExerciseType';
import * as Games from '../models/Games'
import { PatientHomeData } from '../models/PatientHomeData';

const imageKitBaseUrl = 'https://ik.imagekit.io/ttd0gzik4ov'; // use "ttd0gzik4ovDEV" or DEV2 for dev
const firebaseStorageBaseUrl = 'https://firebasestorage.googleapis.com';

export const getImageKitUrlFrom = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(firebaseStorageBaseUrl, `${imageKitBaseUrl}/tr:w-${width},h-${height}`);
    }
    return url.replace(firebaseStorageBaseUrl, imageKitBaseUrl);
};

export const getImageKitThumbnailFromVideo = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(firebaseStorageBaseUrl, `${imageKitBaseUrl}/tr:w-${width},h-${height}/ik-thumbnail.jpg`);
    }
    return url.replace(firebaseStorageBaseUrl, `${imageKitBaseUrl}/ik-thumbnail.jpg`);
};

export const getImageKitVideoQuicktimetoMp4 = (url: string): string => {
    return url.replace(firebaseStorageBaseUrl, `${imageKitBaseUrl}/tr:f-mp4,w-512,h-512,du-01/ik-video.mp4`);
};

export const getImageKitUrlFromCroped = (
    url: string,
    width: number,
    height: number,
    startWidth: number,
    startHeight: number
): string => {
    return url.replace(
        firebaseStorageBaseUrl,
        `${imageKitBaseUrl}/tr:w-${width},h-${height},cm-extract,x-${startWidth},y-${startHeight}`
    );
};

export const secToStringMinSec = (sec: number): string => {
    if (Number.isNaN(sec)) return '0min00';
    const minutes = Math.floor(sec / 60);
    const seconds = sec - minutes * 60;
    return `${minutes}min${seconds < 10 ? `0${seconds}` : seconds}`;
}

export const getImageKitUrlFromRounded = (url: string, width?: number, height?: number): string => {
    if (width && height) {
        return url.replace(
            firebaseStorageBaseUrl,
            `${imageKitBaseUrl}/tr:w-${width},h-${height},r-max`
        );
    }
    return url.replace(firebaseStorageBaseUrl, imageKitBaseUrl);
};

export const validateEmail = (emailReceived: string | null): boolean => {
    const emailRegex =
        /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailReceived && emailReceived.trim() !== '') {
        return emailRegex.test(emailReceived);
    }
    return false;
};

export const validatePassword = (password: string): number[] => {
    const hasCaps = /[A-Z]/;
    const hasSmall = /[a-z]/;
    const hasDigit = /[0-9]/;
    const list: number[] = [];
    if (hasCaps.test(password)) {
        list.push(2);
    }
    if (hasSmall.test(password)) {
        list.push(1);
    }
    if (hasDigit.test(password)) {
        list.push(3);
    }
    if (password.length > 7) {
        list.push(0);
    }
    return list;
};

export const convertToTitleCase = (str: string): string => {
    return str.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDate = (date: any): Date => {
    const dateArray = date.split('/');
    const dateComplete = new Date(dateArray[2], dateArray[1] - 1, dateArray[0]);
    return dateComplete;
};

export const getDateFromTimestamp = (timestampData: Timestamp): string => {
    return new Date(timestampData.seconds * 1000).toLocaleDateString('fr');
};

export const getDateFromTimestamp2 = (timestampData: Timestamp): Date => {
    return new Date(timestampData.seconds * 1000);
};

const translateMonth = (month: string, stringMonth?: boolean): string => {
    if (month === 'Jan') {
        return stringMonth ? 'janvier' : '01';
    }
    if (month === 'Feb') {
        return stringMonth ? 'février' : '02';
    }
    if (month === 'Mar') {
        return stringMonth ? 'mars' : '03';
    }
    if (month === 'Apr') {
        return stringMonth ? 'avril' : '04';
    }
    if (month === 'May') {
        return stringMonth ? 'mai' : '05';
    }
    if (month === 'Jun') {
        return stringMonth ? 'juin' : '06';
    }
    if (month === 'Jul') {
        return stringMonth ? 'juillet' : '07';
    }
    if (month === 'Aug') {
        return stringMonth ? 'août' : '08';
    }
    if (month === 'Sep') {
        return stringMonth ? 'septembre' : '09';
    }
    if (month === 'Oct') {
        return stringMonth ? 'octobre' : '10';
    }
    if (month === 'Nov') {
        return stringMonth ? 'novembre' : '11';
    }
    if (month === 'Dec') {
        return stringMonth ? 'décembre' : '12';
    }
    return '';
};

export const translateDay = (dayNb: number, abbreviated?: boolean): string => {
    if (dayNb === 1) {
        return abbreviated ? 'Lun' : 'Lundi';
    }
    if (dayNb === 2) {
        return abbreviated ? 'Mar' : 'Mardi';
    }
    if (dayNb === 3) {
        return abbreviated ? 'Mer' : 'Mercredi';
    }
    if (dayNb === 4) {
        return abbreviated ? 'Jeu' : 'Jeudi';
    }
    if (dayNb === 5) {
        return abbreviated ? 'Ven' : 'Vendredi';
    }
    if (dayNb === 6) {
        return abbreviated ? 'Sam' : 'Samedi';
    }
    if (dayNb === 7 || dayNb === 0) {
        return abbreviated ? 'Dim' : 'Dimanche';
    }
    return '';
};

export const translateDate = (date: string): string => {
    let stringDate;
    const words = date.split(' ');
    if (words.length === 4) {
        words[1] = translateMonth(words[1]);
        stringDate = `${words[2]}/${words[1]}/${words[3]}`;
        return stringDate;
    }
    return '';
};

// export const YoutubeAPIKey = 'AIzaSyAr9CT1rhhTBnOfTQyM3YNGoBvsk46s_Lk'; // PROD
// export const YoutubeAPIKey = 'AIzaSyBR1Den86CcKYvU3lT27lAvVMG8ED5aqsg'; // DEV

export const youtube_parser = (url: string): string | boolean => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
};

export const codeStorage = (user: User): string | null => {
    if ((user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) && user?.familyCode) {
        return user.familyCode;
    }
    if (user?.role === Roles.PRO && user.establishmentCode) {
        return user.establishmentCode;
    }
    if (user?.role === Roles.ADMIN) {
        return 'admin';
    }
    return null;
};

export const getPatientFamilyCode = (): string => {
    const chars = 'abcdefghijklmnopqrstufwxyzABCDEFGHIJKLMNOPQRSTUFWXYZ1234567890';
    const pwd = _.sampleSize(chars, 6 || 12);
    return pwd.join('');
};

export const getCommunityId = (user: User | null | undefined): string | null => {
    if (user && user.id) {
        if (user.role === Roles.FAMILY || user.role === Roles.SENIOR) {
            if (user.familyCode) {
                return user.familyCode;
            }
        }
        if (user.role === Roles.PRO) {
            if (user.establishmentCode) {
                return user.establishmentCode;
            }
        }
        if (user.role === Roles.ADMIN) {
            return user.id;
        }
    }
    return null;
};
export const getRole = (user: User | null | undefined): Roles | null => {
    if (user) {
        switch (user.role) {
            case Roles.ADMIN:
                return Roles.ADMIN;
            case Roles.FAMILY:
                return Roles.FAMILY;
            case Roles.SENIOR:
                return Roles.SENIOR;
            case Roles.PRO:
                return Roles.PRO;
            default:
                return null;
        }
    }
    return null;
};

export const capitalizeFirstLetter = (a: string): string => {
    return a.charAt(0).toUpperCase() + a.slice(1);
};

export const decreasePuzzlin = (count: number, difficultyLevel: number): boolean => {
    if (difficultyLevel === 2 && count >= 5) {
        return true;
    }
    if (difficultyLevel === 3 && count >= 8) {
        return true;
    }
    if (difficultyLevel === 4 && count >= 11) {
        return true;
    }
    if (difficultyLevel === 5 && count >= 15) {
        return true;
    }
    if (difficultyLevel === 6 && count >= 19) {
        return true;
    }
    if (difficultyLevel === 7 && count >= 24) {
        return true;
    }
    return false;
};

export const decreaseSouvenin = (count: number, difficultyLevel: number): boolean => {
    if (difficultyLevel === 2 && count >= 5) {
        return true;
    }
    if (difficultyLevel === 3 && count >= 9) {
        return true;
    }
    if (difficultyLevel === 4 && count >= 14) {
        return true;
    }
    if (difficultyLevel === 5 && count >= 8) {
        return true;
    }
    if (difficultyLevel === 6 && count >= 11) {
        return true;
    }
    return false;
};

export const determineDifficultyLevel = (level: number): number => {
    if (level === 1) {
        return 3;
    }
    if (level === 2) {
        return 4;
    }
    if (level === 3) {
        return 5;
    }
    return 6;
};

export const randomPicture = (level: number, exoType: ExerciseType): number[] => {
    const arrayLvlToPicture = {
        "PUZZL'IN": [0],
        "SOUVEN'IN": [3, 4, 4, 4, 6, 4, 5, 5, 6, 6],
        "MEMOR'IN": [2, 3, 4, 5, 6, 8, 10, 12, 15, 18],
        "SUDOK'IN": [0],
        "PONG'IN": [0],
        "ORDON'IN": [0],
        "TOURBILLON": [0],
        "MELI'MELO": [0],
    };
    let list: number[] = [];
    let array: number[];
    if (exoType === ExerciseType.MEMORIZ) {
        array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
    } else {
        array = [0, 1, 2, 3, 4, 5];
    }
    list = getMultipleRandom(array, arrayLvlToPicture[exoType as keyof typeof arrayLvlToPicture][level - 1]);

    return list;
};

export const getMultipleRandom = (arr: number[], num: number): number[] => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
};

export const translateMonthByNumber = (month: string): 'janvier' | 'février' | 'mars' | 'avril' | 'mai' | 'juin' | 'juillet' | 'août' | 'septembre' | 'octobre' | 'novembre' | 'décembre' | '' => {
    if (month === '01') {
        return 'janvier';
    }
    if (month === '02') {
        return 'février';
    }
    if (month === '03') {
        return 'mars';
    }
    if (month === '04') {
        return 'avril';
    }
    if (month === '05') {
        return 'mai';
    }
    if (month === '06') {
        return 'juin';
    }
    if (month === '07') {
        return 'juillet';
    }
    if (month === '08') {
        return 'août';
    }
    if (month === '09') {
        return 'septembre';
    }
    if (month === '10') {
        return 'octobre';
    }
    if (month === '11') {
        return 'novembre';
    }
    if (month === '12') {
        return 'décembre';
    }
    return '';
};

export const strDateTolongStr = (date: string): string => {
    let stringDate;
    const words = date.split('/');
    if (words.length === 3) {
        words[1] = translateMonthByNumber(words[1]);
        stringDate = `${words[0]} ${words[1]} ${words[2]}`;
        return stringDate;
    }
    return '';
};

export function stringToColor(string: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    /* eslint-enable no-bitwise */
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}

export function getUrlToGamePage(itemData: PatientHomeData): string {
    const game = Object.values(Games).find(
        (game) => game.type === itemData.exercise.exerciseType
    );
    if (game)
        return `${game.path}/${itemData.exercise.id}/lvl`;
    return '/home';
}