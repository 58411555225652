import { useParams, Redirect } from 'react-router';
import GamesThemeComponent from '../components/GamesTheme';
import { tourbillon, pingpong, memory, sudok, puzzle, reordon, melimots, quizz } from '../models/Games';
import { useGame } from '../stores/Game';
import { useContext } from 'react';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Loader } from '../components/Loader';
import { MenuProvider } from '../providers/MenuProvider';

const GamesTheme = () => {
  const { game: gameTitle, id } = useParams<{ game: string, id: string }>();
  const userDataProp: UserDataProp | null = useContext(UserContext);

  const game = {
    tourbillon,
    pingpong,
    memory,
    sudok,
    puzzle,
    reordon,
    melimots,
    quizz
  }[gameTitle] || null;

  if (!game) {
    console.error('Game not found');
    return <Redirect to="/home" />;
  }

  const { data, loading, error } = useGame(userDataProp?.token || '', gameTitle);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <MenuProvider>
      <GamesThemeComponent title={game.name} icon={game.icon} items={data} path={game.path} />
    </MenuProvider>
  );
};

export default GamesTheme;