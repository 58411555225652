import React, { CSSProperties, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Typography } from './Typography';
import backArrow from '../assets/arrow.svg';
import './InspiNotebook.css';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 16px'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '1.5rem',
        width: '100%'
    },
    'title-container': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '0rem',
        width: '100%'
    },
    'notebook-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '0.5rem',
        width: '100%'
    }
};

interface Props {
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

export function InspiNotebook({ step, setStep }: Props): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const [notebookIndexLookUp, setNotebookIndexLookUp] = React.useState<number>();

    const notebook: { title: string; subTitle: string; element: JSX.Element }[] = [
        {
            title: t('inspiNotebook.title1'),
            subTitle: t('inspiNotebook.subTitle1'),
            element: (
                <p className="text">
                    Voici une liste de questions axées sur les préférences personnelles qui peuvent
                    être intéressantes à inclure dans votre guide d'interview :
                    <ol>
                        <li>Quel est votre plat préféré et pourquoi l'aimez-vous tant ?</li>
                        <li>
                            Avez-vous un livre ou un auteur préféré qui vous a particulièrement
                            marqué ?
                        </li>
                        <li>
                            Quel genre de musique appréciez-vous le plus et quel est votre artiste
                            ou groupe favori ?
                        </li>
                        <li>
                            Préférez-vous les voyages à la plage ou les voyages à la montagne ?
                            Pourquoi ?
                        </li>
                        <li>
                            Quel est votre film ou votre série télévisée préféré(e) et qu'est-ce qui
                            vous attire dans cette œuvre ?
                        </li>
                        <li>
                            Avez-vous un hobby ou une activité que vous aimez particulièrement
                            pratiquer pendant votre temps libre ?
                        </li>
                        <li>Préférez-vous passer du temps en plein air ou à l'intérieur ?</li>
                        <li>
                            Quel est votre endroit préféré pour vous détendre et vous ressourcer ?
                        </li>
                        <li>Aimez-vous les animaux de compagnie ? Avez-vous un animal préféré ?</li>
                        <li>Quel est votre style de décoration intérieure préféré ?</li>
                        <li>
                            Préférez-vous les soirées animées entre amis ou les moments calmes en
                            famille ?
                        </li>
                        <li>Quel est votre type de cuisine préféré ?</li>
                        <li>
                            Avez-vous des activités que vous aimez particulièrement faire avec vos
                            proches ?
                        </li>
                        <li>
                            Aimez-vous les arts visuels ? Avez-vous un artiste ou un mouvement
                            artistique préféré ?
                        </li>
                        <li>Quel est votre moyen de transport préféré pour vous déplacer ?</li>
                        <li>
                            Préférez-vous les films classiques en noir et blanc ou les films
                            contemporains en couleur ?
                        </li>
                        <li>
                            Quel est votre type de paysage préféré : montagne, plage, forêt, ville,
                            etc. ?
                        </li>
                        <li>
                            Avez-vous des plats ou des recettes que vous préparez avec plaisir pour
                            vos proches ?
                        </li>
                        <li>
                            Avez-vous un moment de la journée que vous préférez particulièrement ?
                            (matin, après-midi, soirée)
                        </li>
                        <li>
                            Aimez-vous les activités créatives comme la peinture, la sculpture ou le
                            bricolage ?
                        </li>
                    </ol>
                    Ces questions sur les préférences peuvent donner un aperçu intéressant de la
                    personnalité et des goûts de la personne interviewée. Cela peut également créer
                    des opportunités pour des discussions enrichissantes et échanger sur des sujets
                    qui peuvent être significatifs pour elle.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title2'),
            subTitle: t('inspiNotebook.subTitle2'),
            element: (
                <p className="text">
                    <strong>30 thématiques en culture générale :</strong>
                    <br />
                    <br />
                    <ol>
                        <li>Histoire : </li>
                        <ul>
                            <li>Histoire mondiale</li>
                            <li>Histoire de votre pays</li>
                            <li>
                                Périodes historiques spécifiques (Antiquité, Moyen Âge, Renaissance,
                                etc.)
                            </li>
                            <li>Histoire des civilisations anciennes</li>
                        </ul>
                        <li>Géographie : </li>
                        <ul>
                            <li>Géographie physique (reliefs, climats, etc.)</li>
                            <li>Géographie humaine (cultures, populations, etc.)</li>
                            <li>Géographie politique (capitales, frontières, etc.)</li>
                            <li>Lieux emblématiques</li>
                            <li>Géopolitique mondiale</li>
                        </ul>
                        <li>Politique et Gouvernance : </li>
                        <ul>
                            <li>Systèmes politiques</li>
                            <li>Organisations internationales</li>
                            <li>Personnalités politiques</li>
                            <li>Élections et processus démocratiques</li>
                            <li>Relations internationales</li>
                        </ul>
                        <li>Sciences : </li>
                        <ul>
                            <li>Sciences naturelles (biologie, physique, chimie, etc.)</li>
                            <li>Astronomie</li>
                            <li>Médecine</li>
                            <li>Inventions et découvertes</li>
                            <li>Sciences de l'environnement</li>
                        </ul>
                        <li>Arts et Culture : </li>
                        <ul>
                            <li>Peinture, sculpture, architecture</li>
                            <li>Littérature</li>
                            <li>Musique</li>
                            <li>Cinéma et théâtre</li>
                            <li>Danse et arts du spectacle</li>
                        </ul>
                        <li>Religions et Spiritualité : </li>
                        <ul>
                            <li>Grandes religions du monde</li>
                            <li>Croyances et pratiques spirituelles</li>
                            <li>Histoire des religions</li>
                            <li>Mouvements religieux contemporains</li>
                        </ul>
                        <li>Philosophie : </li>
                        <ul>
                            <li>Grands philosophes et leurs idées</li>
                            <li>Courants philosophiques</li>
                            <li>Éthique et philosophie morale</li>
                            <li>Philosophie politique</li>
                        </ul>
                        <li>Langues et Linguistique : </li>
                        <ul>
                            <li>Langues du monde</li>
                            <li>Étymologie</li>
                            <li>Grammaire et syntaxe</li>
                            <li>Langues en danger</li>
                        </ul>
                        <li>Société et Coutumes : </li>
                        <ul>
                            <li>Traditions culturelles</li>
                            <li>Normes sociales</li>
                            <li>Événements et mouvements sociaux</li>
                            <li>Coutumes alimentaires</li>
                        </ul>
                        <li>Économie : </li>
                        <ul>
                            <li>Concepts économiques (offre, demande, etc.)</li>
                            <li>Histoire économique</li>
                            <li>Systèmes économiques</li>
                            <li>Économie mondiale</li>
                            <li>Investissements et marchés financiers</li>
                        </ul>
                        <li>Sport et Loisirs : </li>
                        <ul>
                            <li>Sports populaires</li>
                            <li>Événements sportifs majeurs</li>
                            <li>Loisirs et divertissements</li>
                            <li>Jeux olympiques et compétitions internationales</li>
                        </ul>
                        <li>Technologie et Informatique : </li>
                        <ul>
                            <li>Avancées technologiques</li>
                            <li>Développements informatiques</li>
                            <li>Personnalités de l'industrie technologique</li>
                            <li>Intelligence artificielle et automatisation</li>
                        </ul>
                        <li>Environnement et Durabilité : </li>
                        <ul>
                            <li>Problématiques environnementales</li>
                            <li>Initiatives durables</li>
                            <li>Énergies renouvelables</li>
                            <li>Conservation de la biodiversité</li>
                            <li>Gestion des déchets</li>
                        </ul>
                        <li>Alimentation et Cuisine : </li>
                        <ul>
                            <li>Cuisines du monde</li>
                            <li>Plats emblématiques</li>
                            <li>Habitudes alimentaires</li>
                            <li>Nutrition et régimes alimentaires</li>
                            <li>Cuisine végétalienne et végétarienne</li>
                        </ul>
                        <li>Éducation et Éducation Civique : </li>
                        <ul>
                            <li>Systèmes éducatifs</li>
                            <li>Droits et devoirs des citoyens</li>
                            <li>Éducation à la citoyenneté</li>
                            <li>Éducation en ligne et apprentissage à distance</li>
                            <li>Éducation spécialisée</li>
                        </ul>
                        <li>Diversité et Inclusion : </li>
                        <ul>
                            <li>Égalité des genres</li>
                            <li>Lutte contre les discriminations</li>
                            <li>Inclusion des minorités</li>
                            <li>Droits des LGBTQ+</li>
                            <li>Accessibilité et inclusion des personnes handicapées</li>
                        </ul>
                        <li>Psychologie et Santé Mentale : </li>
                        <ul>
                            <li>Concepts psychologiques</li>
                            <li>Santé mentale et bien-être</li>
                            <li>Psychologie du développement</li>
                            <li>Thérapies et approches de traitement</li>
                        </ul>
                        <li>Droit et Justice : </li>
                        <ul>
                            <li>Concepts juridiques</li>
                            <li>Systèmes judiciaires</li>
                            <li>Droits de l'homme</li>
                            <li>Droit international</li>
                            <li>Justice sociale</li>
                        </ul>
                        <li>Médias et Communication : </li>
                        <ul>
                            <li>Médias traditionnels et numériques</li>
                            <li>Liberté de la presse</li>
                            <li>Éthique journalistique</li>
                            <li>Influence des médias sur la société</li>
                        </ul>
                        <li>Éthique et Morale : </li>
                        <ul>
                            <li>Dilemmes éthiques</li>
                            <li>Philosophie morale</li>
                            <li>Éthique professionnelle</li>
                            <li>Bioéthique</li>
                            <li>Éthique de la technologie</li>
                        </ul>
                        <li>Espace et Exploration : </li>
                        <ul>
                            <li>Conquête spatiale</li>
                            <li>Planètes et astres du système solaire</li>
                            <li>Astronomie observationnelle</li>
                            <li>Futurs projets spatiaux</li>
                        </ul>
                        <li>Voyages et Tourisme : </li>
                        <ul>
                            <li>Destinations touristiques</li>
                            <li>Expériences de voyage</li>
                            <li>Culture du voyage</li>
                            <li>Tourisme responsable</li>
                            <li>Conseils de voyage</li>
                        </ul>
                        <li>Architecture et Urbanisme : </li>
                        <ul>
                            <li>Styles architecturaux</li>
                            <li>Grands architectes et leurs œuvres</li>
                            <li>Urbanisme et planification urbaine</li>
                            <li>Durabilité dans l'architecture</li>
                        </ul>
                        <li>Événements Historiques Majeurs : </li>
                        <ul>
                            <li>Guerres et conflits mondiaux</li>
                            <li>Révolutions et mouvements de libération</li>
                            <li>Découvertes et explorations historiques</li>
                            <li>Événements marquants du 20e siècle</li>
                        </ul>
                        <li>Science-fiction et Fantaisie : </li>
                        <ul>
                            <li>Œuvres emblématiques de la SF et de la fantasy</li>
                            <li>Univers fictifs et créatures fantastiques</li>
                            <li>Influences de la SF et de la fantasy sur la culture populaire</li>
                            <li>Littérature, films et séries du genre</li>
                        </ul>
                        <li>Musées et Galeries d'Art : </li>
                        <ul>
                            <li>Musées célèbres et leurs collections</li>
                            <li>Expositions d'art majeures</li>
                            <li>Courants artistiques et mouvements culturels</li>
                        </ul>
                        <li>Musique du Monde : </li>
                        <ul>
                            <li>Genres musicaux emblématiques</li>
                            <li>Musiciens et compositeurs influents</li>
                            <li>Événements musicaux majeurs</li>
                            <li>Musique traditionnelle et folklorique</li>
                        </ul>
                        <li>Droit International et Droits de l'Homme : </li>
                        <ul>
                            <li>Traités et accords internationaux</li>
                            <li>Organisations internationales et leurs missions</li>
                            <li>
                                Droits fondamentaux et lutte contre les violations des droits de
                                l'homme
                            </li>
                        </ul>
                        <li>Mythologie et Folklore : </li>
                        <ul>
                            <li>Mythes grecs, romains, nordiques, etc.</li>
                            <li>Créatures mythologiques et légendes</li>
                            <li>Contes et folklore de différentes cultures</li>
                            <li>Influence de la mythologie sur l'art et la littérature</li>
                        </ul>
                        <li>Inventions et Innovations Modernes : </li>
                        <ul>
                            <li>
                                Technologie de pointe (intelligence artificielle, blockchain, etc.)
                            </li>
                            <li>Innovations dans la santé et la médecine</li>
                            <li>Avancées en matière d'énergie et de durabilité</li>
                            <li>Nouvelles tendances en matière de consommation</li>
                        </ul>
                    </ol>
                </p>
            )
        },
        {
            title: t('inspiNotebook.title3'),
            subTitle: t('inspiNotebook.subTitle3'),
            element: (
                <p className="text">
                    Voici une liste de questions qui peuvent aider une personne âgée à commencer à
                    travailler sur son arbre généalogique :
                    <ol>
                        <li>
                            Pouvez-vous me donner le nom complet de vos parents, grands-parents et
                            arrière-grands-parents si vous les connaissez ?
                        </li>
                        <li>
                            Pouvez-vous me dire où et quand sont nés vos parents, grands-parents et
                            arrière-grands-parents ?
                        </li>
                        <li>
                            Avez-vous des informations sur le métier ou la profession de vos
                            ancêtres ?
                        </li>
                        <li>
                            Pouvez-vous me parler des frères et sœurs de vos parents, ainsi que de
                            leurs enfants ?
                        </li>
                        <li>
                            Avez-vous des photos de famille ou des documents anciens qui pourraient
                            aider à retracer l'histoire de votre famille ?
                        </li>
                        <li>
                            Connaissez-vous des anecdotes ou des histoires intéressantes sur vos
                            ancêtres ?
                        </li>
                        <li>
                            Avez-vous des informations sur les mariages de vos parents,
                            grands-parents et arrière-grands-parents, comme les dates et les lieux ?
                        </li>
                        <li>
                            Avez-vous des détails sur les décès de vos ancêtres, comme les dates et
                            les lieux ?
                        </li>
                        <li>
                            Y a-t-il des migrations ou des déplacements importants dans l'histoire
                            de votre famille ?
                        </li>
                        <li>
                            Avez-vous des documents tels que des certificats de naissance, de
                            mariage ou de décès qui pourraient fournir des informations
                            supplémentaires ?
                        </li>
                        <li>
                            Avez-vous des lettres, des journaux intimes ou d'autres écrits de
                            famille qui pourraient contenir des détails sur vos ancêtres ?
                        </li>
                        <li>
                            Avez-vous des informations sur les religions ou les croyances
                            spirituelles de vos ancêtres ?
                        </li>
                        <li>
                            Avez-vous des traditions familiales spéciales qui ont été transmises de
                            génération en génération ?
                        </li>
                        <li>
                            Connaissez-vous des membres de votre famille élargie, comme des oncles,
                            tantes, cousins et cousines, et pouvez-vous fournir des détails à leur
                            sujet ?
                        </li>
                        <li>
                            Avez-vous des informations sur les prénoms et les surnoms de vos
                            ancêtres ?
                        </li>
                        <li>
                            Avez-vous des connaissances sur les origines ethniques ou culturelles de
                            votre famille ?
                        </li>
                    </ol>
                    Ces questions peuvent servir de point de départ pour recueillir des informations
                    sur la généalogie de la personne âgée. N'oubliez pas d'enregistrer soigneusement
                    les réponses et de documenter les détails pour construire un arbre généalogique
                    précis et informatif.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title4'),
            subTitle: t('inspiNotebook.subTitle4'),
            element: (
                <p className="text">
                    Les questions intergénérationnelles peuvent être une excellente façon de
                    favoriser les échanges et les souvenirs entre les membres de la famille. Voici
                    quelques idées de questions ludiques qui peuvent être posées à la fois aux
                    seniors et à leurs proches :
                    <ol>
                        <li>Quelle est votre première mémoire ensemble ?</li>
                        <li>Quels sont vos souvenirs préférés des vacances passées ensemble ?</li>
                        <li>
                            Avez-vous des traditions familiales qui vous tiennent à cœur ?
                            Pouvez-vous les expliquer ?
                        </li>
                        <li>
                            Quels étaient vos jeux ou activités préférés lorsque vous étiez enfant ?
                        </li>
                        <li>
                            Avez-vous des surnoms spéciaux pour les membres de votre famille ?
                            Comment les avez-vous choisis ?
                        </li>
                        <li>
                            Pouvez-vous partager une anecdote drôle ou embarrassante sur l'un des
                            membres de la famille ?
                        </li>
                        <li>
                            Quelles sont les leçons de vie les plus importantes que vous avez
                            apprises de vos parents ou grands-parents ?
                        </li>
                        <li>
                            Avez-vous des talents ou des compétences spéciales que vous aimeriez
                            transmettre à la génération suivante ?
                        </li>
                        <li>
                            Quel a été le moment le plus mémorable que vous avez partagé ensemble en
                            tant que famille ?
                        </li>
                        <li>
                            Avez-vous des recettes familiales spéciales ou des plats préférés que
                            vous aimez cuisiner et partager ?
                        </li>
                        <li>
                            Quels sont vos endroits préférés pour vous détendre en famille ou pour
                            les sorties spéciales ?
                        </li>
                        <li>
                            Pouvez-vous partager des histoires sur les événements historiques que
                            vous avez vécus ensemble en tant que famille ?
                        </li>
                        <li>
                            Quels sont vos rêves et vos espoirs pour les générations futures de
                            votre famille ?
                        </li>
                        <li>
                            Si vous pouviez voyager dans le temps, quelle époque de votre vie
                            aimeriez-vous revivre et pourquoi ?
                        </li>
                        <li>
                            Quelles sont vos musiques, chansons ou films préférés en tant que
                            famille et pourquoi les aimez-vous autant ?
                        </li>
                        <li>
                            Y a-t-il une compétition ou un défi familial que vous aimez relever
                            régulièrement ?
                        </li>
                    </ol>
                    Ces questions peuvent être une source de conversation intéressante et amusante
                    entre les générations. Elles permettent également de créer des liens et de
                    partager des souvenirs d'une manière interactive et engageante. N'hésitez pas à
                    adapter les questions en fonction de la dynamique et des intérêts spécifiques de
                    votre famille.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title5'),
            subTitle: t('inspiNotebook.subTitle5'),
            element: (
                <p className="text" style={{ fontWeight: 500 }}>
                    Le portrait chinois est un moyen amusant et créatif d'en apprendre davantage sur
                    une personne en imaginant ce qu'elle serait si elle était quelque chose d'autre.
                    Voici une liste de questions pour vous aider à dresser le portrait chinois d'une
                    personne âgée :
                    <ol>
                        <li>Si vous étiez un animal, lequel seriez-vous et pourquoi ?</li>
                        <li>Si vous étiez une couleur, laquelle seriez-vous et pourquoi ?</li>
                        <li>Si vous étiez un paysage, lequel seriez-vous et pourquoi ?</li>
                        <li>Si vous étiez un aliment, lequel seriez-vous et pourquoi ?</li>
                        <li>
                            Si vous étiez un instrument de musique, lequel seriez-vous et pourquoi ?
                        </li>
                        <li>Si vous étiez un livre, lequel seriez-vous et pourquoi ?</li>
                        <li>Si vous étiez une saison, laquelle seriez-vous et pourquoi ?</li>
                        <li>
                            Si vous étiez une époque de l'histoire, laquelle seriez-vous et pourquoi
                            ?
                        </li>
                        <li>Si vous étiez un super-pouvoir, lequel seriez-vous et pourquoi ?</li>
                        <li>Si vous étiez un lieu de vacances, lequel seriez-vous et pourquoi ?</li>
                        <li>
                            Si vous étiez un élément de la nature (vent, feu, eau, terre), lequel
                            seriez-vous et pourquoi ?
                        </li>
                        <li>Si vous étiez une émotion, laquelle seriez-vous et pourquoi ?</li>
                        <li>
                            Si vous étiez un moyen de transport, lequel seriez-vous et pourquoi ?
                        </li>
                        <li>
                            Si vous étiez une œuvre d'art célèbre, laquelle seriez-vous et pourquoi
                            ?
                        </li>
                        <li>
                            Si vous étiez une étoile du ciel, laquelle seriez-vous et pourquoi ?
                        </li>
                    </ol>
                    Ces questions peuvent être une façon amusante et créative d'en apprendre
                    davantage sur la personne âgée que vous interviewez. Cela peut également
                    susciter des conversations intéressantes et permettre à la personne de partager
                    des aspects différents de sa personnalité. N'oubliez pas d'encourager la
                    personne à expliquer pourquoi elle a choisi chaque réponse.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title6'),
            subTitle: t('inspiNotebook.subTitle6'),
            element: (
                <p className="text">
                    Voici une liste de questions pour aider à identifier les centres d'intérêt et
                    les préférences d'une personne âgée qui est isolée et n'a pas de famille proche:
                    <ol>
                        <li>
                            Quels sont les passe-temps ou les activités que vous aimez faire lorsque
                            vous avez du temps libre ?
                        </li>
                        <li>
                            Avez-vous des livres, des films ou des séries télévisées préférés que
                            vous aimez regarder ou lire ?
                        </li>
                        <li>
                            Avez-vous des souvenirs d'activités ou de voyages que vous avez
                            particulièrement appréciés par le passé ?
                        </li>
                        <li>
                            Quels sont vos goûts en matière de musique ? Y a-t-il des genres ou des
                            artistes que vous appréciez particulièrement ?
                        </li>
                        <li>
                            Aimez-vous cuisiner ou essayer de nouvelles recettes ? Avez-vous des
                            plats préférés ?
                        </li>
                        <li>
                            Pouvez-vous me parler de vos centres d'intérêt culturels, comme les
                            musées, les expositions, le théâtre, etc. ?
                        </li>
                        <li>
                            Avez-vous des sujets ou des thèmes qui vous passionnent et que vous
                            aimez discuter avec d'autres personnes ?
                        </li>
                        <li>
                            Avez-vous des talents artistiques ou créatifs que vous aimez mettre en
                            pratique, comme la peinture, la sculpture, le tricot, etc. ?
                        </li>
                        <li>
                            Avez-vous des activités physiques que vous aimez faire pour rester en
                            forme ?
                        </li>
                        <li>
                            Avez-vous des intérêts en matière de bien-être, comme le yoga, la
                            méditation, la relaxation, etc. ?
                        </li>
                        <li>
                            Avez-vous des projets ou des rêves que vous aimeriez réaliser dans un
                            avenir proche ou lointain ?
                        </li>
                        <li>
                            Pouvez-vous me parler de votre routine quotidienne et des activités que
                            vous appréciez le plu
                        </li>
                        <li>
                            Avez-vous des animaux de compagnie ou des intérêts liés aux animaux ?
                        </li>
                        <li>
                            Avez-vous des préférences en matière de mode ou de décoration intérieure
                            ?
                        </li>
                        <li>
                            Aimez-vous participer à des activités sociales ou à des groupes
                            communautaires ?
                        </li>
                        <li>
                            Avez-vous des passions ou des intérêts qui vous ont marqué au fil des
                            ans et qui continuent de vous inspirer ?
                        </li>
                    </ol>
                    Ces questions visent à découvrir les centres d'intérêt et les préférences de la
                    personne âgée, ce qui peut ensuite servir de base pour recommander des
                    activités, des passe-temps ou des groupes sociaux qui correspondent à ses goûts.
                    Il est important de prendre en compte les réponses et de respecter les choix et
                    les préférences de la personne pour lui offrir des opportunités qui lui
                    apporteront de la joie et du bien-être.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title7'),
            subTitle: t('inspiNotebook.subTitle7'),
            element: (
                <p className="text">
                    Voici une liste de questions pour discuter de projets avec une personne âgée.
                    Ces questions peuvent aider à identifier les intérêts, les souhaits et les
                    objectifs de la personne, et à envisager des projets qui pourraient lui apporter
                    du plaisir et du sens :
                    <ol>
                        <li>
                            Y a-t-il quelque chose que vous avez toujours voulu apprendre ou
                            essayer, mais que vous n'avez pas eu l'occasion de faire jusqu'à présent
                            ?
                        </li>
                        <li>
                            Avez-vous des compétences ou des talents que vous aimeriez développer ou
                            mettre en pratique ?
                        </li>
                        <li>
                            Quels sont vos passe-temps préférés ? Y a-t-il des façons d'approfondir
                            ou d'explorer ces intérêts davantage ?
                        </li>
                        <li>
                            Avez-vous des projets de voyage ou de visites que vous aimeriez
                            réaliser, que ce soit dans votre région ou à l'étranger ?
                        </li>
                        <li>
                            Avez-vous envisagé de participer à des activités sociales ou à des
                            groupes qui partagent vos intérêts ?
                        </li>
                        <li>
                            Avez-vous des idées de projets créatifs ou artistiques que vous aimeriez
                            réaliser ?
                        </li>
                        <li>
                            Avez-vous des envies de bénévolat ou d'engagement dans votre communauté
                            locale ?
                        </li>
                        <li>
                            Y a-t-il des histoires ou des expériences que vous aimeriez partager
                            avec votre famille ou vos proches ?
                        </li>
                        <li>
                            Avez-vous des idées pour contribuer à des causes qui vous tiennent à
                            cœur ?
                        </li>
                        <li>
                            Avez-vous des projets de rénovation ou de décoration chez vous qui vous
                            tiennent à cœur ?
                        </li>
                        <li>
                            Avez-vous des objectifs de bien-être physique ou mental que vous
                            aimeriez atteindre ?
                        </li>
                        <li>
                            Avez-vous des envies de voyages virtuels ou d'exploration en ligne,
                            comme des visites de musées virtuelles ou des cours en ligne ?
                        </li>
                        <li>
                            Y a-t-il des livres, des films ou des sujets que vous aimeriez explorer
                            davantage ?
                        </li>
                        <li>
                            Avez-vous des idées pour documenter ou partager vos expériences et vos
                            connaissances, comme l'écriture d'un journal, la création d'un blog ou
                            la réalisation de vidéos ?
                        </li>
                        <li>
                            Avez-vous des projets de jardinage ou d'horticulture qui vous
                            intéressent ?
                        </li>
                        <li>
                            Avez-vous des idées pour contribuer à la préservation de l'environnement
                            ou de la nature dans votre région ?
                        </li>
                    </ol>
                    N'oubliez pas d'adapter ces questions en fonction des intérêts spécifiques de la
                    personne que vous interviewez. L'objectif est de susciter des discussions
                    constructives et d'aider à identifier des projets qui apporteront de la joie et
                    du sens à sa vie.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title8'),
            subTitle: t('inspiNotebook.subTitle8'),
            element: (
                <p className="text">
                    Voici des exemples de questions que vous pourriez poser lors de chaque phase de
                    l'interview :
                    <br />
                    <br />
                    <strong>Étape 1 : Préparation</strong>
                    <br />
                    <ul>
                        <li>
                            Avez-vous des documents ou des photos que vous aimeriez partager pour
                            illustrer votre histoire ?
                        </li>
                        <li>
                            Y a-t-il des sujets que vous préférez éviter ou des souvenirs sensibles
                            que nous devrions prendre en compte ?
                        </li>
                    </ul>
                    <strong>Étape 2 : Créer un environnement confortable</strong>
                    <br />
                    <ul>
                        <li>
                            Êtes-vous à l'aise dans cet environnement ? Y a-t-il quelque chose que
                            nous pourrions faire pour rendre l'endroit plus confortable pour vous ?
                        </li>
                        <li>
                            Avez-vous besoin de faire une pause ou de prendre un moment pour vous
                            ressourcer ?
                        </li>
                    </ul>
                    <strong>Étape 3 : Questions d'introduction</strong>
                    <br />
                    <ol>
                        <li>Nom et prénom : </li>
                        <ul>
                            <li>
                                Pourriez-vous me dire comment vous vous appelez et si vous avez un
                                surnom que vous aimez ?
                            </li>
                        </ul>
                        <li>Lieu et date de naissance : </li>
                        <ul>
                            <li>
                                Pouvez-vous me raconter un peu l'endroit où vous êtes né(e) et ce
                                dont vous vous souvenez de votre enfance là-bas ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 4 : L'enfance et l'adolescence</strong>
                    <br />
                    <ol>
                        <li>Pouvez-vous me parler de votre enfance ? : </li>
                        <ul>
                            <li>
                                Quelles étaient vos activités préférées quand vous étiez enfant ?
                            </li>
                            <li>
                                Avez-vous des souvenirs spéciaux avec vos frères et sœurs ou
                                d'autres membres de votre famille ?
                            </li>
                        </ul>
                        <li>Avez-vous des souvenirs particuliers de votre adolescence ? : </li>
                        <ul>
                            <li>
                                Y a-t-il des moments forts ou des événements qui vous ont marqué
                                pendant cette période ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 5 : La vie adulte</strong>
                    <br />
                    <ol>
                        <li>Quels étaient vos intérêts et vos passions en tant qu'adulte ? : </li>
                        <ul>
                            <li>
                                Pouvez-vous me parler des activités que vous aimiez faire pendant
                                vos années d'adulte ?
                            </li>
                            <li>
                                Avez-vous eu des hobbies ou des loisirs qui ont joué un rôle
                                important dans votre vie ?
                            </li>
                        </ul>
                        <li>Pouvez-vous me parler de votre vie professionnelle ? : </li>
                        <ul>
                            <li>Quels étaient vos emplois et vos carrières au fil des ans ?</li>
                            <li>
                                Y a-t-il un travail ou un moment particulier dans votre carrière qui
                                vous a marqué ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 6 : La famille et les relations</strong>
                    <br />
                    <ol>
                        <li>Avez-vous des enfants ? Pouvez-vous me parler d'eux ? : </li>
                        <ul>
                            <li>Avez-vous des souvenirs spéciaux avec vos enfants ?</li>
                            <li>
                                Y a-t-il des valeurs ou des leçons que vous avez cherché à
                                transmettre à vos enfants ?
                            </li>
                        </ul>
                        <li>Avez-vous des petits-enfants ? : </li>
                        <ul>
                            <li>Quels sont vos souvenirs préférés avec vos petits-enfants ?</li>
                        </ul>
                    </ol>
                    <strong>Étape 7 : Les voyages et les loisirs</strong>
                    <br />
                    <ol>
                        <li>Avez-vous voyagé ? Quels sont les endroits qui vous ont marqué ? : </li>
                        <ul>
                            <li>
                                Pouvez-vous me raconter l'un de vos voyages les plus mémorables ?
                            </li>
                            <li>Y a-t-il un endroit que vous avez toujours rêvé de visiter ?</li>
                        </ul>
                        <li>Quels étaient vos passe-temps et loisirs préférés ? : </li>
                        <ul>
                            <li>
                                Quelles étaient vos activités préférées pour vous détendre ou vous
                                amuser ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 8 : Les moments marquants</strong>
                    <br />
                    <ol>
                        <li>
                            Pouvez-vous me parler d'un moment particulièrement marquant de votre vie
                            ? :{' '}
                        </li>
                        <ul>
                            <li>
                                Qu'est-ce qui a rendu ce moment si spécial ou mémorable pour vous ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 9 : Les conseils pour la génération future</strong>
                    <br />
                    <ol>
                        <li>
                            Avez-vous des conseils ou des leçons de vie à partager avec les
                            générations futures ? :{' '}
                        </li>
                        <ul>
                            <li>
                                Y a-t-il des valeurs ou des enseignements que vous aimeriez
                                transmettre aux générations à venir ?
                            </li>
                        </ul>
                    </ol>
                    <strong>Étape 10 : Clôture de l'interview</strong>
                    <br />
                    <ol>
                        <li>Y a-t-il quelque chose que vous aimeriez ajouter ou partager ? : </li>
                        <ul>
                            <li>
                                Est-ce qu'il y a un message que vous aimeriez laisser pour les
                                générations futures ou pour ceux qui écouteront votre histoire ?
                            </li>
                        </ul>
                    </ol>
                    N'oubliez pas d'adapter les questions en fonction de la personne que vous
                    interviewez et de toujours respecter sa sensibilité et son rythme. J'espère que
                    cela vous aide dans votre projet ! Si vous avez besoin de plus d'aide, n'hésitez
                    pas à me demander.
                    <br />
                    <br />
                    <br />
                    Voici une autre façon d’aborder le parcours de vie d’une personne :
                    <ol>
                        <li>
                            <strong>Enfance et Famille :</strong>
                        </li>
                        <ul>
                            <li>
                                Pouvez-vous me parler de votre enfance ? Où êtes-vous né(e) et quels
                                sont vos premiers souvenirs ?
                            </li>
                            <li>
                                Avez-vous des frères et sœurs ? Pouvez-vous me parler de votre
                                relation avec eux ?
                            </li>
                            <li>
                                Quels sont les souvenirs spéciaux que vous avez de vos parents ?
                            </li>
                        </ul>
                        <li>
                            <strong>Éducation et Jeunesse :</strong>
                        </li>
                        <ul>
                            <li>
                                Quelle était votre éducation et vos premières années à l'école ?
                            </li>
                            <li>
                                Y a-t-il un enseignant ou un mentor qui vous a particulièrement
                                marqué ?
                            </li>
                            <li>
                                Avez-vous des souvenirs d'événements marquants pendant votre
                                jeunesse ?
                            </li>
                        </ul>
                        <li>
                            <strong>Vie Adulte et Carrière :</strong>
                        </li>
                        <ul>
                            <li>
                                Pouvez-vous me parler de votre vie professionnelle et de vos
                                différents emplois ?
                            </li>
                            <li>Quel a été le travail le plus mémorable que vous ayez eu ?</li>
                            <li>
                                Avez-vous des réalisations professionnelles dont vous êtes
                                particulièrement fier(e) ?
                            </li>
                        </ul>
                        <li>
                            <strong>Relations et Famille :</strong>
                        </li>
                        <ul>
                            <li>
                                Avez-vous des enfants ? Pouvez-vous me parler d'eux et de votre
                                relation avec eux ?
                            </li>
                            <li>
                                Y a-t-il des moments spéciaux que vous avez partagés avec votre
                                famille ?
                            </li>
                            <li>
                                Avez-vous des petits-enfants ? Quels sont vos souvenirs préférés
                                avec eux ?
                            </li>
                        </ul>
                        <li>
                            <strong>Loisirs et Passions :</strong>
                        </li>
                        <ul>
                            <li>Quels étaient vos passe-temps et loisirs préférés ?</li>
                            <li>
                                Avez-vous des activités ou des hobbies qui ont joué un rôle
                                important dans votre vie ?
                            </li>
                            <li>Y a-t-il des voyages mémorables que vous avez faits ?</li>
                        </ul>
                        <li>
                            <strong>Moments Marquants :</strong>
                        </li>
                        <ul>
                            <li>
                                Pouvez-vous me parler d'un moment particulièrement mémorable de
                                votre vie ?
                            </li>
                            <li>
                                Y a-t-il un événement ou une expérience qui vous a beaucoup
                                influencé(e) ?
                            </li>
                        </ul>
                        <li>
                            <strong>Conseils et Enseignements :</strong>
                        </li>
                        <ul>
                            <li>
                                Avez-vous des conseils ou des leçons de vie que vous aimeriez
                                transmettre aux générations futures ?
                            </li>
                            <li>
                                Quelles valeurs sont importantes pour vous et que vous aimeriez voir
                                perpétuées ?
                            </li>
                        </ul>
                        <li>
                            <strong>Projets et Rêves :</strong>
                        </li>
                        <ul>
                            <li>
                                Avez-vous des projets ou des rêves que vous aimeriez encore réaliser
                                ?
                            </li>
                            <li>
                                Y a-t-il quelque chose que vous aimeriez accomplir ou voir se
                                réaliser dans votre vie ?
                            </li>
                        </ul>
                        <li>
                            <strong>Questions Finales :</strong>
                        </li>
                        <ul>
                            <li>Y a-t-il quelque chose que vous aimeriez ajouter ou partager ?</li>
                            <li>
                                Aimeriez-vous transmettre un message particulier à vos proches ?
                            </li>
                        </ul>
                    </ol>
                    Ces questions devraient permettre au professionnel médico-social d'engager une
                    conversation approfondie avec la personne âgée et de retracer ses mémoires de
                    manière significative. Il est important d'écouter attentivement et de respecter
                    les réponses et les émotions de la personne tout au long de l'entretien.
                    <br />
                    <br />
                    Voici quelques idées concrètes de photos et de vidéos que vous pouvez récupérer
                    pour enrichir la biographie multimédia de la personne.
                    <br />
                    <br />
                    <strong>Introduction</strong>
                    <ol>
                        <li>Nom et prénom : </li>
                        <ul>
                            <li>Image : Une photo de la personne âgée en train de se présenter.</li>
                            <li>
                                Vidéo : Un court clip où la personne se présente et raconte son nom.
                            </li>
                        </ul>
                        <li>Lieu et date de naissance : </li>
                        <ul>
                            <li>
                                Image : Une photo de l'endroit où la personne est née, si
                                disponible.
                            </li>
                            <li>
                                Vidéo : Des images d'archives (si disponibles) de la région où la
                                personne est née, avec une narration de la personne.
                            </li>
                        </ul>
                    </ol>
                    <strong>L'enfance et l'adolescence</strong>
                    <ol>
                        <li>Pouvez-vous me parler de votre enfance ? : </li>
                        <ul>
                            <li>
                                Image : Des photos d'enfance de la personne avec sa famille, ses
                                amis, ses activités préférées.
                            </li>
                            <li>
                                Vidéo : Des clips de famille montrant la personne en train de jouer,
                                de faire des activités de son enfance.
                            </li>
                        </ul>
                        <li>Avez-vous des souvenirs particuliers de votre adolescence ? : </li>
                        <ul>
                            <li>
                                Image : Des photos de la personne pendant son adolescence, des
                                événements ou des lieux significatifs de cette période.
                            </li>
                            <li>
                                Vidéo : Des extraits vidéo d'événements ou de moments marquants de
                                l'adolescence.
                            </li>
                        </ul>
                    </ol>
                    <strong>La vie adulte</strong>
                    <ol>
                        <li>Quels étaient vos intérêts et vos passions en tant qu'adulte ? : </li>
                        <ul>
                            <li>
                                Image : Des photos montrant la personne engagée dans ses passions et
                                intérêts, que ce soit des passe-temps, des voyages, etc.
                            </li>
                            <li>
                                Vidéo : Des clips vidéo de la personne en train de pratiquer ses
                                passe-temps ou de parler de ses intérêts.
                            </li>
                        </ul>
                        <li>Pouvez-vous me parler de votre vie professionnelle ? : </li>
                        <ul>
                            <li>
                                Image : Des photos liées à la carrière de la personne, comme des
                                portraits au travail, des événements professionnels, etc.
                            </li>
                            <li>
                                Vidéo : Des clips de la personne parlant de son parcours
                                professionnel, accompagnés d'images pertinentes.
                            </li>
                        </ul>
                    </ol>
                    <strong>La famille et les relations</strong>
                    <ol>
                        <li>Avez-vous des enfants ? Pouvez-vous me parler d'eux ? : </li>
                        <ul>
                            <li>
                                Image : Des photos de famille avec les enfants de la personne, des
                                moments spéciaux partagés avec eux.
                            </li>
                            <li>
                                Vidéo : Des clips vidéo de la personne avec ses enfants, partageant
                                des moments mémorables.
                            </li>
                        </ul>
                        <li>Avez-vous des petits-enfants ? : </li>
                        <ul>
                            <li>
                                Image : Des photos de la personne avec ses petits-enfants, des
                                souvenirs de moments passés ensemble.
                            </li>
                            <li>
                                Vidéo : Des clips de la personne avec ses petits-enfants, partageant
                                des activités ou des histoires.
                            </li>
                        </ul>
                    </ol>
                    <strong>Les voyages et les loisirs</strong>
                    <ol>
                        <li>Avez-vous voyagé ? Quels sont les endroits qui vous ont marqué ? : </li>
                        <ul>
                            <li>
                                Image : Des photos de voyages, des lieux qui ont eu un impact sur la
                                personne.
                            </li>
                            <li>
                                Vidéo : Montage vidéo de clips de voyages, accompagné de la
                                narration de la personne.
                            </li>
                        </ul>
                        <li>Quels étaient vos passe-temps et loisirs préférés ? : </li>
                        <ul>
                            <li>
                                Image : Des photos de la personne en train de pratiquer ses
                                passe-temps favoris.
                            </li>
                            <li>
                                Vidéo : Des clips de la personne en train de s'adonner à ses loisirs
                                préférés.
                            </li>
                        </ul>
                    </ol>
                    <strong>Les moments marquants</strong>
                    <ol>
                        <li>
                            Pouvez-vous me parler d'un moment particulièrement marquant de votre vie
                            ? :{' '}
                        </li>
                        <ul>
                            <li>
                                Image : Des photos ou des captures d'écran de ce moment particulier.
                            </li>
                            <li>
                                Vidéo : Des clips vidéo ou des images animées (si disponibles)
                                relatifs à ce moment mémorable.
                            </li>
                        </ul>
                    </ol>
                    <strong>Les conseils pour la génération future</strong>
                    <ol>
                        <li>
                            Avez-vous des conseils ou des leçons de vie à partager avec les
                            générations futures ? :{' '}
                        </li>
                        <ul>
                            <li>Image : Une photo de la personne avec un message de conseil.</li>
                            <li>Vidéo : Un clip vidéo de la personne partageant ses conseils.</li>
                        </ul>
                    </ol>
                    N'oubliez pas de toujours obtenir le consentement de la personne âgée et de
                    respecter sa vie privée lors de la collecte et de l'utilisation de ces images et
                    vidéos. Cela peut être une bonne façon d'enrichir la biographie multimédia et de
                    la rendre plus vivante et engageante.
                </p>
            )
        },
        {
            title: t('inspiNotebook.title9'),
            subTitle: t('inspiNotebook.subTitle9'),
            element: (
                <p className="text">
                    Janvier :
                    <ul>
                        <li>Paysages enneigés</li>
                        <li>Feux d'artifice du Nouvel An</li>
                        <li>Célébrations publiques du Nouvel An</li>
                        <li>Sports d'hiver (ski, patinage, raquettes, luge)</li>
                        <li>Promenades hivernales dans la nature</li>
                        <li>Préparation de plats réconfortants</li>
                        <li>Séances de cocooning à la maison (lecture, films, etc.)</li>
                        <li>Résolutions du Nouvel An et planification de l'année</li>
                    </ul>
                    Février :
                    <ul>
                        <li>Célébration de la Saint-Valentin</li>
                        <li>Moments de tendresse entre amis ou proches</li>
                        <li>Images de plats chauds et réconfortants</li>
                        <li>Carnaval ou fêtes locales</li>
                        <li>Création de cartes de vœux ou de petits cadeaux faits main</li>
                        <li>Soirées jeux de société en famille ou entre amis</li>
                        <li>Sorties au cinéma pour découvrir les nouveaux films</li>
                        <li>Excursion dans un café ou salon de thé chaleureux</li>
                    </ul>
                    Mars :
                    <ul>
                        <li>Bourgeons et fleurs qui commencent à apparaître</li>
                        <li>Activités de plein air avec l'arrivée du printemps</li>
                        <li>Jardinage et préparation des semis</li>
                        <li>Célébration de la Journée de la Femme (sans connotation religieuse)</li>
                        <li>Exploration de parcs ou jardins botaniques</li>
                        <li>Sortie culturelle (musée, galerie d'art, etc.)</li>
                        <li>Participation à des ateliers créatifs (peinture, poterie, etc.)</li>
                        <li>Journée dédiée à la détente et au bien-être (spa, yoga, méditation)</li>
                    </ul>
                    Avril :
                    <ul>
                        <li>Chasse aux œufs de Pâques</li>
                        <li>Fleurs en pleine floraison</li>
                        <li>Activités de nettoyage de printemps</li>
                        <li>Journées de pluie et atmosphère paisible</li>
                        <li>Excursions à vélo ou à pied pour profiter du beau temps</li>
                        <li>Séance de shopping pour actualiser sa garde-robe de printemps</li>
                        <li>Dégustation de mets printaniers et de nouvelles saveurs</li>
                        <li>Sorties pour observer la faune et la flore locales</li>
                    </ul>
                    Mai :
                    <ul>
                        <li>Journées ensoleillées dans le parc</li>
                        <li>Fêtes de quartier ou festivités locales</li>
                        <li>Commencement de l'activité touristique</li>
                        <li>Fête du Travail et défilés</li>
                        <li>
                            Pratique de sports ou activités physiques en extérieur (jogging, vélo,
                            etc.)
                        </li>
                        <li>Organisation d'un pique-nique entre amis ou en famille</li>
                        <li>Découverte de nouvelles recettes à base de produits de saison</li>
                        <li>Participation à des événements sportifs locaux</li>
                    </ul>
                    Juin :
                    <ul>
                        <li>Début de l'été et premiers jours à la plage</li>
                        <li>Fête de la Musique (selon les pays)</li>
                        <li>Sorties en plein air et pique-niques</li>
                        <li>Célébrations de fin d'année scolaire (pour les écoles)</li>
                        <li>Découverte de nouvelles plages ou lieux de villégiature</li>
                        <li>Organisation d'un barbecue entre amis ou en famille</li>
                        <li>Participation à des festivals de musique ou d'arts locaux</li>
                        <li>Exploration de sentiers de randonnée ou de pistes cyclables</li>
                    </ul>
                    Juillet :
                    <ul>
                        <li>Feux d'artifice pour la fête nationale (selon les pays)</li>
                        <li>Pique-niques et barbecues en plein air</li>
                        <li>Journées à la plage et activités nautiques</li>
                        <li>Festivals d'été et concerts en plein air</li>
                        <li>Découverte de nouveaux parcs d'attractions ou de parcs à thème</li>
                        <li>Organisation de soirées à thème entre amis ou en famille</li>
                        <li>
                            Participation à des événements sportifs estivaux (tournois,
                            compétitions, etc.)
                        </li>
                        <li>
                            Excursions en bateau ou en kayak pour explorer les cours d'eau locaux
                        </li>
                    </ul>
                    Août :
                    <ul>
                        <li>Vacances d'été à la plage ou à la montagne</li>
                        <li>Couchers de soleil spectaculaires</li>
                        <li>Excursions en plein air et randonnées</li>
                        <li>Festivals gastronomiques ou culturels</li>
                        <li>Dégustation de produits régionaux ou de spécialités locales</li>
                        <li>Soirées cinéma en plein air</li>
                        <li>Sorties pour observer les étoiles ou les météores (perséides)</li>
                        <li>Participation à des événements culturels ou artistiques locaux</li>
                    </ul>
                    Septembre :
                    <ul>
                        <li>Fruits et légumes de saison dans les marchés</li>
                        <li>Rentrée scolaire et ambiance de retour à l'école</li>
                        <li>Randonnées en montagne et paysages automnaux</li>
                        <li>
                            Journées de sensibilisation à diverses causes (sans connotation
                            religieuse)
                        </li>
                        <li>Découverte de nouveaux cafés ou restaurants locaux</li>
                        <li>Participation à des ateliers de cuisine ou de pâtisserie</li>
                        <li>Sorties pour observer la migration des oiseaux</li>
                        <li>Pratique de sports d'équipe ou d'activités collectives</li>
                    </ul>
                    Octobre :
                    <ul>
                        <li>Halloween et costumes créatifs</li>
                        <li>Feuilles d'automne et paysages colorés</li>
                        <li>Fêtes de la récolte ou festivals de la citrouille</li>
                        <li>Ambiance chaleureuse et décoration d'intérieur</li>
                        <li>
                            Sorties pour visiter des lieux hantés ou des maisons hantées (si cela
                            convient à la personne)
                        </li>
                        <li>Dégustation de vins ou de cidres de saison</li>
                        <li>Excursions pour cueillir des pommes ou des citrouilles</li>
                        <li>Participation à des événements artistiques ou artisanaux locaux</li>
                    </ul>
                    Novembre :
                    <ul>
                        <li>Ambiance chaleureuse et feutrée à la maison</li>
                        <li>Journées douillettes sous une couverture</li>
                        <li>Activités de préparation pour les fêtes de fin d'année</li>
                        <li>Journée mondiale de la gentillesse</li>
                        <li>Sorties pour admirer les migrations d'oiseaux</li>
                        <li>Participation à des ateliers de création de décorations de Noël</li>
                        <li>Visite de marchés de Noël ou de foires artisanales locales</li>
                        <li>Excursions pour découvrir des musées ou des expositions temporaires</li>
                    </ul>
                    Décembre :
                    <ul>
                        <li>Décorations de Noël et sapins illuminés</li>
                        <li>Moments festifs et de partage pendant les fêtes de fin d'année</li>
                        <li>Marchés de Noël et ambiance festive dans les rues</li>
                        <li>Réflexion sur l'année écoulée et projets pour la nouvelle année</li>
                        <li>Organisation d'une soirée de réveillon ou d'un repas festif</li>
                        <li>Participation à des chorales ou des concerts de Noël</li>
                        <li>Excursions pour visiter des crèches de Noël traditionnelles</li>
                        <li>Dégustation de mets et de pâtisseries festives</li>
                    </ul>
                </p>
            )
        },
        {
            title: t('inspiNotebook.title10'),
            subTitle: t('inspiNotebook.subTitle10'),
            element: (
                <p className="text">
                    Voici une liste de questions originales qui peuvent vous aider à dresser un
                    portrait intéressant et unique de quelqu'un :
                    <ol>
                        <li>Quel est le dernier rêve dont vous vous souvenez ?</li>
                        <li>
                            Si vous pouviez avoir un dîner en tête-à-tête avec n'importe quelle
                            personne, vivante ou décédée, qui choisiriez-vous et pourquoi ?
                        </li>
                        <li>
                            Si vous pouviez maîtriser instantanément une nouvelle compétence,
                            laquelle choisiriez-vous ?
                        </li>
                        <li>
                            Quel est le voyage le plus mémorable que vous ayez jamais fait et
                            qu'avez-vous appris de cette expérience ?
                        </li>
                        <li>
                            Quelle est la chanson qui vous met toujours de bonne humeur, même les
                            jours difficiles ?
                        </li>
                        <li>
                            Si vous pouviez vivre à n'importe quelle époque de l'histoire, laquelle
                            choisiriez-vous et pourquoi ?
                        </li>
                        <li>
                            Quel est le meilleur conseil que vous ayez jamais reçu et qui vous a
                            marqué ?
                        </li>
                        <li>
                            Si vous pouviez avoir un animal exotique comme animal de compagnie,
                            lequel choisiriez-vous et pourquoi ?
                        </li>
                        <li>
                            Quelle est votre saison préférée et qu'est-ce que vous appréciez le plus
                            à ce sujet ?
                        </li>
                        <li>
                            Si vous deviez écrire un livre, de quel genre serait-il et de quoi
                            parlerait-il ?
                        </li>
                        <li>
                            Quel est votre endroit préféré dans le monde et qu'est-ce qui le rend si
                            spécial pour vous ?
                        </li>
                        <li>
                            Quelle est la chose la plus courageuse que vous ayez jamais faite dans
                            votre vie ?
                        </li>
                        <li>
                            Si vous pouviez vivre dans un univers fictif (film, livre, jeu vidéo),
                            lequel choisiriez-vous ?
                        </li>
                        <li>
                            Quel est le repas que vous pourriez manger tous les jours sans jamais
                            vous lasser ?
                        </li>
                        <li>
                            Si vous pouviez être témoin d'un événement historique, lequel
                            choisiriez-vous ?
                        </li>
                    </ol>
                    Ces questions originales peuvent conduire à des réponses fascinantes et aider à
                    mieux comprendre la personne que vous interviewez. N'hésitez pas à personnaliser
                    ou à ajouter des questions en fonction de la personne et de ses intérêts
                    spécifiques.
                </p>
            )
        }
    ];

    useEffect(() => {
        if (step === 1) {
            setNotebookIndexLookUp(0);
        }
        // set scroll to top for inspi-notebook
        const element = document.getElementById('inspi-notebook');
        if (element) element.scrollTop = 0;
    }, [step]);

    return (
        <div
            id="inspi-notebook"
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%',
                position: 'relative'
            }}>
            {step === 1 && (
                <>
                    <Typography
                        variant="p"
                        sx={{
                            textAlign: 'left',
                            color: '#6B6B6B',
                            fontWeight: 500,
                            margin: '2.38rem 0'
                        }}>
                        {t('inspiNotebook.description')}
                    </Typography>
                    <div style={{ ...classes['container'] }}>
                        {notebook.map((item, index) => (
                            <div
                                style={{ ...classes['notebook-container'] }}
                                onClick={() => {
                                    setNotebookIndexLookUp(index);
                                    setStep(2);
                                }}>
                                <div style={{ ...classes['title-container'] }}>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            textAlign: 'left',
                                            // fontWeight: 500,
                                            fontSize: '1rem'
                                        }}>
                                        {item.title}
                                    </Typography>
                                    <Typography
                                        variant="p"
                                        sx={{
                                            textAlign: 'left',
                                            color: '#6B6B6B',
                                            fontWeight: 500,
                                            fontSize: '0.825rem'
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: `${item.subTitle}`
                                        }}
                                        children={undefined}
                                    />
                                </div>
                                <img
                                    src={backArrow}
                                    style={{
                                        height: '1rem',
                                        width: 'auto',
                                        rotate: '-90deg'
                                    }}
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
            {step === 2 && notebookIndexLookUp !== undefined && (
                <>
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: 'left',

                            // fontWeight: 500,
                            fontSize: '1.5rem'
                        }}>
                        {notebook[notebookIndexLookUp].title}
                    </Typography>
                    <Typography
                        variant="p"
                        sx={{
                            textAlign: 'left',
                            width: '100%'
                            // color: '#6B6B6B',
                            // fontWeight: 500,
                            // fontSize: '0.825rem'
                        }}
                        dangerouslySetInnerHTML={{
                            __html: `${notebook[notebookIndexLookUp].subTitle}`
                        }}
                        children={undefined}
                    />
                    {notebook[notebookIndexLookUp].element}
                </>
            )}
        </div>
    );
}
