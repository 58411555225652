import React, { CSSProperties } from 'react';
import rings from '../assets/rings.svg';

const classes: { [key: string]: CSSProperties } = {};

interface Props {
    disableBg?: boolean;
    sx?: CSSProperties;
}

export function Loader({disableBg, sx}: Props): JSX.Element {
    return (
        <div
            style={{
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                backgroundColor: disableBg ? 'none' : 'rgba(0,0,0,0.5)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 100,
                ...sx
            }}>
            <img src={rings} alt="loader" style={{ width: '5rem' }} />
        </div>
    );
}
