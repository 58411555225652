import React, { CSSProperties, ReactElement } from 'react';
import '../utilities/utils.css';
import calendar from '../assets/calendar.png';
import { PatternFormat } from 'react-number-format';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '0.5rem'
    },
    'input-root': {
        padding: '1rem',
        // backgroundColor: 'var(--color-white)',
        color: 'var(--textarea-text-color)',
        width: '100%',
        border: '1px solid var(--textarea-border-color)',
        fontSize: '1rem',
        fontFamily: 'var(--ion-font-family)',
        borderRadius: '0.5rem',
        position: 'relative'
        // outline: 'none',

        // WebkitBorderRadius: '5px',
        // MozBorderRadius: '5px',
        // borderRadius: '5px',
        // border: '1px solid #8e8e8e',
        // backgroundColor: '#f5f5f5',
        // height: '16px',
        // padding: '4px',
        // color: '#4a4a4a',
        // float: 'left',
        // width: '100%',
    },
    'input-button': {
        width: '1.5rem',
        height: '1.5rem',
        backgroundColor: 'var(--color-primary)',
        border: 'none',
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        padding: '0px'
        // margin: '0 0 0 3rem'

        // border: '0',
        // padding: '0',
        // margin: '0 0 0 24px',
        // width: '24px',
        // height: '24px',
        // float: 'left',
    },
    'label-root': {
        color: 'var(--color-primary)',
        textAlign: 'left',
        width: '100%',
        paddingLeft: '.5rem',
        fontSize: '1rem',
        fontWeight: '600',
        fontFamily: 'var(--ion-font-family)'
    },
    'desc-root': {
        color: 'var(--color-primary)',
        textAlign: 'left',
        width: '100%',
        margin: '0',
        paddingLeft: '.5rem',
        fontSize: '0.8rem',
        fontWeight: '400'
    },
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

interface Props {
    pink?: boolean;
    value?: string;
    sx?: CSSProperties;
    sxLabel?: CSSProperties;
    sxDesc?: CSSProperties;
    sxRoot?: CSSProperties;
    sxBtnClick?: CSSProperties;
    onChange?: (value: string) => void;
    onBlur?: (value: string) => void;
    btnClick?: () => void;
    placeholder?: string;
    label?: string;
    labelStar?: boolean;
    labelPlaceholder?: boolean;
    desc?: string;
    error?: boolean;
    imgIntoInput?: string;
    type?: string;
    disabled?: boolean;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    multiple?: boolean;
    accept?: string;
    maxLength?: number;
    format?: string;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export function Input({
    pink,
    value,
    sx,
    sxLabel,
    sxDesc,
    sxRoot,
    sxBtnClick,
    onChange,
    onBlur,
    btnClick,
    placeholder,
    label,
    labelStar,
    labelPlaceholder,
    desc,
    error,
    imgIntoInput,
    type,
    disabled,
    ref,
    multiple,
    accept,
    maxLength,
    format,
    onKeyDown
}: Props): JSX.Element {
    const [inputFocus, setInputFocus] = React.useState(false);
    return !labelPlaceholder ? (
        <div style={{ ...classes['div-root'], ...sxRoot }}>
            {label !== undefined && (
                <label
                    style={{
                        ...classes['label-root'],
                        ...(pink && inputFocus ? { color: 'var(--button-bg-color-pink)' } : {}),
                        ...sxLabel
                    }}
                    htmlFor={label}>
                    {label}
                    {labelStar && <span style={{ color: 'var(--color-error)' }}>&nbsp;*</span>}
                </label>
            )}
            {desc !== undefined && <p style={{ ...classes['desc-root'], ...sxDesc }}>{desc}</p>}
            <div style={{ position: 'relative', width: '100%' }}>
                {format ? (
                    <PatternFormat
                        format={format}
                        style={{
                            ...classes['input-root'],
                            ...{
                                cursor: disabled ? 'not-allowed' : '',
                                backgroundColor: disabled
                                    ? 'var(--input-bg-color-disabled)'
                                    : 'var(--color-white)',
                                paddingRight: imgIntoInput ? '3rem' : '1rem'
                            },
                            ...(error ? { borderColor: 'var(--color-error)' } : {}),
                            ...sx
                        }}
                        name={label}
                        value={value}
                        placeholder={placeholder}
                        onChange={(input): void => onChange && onChange(input.target.value)}
                    />
                ) : (
                    <input
                        onBlurCapture={(e) => {
                            if (onBlur) {
                                onBlur(e.target.value);
                            }
                        }}
                        disabled={disabled}
                        type={type}
                        name={label}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                        className={pink ? 'input-pink' : ''}
                        maxLength={maxLength}
                        style={{
                            ...classes['input-root'],
                            ...{
                                cursor: disabled ? 'not-allowed' : '',
                                backgroundColor: disabled
                                    ? 'var(--input-bg-color-disabled)'
                                    : 'var(--color-white)',
                                paddingRight: imgIntoInput ? '3rem' : '1rem'
                            },
                            ...(error ? { borderColor: 'var(--color-error)' } : {}),
                            ...sx
                        }}
                        value={value}
                        placeholder={placeholder}
                        onKeyDown={(e) => onKeyDown && onKeyDown(e)}

                        onChange={(e) => {
                            if (onChange) {
                                onChange(e.target.value);
                            }
                        }}
                        ref={ref}
                        multiple={multiple}
                        accept={accept}
                    />
                )}
                {btnClick && (
                    <input
                        disabled={disabled}
                        type="button"
                        value=""
                        onClick={btnClick}
                        style={{
                            ...classes['input-button'],
                            ...{ cursor: disabled ? 'not-allowed' : 'pointer' },
                            ...sxBtnClick,
                            background: `transparent url('${imgIntoInput}') center center  no-repeat`
                        }}
                    />
                )}
            </div>
        </div>
    ) : (
        <div className="container" style={{ fontFamily: 'var(--ion-font-family)', ...sxRoot }}>
            <div className="material-textfield">
                <input
                    placeholder={placeholder ?? ' '}
                    disabled={disabled}
                    type={type}
                    name={label}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    style={{
                        backgroundColor: disabled
                            ? 'var(--input-bg-color-disabled)'
                            : 'var(--color-white)',
                        fontFamily: 'var(--ion-font-family)'
                    }}
                    value={value}
                    maxLength={maxLength}
                    onChange={(e) => {
                        if (onChange) {
                            onChange(e.target.value);
                        }
                    }}
                    ref={ref}
                    multiple={multiple}
                    accept={accept}
                />
                <label>{label}</label>
            </div>
        </div>
    );
}
