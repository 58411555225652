import { useRef } from 'react';

import { pingpong } from '../../../models/Games';
import { GameProvider, GameRef } from '../../../providers/GameProvider';
import { PingPong } from '../../../components/PingPong';
import { CongratsWindow } from '../../../windows/Congrats';
import { FeedbackWindow } from '../../../windows/Feedback';
import { NextGameWindow } from '../../../windows/NextGame';
import CongratsSvg from '../assets/congrats.svg?react';
import EinsteinSvg from '../assets/einstein.svg?react';
import styles from '../assets/styles.module.css';

export default () => {
    const pingPongRef = useRef<GameRef>(null);

    const getTips = () => {
        if (!pingPongRef.current) return;
        pingPongRef.current?.tips();
    };

    const resetGame = () => {
        if (!pingPongRef.current) return;
        pingPongRef.current?.reset();
    }

    const colors = {
        primary: pingpong.color,
        secondary: pingpong.secondaryColor,
        border: pingpong.border,
    };

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            endWindows={[{
                customImage: { Image: CongratsSvg, className: styles.congrats },
                Component: CongratsWindow,
            }, {
                Component: FeedbackWindow,
            }, {
                customImage: { Image: EinsteinSvg, className: styles.einstein },
                Component: NextGameWindow,
            }]}
            background={pingpong.background}
        >
            <PingPong ref={pingPongRef} />
        </GameProvider>
    );
};
