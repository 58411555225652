import firestore, { Timestamp } from 'firebase/firestore';

export class User {
    id?: string | undefined;

    role?: 'PATIENT' | 'FAMILY' | 'THERAPIST' | 'ADMIN' | undefined;

    emailId?: string | undefined;

    firstName?: string | undefined | null;

    lastName?: string | undefined;

    dob?: Timestamp | undefined;

    isChangePasswordRequired?: boolean | undefined;

    profilePhoto?: string | File | undefined;

    createdDate?: Timestamp | null;

    userId?: string;

    familyCode?: string;

    relationship?: string;

    fonction?: string;

    establishmentCode?: string | undefined;

    establishmentName?: string | undefined;

    isSubscribed?: boolean | undefined;

    needDelete?: boolean | undefined;

    needViewOnboarding?: boolean | undefined;

    constructor({
        id,
        role,
        emailId,
        firstName,
        lastName,
        dob,
        isChangePasswordRequired,
        profilePhoto,
        createdDate,
        userId,
        familyCode,
        relationship,
        fonction,
        establishmentCode,
        establishmentName,
        isSubscribed,
        needDelete,
        needViewOnboarding
    }: {
        id?: string;
        role?: 'PATIENT' | 'FAMILY' | 'THERAPIST' | 'ADMIN';
        emailId?: string;
        lastName?: string;
        firstName?: string;
        dob?: Timestamp;
        isChangePasswordRequired?: boolean;
        profilePhoto?: string | File;
        createdDate?: Timestamp;
        userId?: string;
        familyCode?: string;
        relationship?: string;
        fonction?: string;
        establishmentCode?: string;
        establishmentName?: string;
        isSubscribed?: boolean;
        needDelete?: boolean;
        needViewOnboarding?: boolean;
    }) {
        this.id = id;
        this.role = role;
        this.emailId = emailId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.dob = dob;
        this.isChangePasswordRequired = isChangePasswordRequired;
        this.profilePhoto = profilePhoto;
        this.createdDate = createdDate;
        this.userId = userId;
        this.familyCode = familyCode;
        this.relationship = relationship;
        this.fonction = fonction;
        this.establishmentCode = establishmentCode;
        this.establishmentName = establishmentName;
        this.isSubscribed = isSubscribed;
        this.needDelete = needDelete;
        this.needViewOnboarding = needViewOnboarding;
    }
}

export const UserConverter: firestore.FirestoreDataConverter<User> = {
    toFirestore(user) {
        return {
            role: user.role,
            emailId: user.emailId,
            firstName: user.firstName,
            lastName: user.lastName,
            dob: user.dob,
            isChangePasswordRequired: user.isChangePasswordRequired,
            profilePhoto: user.profilePhoto,
            createdDate: user.createdDate,
            userId: user.userId,
            familyCode: user.familyCode,
            relationship: user.relationship,
            fonction: user.fonction,
            establishmentCode: user.establishmentCode,
            isSubscribed: user.isSubscribed,
            needDelete: user.needDelete,
            needViewOnboarding: user.needViewOnboarding
        };
    },
    fromFirestore(snapshot, options): User {
        const data = snapshot.data(options);
        return new User({
            id: snapshot.id,
            role: data.role,
            emailId: data.emailId,
            firstName: data.firstName,
            lastName: data.lastName,
            dob: data.dob,
            isChangePasswordRequired: data.isChangePasswordRequired,
            profilePhoto: data.profilePhoto,
            createdDate: data.createdDate,
            userId: data.userId,
            familyCode: data.familyCode,
            relationship: data.relationship,
            fonction: data.fonction,
            establishmentCode: data.establishmentCode,
            isSubscribed: data.isSubscribed,
            needDelete: data.needDelete,
            needViewOnboarding: data.needViewOnboarding
        });
    }
};
