import React, { useEffect, useState, useContext, CSSProperties, useImperativeHandle } from 'react';
import { Box, Card, CssBaseline, Grid, Paper, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLongPress } from 'use-long-press';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { SuccessModal } from './../../components/SuccessModal';
import { ExerciseItem } from '../../models/ExerciseItem';
import { getImageKitUrlFrom } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import question from '../../assets/question.svg';
import './QuizExercise2.css';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import '../../utilities/utils.css';
import { isPlatform } from '@ionic/core';
import { useGame } from '../../contexts/GameContext';
import Preview from '../../pages/games/Preview';
import CustomButton from '../Button/index';
import { Media } from '../../models/Media';

const ImageContainer = styled('img')({
    maxHeight: 'calc(100vh - 120px - 16vh - 16px)',
    maxWidth: 'calc(100vh - 120px - 16vh - 16px)',
    height: 'calc(50vw - 60px - 16px)',
    width: 'calc(50vw - 60px - 16px)',
    objectFit: 'cover'
});

interface LocationState {
    memoryLevel: number;
    exerciseItemList: Media[];
    currentExercise: Media;
    completedExercises: string[];
    setCompletedExercises: (completedExerciseId: string) => void;
    incrementErrorCount: () => void;
    incrementClueCount: () => void;
}

export const QuizExercise2 = React.forwardRef((props: LocationState, ref) => {
    const {
        memoryLevel,
        exerciseItemList,
        currentExercise,
        completedExercises,
        setCompletedExercises,
        incrementErrorCount,
        incrementClueCount
    } = props;
    const { t } = useTranslation();
    const { writeMessage, displayInstruction, showUi, hideUi } = useGame();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { id } = useParams<{ id: string }>();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [exerciseItem, setExerciseItem] = useState<Media | null>();
    const [removedOptionList, setRemovedOptionList] = useState<string[]>([]);
    const [answerList, setAnswerList] = useState<string[] | null>(null);
    const [isShuffled, setIsShuffled] = useState<boolean>(false);
    const [isAnimating, setIsAnimating] = useState<boolean>(false);
    const nbOfElementToDisplay = [3, 4, 4, 4, 4, 4, 5, 5, 6, 6];
    const [itemToShake, setItemToShake] = useState<string>('');
    const [isMemorization, setIsMemorization] = useState<boolean>(memoryLevel >= 6);
    const [answerListToDisplay, setAnswerListToDisplay] = useState<{ [key: string]: string }>();
    const [timerId, setTimerId] = useState<NodeJS.Timeout>();
    const { width } = useWindowDimensions();

    useImperativeHandle(ref, () => ({
        tips: () => handleClueClick(),
    }));

    useEffect(() => {
        if (isMemorization) {
            hideUi();
        }
        if (!isMemorization && completedExercises.length === 0) {
            displayInstruction();
        }
    }, [isMemorization]);

    useEffect(() => {
        let tmpTimerId;

        if (isMemorization) {
            tmpTimerId = setTimeout(() => {
                setIsMemorization(false);
            }, 10000);
            setTimerId(tmpTimerId);
        } else if (timerId && !isMemorization) {
            clearTimeout(timerId);
        }
    }, [isMemorization]);

    const getCharacter = (str: string, index: number): string => {
        const arrayCharNotMove = [
            '(',
            ')',
            "'",
            '"',
            ',',
            '.',
            '?',
            '!',
            ':',
            ';',
            '-',
            '~',
            '&',
            '[',
            ']',
            '{',
            '}',
            '°',
            '%',
            '€',
            '$',
            '£',
            '§',
            '²',
            '¤',
            '*',
            '+',
            '/',
            '=',
            '#',
            '@',
            'µ',
            '‘'
        ];
        if (arrayCharNotMove.includes(str)) {
            return str;
        }
        if (memoryLevel === 3 || memoryLevel === 7) {
            if (index % 2 === 0) {
                return str;
            }
            return '_';
        }
        if (memoryLevel === 4 || memoryLevel === 5 || memoryLevel === 8 || memoryLevel === 9) {
            if (index === 1) {
                return '_ ';
            }
            if (index === 2) {
                return '_';
            }
            if (index === 0 || index % 2 === 1) {
                return str;
            }
            return '_';
        }
        return '_';
    };

    const getItemToString = (word: string): string => {
        if (word && memoryLevel >= 3 && memoryLevel !== 6) {
            const wordWithHole = word
                .trim()
                .split(' ')
                .map((thisWord) => {
                    if (memoryLevel !== 10) {
                        return thisWord
                            .split('')
                            .map((letter, index) => {
                                return getCharacter(letter, index);
                            })
                            .join('');
                    }
                    if (memoryLevel === 10) {
                        if (thisWord.length <= 3) return thisWord;
                        // create var "thisWordWithUnsort" and set it to "thisWord" but unsorted
                        let thisWordWithUnsort = thisWord;
                        if (thisWord.length === 1) return thisWord;
                        while (thisWordWithUnsort === thisWord) {
                            thisWordWithUnsort = thisWord
                                .split('')
                                .sort(() => Math.random() - 0.5)
                                .join('');
                        }
                        return thisWordWithUnsort;
                    }
                    return '';
                })
                .join('\u00A0\u00A0\u00A0');
            return wordWithHole;
        }
        return word;
    };

    useEffect(() => {
        if (answerList && answerList?.length > 1 && !isShuffled) {
            const array = answerList;
            for (let i = array.length - 1; i > 0; i -= 1) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = array[i];
                array[i] = array[j];
                array[j] = temp;
            }
            setAnswerList(array);
            setIsShuffled(true);
        }

        if (answerList && answerList?.length > 1 && !answerListToDisplay) {
            let templist = {};
            answerList.forEach((item, index) => {
                templist = { ...templist, [item]: getItemToString(item) };
            });
            setAnswerListToDisplay(templist);
        }
    }, [answerList]);

    useEffect(() => {
        const list: string[] = [];
        if (
            exerciseItemList &&
            user &&
            (user.role === Roles.SENIOR || user.role === Roles.FAMILY)
        ) {
            exerciseItemList.forEach((item) => {
                if (item.id === id && item.title) {
                    list.push(item.title);
                }
            });
            if (currentExercise && currentExercise.title && memoryLevel === 5) {
                list.push(currentExercise.title);
            }
            exerciseItemList.forEach((item) => {
                if (
                    item.title &&
                    list.length < nbOfElementToDisplay[memoryLevel - 1] &&
                    item.id !== id &&
                    list.indexOf(item.title) === -1
                ) {
                    list.push(item.title);
                }
            });
            setAnswerList(list);
        }
        if (exerciseItemList && user && user.role === Roles.PRO) {
            exerciseItemList.forEach((item) => {
                if (item.title) {
                    list.push(item.title);
                }
            });
        }
        setAnswerList(list);
        if (currentExercise) {
            setExerciseItem(currentExercise);
        }
    }, []);

    const handleFormModal = (): void => {
        nextExercise();
    };

    const removeAnswerFromList = (answer: string): void => {
        if (answerList) {
            const index = answerList.indexOf(answer);
            if (index > -1) {
                answerList.splice(index, 1);
            }
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onOptionClick = async (answer: string, event: any): Promise<void> => {
        setIsAnimating(true);
        let tempSuccess = false;
        if (exerciseItem && answer === exerciseItem.title) {
            if (event.target.tagName === 'BUTTON') {
                // eslint-disable-next-line no-param-reassign
                event.target.style.backgroundColor = '#00DF76';
                event.target.children[0].style.color = '#FFFFFF';
                event.target.children[0].innerHTML = answer;
            } else {
                const parentButton = event.target.closest('button');
                // eslint-disable-next-line no-param-reassign
                parentButton.style.backgroundColor = '#00DF76';
                event.target.style.color = '#FFFFFF';
                event.target.innerHTML = answer;
            }
            setIsSuccess(true);
            tempSuccess = true;
        } else {
            incrementErrorCount();
            setIsSuccess(false);
            setRemovedOptionList([...removedOptionList, answer]);
            setItemToShake(answer);
            // stop shake
            setTimeout(() => {
                setItemToShake('');
            }, 3100);
            if (memoryLevel === 1) {
                setTimeout(() => {
                    removeAnswerFromList(answer);
                }, 3000);
            }
        }
        setTimeout(() => {
            if (tempSuccess) {
                writeMessage({ text: t('Bravo !'), color: 'success' });
            } else {
                writeMessage({ text: t('Retentez votre chance ! Choisissez une autre proposition.'), color: 'wrong' });
            }
            setOpenModal(true);
            setIsAnimating(false);
        }, 1000);
        setTimeout(() => {
            setOpenModal(false);
            if (openModal === false || isSuccess) {
                if (exerciseItem && answer === exerciseItem.title) {
                    handleFormModal();
                }
            }
        }, 6000);
    };

    const bindLongPress = useLongPress((event, index) => {
        if (answerList) {
            onOptionClick(answerList[Number(index.context)], event);
        }
    }, { threshold: 1000 });

    const getExercises = (): JSX.Element[] | null => {
        if (answerList) {
            return answerList.map((item, index) => {
                return (
                    <div key={index}>
                        {width && width < 480 ? (
                            <div
                                style={{
                                    display: 'flex',
                                    position: 'relative',
                                    alignContent: 'center'
                                }}>
                                <Button
                                    className={
                                        itemToShake === item
                                            ? memoryLevel === 1
                                                ? 'itemFadeout'
                                                : 'itemShake'
                                            : ''
                                    }
                                    sx={{
                                        ...{
                                            margin: '0.2rem 0.2rem',
                                            height: width && width < 480 ? '6rem' : '',
                                            maxHeight: width && width < 480 ? '6rem' : '',
                                            width: width && width < 480 ? '100%' : '',
                                            maxWidth: width && width < 480 ? '100vw' : ''
                                        }
                                    }}
                                    fullWidth={width && width < 480 ? false : true}
                                    disabled={isAnimating}
                                    disableElevation
                                    variant="dashed"
                                    size="large"
                                    {...bindLongPress(index)}
                                    onClick={(): void => { onOptionClick(item, event); }}>
                                    <Typography
                                        variant="h2"
                                        color="#374653"
                                        sx={{
                                            fontWeight: 500,
                                            fontSize:
                                                memoryLevel === 4 ||
                                                    memoryLevel === 5 ||
                                                    memoryLevel === 8 ||
                                                    memoryLevel === 9
                                                    ? '1.5rem ! important'
                                                    : !isPlatform('tablet') &&
                                                        (isPlatform('ios') || isPlatform('android'))
                                                        ? '1.5rem'
                                                        : '2rem',
                                            maxWidth: '100%',
                                            textAlign: 'center'
                                        }}>
                                        {answerListToDisplay && answerListToDisplay[item]}
                                    </Typography>
                                </Button>
                            </div>
                        ) : (
                            <Grid
                                sx={{ marginBottom: '25px', maxWidth: '100% !important' }}
                                item
                                xs
                                key={item}>
                                <Button
                                    className={
                                        itemToShake === item
                                            ? memoryLevel === 1
                                                ? 'itemFadeout'
                                                : 'itemShake'
                                            : ''
                                    }
                                    style={{
                                        ...{
                                            position: 'relative',
                                            backgroundColor: '#FFFFFF',
                                            padding: '35px 0px'
                                        }
                                    }}
                                    fullWidth
                                    disabled={isAnimating}
                                    disableElevation
                                    variant="dashed"
                                    size="large"
                                    {...bindLongPress(index)}
                                    onClick={(): void => {
                                        onOptionClick(item, event);
                                    }}>
                                    <Typography
                                        variant="h2"
                                        color="#374653"
                                        sx={{
                                            fontWeight: 500,
                                            maxWidth: '100%',
                                            fontSize:
                                                memoryLevel === 4 ||
                                                    memoryLevel === 5 ||
                                                    memoryLevel === 8 ||
                                                    memoryLevel === 9
                                                    ? '1.5rem ! important'
                                                    : '1.5rem',
                                        }}>
                                        {answerListToDisplay && answerListToDisplay[item]}
                                    </Typography>
                                </Button>
                            </Grid>
                        )}
                    </div>
                );
            });
        }
        return null;
    };

    const handleRemovedOptionList = (list: string[]): void => {
        const item = list[Math.floor(Math.random() * list.length)];
        setItemToShake(item);
        setTimeout(() => {
            setItemToShake('');
            const newList = answerList?.filter((answer) => answer !== item);
            if (newList) setAnswerList(newList);
        }, 3100);
    };

    const handleClueClick = (): void => {
        if (exerciseItem) {
            incrementClueCount();
            const list = answerList?.filter((item) => {
                return item !== exerciseItem.title;
            });
            if (memoryLevel <= 2 || memoryLevel === 6) {
                if (list && answerList) {
                    const list1 = list.filter((item) => !removedOptionList.includes(item));
                    setAnswerList(answerList.filter((item) => !removedOptionList.includes(item)));
                    handleRemovedOptionList(list1);
                }
            } else if (memoryLevel !== 10) {
                const tempAnswerListToDisplay = answerListToDisplay;
                answerList?.forEach((item) => {
                    if (tempAnswerListToDisplay) {
                        let isFisrtChar = true;
                        const newStr = tempAnswerListToDisplay[item]
                            .replaceAll('_ _', '__')
                            .replaceAll('\u00A0\u00A0\u00A0', ' ')
                            .split('')
                            .map((char, index) => {
                                if (isFisrtChar && char === '_' && item.split('')[index] !== '_') {
                                    isFisrtChar = false;
                                    return item.split('')[index];
                                }
                                return char;
                            })
                            .join('')
                            .replaceAll('__', '_ _');
                        tempAnswerListToDisplay[item] = newStr;
                    }
                });
                setAnswerListToDisplay(tempAnswerListToDisplay);

                // for reload answer list to display when click clue
                if (answerList) setAnswerList([...answerList]);
            } else {
                const tempAnswerListToDisplay = answerListToDisplay;
                answerList?.forEach((item) => {
                    if (tempAnswerListToDisplay) {
                        let isFisrtword = true;
                        const newStr = tempAnswerListToDisplay[item]
                            .split('\u00A0\u00A0\u00A0')
                            .map((word, index) => {
                                if (isFisrtword && word !== item.split(' ')[index]) {
                                    isFisrtword = false;
                                    return item.split(' ')[index];
                                }
                                return word;
                            })
                            .join('\u00A0\u00A0\u00A0');
                        tempAnswerListToDisplay[item] = newStr;
                    }
                });
                setAnswerListToDisplay(tempAnswerListToDisplay);

                // for reload answer list to display when click clue
                if (answerList) setAnswerList([...answerList]);
            }
        }
    };

    const nextExercise = (): void => {
        if (!exerciseItem) return;
        if (
            completedExercises &&
            completedExercises.length > 0 &&
            exerciseItem.id
        ) {
            setCompletedExercises(exerciseItem.id);
        } else if (exerciseItem.id) {
            setCompletedExercises(exerciseItem.id);
        }
    };

    const onSkipPreview = (): void => {
        setIsMemorization(false);
        showUi();
    };

    if (isMemorization) {
        return (
            <Preview title={exerciseItem?.title} Picture={exerciseItem?.url as string} onSkip={() => onSkipPreview()} />
        )
    }

    return (
        <Box
            flex={1}
            display="flex"
            flexDirection="column"
            height="80vh"
            justifyItems="center"
            sx={{
                backgroundColor: '#FFFFFF',
                overflowY: width && width < 480 ? 'auto' : 'hidden',
                paddingTop: '3rem',
                height: '100%'
            }}>
            <CssBaseline />
            <Box
                sx={{
                    minHeight: '78px',
                    maxHeight: '78px',
                    display: 'block',
                    top: 0,
                    position: 'absolute'
                }}>
            </Box>
            <div
                style={{
                    ...{
                        display: 'flex',
                        width: '100%',
                        flexDirection: width && width < 480 ? 'column' : 'row',
                        padding: '1rem',
                        gap: '1rem',
                        marginBottom: '8rem'
                    }
                }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: '100%',
                        width: width && width < 480 ? '100%' : '50%',
                        height: width && width < 480 ? '50%' : 'auto'
                    }}>
                    {exerciseItem && typeof exerciseItem.url === 'string' && (
                        <Card
                            sx={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                aspectRatio: '1/1',
                                borderRadius: '35px',
                                boxShadow: '0px 5px 30px #A0BDD6',
                                border: '8px solid #FFFFFF',
                                position: 'relative',
                                backgroundColor: 'transparent !important'
                            }}>
                            <ImageContainer
                                src={getImageKitUrlFrom(exerciseItem.url, 512, 512)}
                                alt="exercise image"
                                style={{
                                    height: width && width < 480 ? '100%' : '',
                                    width: width && width < 480 ? '100%' : '',
                                    filter:
                                        !isMemorization && memoryLevel >= 6
                                            ? 'blur(15px)'
                                            : 'none'
                                }}
                            />
                            <img
                                src={question}
                                alt="question"
                                style={{
                                    display:
                                        !isMemorization && memoryLevel >= 6 ? 'block' : 'none',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: width && width < 480 ? '3rem' : '24vh',
                                    height: width && width < 480 ? '3rem' : '24vh'
                                }}
                            />
                        </Card>
                    )}
                </div>
                <div
                    className="disable-scroll"
                    style={{
                        width: width && width < 480 ? '100%' : '50%',
                        height: width && width < 480 ? 'auto' : 'auto',
                        justifyContent: 'center',
                        alignItems: 'stretch',
                        margin: 'auto',
                    }}>
                    {!isMemorization && getExercises()}
                </div>
            </div>
            {!isMemorization && memoryLevel >= 6 && <div className='ctaContainer'>
                <CustomButton label={t('Revoir l’image')} theme='SECONDARY' className={'cta'} action={() => { setIsMemorization(true); hideUi(); }} />
            </div>}
        </Box>
    );
});
