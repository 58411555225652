/* eslint-disable import/no-default-export */
/* eslint-disable no-else-return */
/* eslint-disable no-param-reassign */
/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable consistent-return */
import global from '../../global';
import { GRID } from '../../typings';
import { checkGrid, identifySquare, isInCol, isInRow, isInSquare, shuffle } from '../../utils';


/**
 * a backtracking / recursive function to check all the possible combinations of numbers until a solution is found
 * @param grid 9x9 Sudoku grud
*
*/
function fillGrid(grid: GRID) {
    const numbers = global.gridSize === 9 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4];
    const limit = global.gridSize === 9 ? 81 : 16;
    let row = 0;
    let col = 0;

    for (let i = 0; i < limit; i++) {
        row = Math.floor(i / global.gridSize);
        col = i % global.gridSize;

        if (grid[row][col] === 0) {
            shuffle(numbers);

            for (let value of numbers) {
                // is it not in the grid row?
                if (!isInRow({ grid, row, value }))
                    if (!isInCol({ col, grid, value })) {
                        // is it not in the grid column?
                        // is it not in the grid square?
                        // if so...
                        const square = identifySquare({ col, grid, row });
                        if (!isInSquare({ square, value })) {
                            // ...
                            grid[row][col] = value;
                            // check grid if it is full, if so, stop and return true
                            if (checkGrid(grid)) return true;
                            else if (fillGrid(grid)) return true;
                            // otherwise we run fullGrid(grid) again
                        }
                    }
            }

            break;
        }
    }

    grid[row][col] = 0;
}

export default fillGrid;
