import React, { CSSProperties, ReactElement } from 'react';
import '../utilities/utils.css';

const classes: { [key: string]: CSSProperties } = {
    'textarea-root': {
        padding: '1rem',
        backgroundColor: 'var(--color-white)',
        color: 'var(--textarea-text-color)',
        width: '100%',
        border: '1px solid var(--textarea-border-color)',
        fontSize: '1rem',
        borderRadius: '0.5rem',
        resize: 'none',
        height: '9rem',
        minHeight: '9rem',
    }
};

interface Props {
    value?: string;
    sx?: CSSProperties;
    onChange?: (value: string) => void;
    placeholder?: string;
    maxLength?: number;
}

export function TextField({ value, sx, onChange, placeholder, maxLength }: Props): JSX.Element {
    return (
        <textarea
            style={{ ...classes['textarea-root'], ...sx }}
            value={value}
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={(e) => {
                if (onChange) {
                    onChange(e.target.value);
                }
            }}
        />
    );
}
