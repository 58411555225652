import { useContext } from "react";
import { withTranslation, WithTranslation } from "react-i18next";

import ButtonCard from '../ButtonCard';

import { UserContext } from "../../providers/UserProvider";
import ToolBoxLogo from './assets/toolbox_logo.svg?react';
import { cardItemsUser, cardItemsPro } from './model';
import style from './style.module.css';

interface HomeButtonCardGridProps extends WithTranslation {
    openMenu: (page: string) => void;
};

const HomeButtonCardGrid = ({ t, openMenu }: HomeButtonCardGridProps) => {
    const userDataProp = useContext(UserContext);
    const role = ['ADMIN', 'THERAPIST'].includes(userDataProp?.user?.role || '') ? 'pro' : 'user';

    if (role === 'pro') {
        return (
            <div className={style.proContainer}>
                <div className={style.toolBoxContainer}>
                    <div className={style.toolBoxLogoContainer}>
                        <ToolBoxLogo className={style.toolBoxLogo} />
                        <div className={style.toolBoxTitle}>{t('Ma boite à outil Pro')}</div>
                    </div>
                    <div className={style.toolBox}>
                        {cardItemsPro.filter((cardItem) => cardItem.toolBox).map((cardItem, index) => (
                            <ButtonCard
                                key={index}
                                action={cardItem.menu ? () => openMenu(cardItem.action) : cardItem.action}
                                Icon={cardItem.Icon}
                                title={t(cardItem.title)}
                                colors={cardItem.colors}
                                disabled={cardItem.disabled}
                            />
                        ))}
                    </div>
                </div>
                <div className={style.proGrid}>
                    {cardItemsPro.filter((cardItem) => !cardItem.toolBox).map((cardItem, index) => (
                        <ButtonCard
                            key={index}
                            action={cardItem.menu ? () => openMenu(cardItem.action) : cardItem.action}
                            Icon={cardItem.Icon}
                            title={t(cardItem.title)}
                            colors={cardItem.colors}
                            disabled={cardItem.disabled}
                        />
                    ))}
                </div>
            </div>
        );
    }

    return (
        <div className={style.grid}>
            {cardItemsUser.map((cardItem, index) => (
                <ButtonCard
                    key={index}
                    action={cardItem.menu ? () => openMenu(cardItem.action) : cardItem.action}
                    Icon={cardItem.Icon}
                    title={t(cardItem.title)}
                    colors={cardItem.colors}
                    disabled={cardItem.disabled}
                />
            ))}
        </div>
    );
};

export default withTranslation()(HomeButtonCardGrid);

