import { useContext, useEffect, useRef, useState } from 'react';

import { quizz } from '../../../models/Games';
import { GameProvider, GameRef, GameProps } from '../../../providers/GameProvider';
import { Quizz } from '../../../components/Quizz';
import { NextGameWindow } from '../../../windows/NextGame';
import { FeedbackWindow } from '../../../windows/Feedback';
import { CongratsWindow } from '../../../windows/Congrats';
import CongratsSvg from '../assets/congrats.svg?react';
import EinsteinSvg from '../assets/einstein.svg?react';
import styles from '../assets/styles.module.css';
import { getPatientUser } from '../../../services/cloudFirestore';
import { UserDataProp } from '../../../models/userDataProp';
import { UserContext } from '../../../providers/UserProvider';
import { Roles } from '../../../models/Roles';
import { Loader } from '../../../components/Loader';
import { useParams } from 'react-router-dom';
import { getGame } from '../../../stores/Game';
import { getUserById } from '../../../stores/User';

export default (): JSX.Element  => {
    const [refreshKey, setRefreshKey] = useState(0);
    const quizzRef = useRef<GameRef>(null);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [encouragement, setEncouragement] = useState<GameProps['encouragement'] | undefined>(undefined);
    const [congratulation, setCongratulation] = useState<GameProps['congratulation'] | undefined>(undefined);
    const [endloading, setEndLoading] = useState<boolean>(false);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    useEffect(() => {
        const getGames = async (): Promise<void> => {
            const gameId = window.location.pathname.split('/')[2]
            if (!gameId) return;
            const game = await getGame(userDataProp.token ?? '', gameId);
            const seniorData = await getPatientUser(user?.id ?? '');
            const author = await getUserById(userDataProp.token ?? '', game?.author ?? '');
            let tempNbRealisation = 0;
            seniorData?.statistics?.map((statistic) => {
                if (statistic.exerciseId === game?.id) {
                    tempNbRealisation = statistic.details.length;
                }
            });
            setCongratulation({
                msg: game?.congratulationsmessage,
                video: game?.reward as string || undefined,
            });
            setEncouragement({
                msg: game?.encouragementmessage ?? '',
                nbRealisation: tempNbRealisation,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });

            setEndLoading(true);
        };
        
        if (user && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) getGames();
        else setEndLoading(true);
    }, []);

    const getTips = () => {
        if (!quizzRef.current) return;
        quizzRef.current?.tips();
    };

    const resetGame = () => {
        setRefreshKey(refreshKey + 1);
    };

    const colors = {
        primary: quizz.color,
        secondary: quizz.secondaryColor,
        border: quizz.border,
    };

    if (!endloading) return (<Loader />);

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            encouragement={encouragement}
            endWindows={[{
                customImage: { Image: CongratsSvg, className: styles.congrats },
                Component: CongratsWindow,
            }, {
                Component: FeedbackWindow,
            }, {
                customImage: { Image: EinsteinSvg, className: styles.einstein },
                Component: NextGameWindow,
            }]}
            tipsEnabled
            congratulation={congratulation}
            instructionEnabled
            instructionMessage={quizz.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
            background={quizz.background}
        >
            <Quizz key={refreshKey} ref={quizzRef} />
        </GameProvider>
    );
};
