import { FunctionComponent, SVGProps } from 'react';

import Action from '../Action';
import style from './style.module.css';

interface ButtonCardProps {
    action: string | (() => void);
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    colors: {
        primary: string;
        secondary: string;
    };
    disabled?: boolean;
};

const ButtonCard = ({
    action,
    Icon,
    title,
    colors,
    disabled,
}: ButtonCardProps) => {
    return (
        <Action action={disabled ? '' : action} className={style.root}>
            <div className={style.iconContainer}>
                <Icon style={{ 
                    '--primary': disabled ? '#E0E0E0' : colors.primary,
                    '--secondary': disabled ? '#9E9E9E' : colors.secondary,
                 } as React.CSSProperties} />
            </div>
            <span className={[disabled ? style.disabled : '', style.title].join(' ')}>{title}</span>
        </Action>
    );
};

export default ButtonCard;