import React, { CSSProperties, ReactElement } from 'react';
import closeIcon from '../assets/closeIcon.png';
import { Typography } from './Typography';
import { ModalInfo } from './ModalInfo';
import { t } from 'i18next';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    },
    modal: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '0.8rem',
        zIndex: 1001,
        // padding: '10px',
        overflowY: 'auto'
    },
    'modal-header': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '3rem',
        position: 'sticky',
        padding: '0.5rem',
        backgroundColor: 'white',
        top: '0'
    },
    'modal-body': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 3rem)',
        marginTop: '0rem',
        padding: '0.5rem',
        marginBottom: '0.5rem'
    },
    'close-button': {
        width: '2rem',
        height: '2rem',
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        cursor: 'pointer'
    },
    'modal-title': {
        color: '#333',
        fontWeight: '600'
    }
};

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    type: 'games' | 'media';
}

export function ShareRulesModal({ isOpen, closeModal, type }: Props): JSX.Element {
    return (
        <ModalInfo
            isOpen={isOpen}
            closeModal={closeModal}
            title={t('shareRules.title') ?? ''}
            maxHeight="95vh">
            <div
                style={{
                    lineHeight: '1.5rem',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    gap: '1rem'
                }}>
                <p
                    style={{
                        margin: '0',
                        fontSize: '0.8125rem'
                    }}>
                    {t(`shareRules.${type}.desc1`)}
                </p>
                <p
                    style={{
                        margin: '0',
                        fontSize: '0.8125rem'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(`shareRules.${type}.desc2`) || ''
                    }}
                />
                <p
                    style={{
                        margin: '0',
                        fontSize: '0.8125rem'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(`shareRules.${type}.desc3`) || ''
                    }}
                />
                <p
                    style={{
                        margin: '0',
                        fontSize: '0.8125rem'
                    }}
                    dangerouslySetInnerHTML={{
                        __html: t(`shareRules.${type}.desc4`) || ''
                    }}
                />
            </div>
        </ModalInfo>
    );
}
