import styles from './styles.module.css';

import Heart from './assets/heart.svg?react';
import Love from './assets/love.svg?react';
import Like from './assets/like.svg?react';
import Neutral from './assets/neutral.svg?react';
import Dislike from './assets/dislike.svg?react';
import Heading from '../../components/Heading';
import Action from '../../components/Action';

export const FeedbackWindow = ({ onClose }: { onClose: () => void }) => {
    return (
        <div className={styles.root}>
            <Action className={styles.heartHalo} />
            <Heart className={styles.heart} />
            <Heading center className={styles.heading}>Avez-vous aimé ces jeux ?</Heading>
            <Heading center className={styles.subHeading}>Placez votre doigt sur la figure correspondant à votre avis</Heading>
            <Action className={[styles.card, styles.love].join(' ')} action={onClose}>
                <Love className={styles.icon} />
                <Heading className={styles.label}>J'ai adoré !</Heading>
            </Action>
            <Action className={[styles.card, styles.like].join(' ')} action={onClose}>
                <Like className={styles.icon} />
                <Heading className={styles.label}>J'ai bien aimé</Heading>
            </Action>
            <Action className={[styles.card, styles.neutral].join(' ')} action={onClose}>
                <Neutral className={styles.icon} />
                <Heading className={styles.label}>Je n'ai pas d'avis</Heading>
            </Action>
            <Action className={[styles.card, styles.dislike].join(' ')} action={onClose}>
                <Dislike className={styles.icon} />
                <Heading className={styles.label}>Je n'ai pas aimé</Heading>
            </Action>
            <Action action={onClose}>
                <Heading className={styles.close}>Passer</Heading>
            </Action>
        </div>
    );
}