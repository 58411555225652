import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { logOut } from '../services/firebaseAuth';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { User } from '../models/User';
import { Typography } from './Typography';
import { getAllFamilly, getPatientByFamilyCode } from '../services/cloudFirestore';
import { Family } from '../models/Family';
import arrow from '../assets/Next.svg';
import { useHistory } from 'react-router';
import { Share } from '@capacitor/share';
import { Roles } from '../models/Roles';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { getBeneficiaryMembers, getFamilyMembers } from '../stores/User';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'back-button': {
        position: 'relative',
        alignSelf: 'flex-start',
        width: 'fit-content',
        margin: '1rem 1rem'
    },
    'profile-image': {
        alignSelf: 'center',
        marginBottom: '1rem'
    },
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    },
    'icon-nav': {
        width: '2rem',
        height: '2rem',
        cursor: 'pointer',
        position: 'absolute',
        right: '0%'
    }
};

interface Props {
    user: User | null | undefined;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setFocusedUserId: React.Dispatch<React.SetStateAction<string>>;
}

export function FamilyMembers({ user, step, setStep, setFocusedUserId }: Props): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const [familyMembers, setFamilyMembers] = useState<Family[]>([]);
    const [seniorMember, setSeniorMember] = useState<User>();
    const history = useHistory();

    useEffect(() => {
        const getFamilyMember = async (): Promise<void> => {
            let allFamily: Family[] = [];

            if (userDataProp.token) {
                const allSenior = await getBeneficiaryMembers(userDataProp.token);
                setSeniorMember(allSenior[0]);
                allFamily = await getFamilyMembers(userDataProp.token);
            }
            allFamily = allFamily.filter((member) => member.id !== user?.id);
            setFamilyMembers(allFamily);
        };

        getFamilyMember();
    }, []);

    return (
        <div
            style={{
                ...classes['root']
            }}>
            <Typography variant="h2" sx={{ margin: '1rem 0 3rem 0' }}>
                {user?.familyCode
                    ? t('FamilyCode') + ' : ' + user?.familyCode
                    : t('FamilyCode') + ' : ' + t('Undefined')}
            </Typography>
            {familyMembers.map((member) => (
                <div
                    style={{
                        width: '100%',
                        alignSelf: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        position: 'relative',
                        paddingBottom: '1rem'
                    }}>
                    <Typography
                        key={member.firstName + ' ' + member.lastName}
                        variant="h3"
                        sx={{
                            cursor: 'pointer',
                            textAlign: 'left',
                            alignSelf: 'center',
                            justifySelf: 'flex-start',
                            justifyContent: 'flex-start',
                            width: width && width < 768 ? '88%' : '100%'
                        }}
                        onClick={() => {
                            if (member.id) {
                                setFocusedUserId(member.id?.toString());
                                setStep(step + 1);
                            }
                        }}>
                        <span>{member.firstName + ' ' + member.lastName}</span>
                        {member.legalReferent && (
                            <div
                                style={{
                                    backgroundColor: '#0D224B',
                                    color: 'white',
                                    borderRadius: '0.5rem',
                                    border: '1px solid var(--textarea-border-color)',
                                    marginLeft: '1rem',
                                    padding: '1rem 1rem 1rem 1rem'
                                }}>
                                <span>{t('Ambassador')}</span>
                            </div>
                        )}
                    </Typography>
                    <img
                        src={arrow}
                        alt="arrowLeft"
                        onClick={() => {
                            if (member.id) {
                                setFocusedUserId(member.id?.toString());
                                setStep(step + 1);
                            }
                        }}
                        style={classes['icon-nav']}
                    />
                </div>
            ))}
            <Typography
                variant="p"
                sx={{
                    marginTop: '2rem',
                    marginBottom: '1.5rem',
                    textAlign: 'center',
                    alignSelf: 'center',
                    color: '#333',
                    fontSize: '0.875',
                    fontWeight: '400'
                }}>
                {t('InviteGoodStarText1')}
                <span style={{ textDecoration: 'underline' }}>{t('InviteGoodStarText2')}</span>
                {t('InviteGoodStarText3')}
            </Typography>
            <Button
                sx={{ ...classes['logout-button'] }}
                // pink
                onClick={async () => {
                    await Share.share({
                        title: 'Bonjour!',
                        text:
                            user?.role === Roles.SENIOR
                                ? t('InvitationJoinStimulinSenior', {
                                      code: user?.familyCode,
                                      name: user?.firstName
                                  }) ?? ''
                                : t('InvitationJoinStimulinFamily', {
                                      seniorName: seniorMember?.firstName ?? '',
                                      code: user?.familyCode,
                                      name: user?.firstName
                                  }) ?? '',
                        url: `https://appv1.stimulin.fr/register/${user?.familyCode}`
                    });
                }}>
                {t('InviteGoodStar')}
            </Button>
        </div>
    );
}
