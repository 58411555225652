import { Button } from "../../components/Button/index";
import style from './style.module.css';

interface DeleteWindowProps {
    type: string;
    onDelete: () => void;
    onCancel: () => void;
};

export const DeleteWindow = ({ type, onDelete, onCancel }: DeleteWindowProps) => {
    return (
        <div className={style.root}>
            <div className={style.title}>{`Êtes-vous sûr de vouloir supprimer ce ${type} ?`}</div>
            <div className={style.subtitle}>{`Attention, ce ${type} sera deffinitivement supprimé et ne pourra pas être récupéré.`}</div>
            <div className={style.ctaContainer}>
                <Button className={[style.button, style.confirm].join(' ')} label='OUI' theme='PRIMARY' action={onDelete} />
                <Button className={[style.button, style.cancel].join(' ')} label='NON' theme='SECONDARY' action={onCancel} />
            </div>
        </div>
    );
};

export default DeleteWindow;