import React, { CSSProperties, useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { logOut } from '../services/firebaseAuth';
import { ProfileImage } from './ProfilImage';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Patient } from '../models/Patient';
import { getPatientUser, updateUserNeedDelete } from '../services/cloudFirestore';
import { Input } from './Input';
import { Typography } from './Typography';
import { useHistory } from 'react-router';
import greenCheck from '../assets/greenCheck.png';
import { PopupModal } from './PopupModal';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'subscription-root': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        overflowY: 'hidden',
        padding: '1rem 0.5rem',
        alignSelf: 'center',
        alignItems: 'center'
    },
    'profile-image': {
        alignSelf: 'center',
        marginBottom: '1rem'
    },
    'informations-root': {
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        width: '100%',
        marginTop: '1rem',
        marginBottom: '2rem'
    },
    'subscription-dates': {
        display: 'flex',
        position: 'relative',
        width: '100%',
        marginTop: '1rem'
    }
};

interface Props {
    userId: string | undefined;
}

export function Subscription({ userId }: Props): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const [user, setUser] = useState<Patient | null>();
    const history = useHistory();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [openValidationModal, setOpenValidationModal] = useState<boolean>(false);
    const isPortrait = useMemo(() => {
        if (height && width) {
            return height > width;
        }
        return false;
    }, [height, width]);

    useEffect(() => {
        if (userId) {
            getPatientUser(userId?.toString()).then((data) => {
                setUser(data);
            });
        }
    }, []);

    return (
        <div
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%',
                position: 'relative'
            }}>
            <PopupModal
                closeModal={() => setOpenModal(false)}
                isOpen={openModal}
                children={
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#E5007B',
                                textAlign: 'center',
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                lineHeight: '1.25rem'
                            }}>
                            {user?.isSubscribed
                                ? t('modalUnsubscribe.title')
                                : t('modalDelete.title')}
                        </Typography>
                        <br />
                        <Typography
                            variant="p"
                            sx={{
                                color: '#374653',
                                textAlign: 'center',
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                lineHeight: '1.25rem'
                            }}>
                            {user?.isSubscribed
                                ? t('modalUnsubscribe.subtitle')
                                : t('modalDelete.subtitle')}
                        </Typography>
                        <br />
                        <Typography
                            variant="p"
                            sx={{
                                color: '#374653',
                                textAlign: 'center',
                                fontSize: '0.6875rem',
                                fontWeight: '500',
                                lineHeight: '0.9375rem'
                            }}>
                            {user?.isSubscribed ? (
                                <>
                                    &rarr; {t('modalUnsubscribe.desc1')}
                                    <br />
                                    <br />
                                    &rarr; {t('modalUnsubscribe.desc2')}
                                    <br />
                                    <br />
                                    &rarr; {t('modalUnsubscribe.desc3')}
                                    <br />
                                    <br />
                                    &rarr; {t('modalUnsubscribe.desc4')}
                                </>
                            ) : (
                                <>
                                    &rarr; {t('modalDelete.desc1')}
                                    <br />
                                    <br />
                                    &rarr; {t('modalDelete.desc2')}
                                    <br />
                                    <br />
                                    &rarr; {t('modalDelete.desc3')}
                                    <a href="mailto:hello@stimulin.fr">hello@stimulin.fr</a>
                                </>
                            )}
                        </Typography>

                        <Button
                            pink
                            onClick={async () => {
                                if (userId) {
                                    await updateUserNeedDelete(userId, true)
                                        .then(() => {
                                            setUser({
                                                ...user,
                                                needDelete: true
                                            });
                                            setOpenModal(false);
                                            setOpenValidationModal(true);
                                        })
                                        .catch((error) => {});
                                }
                            }}
                            sx={{
                                marginTop: '1.5rem'
                            }}>
                            {t('modalUnsubscribe.validate')}
                        </Button>
                        <Button
                            onClick={() => setOpenModal(false)}
                            sx={{
                                marginTop: '1.5rem'
                            }}>
                            {t('modalUnsubscribe.cancel')}
                        </Button>
                    </>
                }
                sxRoot={{
                    top: '0',
                    left: isPortrait ? '0px' : '55%',
                    width: 'auto',
                    height: '100%',
                    padding: '1rem 32px'
                }}
                width="90%"
                height="auto"
            />
            {openValidationModal && (
                <div
                    style={{
                        position: 'fixed',
                        top: '0',
                        left: isPortrait ? '0' : '55%',
                        width: isPortrait ? '100%' : '45%',
                        height: '100%',
                        zIndex: 100,
                        backgroundColor: '#fff'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            gap: '2rem',
                            overflowY: 'auto',
                            padding: '1rem 1rem'
                        }}>
                        <Typography
                            variant="h3"
                            sx={{
                                color: '#E5007B',
                                fontSize: '1.75rem',
                                fontWeight: '700',
                                textAlign: 'center'
                            }}>
                            {user?.isSubscribed
                                ? t('modalUnsubscribe.validation.title')
                                : t('modalDelete.validation.title')}
                        </Typography>
                        <img src={greenCheck} alt="green check" style={{ height: '5rem' }} />
                        <Typography
                            variant="p"
                            sx={{
                                color: '#002F55',
                                fontSize: '1.27rem',
                                fontWeight: '500',
                                textAlign: 'center'
                            }}>
                            {user?.isSubscribed ? (
                                <>
                                    {t('modalUnsubscribe.validation.desc1')}
                                    <br />
                                    {t('modalUnsubscribe.validation.desc2')}
                                    <br />
                                    <br />
                                    {t('modalUnsubscribe.validation.desc3')}
                                    <br />
                                    {t('modalUnsubscribe.validation.desc4')}
                                    <br />
                                    <br />
                                    {t('modalUnsubscribe.validation.desc5')}
                                    <a href="mailto:hello@stimulin.fr">hello@stimulin.fr</a>
                                    {'. '}
                                    {t('modalUnsubscribe.validation.desc6')}
                                    <br />
                                    <br />
                                    {t('modalUnsubscribe.validation.desc7')}
                                </>
                            ) : (
                                <>
                                    {t('modalDelete.validation.desc1')}
                                    <br />
                                    {t('modalDelete.validation.desc2')}
                                    <br />
                                    <br />
                                    {t('modalDelete.validation.desc3')}
                                    <a href="mailto:hello@stimulin.fr">hello@stimulin.fr</a>
                                    {'. '}
                                    {t('modalDelete.validation.desc4')}
                                    <br />
                                    <br />
                                    {t('modalDelete.validation.desc5')}
                                </>
                            )}
                        </Typography>
                        <Button
                            pink
                            onClick={() => {
                                setOpenValidationModal(false);
                            }}>
                            {t('modalUnsubscribe.validation.button')}
                        </Button>
                    </div>
                </div>
            )}
            <div
                style={{
                    ...classes['subscription-root']
                }}>
                <ProfileImage
                    key="profile picture"
                    sx={{ ...classes['profile-image'] }}
                    width={80}
                    height={80}
                    name={`${user?.firstName} ${user?.lastName}`}
                    url={user?.profilePhoto}
                />
                <Typography variant="h2">{t('MySubscription')}</Typography>
                <div
                    style={{
                        ...classes['informations-root']
                    }}>
                    <Input
                        key="subscription start date"
                        label={t('SubscriptionStartDate') ?? ''}
                        value={user?.startDate?.toDate().toISOString().substring(0, 10)}
                        disabled
                        sxRoot={{
                            ...classes['subscription-dates'],
                            ...{ cursor: 'not-allowed' }
                        }}
                        type="date"
                    />
                    <Input
                        key="subscription end date"
                        label={t('SubscriptionEndDate') ?? ''}
                        value={user?.endDate?.toDate().toISOString().substring(0, 10)}
                        disabled
                        sxRoot={{
                            ...classes['subscription-dates'],
                            ...{ cursor: 'not-allowed' }
                        }}
                        type="date"
                    />
                </div>
                <Button
                    pink
                    disabled={user?.needDelete}
                    onClick={async () => {
                        setOpenModal(true);
                    }}>
                    {user?.isSubscribed && !user?.needDelete && t('Unsubscribe')}
                    {!user?.isSubscribed && !user?.needDelete && t('DeleteAccount')}
                    {user?.needDelete && user?.isSubscribed && t('AccountDesactivated')}
                    {user?.needDelete && !user?.isSubscribed && t('AccountDeleted')}
                </Button>
            </div>
        </div>
    );
}
