import ReactPlayer from 'react-player';
import { useHistory } from 'react-router';
import { t } from 'i18next';

import Gift from './assets/gift.svg?react';
import Stars from './assets/stars.svg?react';
import Heading from '../../../components/Heading';
import styles from './styles.module.css';
import { VideoPlayer } from '../../../components/VideoPlayer';

interface RewardProps {
    congratulation?: {
        title?: string;
        msg?: string;
        video?: string;
    };
    onReset?: () => void;
};

const Reward: React.FC<RewardProps> = ({ congratulation, onReset }) => {
    const history = useHistory();

    const handleOnClick = (path: string) => {
        history.push(path);
    };

    return (
        <div className={styles.container}>

            <div className={styles.titleContainer}>
                <Gift className={styles.gift} />
                {congratulation?.title && <Heading center className={styles.title}>{congratulation?.title}</Heading>}
            </div>

            {congratulation?.video &&
                <div className={styles.videoContainer}>
                    <>
                        <VideoPlayer url={congratulation.video} autoPlay={true} sx={{ borderRadius: 'var(--radius-l)', overflow: 'hidden' }} />
                        <Stars className={[styles.stars, styles.topLeftCorner].join(' ')} />
                    </>
                </div>
            }

            {congratulation?.msg && <div className={styles.messageContainer}>
                <div className={styles.message}>{congratulation.msg}</div>
            </div>}

            <div className={styles.actionsContainer}>
                <div className={styles.actions} onClick={() => onReset && onReset()}>
                    <div className={styles.cta}>
                        <Heading center className={styles.label}>{t('Continuer à jouer')}</Heading>
                    </div>
                </div>
                <div className={styles.actions} onClick={() => handleOnClick('/games')}>
                    <div className={[styles.cta, styles.outlined].join(' ')}>
                        <Heading center className={styles.label}>{t('Arrêter ce jeu')}</Heading>
                    </div>
                </div>
            </div>

        </div >
    );
};

export default Reward;