import { Avatar, Box } from '@mui/material';
import React, { ReactElement } from 'react';
import { getImageKitUrlFrom } from '../../../utilities/utils';

interface ProfileImageProps {
    width: number;
    height: number;
    name: string;
    url: string | File | undefined;
    className?: string;
}

function stringToColor(string: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function stringAvatar(name: string, width: number, height: number) {
    return {
        sx: {
            fontSize: '1.6rem',
            width,
            height,
            bgcolor: stringToColor(name)
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
}

export function ProfileImage({ width, height, name, url, className }: ProfileImageProps): ReactElement {
    const initials = (): ReactElement => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Avatar {...stringAvatar(name, width, height)} className={className} />;
    };

    const profile = (): ReactElement => {
        if (typeof url === 'string') {
            return <Avatar sx={{ width, height }} className={className} src={getImageKitUrlFrom(url!, width, height)} />;
        }
        return <Avatar sx={{ width, height }} className={className} src={url?.webkitRelativePath} />;
    };

    return (
        <Box display="flex" justifyContent="center" marginBottom="10px" className={className}>
            {url !== undefined ? profile() : initials()}
        </Box>
    );
}
