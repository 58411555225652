import { WithTranslation, withTranslation } from "react-i18next";

import style from "./style.module.css";
import Action from "../Action";

interface GameTypeCardProps extends WithTranslation {
    titre: string;
    action: string;
    Illustration: React.FC<React.SVGProps<SVGSVGElement>>;
    description?: string;
    Logo: React.FC<React.SVGProps<SVGSVGElement>>;
};

const GameTypeCard = ({
    t,
    titre,
    action,
    Illustration,
    description,
    Logo,
}: GameTypeCardProps) => {
    return (
        <Action className={style.card} action={action}>
            <div
                className={style.cardImage}
            >
                <Illustration />
            </div>
            <div className={style.cardIconContainer}>
                <Logo className={style.cardIcon} />
            </div>
            <div className={style.cardContent}>
                <div className={[style.cardTitle, description ? '' : style.center].join(' ')}>
                    {t(titre)}
                </div>
                {description && <div className={style.cardDescription}>
                    {t(description)}
                </div>}
            </div>
        </Action>
    );
};

export default withTranslation()(GameTypeCard);