import { useContext, useEffect, useRef, useState } from "react";

import { memory } from "../../../models/Games";
import { GameProps, GameProvider, GameRef } from "../../../providers/GameProvider";
import { Memory } from "../../../components/Memory";
import { CongratsWindow } from "../../../windows/Congrats";
import { FeedbackWindow } from "../../../windows/Feedback";
import { NextGameWindow } from "../../../windows/NextGame";
import CongratsSvg from '../assets/congrats.svg?react';
import EinsteinSvg from '../assets/einstein.svg?react';
import styles from '../assets/styles.module.css';
import { UserDataProp } from "../../../models/userDataProp";
import { UserContext } from "../../../providers/UserProvider";
import { getPatientUser } from "../../../services/cloudFirestore";
import { Roles } from "../../../models/Roles";
import { Loader } from "../../../components/Loader";
import { useParams } from "react-router-dom";
import { getGame } from "../../../stores/Game";
import { getUserById } from "../../../stores/User";

export default () => {
    const memoryRef = useRef<GameRef>(null);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [congratulation, setCongratulation] = useState<GameProps['congratulation'] | undefined>(undefined);
    const [encouragement, setEncouragement] = useState<GameProps['encouragement'] | undefined>(undefined);
    const [endloading, setEndLoading] = useState<boolean>(false);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    
    useEffect(() => {
        const getGames = async (): Promise<void> => {
            const gameId = window.location.pathname.split('/')[2]
            if (!gameId) return;
            const game = await getGame(userDataProp.token ?? '', gameId);
            const seniorData = await getPatientUser(user?.id ?? '');
            const author = await getUserById(userDataProp.token ?? '', game?.author ?? '');
            let tempNbRealisation = 0;
            seniorData?.statistics?.map((statistic) => {
                if (statistic.exerciseId === game?.id) {
                    tempNbRealisation = statistic.details.length;
                }
            });
            setCongratulation({
                msg: game?.congratulationsmessage,
                video: game?.reward as string || undefined,
            });
            setEncouragement({
                msg: game?.encouragementmessage ?? '',
                nbRealisation: tempNbRealisation,
                image: author?.profilePhoto as string ?? '',
                firstName: author?.firstName ?? '',
                lastName: author?.lastName ?? '',
            });

            setEndLoading(true);
        };
        
        if (user && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) getGames();
        else setEndLoading(true);
    }, []);

    const getTips = () => {
        if (!memoryRef.current) return;
        memoryRef.current?.tips();
    };

    const resetGame = () => {
        if (!memoryRef.current) return;
        memoryRef.current?.reset();
    }

    const colors = {
        primary: memory.color,
        secondary: memory.secondaryColor,
        border: memory.border,
    };

    if (!endloading) return (<Loader />);

    return (
        <GameProvider
            colors={colors}
            onReset={resetGame}
            onTipsClick={getTips}
            congratulation={congratulation}
            encouragement={encouragement}
            endWindows={[{
                customImage: { Image: CongratsSvg, className: styles.congrats },
                Component: CongratsWindow,
            }, {
                Component: FeedbackWindow,
            }, {
                customImage: { Image: EinsteinSvg, className: styles.einstein },
                Component: NextGameWindow,
            }]}
            tipsEnabled
            instructionEnabled
            background={memory.background}
            instructionMessage={memory.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
        >
            <Memory ref={memoryRef} />
        </GameProvider>
    );
};