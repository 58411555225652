import React, { CSSProperties, useEffect, useState } from 'react';
import { UserDataProp } from '../../models/userDataProp';
import { useContext } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../components/Typography';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import shareVideoYt from '../../assets/shareVideoYt.png';
import shareVideo from '../../assets/shareVideo.png';
import sharePicture from '../../assets/sharePicture.png';


const classes: { [key: string]: CSSProperties } = {
    'root-step': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem'
    },
    element: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        gap: '1rem',
        padding: '0.5rem',
        borderRadius: '16px',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px rgba(164, 161, 196, 0.74)'
    },
    'img-element': {
        height: 'auto',
        width: '124px',
        borderRadius: '8px'
    },
    'title-element': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        width: 'calc(100% - 124px)'
    },
    h2: {
        color: '#1C0D4B',
        textAlign: 'left',
        fontWeight: '600'
    },
    h3: {
        color: '#DE358E',
        fontWeight: '500',
        fontSize: '1rem',
        textAlign: 'left',
        justifyContent: 'flex-start'
    },
    h4: {
        color: '#195BBE'
    },
    'list-picture': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        // gap: '5px',
        flexWrap: 'wrap',
        width: '100%',
        overflowY: 'auto',
        paddingBottom: '5rem'
    },
    'element-picture': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '3px',
        paddingRight: '3px',
        paddingTop: '3px',
        paddingBottom: '3px',
        position: 'relative',
        cursor: 'pointer'
    },
    'checkbox-picture': {
        position: 'absolute',
        top: '0.5rem',
        left: '0.75rem',
        opacity: '1'
    },
    'button-next': {
        position: 'absolute',
        bottom: '1rem',
        left: '0',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100%',
        // width: 'calc(100% - 2rem)',
        height: '3rem',
        zIndex: 100
    },
    'game-finish': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0.5rem',
        gap: '1rem',
        width: '100%',
        height: '8.75rem',
        position: 'relative',
        backgroundColor: '#fff',
        borderRadius: '16px',
        boxShadow: '0px 4px 12px rgba(164, 161, 196, 0.74)'
    },
    'game-finish-picture': {
        height: '100%',
        width: 'auto',
        borderRadius: '8px'
    },
    'game-finish-message': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%'
    },
    'game-finish-title': {
        paddingTop: '1rem',
        textAlign: 'left',
        justifyContent: 'flex-start'
    },
    'game-finish-button': {
        marginBottom: '0.5rem',
        width: 'fit-content',
        padding: '13px 16px',
        backgroundColor: 'var(--color-primary)'
    },
    'give-name': {
        justifyContent: 'flex-start',
        marginTop: '2.5rem',
        fontSize: '1rem',
        width: '100%'
    },
    'input-name': {
        width: '100%',
        marginTop: '1rem'
    },
    details: {
        width: '100%',
        textAlign: 'left',
        marginTop: '2rem',
        fontWeight: '600'
    },
    'details-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '5rem'
    },
    'step-desc': {
        color: '#6B6B6B',
        fontSize: '1rem'
    }
};

interface ChoiseCreateImportProps {
    onSelectGames: Function;
    onSelectPicture: Function;
    onSelectVideo: Function;
    onSelectVideoYT: Function;
}

export function ChoiseCreateImport({
    onSelectGames,
    onSelectPicture,
    onSelectVideo,
    onSelectVideoYT
}: ChoiseCreateImportProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { height, width } = useWindowDimensions();

    const listModel: {
        title: string;
        img: string;
        funcToCall: Function;
    }[] = [
        // { title: t('makeAGame'), img: puzzleIcon, funcToCall: onSelectGames },
        { title: t('sharePicture'), img: sharePicture, funcToCall: onSelectPicture },
        { title: t('shareVideo'), img: shareVideo, funcToCall: onSelectVideo },
        { title: t('shareVideoYt'), img: shareVideoYt, funcToCall: onSelectVideoYT }
    ];

    return (
        <div style={{ ...classes['root-step'], padding: '0px 32px 40px' }}>
            <Typography sx={{...classes['step-desc'], padding: '1rem 0 1.5rem' }}>{t('importDesc')}</Typography>
            {listModel.map((model, index) => {
                return (
                    <div
                        onClick={():void => model.funcToCall()}
                        key={index}
                        style={classes['element']}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                gap: '10px'
                            }}>
                            <img src={model.img} alt={model.title} style={classes['img-element']} />
                        </div>
                        <div style={classes['title-element']}>
                            <Typography
                                variant="h2"
                                sx={classes['h2']}>
                                {model.title}
                            </Typography>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
