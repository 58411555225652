import React, {
    CSSProperties,
    Dispatch,
    MutableRefObject,
    ReactElement,
    SetStateAction,
    useEffect,
    useRef,
    useState
} from 'react';
import ReactPlayer from 'react-player';
import { Typography } from './Typography';
import { Button } from './Button';
import { Slider } from './Slider';
import BigPlayButton from '../assets/bigPlayButton.svg';
import BigPauseButton from '../assets/bigPauseButton.svg';
import { isPlatform } from '@ionic/core';
import useWindowDimensions from '../utilities/useWindowDimensions';
// import 'capacitor-video-player';
// import { useVideoPlayer } from 'react-video-player-hook';
// import * as PluginsLibrary from 'capacitor-video-player';

const classes: { [key: string]: CSSProperties } = {
    'video-player': {
        // width: '100%',
        // height: '100%'
    },
    'container-video-player': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '100%',
        // maxHeight: 'calc(100vh - 70px - 1rem)',
        // maxHeight: '100%',
        // padding: '1rem',
        aspectRatio: '16 / 9',
        backgroundColor: 'black',
        position: 'relative'
    }
};

interface Props {
    // type: 'video-person' | 'video-youtube' | 'picture';
    url: string;
    autoPlay?: boolean;
    onEnded?: () => void;
    onMediaError?: () => void;
    setTimePlayed?: (nb: number) => void;
    sx?: CSSProperties;
}

export function VideoPlayer({
    url,
    autoPlay = false,
    onEnded,
    onMediaError,
    setTimePlayed,
    sx
}: Props): JSX.Element {
    const { height, width } = useWindowDimensions();
    const [playing, setPlaying] = useState(autoPlay);
    const [durationSeconds, setDurationSeconds] = useState(0);
    const [playedSeconds, setPlayedSeconds] = useState(0);
    const playerRef = useRef() as MutableRefObject<ReactPlayer>;
    const [displayPauseButton, setDisplayPauseButton] = useState(false);
    const [displayTimeBar, setDisplayTimeBar] = useState(false);
    const [clickGoYt, setClickGoYt] = useState(false);

    const timeoutIdRef = useRef<number | null>(null); // Utilisation de useRef pour stocker la valeur de timeoutId
    const handleMouseMove = (event: MouseEvent) => {
        setDisplayPauseButton(true);

        // Réinitialiser le délai si la souris bouge à nouveau
        if (timeoutIdRef.current) {
            window.clearTimeout(timeoutIdRef.current);
        }

        timeoutIdRef.current = window.setTimeout(() => {
            setDisplayPauseButton(false);
        }, 2000);
    };

    useEffect(() => {
        setTimePlayed && setTimePlayed(playedSeconds);
    }, [playedSeconds]);
    /**
     * calcul du temps de lecture
     * test d'une solution pour calculer exactement le temps de lecture
     */
    // const [played, setPlayed] = useState(0);
    // const [timePlaying, setTimePlaying] = useState<number[]>([]);
    // const [intervalPlaying, setIntervalPlaying] = useState<{ start: number; end: number }[]>([]);
    // useEffect(() => {
    //     // calcul interval de lecture
    //     let start = 0;
    //     timePlaying.forEach((time, index) => {
    //         if (index === timePlaying.length - 1) {
    //             setIntervalPlaying([...intervalPlaying, { start: Math.round(start), end: Math.round(time) }]);
    //         } else if (index === 0) {
    //             start = 0;
    //         } else {
    //             console.log(time, timePlaying[index - 1],time - timePlaying[index - 1]);
    //             if (time - timePlaying[index - 1] < 0) {
    //                 console.log('set intervalPlaying');
    //                 start = time;
    //             } else if (time - timePlaying[index - 1] > 2) {
    //                 setIntervalPlaying([
    //                     ...intervalPlaying,
    //                     { start: Math.round(start), end: Math.round(timePlaying[index - 1])}
    //                 ]);
    //                 console.log('set intervalPlaying');
    //                 start = time;
    //             }
    //         }
    //     });
    // }, [playing]);

    // useEffect(() => {
    //     console.log(intervalPlaying);
    //     // calcul temp de lecture
    //     let time = 0;
    //     intervalPlaying.forEach((interval) => {
    //         time += interval.end - interval.start;
    //     });
    //     console.log(time);
    // }, [intervalPlaying]);

    return (
        <div
            onClick={(): void => {
                if (clickGoYt) {
                    window.open(
                        `https://youtube.com/watch?v=${url.match(/[\w\\-]{11,}/)}`,
                        '_blank'
                    );
                } else {
                    setTimeout(() => {
                        setDisplayPauseButton(false);
                    }, 2000);
                    setDisplayPauseButton(true);
                }
            }}
            // @ts-ignore
            onMouseMove={handleMouseMove}
            // on mouse stop move set to false
            onMouseEnter={(): void => setDisplayPauseButton(true)}
            onMouseLeave={(): void => setDisplayPauseButton(false)}
            style={{
                ...classes['container-video-player'],
                ...(url.search('youtu.be') !== -1 || url.search('youtube') !== -1
                    ? { height: '100%', width: '100%' }
                    : {}),
                ...sx
            }}>
            <ReactPlayer
                height="100%"
                width="100%"
                style={{ pointerEvents: 'none' }}
                ref={playerRef}
                controls={false}
                playing={playing}
                sx={{ ...classes['video-player'] }}
                url={url}
                onProgress={({ playedSeconds }) => setPlayedSeconds(playedSeconds)}
                /**
                 * calcul du temps de lecture
                 * test d'une solution pour calculer exactement le temps de lecture
                 */
                // onProgress={({ playedSeconds }) => {
                //     setPlayedSeconds(playedSeconds);
                //     setTimePlaying([...timePlaying, playedSeconds]);
                //     console.log(timePlaying);
                // }}
                onSeek={setPlayedSeconds}
                onDuration={setDurationSeconds} // This is called when the player has the duration
                onEnded={onEnded}
                onError={(e) => {
                    if (e === '150' && onMediaError) {
                        setClickGoYt(true);
                        onMediaError();
                    }
                }}
            />
            {!playing && (
                <Button
                    // ref={btnPlay}
                    onClick={(): void => setPlaying(true)}
                    sx={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        top: 'calc(50% - 50px)',
                        left: 'calc(100% / 2 - 50px)',
                        backgroundColor: 'transparent'
                    }}>
                    <img
                        src={BigPlayButton}
                        alt="PlayButton"
                        style={{
                            height: '100px',
                            width: '100px'
                        }}
                    />
                </Button>
            )}
            {playing && (
                <Button
                    onClick={(): void => setPlaying(false)}
                    onMouseOver={(): void => setDisplayPauseButton(true)}
                    sx={{
                        position: 'absolute',
                        height: '100px',
                        width: '100px',
                        top: 'calc(50% - 50px)',
                        left: 'calc(100% / 2 - 50px)',
                        backgroundColor: 'transparent',
                        // bottom: '10px',
                        display: displayPauseButton ? 'flex' : 'none'
                    }}>
                    <img
                        src={BigPauseButton}
                        alt="PauseButton"
                        style={{
                            height: '100px',
                            width: '100px'
                        }}
                    />
                </Button>
            )}
            {playerRef && playerRef.current && (
                <div
                    style={{
                        display: displayPauseButton || !playing || displayTimeBar ? 'flex' : 'none',
                        alignItems: 'center',
                        width: '90%',
                        paddingLeft: '10px',
                        position: 'absolute',
                        zIndex: '3600',
                        bottom: '20px'
                    }}
                    onMouseOver={(): void => setDisplayTimeBar(true)}
                    onMouseLeave={(): void => setDisplayTimeBar(false)}>
                    <span
                        style={{
                            color: '#ffffff',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            marginRight: '10px',
                            marginLeft: '10px',
                            backgroundColor: 'black',
                            padding: '0 4px',
                            fontFamily: 'Luciole'
                        }}>
                        {Math.trunc(playedSeconds) < 60
                            ? `0:${
                                  Math.trunc(playedSeconds) < 10
                                      ? `0${Math.trunc(playedSeconds)}`
                                      : Math.trunc(playedSeconds)
                              }`
                            : `${Math.floor(Math.trunc(playedSeconds) / 60)}:${
                                  Math.floor(Math.trunc(playedSeconds) % 60) < 10
                                      ? `0${Math.floor(Math.trunc(playedSeconds) % 60)}`
                                      : Math.floor(Math.trunc(playedSeconds) % 60)
                              }`}
                    </span>
                    <Slider
                        pink
                        // size="small"
                        min={0}
                        // defaultValue={playerRef.current.getCurrentTime() * 1000}
                        onChange={(e: any, value: number): void => {
                            const newValue = value / 1000;
                            playerRef.current.seekTo(newValue);
                            setPlayedSeconds(newValue);
                        }}
                        value={playedSeconds * 1000}
                        max={playerRef.current.getDuration() * 1000}
                        aria-label="time bar"
                        // valueLabelDisplay="auto"
                        // valueLabelFormat={(value: number): string => {
                        //     const minutes = Math.floor(value / 1000 / 60);
                        //     const seconds = Math.floor((value / 1000) % 60);
                        //     return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
                        // }}
                    />
                    {playerRef.current && playerRef.current.getDuration && (
                        <span
                            style={{
                                color: '#ffffff',
                                fontSize: '1.5rem',
                                fontWeight: 'bold',
                                marginRight: '10px',
                                marginLeft: '10px',
                                backgroundColor: 'black',
                                padding: '0 4px',
                                fontFamily: 'Luciole'
                            }}>
                            {Math.trunc(playerRef.current.getDuration()) < 60
                                ? `0:${
                                      Math.trunc(playerRef.current.getDuration()) < 10
                                          ? `0${Math.trunc(playerRef.current.getDuration())}`
                                          : Math.trunc(playerRef.current.getDuration())
                                  }`
                                : `${Math.floor(
                                      Math.trunc(playerRef.current.getDuration()) / 60
                                  )}:${
                                      Math.floor(Math.trunc(playerRef.current.getDuration()) % 60) <
                                      10
                                          ? `0${Math.floor(
                                                Math.trunc(playerRef.current.getDuration()) % 60
                                            )}`
                                          : Math.floor(
                                                Math.trunc(playerRef.current.getDuration()) % 60
                                            )
                                  }`}
                        </span>
                    )}
                </div>
            )}
        </div>
    );
}
