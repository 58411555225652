import GameTypeCard from "../GameTypeCard";
import style from "./style.module.css";
import { WithTranslation, withTranslation } from "react-i18next";

interface GameTypeGridProps extends WithTranslation {
    items: {
        titre: string;
        Illustration: React.FC<React.SVGProps<SVGSVGElement>>;
        description?: string;
        Logo: React.FC<React.SVGProps<SVGSVGElement>>;
        action: string;
    }[];
};

const GameTypeGrid = ({ t, items }: GameTypeGridProps) => {
    return (
        <div className={style.grid}>
            {items.map(({ titre, Illustration, description, Logo, action }, index) => (
                <GameTypeCard
                    key={index}
                    titre={titre}
                    action={action}
                    Illustration={Illustration}
                    description={description}
                    Logo={Logo}
                />
            ))}
        </div>
    );
};

export default withTranslation()(GameTypeGrid);