import Play from './assets/play.svg?react';
import Create from './assets/create.svg?react';
import Media from './assets/media.svg?react';
import Import from './assets/import.svg?react';
import Stat from './assets/stat.svg?react';
import Profile from './assets/profile.svg?react';
import Invite from './assets/invite.svg?react';
import Help from './assets/help.svg?react';
import Community from './assets/community.svg?react';
import Tutorial from './assets/tutorial.svg?react';
import Inspiration from './assets/inspiration.svg?react';
import Learn from './assets/learn.svg?react';

interface CardItem {
    toolBox: boolean;
    action: string;
    Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
    colors: {
        primary: string;
        secondary: string;
    };
    menu?: boolean;
    disabled?: boolean;
}

export const cardItemsPro: CardItem[] = [
    {
        toolBox: true,
        action: `MyFamilyMembers`,
        menu: true,
        Icon: Community,
        title: 'Visualiser ma communauté',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(237, 125, 212, 1)',
        }
    }, {
        toolBox: true,
        action: `https://www.notion.so/Voir-mes-tutos-c9bba278e9d64e97be832ab7531fb135?pvs=4`,
        Icon: Tutorial,
        title: 'Voir mes tutos',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(0, 208, 208, 1)',
        }
    }, {
        toolBox: true,
        action: `https://basalt-football-eb0.notion.site/M-inspirer-c12dc4168d2c45e29ae30059ded161aa`,
        Icon: Inspiration,
        title: `M'inspirer`,
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(0, 134, 177, 1)',
        }
    }, {
        toolBox: true,
        action: `https://basalt-football-eb0.notion.site/Me-former-90b34ed947c7497ea1fd910de16c6530`,
        Icon: Learn,
        title: 'Me former',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(0, 208, 208, 1)',
        }
    }, {
        toolBox: true,
        action: 'https://www.notion.so/Obtenir-de-l-aide-e8400d5201e044799bdb17cea34a8d61?pvs=4',
        Icon: Help,
        title: `Obtenir de l'aide`,
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(39, 207, 167, 1)',
        },
        disabled: false,
    }, {
        toolBox: true,
        action: `MyStatistics`,
        menu: true,
        Icon: Stat,
        title: 'Voir mes statistiques',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(255, 130, 75, 1)',
        }
    }, {
        toolBox: false,
        action: `/games`,
        Icon: Play,
        title: 'Tester un jeu',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(114, 0, 255, 1)',
        }
    }, {
        toolBox: false,
        menu: true,
        action: `create`,
        Icon: Create,
        title: 'Créer un jeu',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(232, 38, 249, 1)',
        },
    }, {
        toolBox: false,
        action: `/album`,
        Icon: Media,
        title: 'Consulter mes médias',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(0, 142, 221, 1)',
        }
    }, {
        toolBox: false,
        menu: true,
        action: `import`,
        Icon: Import,
        title: 'Importer des médias',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(89, 195, 255, 1)',
        },
    }, {
        toolBox: false,
        action: `PersonalInformations`,
        menu: true,
        Icon: Profile,
        title: 'Gérer mon compte',
        colors: {
            primary: 'rgba(255, 255, 255, 1)',
            secondary: 'rgba(123, 176, 211, 1)',
        }
    }
];

export const cardItemsUser: CardItem[] = [
    {
        toolBox: false,
        action: `/games`,
        Icon: Play,
        title: 'Jouer à un jeu',
        colors: {
            primary: 'rgba(0, 142, 221, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        }
    }, {
        toolBox: false,
        menu: true,
        action: `create`,
        Icon: Create,
        title: 'Créer un jeu',
        colors: {
            primary: 'rgba(89, 195, 255, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        },
    }, {
        toolBox: false,
        action: `/album`,
        Icon: Media,
        title: 'Consulter mes médias',
        colors: {
            primary: 'rgba(114, 0, 255, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        }
    }, {
        toolBox: false,
        menu: true,
        action: `import`,
        Icon: Import,
        title: 'Importer des médias',
        colors: {
            primary: 'rgba(177, 115, 255, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        },
    }, {
        toolBox: false,
        menu: true,
        action: `MyStatistics`,
        Icon: Stat,
        title: 'Voir mes statistiques',
        colors: {
            primary: 'rgba(255, 148, 102, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        }
    }, {
        toolBox: false,
        action: `PersonalInformations`,
        menu: true,
        Icon: Profile,
        title: 'Gérer mon compte',
        colors: {
            primary: 'rgba(137, 183, 214, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        }
    }, {
        toolBox: false,
        action: `MyFamilyMembers`,
        menu: true,
        Icon: Invite,
        title: 'Inviter des proches',
        colors: {
            primary: 'rgba(255, 110, 110, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        }
    },
    {
        toolBox: false,
        action: 'https://www.notion.so/Obtenir-de-l-aide-e8400d5201e044799bdb17cea34a8d61?pvs=4',
        Icon: Help,
        title: `Obtenir de l'aide`,
        colors: {
            primary: 'rgba(39, 207, 167, 1)',
            secondary: 'rgba(255, 255, 255, 1)',
        },
        disabled: false,        
    },
];