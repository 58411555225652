/* eslint-disable no-plusplus */
/* eslint-disable curly */
import global from '../../global';
import { GRID, INDEX } from '../../typings';

function copyGrid(grid: GRID): GRID {
    const gridCopy: any = global.gridSize === 4 ? [
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0],
        [0, 0, 0, 0]
    ] : [
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0],
        [0, 0, 0, 0, 0, 0, 0, 0, 0]
    ];

    for (let r: INDEX = 0; r < global.gridSize; r++)
        for (let c: INDEX = 0; c < global.gridSize; c++) gridCopy[r][c] = grid[r][c];

    return gridCopy;
}

// eslint-disable-next-line import/no-default-export
export default copyGrid;
