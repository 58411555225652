import { createContext, useContext } from 'react';

export interface WindowContextProps {
    component?: () => JSX.Element;
    openWindow?: (args: WindowContextProps) => void;
    closeWindow?: () => void;
    isOpened?: boolean;
    props?: any;
    customImage?: {
        Image?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        className?: string;
    };
}

export const WindowContext = createContext<WindowContextProps>({
    component: undefined,
    openWindow: () => { },
    closeWindow: () => { },
    isOpened: false,
    props: {},
});

export const useWindow = () => useContext(WindowContext);
