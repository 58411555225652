import React, { memo } from 'react';
import styles from './styles.module.css';
import { DragItem } from '../../models';
import { useParams } from 'react-router-dom';

interface CardProps {
    letter: string;
    cardPreview?: boolean;
    status: DragItem['status'];
    holded?: boolean;
}

const Card: React.FC<CardProps> = memo(({ letter, status, cardPreview, holded }) => {
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    const cardSize = lvl <= 5 ? styles.large : lvl <= 7 ? styles.medium : styles.small;
    const letterSize = lvl <= 5 ? styles.largeLetter : lvl <= 7 ? styles.mediumLetter : styles.smallLetter
    
    return (
        <div className={[cardSize, styles.root, holded ? styles.holded : '', styles[`root-${status}`]].join(' ')} role={cardPreview ? 'CardPreview' : 'Card'}>
            <div className={[letterSize, styles.letter, styles[status]].join(' ')}>
                {letter}
            </div>
        </div>
    );
});

export default Card;