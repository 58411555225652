import React from 'react';

import Icon from './point.svg?react';

import styles from './styles.module.css';

interface PointsProps {
    points: number;
    className?: string;
    color?: string;
}

const Points: React.FC<PointsProps> = ({ points, className, color }) => {

    return (
        <div className={[styles.root, className].join(' ')} style={{
            '--color': color,
        } as React.CSSProperties}>
            <Icon className={styles.icon} />
            <div className={styles.text}>{points}</div>
        </div>
    );
};

export default Points;