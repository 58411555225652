import React from 'react';

import styles from './styles.module.css';
import Different from './different.svg?react';
import Card from '../../Card';
import Action from '../../Action';
import { option } from '../interfaces';

interface Props {
    Picture: string;
    options: option[];
    onClick: (id: option["id"]) => void;
    lvl: number;
}

const GameGrid: React.FC<Props> = ({ Picture, options, onClick, lvl }) => {

    return (
        <div className={styles.grid}>
            {options.map((option, index) => (
                <Action key={index} action={() => onClick(option.id)}>
                    <div
                        className={[styles.card, styles[option.status], lvl <= 5 ? styles.large : ''].join(' ')}
                    >
                        <>
                            <div className={[styles.cardContent, option.rotation % 30 !== 0 ? styles.cardScaled : ''].join(' ')}
                                style={{
                                    'backgroundImage': `url(${Picture})`,
                                    'transform': `rotate(${option.rotation}deg)${option.flip ? ' scale(-1, 1)' : ''}`,
                                } as React.CSSProperties}
                            />
                            {option.status === 'incorrect' && <Different className={styles.different} />}
                        </>
                    </div>
                </Action>
            ))}
        </div>
    );
};

export default GameGrid;