import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { User } from '../models/User';
import { getImageKitUrlFrom } from '../utilities/utils';
import { Typography } from './Typography';
import hearts from '../assets/hearts.png';
import { CheckBox } from './CheckBox';
import {} from '../models/Patient';
import { ProfileImage } from './ProfilImage';
import { useTranslation } from 'react-i18next';
import { Select } from './Select';
import { Roles } from '../models/Roles';
import { Establishment } from '../models/Establishment';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        alignItems: 'center',
        height: 'fit-content',
        width: '100%',
        gap: '1rem',
        padding: '0.4rem 0px'
    },
    divUser: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '0.5rem'
    },
    img: {
        height: '3.5rem',
        width: '3.5rem',
        maxHeight: '3.5rem',
        borderRadius: '50%',
        objectFit: 'cover'
    },
    userName: {
        fontSize: '1rem',
        fontWeight: '500',
        color: '#374653'
    },
    userCity: {
        fontSize: '0.8rem',
        fontWeight: '400',
        color: '#5C6670'
    }
};

interface Props {
    ets?: Establishment | null | undefined;
    checkbox?: boolean;
    checked?: boolean;
    onCheck?: (checked: boolean) => void;
    selectAll?: boolean;
    selectAllTitle?: string;
    selectPermission?: boolean;
    onChange?: (value: string) => void;
    selectValue?: string;
    onClick?: () => void;
}

export function DisplayEts({
    ets,
    checkbox,
    onCheck,
    selectAll,
    selectAllTitle,
    checked,
    selectPermission,
    onChange,
    selectValue,
    onClick
}: Props): JSX.Element {
    const { t } = useTranslation();

    const globalClick = (): void => {
        if (onClick) {
            onClick();
        } else if (onCheck) {
            onCheck(!checked);
        }
    };

    return selectAll ? (
        <div
            onClick={globalClick}
            style={{
                ...classes['root']
            }}>
            <div style={{ ...classes['divUser'] }}>
                <img src={hearts} alt="user" style={{ ...classes['img'] }} />
                {/* <ProfileImage
                    width={150}
                    height={150}
                    name={`${user?.firstName} ${user?.lastName}`}
                    url={user?.profilePhoto}
                    sx={{ ...classes['img'] }}
                /> */}
                <div>
                    <Typography variant="h3" sx={{ ...classes['userName'] }}>
                        {selectAllTitle}
                        {/* {t(`sendAllSenior.${user?.role === Roles.PRO ? 'pro' : 'family'}`)} */}
                    </Typography>
                </div>
            </div>
            {checkbox && (
                <CheckBox
                    checked={checked}
                    setChecked={(value: boolean) => {
                        if (onCheck) {
                            onCheck(value);
                        }
                    }}
                />
            )}
            {selectPermission && (
                <Select
                    opts={[
                        { value: 'read', label: t('canUse') },
                        { value: 'write', label: t('canEdit') }
                    ]}
                    onChange={(value) => {
                        onChange && onChange(value.target.value);
                    }}
                    value={selectValue}
                    sx={{
                        padding: '8px'
                    }}
                    sxRoot={{
                        border: '2px solid var(--select-text-color)',
                        borderRadius: '0.5rem'
                    }}
                />
            )}
        </div>
    ) : (
        <div
            onClick={globalClick}
            style={{
                ...classes['root']
            }}>
            <div style={{ ...classes['divUser'] }}>
                <ProfileImage
                    width={150}
                    height={150}
                    name={`${
                        ets?.establishmentName?.includes(' ')
                            ? ets?.establishmentName
                            : `${ets?.establishmentName?.split('')[0]} ${
                                  ets?.establishmentName?.split('')[1]
                              }`
                    }`}
                    url={undefined}
                    sx={{ ...classes['img'] }}
                />
                <div>
                    <Typography variant="h3" sx={{ ...classes['userName'] }}>
                        {ets?.establishmentName}
                    </Typography>
                    <Typography variant="h4" sx={{ ...classes['userCity'] }}>
                        {ets?.city}
                    </Typography>
                </div>
            </div>
            {checkbox && (
                <CheckBox
                    checked={checked}
                    setChecked={(value: boolean) => {
                        if (onCheck) {
                            onCheck(value);
                        }
                    }}
                />
            )}
            {selectPermission && (
                <Select
                    opts={[
                        { value: 'read', label: t('canUse') },
                        { value: 'write', label: t('canEdit') }
                    ]}
                    onChange={(value) => {
                        onChange && onChange(value.target.value);
                    }}
                    value={selectValue}
                    sx={{
                        padding: '8px'
                        // width: 'fit-content',
                    }}
                    sxRoot={{
                        border: '2px solid var(--select-text-color)',
                        borderRadius: '0.5rem'
                    }}
                />
            )}
        </div>
    );
}
