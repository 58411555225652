import axios from 'axios';
import { env } from '../../env';
import { computeCroppedArea } from 'react-easy-crop/helpers';

const url = env.API_URL + '/api/stats';

export const getStat = async (
    token: string,
    type: string,
    data: {
        startDate: Date;
        endDate: Date;
        idList: string[];
        timeScale?: string;
        type?: string;
    }
): Promise<any> =>
    axios
        .post(`${url}/${type}`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data;
        })
        .catch((error: any) => {
            // console.log(error);
            return null;
        });

export const insertStats = async (
    token: string,
    data: any
): Promise<any> => {
    try {
        const response = await axios.put(`${url}/game/played`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        return null;
    }
}

export const insertSatisfaction = async (
    token: string,
    data: any
): Promise<any> => {
    try {
        const response = await axios.put(`${url}/game/satisfaction`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        return null;
    }
}

export const lookupMedia = async (
    token: string,
    data: any
    /*
    Example Value Schema
    {
        "idMedia": "string",
        "watchTime": 0
    }
    */
): Promise<any> => {
    try {
        const response = await axios.put(`${url}/media/look`, data, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        return null;
    }
}