import React, { CSSProperties } from 'react';
import Back from '../assets/Back.svg';

const classes: { [key: string]: CSSProperties } = {
    'root': {
        height: '3rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: '#333333',
        width: '100%',
        fontSize: '1rem'
    }
};

interface Props {
    title: string;
    sx?: CSSProperties;
    onClick?: () => void;
}

export function ItemList({ title, sx, onClick }: Props): JSX.Element {
    return (
        <div
            onClick={() => {
                onClick ? onClick() : () => {};
            }}
            style={{
                ...classes['root'],
                ...sx,
                ...(onClick ? { cursor: 'pointer' } : {})
            }}>
            <span>{title}</span>
            {/* <span>{`>`}</span> */}
            <img src={Back} alt="back" style={{transform: 'rotate(180deg)' }} />
        </div>
    );
}
