import React from 'react';
import stimulinAvatarBlue from '../../assets/stimulinAvatarBlue.png';
import { Typography } from '../Typography';

interface Props {
    text: string;
}

export function StimbotInfo({ text }: Props): JSX.Element {

    return (
        <div style={{
            width: '100%',
            height: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem',
            gap: '0.5rem',
            padding: '0.5rem',
            backgroundColor: '#EBF2FF',
        }}>
            <img src={stimulinAvatarBlue} alt="Albert de Stimul'in" style={{
                width: '3rem',
                height: '3rem'
            }} />
            <Typography variant="p" sx={{
                color: '#0D224B',
                fontSize: '0.875rem',
                fontWeight: '500',
                width: '100%',
                lineHeight: '1.125rem',
            }}>
                {text}
            </Typography>
        </div>
    );
}
