import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { logOut } from '../services/firebaseAuth';
import { ProfileImage } from './ProfilImage';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Input } from './Input';
import { User } from '../models/User';
import { changePassword } from '../services/firebaseAuth';
import openedEye from '../assets/openedEye.svg';
import closedEye from '../assets/closedEye.svg';
import { useHistory } from 'react-router';
import { LogoutButton } from './LogoutButton';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'back-button': {
        position: 'relative',
        alignSelf: 'flex-start',
        width: 'fit-content',
        margin: '1rem 1rem'
    },
    'profile-image': {
        alignSelf: 'center',
    },
    'passwords-root': {
        flexDirection: 'column',
        display: 'flex',
        position: 'relative',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginBottom: '1rem',
        textAlign: 'center',
        alignItems: 'center',
        gap: '1rem'
    },
    'passwords-form': {
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        gap: '1rem'
    },
    'passwords-fields': {
        display: 'flex',
        position: 'relative',
        width: '90%',
        // margin: '2.5rem 0rem'
    },
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '95%',
        textAlign: 'center',
        alignSelf: 'center'
    }
};

interface Props {
    user: User | null | undefined;
    photo: string | File | undefined;
}

export function ChangePassword({ user, photo }: Props): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const [oldPassword, setOldPassword] = useState<string>('');
    const [firstPassword, setFirstPassword] = useState<string>('');
    const [secondPassword, setSecondPassword] = useState<string>('');
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showFirstPassword, setShowFirstPassword] = useState<boolean>(false);
    const [showSecondPassword, setShowSecondPassword] = useState<boolean>(false);
    const [errorMessages, setErrorMessages] = useState<string>();
    const history = useHistory();

    const updatePassword = async (
        oldPassword: string,
        firstPassword: string,
        secondPassword: string,
        id: string | undefined
    ) => {
        if (firstPassword !== secondPassword) {
            setErrorMessages('PasswordsDontMatch');
            return;
        }
        if (firstPassword.length < 6) {
            setErrorMessages('mdp6char');
            return;
        }
        if (firstPassword === secondPassword && user && firstPassword.length >= 6 && oldPassword) {
            const result = await changePassword(oldPassword, firstPassword, user);
            if (result.success === false) {
                if (result.error === 'wrongPassword') {
                    setErrorMessages('WrongPassword');
                }
            } else {
                // alert(t('PasswordChanged'));
                logOut(user);
                history.push('/login/me');
            }
        }
    };

    useEffect(() => {
        setErrorMessages(undefined);
    }, [oldPassword, firstPassword, secondPassword]);

    return (
        <div
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%'
            }}>
            <ProfileImage
                key="profile picture"
                sx={{ ...classes['profile-image'] }}
                width={80}
                height={80}
                name={`${user?.firstName} ${user?.lastName}`}
                url={photo}
            />
            <div
                style={{
                    ...classes['passwords-root']
                }}>
                {/* <form
                    key="password form"
                    style={{ ...classes['passwords-form'] }}
                    onSubmit={(): void => {
                        updatePassword(oldPassword, firstPassword, secondPassword, user?.id);
                    }}
                    onKeyDown={(event): void => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            event.stopPropagation();
                            updatePassword(oldPassword, firstPassword, secondPassword, user?.id);
                        }
                    }}> */}
                    <Input
                        key="old password"
                        label={t('EnterOldPassword') ?? ''}
                        placeholder={t('passwordFiller') ?? ''}
                        type={showOldPassword ? '' : 'password'}
                        sxRoot={{
                            ...classes['passwords-fields']
                        }}
                        onChange={(value: string): void => {
                            setOldPassword(value);
                        }}
                        value={oldPassword}
                        imgIntoInput={showFirstPassword ? openedEye : closedEye}
                        btnClick={(): void => {
                            setShowOldPassword(!showOldPassword);
                        }}
                    />
                    <Input
                        key="new password"
                        label={t('EnterNewPassword') ?? ''}
                        placeholder={t('passwordFiller') ?? ''}
                        type={showFirstPassword ? '' : 'password'}
                        sxRoot={{
                            ...classes['passwords-fields']
                        }}
                        onChange={(value: string): void => {
                            setFirstPassword(value);
                        }}
                        value={firstPassword}
                        imgIntoInput={showFirstPassword ? openedEye : closedEye}
                        btnClick={(): void => {
                            setShowFirstPassword(!showFirstPassword);
                        }}
                    />
                    <Input
                        key="confirm new password"
                        label={t('ConfirmNewPassword') ?? ''}
                        placeholder={t('passwordFiller') ?? ''}
                        type={showSecondPassword ? '' : 'password'}
                        sxRoot={{
                            ...classes['passwords-fields']
                        }}
                        onChange={(value: string): void => {
                            setSecondPassword(value);
                        }}
                        value={secondPassword}
                        imgIntoInput={showSecondPassword ? openedEye : closedEye}
                        btnClick={(): void => {
                            setShowSecondPassword(!showSecondPassword);
                        }}
                    />
                    {errorMessages && <p style={{ color: 'red' }}>{t(errorMessages)}</p>}
                    <Button
                        key="confirm"
                        disabled={oldPassword === '' || firstPassword === '' || secondPassword === ''}
                        sx={{ position: 'relative', width: '90%' }}
                        onClick={() => {
                            updatePassword(oldPassword, firstPassword, secondPassword, user?.id);
                        }}>
                        {t('Confirm')}
                    </Button>
                {/* </form> */}
            </div>
            <LogoutButton />
        </div>
    );
}
