import axios from 'axios';
import { User } from '../models/User';
import { env } from '../../env';
import { returnTo } from '../main';
import { Family } from '../models/Family';
import { getDocId } from '../services/cloudFirestore';

const url = env.API_URL;

export const getUser = async (token: string): Promise<User | null> => {
    const tmpUser = await axios
        .get(`${url}/user`, {
            headers: {
                // AccessControlAllowOrigin: 'http://localhost:8100',
                Authorization: `Bearer ${token}`
            }
        })
        .then(async (response: any) => {
            // console.log('response.data.data', response.data.data);
            const t = await getDocId(response.data.data.id.replace('auth0|', ''));
            return { ...response.data.data, userId: t } as User;
        })
        .catch((error: any) => {
            return null;
        });
    return tmpUser;
};

export const saveUser = async (user: User, token: string): Promise<User | null> => {
    const tmpUser = await axios
        .post(`${url}/user`, user, {
            headers: {
                // AccessControlAllowOrigin: 'http://localhost:8100',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data as User;
        })
        .catch((error: any) => {
            return null;
        });
    return tmpUser;
}

export const updateNeedViewOnboarding = async (token: string, needView: boolean): Promise<boolean> => {
    const tmpUser = await axios
        .put(`${url}/user/needViewOnboarding`, { needViewOnboarding: needView }, {
            headers: {
                // AccessControlAllowOrigin: 'http://localhost:8100',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data as boolean;
        })
        .catch((error: any) => {
            return false;
        });
    return tmpUser;
}

export const changePassword = async (token: string): Promise<any> => {
    const response = await axios
        .get(`${url}/user/change-password`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then((response: any) => {
            return response.data;
        }).catch((error: any) => {
            console.log(error);
            return null;
        });
    return response;
}

export const getFamilyMembers = async (token: string): Promise<Family[]> => {
    const tmpFamily = await axios
        .get(`${url}/user/my-family`, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        })
        .then((response: any) => {
            return response.data.data as Family[];
        })
        .catch((error: any) => {
            console.log('error', error);
            return [];
        });
    return tmpFamily;
}

export const getBeneficiaryMembers = async (token: string): Promise<Family[]> => {
    const tmpFamily = await axios
        .get(`${url}/user/beneficiary-into-family`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data as Family[];
        })
        .catch((error: any) => {
            return [];
        });
    return tmpFamily;
}

export const checkEmail = async (email: string): Promise<boolean> => {
    const response = await axios
        .get(`${url}/if-user-exist?email=${email}`)
        .then((response: any) => {

            return response.data as boolean;
        })
        .catch((error: any) => {
            return false;
        });
    return response;
}

export const deleteUser = async (token: string): Promise<boolean> => {
    const tmpUser = await axios
        .get(`${url}/user/delete-user`, {
            headers: {
                // AccessControlAllowOrigin: 'http://localhost:8100',
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data as boolean;
        })
        .catch((error: any) => {
            return false;
        });
    return tmpUser;
}

export const getUserById = async (token: string, id: string): Promise<User | null> => {
    const tmpUser = await axios
        .get(`${url}/user/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data[0] as User;
        })
        .catch((error: any) => {
            return null;
        });
    return tmpUser;
}

export const getAll = async (token: string): Promise<any[]> => {
    const tmpUser = await axios
        .get(`${url}/user/all`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response: any) => {
            return response.data.data;
        })
        .catch((error: any) => {
            return [];
        });
    return tmpUser;
}