/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`;

export const Row = styled.div<{ gridSize: number }>`
    ${({ gridSize }) => css`
        display: flex;
        flex-flow: row;

        &:nth-child(1) {
            div {
                border-top: solid 4px var(--color-black);
            }
        }

        &:nth-child(${gridSize === 4 ? 2 : 3}),
        &:nth-child(${gridSize === 4 ? 4 : 6}) {
            div {
                border-bottom: solid 4px var(--color-black););
            }
        }

        &:nth-child(9) {
            border-bottom: solid 4px var(--color-black);
        }

        div {
            &:nth-child(1) {
                border-left: solid 4px var(--color-black);;
            }
            &:nth-child(${gridSize === 4 ? 2 : 3}),
            &:nth-child(${gridSize === 4 ? 4 : 6}),
            &:nth-child(9) {
                border-right: solid 4px var(--color-black););
            }
        }
    `}
`;
