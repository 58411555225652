import { FC, ReactNode, useState, useMemo } from "react";

import { MenuContext, MenuContextProps, useMenu } from "../contexts/MenuContext";
import Menu from '../components/Menu';

const defaultState: MenuContextProps = {
    component: undefined,
    props: {},
    isOpened: false
};

const MenuManager = () => {
    const { isOpened, component: Component, props, closeMenu } = useMenu();

    return (
        <>
            <Menu {...props} isOpened={isOpened} onClose={closeMenu}>
                {Component && <Component />}
            </Menu>
        </>
    );
};

interface MenuProviderProps {
    children: ReactNode;
}

export const MenuProvider: FC<MenuProviderProps> = ({ children }) => {
    const [openState, setOpenState] = useState(false);
    const [state, setState] = useState<MenuContextProps>(defaultState);

    const actions = useMemo(
        () => ({
            open({ component = undefined, isOpened = false, ...props }: MenuContextProps) {
                setState({
                    component,
                    props,
                    isOpened,
                });
                setOpenState(true);
            },
            close() {
                setOpenState(false);
            },
        }),
        [],
    );
    return (
        <MenuContext.Provider
            value={{
                isOpened: openState,
                component: state.component,
                props: state.props,
                openMenu: (args: MenuContextProps) => actions.open(args),
                closeMenu: actions.close,
            }}
        >
            <MenuManager />
            {children}
        </MenuContext.Provider>
    );
};