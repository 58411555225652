import React from 'react';
import './login.css';
import cross from '../../assets/cross.svg'
import info from '../../assets/infoLogo.svg';

interface InfoModalProps {
    setDisplayInfoModal: (value: boolean) => void;
}

export function InfoModal({setDisplayInfoModal}: InfoModalProps): JSX.Element {
    return (
        <>
            <div
                id="info-modal"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: '0px',
                    left: '0px',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: '1000'
                }}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '0.8rem',
                        zIndex: '1001',
                        overflowY: 'hidden',
                        backgroundColor: 'rgb(245, 245, 245)',
                        height: 'fit-content',
                        width: 'fit-content',
                        maxHeight: '80vh',
                        maxWidth: '75vw'
                    }}>
                    <img
                        id="close-modal"
                        src={cross}
                        alt="close"
                        onClick={() => setDisplayInfoModal(false)}
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                            cursor: 'pointer',
                            border: '2px solid rgb(227, 230, 232)',
                            borderRadius: '50%',
                            height: '3rem',
                            width: '3rem',
                            zIndex: '1002'
                        }}
                    />
                    <div
                        style={{
                            position: 'relative',
                            display: 'flex',
                            overflowY: 'auto',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: 'fit-content',
                            minWidth: '75vw',
                            width: 'fit-content',
                            maxWidth: '75vw',
                            padding: '2rem'
                        }}>
                        <img
                            src={info}
                            alt="info"
                            className="info-logo"
                            style={{ height: '1.8rem', width: '1.8rem' }}
                        />
                        <h2
                            style={{
                                fontSize: '1.5rem',
                                fontWeight: 700,
                                color: 'rgb(229, 0, 123)',
                                margin: '1rem 0px 0px',
                                textAlign: 'center',
                                width: '100%',
                                fontFamily: 'var(--ion-font-family)'
                            }}>
                            “Code Bonnes Etoiles”
                        </h2>
                        <div
                            style={{
                                marginTop: '1rem',
                                color: 'var(--main-blue-color)',
                                lineHeight: '2rem',
                                fontWeight: 600,
                                fontSize: '1rem',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textAlign: 'center',
                                gap: '2rem'
                            }}>
                            <div>
                                C’est un code unique qui permet aux utilisateurs Stim’App d’une même
                                famille d’activer gratuitement un compte personnel et rejoindre leur
                                espace privé Stim’App.
                                <br />
                                <br />
                                Ce code est disponible dans la rubrique “Mes informations
                                personnelles” de vos proches déjà connectés à Stim’App.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
