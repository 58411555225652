/* eslint-disable @typescript-eslint/explicit-function-return-type */
import styled, { css } from 'styled-components';

export const Button = styled.button`
    ${({ theme }) => css`
        align-items: center;
        background-color: var(--color-white);
        border: 4px solid rgba(28, 13, 75, 0.3);
        border-radius: var(--radius-l);
        color: var(--color-primary);
        cursor: pointer;
        display: flex;
        flex: 1;
        font-size: 16px;
        font-weight: bold;
        height: 40px;
        justify-content: center;
        margin: 4px 2px;
        min-height: 40px;
        opacity: 0.9;
        transition: ${theme.transition};
        transition: all 0.2s;
        line-height: normal;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
        }

        &:active {
            transform: translateY(0px);
            outline: none;
        }
    `}
`;
