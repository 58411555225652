import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { User } from '../models/User';
import { Typography } from './Typography';
import { useHistory } from 'react-router';
import graphStats from '../assets/graphStats.svg';
import statsIcon from '../assets/statsIcon.svg';
import statsIconGrows from '../assets/statsIconGrows.svg';
import smileyGood from '../assets/smileyGood.svg';
import starBackground from '../assets/starBackground.svg';
import '../utilities/utils.css';
import { Select } from './Select';
import {
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import Chart from 'chart.js/auto';
import { Line } from 'react-chartjs-2';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    addData,
    calcEvol,
    classes,
    getLabelFromDate
} from './ProfileStatisticsStyle';
import {
    getPatientUser,
    getPatientsData,
    getReview,
    getStatTimeUseAppByUser
} from '../services/cloudFirestore';
import { StatsSatisfaction } from '../components/StatsComponents/StatsSatisfaction';
// import { StatsLvlDifficult } from './StatsComponents/StatsLvlDifficult';
import { Patient } from '../models/Patient';
import { StatsTimePlay } from './StatsComponents/StatsTimePlay';
import { StatsLvlUse } from './StatsComponents/StatsLvlUse';
import { StimbotInfo } from './StatsComponents/StimbotInfo';
import { StatsImportMedia } from './StatsComponents/StatsImportMedia';
import { StatsCreateGame } from './StatsComponents/StatsCreateGame';
import { StatEvol } from './StatsComponents/StatEvol';
import { StatsGamesFinisVsStarted } from './StatsComponents/StatsGamesFinisVsStarted';
import { StatsGames } from './StatsComponents/StatsGames';
import { getDateFromTimestamp2 } from '../utilities/utils';
import { Timestamp } from 'firebase/firestore';
import { StatsLookedMedia } from './StatsComponents/StatsLookedMedia';
import { Button } from './Button';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Roles } from '../models/Roles';
import { StatsActifUsers } from './StatsComponents/StatsActifUsers';
import { getStat } from '../stores/Stats';

interface Props {
    userList: Patient[] | null | undefined;
    setStepTitle?: (title: string) => void;
}

export function ProfileStatistics({ userList, setStepTitle }: Props): JSX.Element {
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [userDataList, setUserDataList] = useState<Patient[]>();
    const [nbMinutes, setNbMinutes] = useState<number>(0);
    const [evolNb, setEvolNb] = useState<string>('');
    const [nbLogin, setNbLogin] = useState<number>(0);
    const [evolTimeNb, setEvolTimeNb] = useState<string>('');

    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'top' as const
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const labels = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'];

    const [data, setData] = useState<any | undefined>({
        labels,
        datasets: [
            {
                fill: true,
                label: 'Nombre de connexion(s)',
                // data: labels.map(() => 2),
                data: [0, 0, 0, 0, 0, 0, 0],
                borderColor: '#0047FF',
                borderWidth: 2,
                pointBackgroundColor: '#0047FF',
                pointRadius: 6,
                pointHoverRadius: 6,
                pointHoverBackgroundColor: '#0047FF',
                pointHoverBorderColor: '#FFFFFF',
                pointHoverBorderWidth: 2,
                pointHitRadius: 10,
                pointBorderWidth: 2,
                pointBorderColor: '#FFFFFF',
                pointStyle: 'circle',
                backgroundColor: ({ chart: { ctx } }: any) => {
                    // linear-gradient(180deg, rgba(0, 71, 255, 0.64) 0%, rgba(0, 71, 255, 0.00) 132%)
                    const bg = ctx.createLinearGradient(0, 0, 0, 250);
                    bg.addColorStop(0, 'rgba(0, 71, 255, 0.64)');
                    bg.addColorStop(1, 'rgba(0, 71, 255, 0.00)');

                    // More config for your gradient
                    return bg;
                }
                // backgroundColor: 'linear-gradient(180deg, rgba(0, 71, 255, 0.64) 0%, rgba(0, 71, 255, 0.00) 132%)',
            }
        ]
    });

    const calcData = async () => {
        if (userDataList && userDataList.length > 0) {
            const res = await getStat(
                userDataProp?.token || '',
                'time-use',
                {
                    startDate: new Date(endDate.getFullYear(), endDate.getMonth(), 1, 24, 59, 59),
                    endDate,
                    idList: userDataList.map((user) => user.id ?? ''),
                    timeScale
                }
            );
            const res2 = await getStat(
                userDataProp?.token || '',
                'login',
                {
                    startDate,
                    endDate,
                    idList: userDataList.map((user) => user.id ?? ''),
                    timeScale
                }
            );
            // convert to miliseconds to minutes
            const logintime = parseInt((res?.data?.timeuse / 60).toFixed(0)) || 0;

            setNbMinutes(logintime);
            setNbLogin(res2.data.reduce((a: any,b: any) => a + b,0));

            setData({
                ...data,
                labels: getLabelFromDate(startDate, endDate, timeScale),
                datasets: [
                    {
                        ...data.datasets[0],
                        data: res2.data
                    }
                ]
            });
        }
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, startDate, endDate]);

    useEffect(() => {
        // console.log('userList', userList);
        // const fetchData = async () => {
        //     const tempList: Patient[] = [];
        //     await Promise.all(
        //         userList?.map(async (user) => {
        //             if (user && user.id) {
        //                 await getPatientUser(user.id).then((res) => {
        //                     if (res) {
        //                         tempList.push(res);
        //                     }
        //                 });
        //             }
        //         }) || []
        //     );
        // };
        if (userList) setUserDataList(userList);

        // fetchData();
    }, [userList]);

    return (
        <div
            id="profile-statistics"
            style={{
                ...classes['root']
            }}>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2.5rem'
                }}>
                {/* header */}
                <div style={{ ...classes['top-bg'] }} />
                <img src={graphStats} alt="graph" style={{ ...classes['top-graph-bg'] }} />
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '1rem 1.5rem',
                        gap: '0.75rem'
                    }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            marginBottom: '0.5rem'
                        }}>
                        <img src={statsIcon} alt="stats" style={{ ...classes['icon'] }} />
                        <SelectTimeline
                            optColorBlue
                            arrowWhite
                            sxRoot={{ ...classes['select-white-root'], width: 'fit-content' }}
                            sx={{
                                ...classes['select-white'],
                                width: 'fit-content',
                                padding: '0px 3rem 0px 1rem'
                            }}
                            value={timeScale}
                            onChange={(data: {
                                val: string;
                                startDate: Date;
                                endDate: Date;
                                timeScale: 'day' | 'week' | 'month' | 'year';
                            }) => {
                                setEndDate(data.endDate);
                                setStartDate(data.startDate);
                                setTimeScale(data.timeScale);
                            }}
                        />
                    </div>
                    <Typography variant="p" sx={{ ...classes['headText'] }}>
                        {t('useTime')}
                    </Typography>
                    <Typography variant="p" sx={{ ...classes['headTextBold'] }}>
                        {/* {t(`hour${nbHours > 1 ? 's' : ''}`, { h: nbHours })} */}
                        {nbMinutes > 120
                            ? t('hours', { h: Math.round(nbMinutes / 60) })
                            : t(`minute${nbMinutes > 1 ? 's' : ''}`, { m: nbMinutes })}
                    </Typography>
                    <Typography variant="p" sx={{ ...classes['headText'] }}>
                        {t(
                            `TimeProgression${
                                evolTimeNb.includes('+')
                                    ? '+'
                                    : evolTimeNb.includes('-')
                                    ? '-'
                                    : '='
                            }`,
                            { p: `${evolTimeNb}` }
                        )}
                    </Typography>
                </div>
                {/* 1 */}
                <div style={{ ...classes['stat-card-container'] }}>
                    <div style={{ ...classes['stat-card-title-container'] }}>
                        <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                            {t('profile.statistics.nbLoginTitle')}
                        </Typography>
                        <StatToolkit
                            value={nbLogin}
                            tooltipText={t('profile.statistics.nbLoginTooltip', { nb: nbLogin })}
                        />
                    </div>
                    <div style={{ ...classes['stat-card'] }}>
                        <StimbotInfo text={t('profile.statistics.nbLoginInfo')} />
                        <SelectTimeline
                            value={timeScale}
                            onChange={(data: {
                                val: string;
                                startDate: Date;
                                endDate: Date;
                                timeScale: 'day' | 'week' | 'month' | 'year';
                            }) => {
                                setEndDate(data.endDate);
                                setStartDate(data.startDate);
                                setTimeScale(data.timeScale);
                            }}
                        />
                        <Line options={options} data={data} />
                        <StatEvol evolNb={evolNb} />
                    </div>
                </div>

                {userDataList && user?.role === Roles.ADMIN && <StatsActifUsers userDataList={userDataList} />}
                {userDataList && <StatsImportMedia userDataList={userDataList} />}
                {userDataList && <StatsCreateGame userDataList={userDataList} />}
                {userDataList && <StatsLookedMedia userDataList={userDataList} />}
                {userDataList && <StatsGamesFinisVsStarted userDataList={userDataList} />}
                {userDataList && <StatsGames userDataList={userDataList} />}
                {userDataList && <StatsSatisfaction userDataList={userDataList} />}

                <Button
                    pink
                    sx={{
                        width: 'fit-content',
                        padding: '0.5rem 1rem',
                        alignSelf: 'center'
                        // marginTop: '1rem'
                    }}
                    onClick={() => {
                        // return on top of the profile-statistics
                        const element = document.getElementById('profile-statistics');
                        if (element) {
                            // scroll to top of the element
                            element.scrollTo({ top: 0, behavior: 'smooth' });
                        }
                    }}>
                    {t('profile.statistics.scrollToTop')}
                </Button>
                <div style={{ width: '100%', minHeight: '1rem' }} />
            </div>
        </div>
    );
}
