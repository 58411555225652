import React from 'react';
import './login.css';
import '../../utilities/utils.css';
import { CheckBox } from '../../components/CheckBox';
import { isPlatform } from '@ionic/react';
import closedEye from '../../assets/closedEye.svg'
import openEye from '../../assets/openedEye.svg';
import info from '../../assets/infoLogo.svg';
import { t } from 'i18next';

interface SingUpFormProps {
    register: (email: string, pass: string, codeBe: string, cgu: boolean) => void;
    setDisplayPage: (t: 'login' | 'singup' | 'forgot') => void;
    setDisplayInfoModal: (value: boolean) => void;
}

export function SingUpForm({ register, setDisplayPage, setDisplayInfoModal }: SingUpFormProps): JSX.Element {
    const [showPassword, setShowPassword] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [codeBe, setCodeBe] = React.useState('');
    const [cgu, setCgu] = React.useState(false);

    return (
        <>
            <h3>{t('singup.title')}</h3>
            <h5>{t('singup.subtitle')}</h5>
            <form id="signup-form" onSubmit={(e) => e.preventDefault()} method="post">
                <span id="see-login-form" onClick={() => setDisplayPage('login')}>&#10006;</span>
                <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input
                        type="email"
                        onChange={(e) => setEmail(e.target.value)}
                        className="form-control input"
                        id="signup-email"
                        placeholder="ex : jean.dupont@hotmail.com"
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="name">Mot de passe</label>
                    <div style={{ position: 'relative', width: '100%' }}>
                        <input
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPassword ? 'text' : 'password'}
                            className="form-control input"
                            id="signup-password"
                            placeholder="********"
                        />
                        <input
                            type="button"
                            id="show-password-regiser"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                                backgroundColor: 'transparent',
                                backgroundImage: `url(${showPassword ? openEye : closedEye})`,
                                width: '1.6rem',
                                height: '1.6rem',
                                border: 'none',
                                position: 'absolute',
                                right: '0.6rem',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                padding: '0px',
                                cursor: 'pointer'
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="codebe">
                        Code bonne étoile
                        <img
                            id="info-modal-trigger"
                            src={info}
                            onClick={() => setDisplayInfoModal(true)}
                            alt="info"
                            style={{ height: '1.2rem', width: '1.2rem', cursor: 'pointer', marginLeft: '0.5rem' }}
                        />
                    </label>
                    <input
                        onChange={(e) => setCodeBe(e.target.value)}
                        type="text"
                        className="form-control input"
                        id="signup-codebe"
                        placeholder="A1b2C3"
                    />
                </div>
                <div className="form-group" style={{ display: 'flex' }}>
                    {/* <input
                        type="checkbox"
                        className="checkbox"
                        id="signup-CGU"
                        style={{
                            width: 'fit-content',
                            height: 'fit-content',
                            margin: 'auto 0'
                        }}
                    /> */}
                    <CheckBox sx={{ margin: 'auto 0' }} checked={cgu} setChecked={(e) => {
                        setCgu(e);
                    }} />
                    <label
                        htmlFor="signup-CGU"
                        style={{ fontSize: '1rem', fontWeight: 500, margin: 0 }}>
                        J'accepte les{' '}
                        <a
                            href="https://appv1.stimulin.fr/cgu.html"
                            target="_blank"
                            rel="noreferrer"
                            color="secondary">
                            conditions générales d'utilisation
                        </a>
                        {' '}et{' '}
                        <a
                            href="https://appv1.stimulin.fr/politique-de-confidentialite.html"
                            target="_blank"
                            rel="noreferrer"
                            color="secondary">
                            politique de confidentialité
                        </a>
                        {' '}de Stimul'in.
                    </label>
                </div>
                <div className="captcha-container form-group"></div>
                <button type="submit" id="btn-signup" className="btn btn-primary btn-block" onClick={() => register(email, password, codeBe, cgu)}>
                    M'inscrire
                </button>
                <span
                    id="or-span"
                    style={{
                        display: isPlatform('ipad') || isPlatform('iphone') ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#1c0d4b',
                        gap: '1rem',
                        fontSize: '1rem',
                        fontWeight: 500,
                        margin: '0px',
                        paddingTop: '2rem',
                        paddingBottom: '1.63rem',
                        width: '100%'
                    }}>
                    <hr
                        style={{
                            margin: '0px',
                            padding: '0px',
                            border: '0px',
                            height: '1px',
                            backgroundColor: '#1c0d4b',
                            width: '45%'
                        }}
                    />
                    OU
                    <hr
                        style={{
                            margin: '0px',
                            padding: '0px',
                            border: '0px',
                            height: '1px',
                            backgroundColor: '#1c0d4b',
                            width: '45%'
                        }}
                    />
                </span>
                <div
                    id="new-subscribe-container"
                    style={{
                        display: isPlatform('ipad') || isPlatform('iphone') ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}>
                    <h4 style={{ color: '#1c0d4b' }}>Vous n'avez pas de code bonne étoile ?</h4>
                    <p style={{ color: '#1c0d4b' }}>
                        Je souscris un abonnement pour activer mon espace familial
                    </p>
                    <button id="btn-subscribe" type="button" onClick={() => window.location.href = "https://buy.stripe.com/28o2ai6ic6qfg367sD"}>J'abonne ma famille</button>
                </div>
            </form>
        </>
    );
}
