import React, { CSSProperties, ReactElement } from 'react';
import closeIcon from '../assets/closeIcon.png';
import { Typography } from './Typography';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000,
    },
    modal: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: '0.8rem',
        zIndex: 1001,
        // padding: '10px',
        overflowY: 'auto',
    },
    'modal-header': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: '3rem',
        position: 'sticky',
        padding: '0.5rem',
        backgroundColor: 'white',
        top: '0',
    },
    'modal-body': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100% - 3rem)',
        marginTop: '0rem',
        padding: '0.5rem',
        marginBottom: '0.5rem',
    },
    'close-button': {
        width: '2rem',
        height: '2rem',
        borderRadius: '50%',
        border: 'none',
        outline: 'none',
        cursor: 'pointer'
    },
    'modal-title': {
        color: '#333',
        fontWeight: '600'
    }
};

interface Props {
    headerTitle?: string;
    closeModal?: null | (() => void);
    isOpen: boolean;
    children?: ReactElement;
    width?: string;
    height?: string;
    minHeight?: string;
    minWidth?: string;
    sxRoot?: CSSProperties;
    sxPopup?: CSSProperties;
}

export function PopupModal({
    headerTitle,
    closeModal = null,
    isOpen,
    children,
    width,
    height,
    minHeight,
    minWidth,
    sxRoot,
    sxPopup
}: Props): JSX.Element {
    return (
        <div style={{ ...classes['div-root'], display: isOpen ? 'flex' : 'none', ...sxRoot }}>
            <div
                style={{
                    ...classes['modal'],
                    width: !width ? '50%' : width,
                    height: !height ? '50%' : height,
                    minHeight: !minHeight ? 'auto' : minHeight,
                    minWidth: !minWidth ? 'auto' : minWidth,
                    ...sxPopup
                }}>
                <div style={{ ...classes['modal-header'] }}>
                    <Typography variant="h4" sx={{ ...classes['modal-title'] }}>
                        {headerTitle}
                    </Typography>
                    {closeModal !== null && (
                        <button style={{ ...classes['close-button'] }} onClick={closeModal}>
                            <img
                                src={closeIcon}
                                alt="close"
                                style={{ width: '100%', height: '100%', borderRadius: '50%' }}
                            />
                        </button>
                    )}
                </div>
                <div style={{ ...classes['modal-body'] }}>{children}</div>
            </div>
        </div>
    );
}
