import { IonAvatar } from '@ionic/react';
import React, { ReactElement, CSSProperties } from 'react';
import { getImageKitUrlFrom } from '../utilities/utils';

const classes: { [key: string]: CSSProperties } = {
    'profile-image-root': {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '10px'
    }
};

interface ProfileImageProps {
    width: number;
    height: number;
    name: string;
    sx?: CSSProperties;
    url: string | File | undefined;
}

function stringToColor(string: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    /* eslint-enable no-bitwise */
    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function stringIonAvatar(name: string, width: number, height: number, sx?: CSSProperties) {
    return {
        style: {
            fontSize: '1.6rem',
            width,
            height,
            display: 'flex',
            textAlign: 'center',
            color: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: stringToColor(name),
            padding: '33% 0',
            textTransform: 'uppercase',
            ...sx
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
    };
}

export function ProfileImage({ width, height, name, url, sx }: ProfileImageProps): ReactElement {
    const initials = (): ReactElement => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <IonAvatar {...stringIonAvatar(name, width, height, sx)} />;
    };

    const profile = (): ReactElement => {
        if (typeof url === 'string') {
            return (
                <IonAvatar style={{ ...sx }}>
                    <img src={getImageKitUrlFrom(url!, width, height)} alt="profil picture" />
                </IonAvatar>
            );
        }
        return (
            <IonAvatar style={{ width, height }}>
                <img
                    src={url ? getImageKitUrlFrom(url?.webkitRelativePath, width, height) : ''}
                    alt="profil picture"
                />
            </IonAvatar>
        );
    };

    return (
        <div style={{ ...classes['profile-image-root'], ...sx }}>
            {url ? profile() : initials()}
        </div>
    );
}
