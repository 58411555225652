import { WithTranslation, withTranslation } from "react-i18next";

import style from "./style.module.css";
import { FunctionComponent, SVGProps } from "react";
import { Button } from "../Button/index";
import Dropdown from "../Dropdown";

interface TabBarProps extends WithTranslation {
    onSelected: (value: string) => void;
    items: { active: boolean, value: string, label: string, icon?: FunctionComponent<SVGProps<SVGSVGElement>> }[];
};

const TabBar = ({ t, items, onSelected }: TabBarProps) => {
    return (
        <nav className={style.root}>
            <div className={style.desktop}>
                {items.map((item, index) => (
                    <Button
                        key={index}
                        theme={item.active ? "PRIMARY" : "SECONDARY"}
                        size="MEDIUM"
                        className={style.item}
                        Icon={item.icon}
                        action={() => onSelected(item.value)}
                        label={item.label}
                    />
                ))}
            </div>
            <div className={style.mobile}>
                <Dropdown
                    items={items}
                    action={(item) => onSelected(item.value)}
                />
            </div>
        </nav>
    );
};

export default withTranslation()(TabBar);