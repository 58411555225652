import React, { useContext, useEffect } from 'react';

import { UserContext } from '../providers/UserProvider';
import HomePro from '../components/HomePro';
import HomeUser from '../components/HomeUser';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { MenuProvider } from '../providers/MenuProvider';

const NewHome: React.FC = () => {
    const userDataProp = useContext(UserContext);
    const role = ['ADMIN', 'THERAPIST'].includes(userDataProp?.user?.role || '') ? 'pro' : 'user';

    useEffect(() => {
        try {
            ScreenOrientation.unlock();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <MenuProvider>
            {role === 'pro'
                ? <HomePro />
                : <HomeUser />
            }
        </MenuProvider>
    );
};

export default NewHome;