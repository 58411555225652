import React, { useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Exercise } from '../../models/Exercise';
import { ExerciseItem } from '../../models/ExerciseItem';
import { randomPicture } from '../../utilities/utils';
import { UserContext } from '../../providers/UserProvider';
import { Roles } from '../../models/Roles';
import { UserDataProp } from '../../models/userDataProp';
import { ExerciseType } from '../../models/ExerciseType';
import { QuizExercise2 } from './QuizExercise2';
import { useGame } from '../../contexts/GameContext';
import { GameRef } from '../../providers/GameProvider';
import { Loader } from '../Loader';
import { getGame } from '../../stores/Game';
import { Media } from '../../models/Media';

export const Quizz = React.forwardRef((_, ref) => {
    const quizz2Ref = useRef<GameRef>();
    const { endGame, startTimer, stopTimer, displayFunfact, closeFunfact, hideUi } = useGame();
    const { id } = useParams<{ id: string }>();
    const { lvlId } = useParams<{ lvlId: string }>();
    const level = parseInt(lvlId);
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [errorCount, setErrorCount] = useState<number>(0);
    const [clueCount, setClueCount] = useState<number>(0);
    const [completedExercises, setCompletedExercises] = useState<string[]>([]);
    const [exerciseItemList, setExerciseItemList] = useState<Media[]>([]);
    const [currentExerciseIndex, setCurrentExerciseIndex] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useImperativeHandle(ref, () => ({
        tips: () => {
            if (quizz2Ref.current) {
                quizz2Ref.current?.tips();
            }
        },
    }));

    useEffect(() => {
        if (level >= 6) {
            hideUi(); // Hide UI on levels with preview
        }
        startTimer();
        if (user?.role === Roles.SENIOR) {
            localStorage.setItem('startTime', new Date().toString());
        }
    }, []);

    const getItemList = async (globalExercise: Exercise): Promise<void> => {
        let listArray: number[] = [];
        const list: Media[] = [];
        listArray = randomPicture(level, ExerciseType.QUIZ);
        if (globalExercise.mediaList) {
            await Promise.all(
                listArray.map(async (element) => {
                    if (globalExercise.mediaList && globalExercise.mediaList[element]) {
                        const item = globalExercise.mediaList[element];
                        if (item && item.id) {
                            list.push(item);
                        }
                    }
                })
            );
            setExerciseItemList(list);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const getExerciseList = async (): Promise<void> => {
            if (user && user.id) {
                const globalExercise = await getGame(userDataProp.token ?? '', id);
                if (globalExercise && id !== undefined && id !== null) {
                    await getItemList(globalExercise);
                }
            }
        };
        getExerciseList();
    }, [id, userDataProp]);

    useEffect(() => {
        if (exerciseItemList.length > 0 && completedExercises.length === exerciseItemList.length) {
            stopTimer();
            const funFact = exerciseItemList.find(item => item.anecdote);
            if (funFact) {
                displayFunfact({
                    title: funFact?.title || '',
                    text: funFact?.anecdote,
                    Picture: funFact?.url as string,
                    onClose: () => {
                        closeFunfact();
                        endGame({
                            clueCount,
                            errorCount,
                        });
                    },
                });
            } else {
                endGame({
                    clueCount,
                    errorCount,
                });
            }
        }
    }, [completedExercises, exerciseItemList]);

    const handleExerciseComplete = (completedExerciseId: string) => {
        setCompletedExercises(prev => [...prev, completedExerciseId]);
        if (currentExerciseIndex < exerciseItemList.length - 1) {
            setCurrentExerciseIndex(prev => prev + 1);
        }
    };

    if (isLoading)
        return <Loader />;

    return <QuizExercise2
        key={currentExerciseIndex}
        ref={quizz2Ref}
        memoryLevel={level ?? 1}
        exerciseItemList={exerciseItemList}
        currentExercise={exerciseItemList[currentExerciseIndex]}
        completedExercises={completedExercises}
        setCompletedExercises={handleExerciseComplete}
        incrementErrorCount={() => setErrorCount(errorCount + 1)}
        incrementClueCount={() => setClueCount(clueCount + 1)}
    />;
});
