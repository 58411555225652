import React, { useState, useEffect } from 'react';

import styles from './styles.module.css';
import EinsteinSVG from './einstein.svg?react';
import TipsSVG from './tips.svg?react';
import SpeechBubble from '../SpeechBubble';

export interface Message {
    text: string;
    color?: 'success' | 'wrong' | 'info';
}

interface TipsProps {
    message?: Message;
    onClick: () => void;
}

const Tips: React.FC<TipsProps> = ({ message, onClick }) => {

    return (
        <div className={styles.container} onClick={onClick}>
            <>
                <EinsteinSVG className={styles.einstein} />
                {!message && <TipsSVG className={styles.tips} />}
            </>
            {message && <div className={styles.bubble}>
                <SpeechBubble message={message.text} color={message.color} />
            </div>}
        </div>
    );
};


export default Tips;