import { useContext } from 'react';
import './Header.css';
import { IonAvatar, IonHeader, isPlatform } from '@ionic/react';
import React from 'react';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { getImageKitUrlFrom, stringToColor } from '../utilities/utils';
import logo from '../assets/logo.png';
import { useHistory, useLocation } from 'react-router';
import logoSmall from '../assets/logo-small.svg';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Typography } from './Typography';
import { Button } from './Button';
import BackButton from '../assets/backButton.png';

interface Props {
    refresh?: () => void;
    backFunc?: () => void;
}

export function Header({ refresh, backFunc }: Props): JSX.Element {
    const history = useHistory();
    const { height, width } = useWindowDimensions();
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    return (
        // style={{ display: window.location.pathname === '/pong' ? 'none' : 'flex'  }}
        <IonHeader
            class="header ion-no-border"
            style={{
                height: '5rem'
            }}>
            {pathname === '/forgot-password' && backFunc ? (
                <Button
                    sx={{ height: '80%', width: 'auto', padding: '0.8rem' }}
                    outline
                    onClick={(): void => backFunc()}>
                    <img style={{}} src={BackButton} alt="back button" />
                </Button>
            ) : (
                <img
                    style={{ cursor: 'pointer', border: '0.3rem solid transparent' }}
                    onClick={
                        refresh
                            ? refresh
                            : () => {
                                  history.push('/');
                              }
                    }
                    src={
                        (width && width < 768) ||
                        isPlatform('ipad') ||
                        isPlatform('ios') ||
                        isPlatform('android')
                            ? logoSmall
                            : logoSmall
                    }
                    alt="logo"
                />
            )}
            {(pathname === '/forgot-password' ||
                pathname === '/login' ||
                pathname === '/register' ||
                pathname.slice(0, 6) === '/login' ||
                pathname === '/new-account' ||
                pathname === '/new-account-confirm' ||
                pathname === '/guide-utilisateurs') && (
                <Typography
                    sx={{
                        fontSize: '1rem',
                        fontWeight: '500',
                        cursor: 'pointer'
                    }}
                    onClick={(): void => history.push('/login/me')}>
                    {t('loginMe')}
                </Typography>
            )}

            {user && user.profilePhoto && typeof user.profilePhoto === 'string' && (
                <div
                    style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                    onClick={(): void => {
                        if (user) history.push('/profile');
                    }}>
                    {(width && width < 768) ||
                    isPlatform('ipad') ||
                    isPlatform('ios') ||
                    isPlatform('android')
                        ? t('MyInfo')
                        : t('MyInfo')}
                    <IonAvatar>
                        <img
                            src={getImageKitUrlFrom(user.profilePhoto, 150, 150)}
                            alt="profil picture"
                        />
                    </IonAvatar>
                </div>
            )}
            {user &&
                user.firstName &&
                user.lastName &&
                (!user.profilePhoto || typeof user.profilePhoto !== 'string') && (
                    <div
                        style={{ cursor: 'pointer', fontSize: '1.2rem' }}
                        onClick={(): void => {
                            if (user) history.push('/profile');
                        }}>
                        {(width && width < 768) ||
                        isPlatform('ipad') ||
                        isPlatform('ios') ||
                        isPlatform('android')
                            ? t('MyInfo')
                            : t('MyInfo')}

                        <div
                            onClick={(): void => {
                                if (user) history.push('/profile');
                            }}
                            style={{
                                marginLeft: '1rem',
                                height: '3.2rem',
                                width: '3.2rem',
                                borderRadius: '50%',
                                // position: 'absolute',
                                // top: '0.5rem',
                                cursor: 'pointer',
                                fontSize: '1.2rem',
                                // right: '0.5rem',
                                zIndex: 1,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'uppercase',
                                color: '#FFFFFF',
                                backgroundColor: stringToColor(`${user.firstName} ${user.lastName}`)
                            }}>
                            {`${user.firstName.split('')[0]}${user.lastName.split('')[0]}`}
                        </div>
                    </div>
                )}
        </IonHeader>
    );
}
