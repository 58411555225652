/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGlobalStyle, css } from 'styled-components';

// eslint-disable-next-line import/no-default-export
export default createGlobalStyle`
  ${({ theme }) => css`
      html {
          height: 100%;

          body {
              display: flex;
              flex-direction: column;
              height: 100%;
              width: 100%;
              margin: 0;

              #root {
                  background: ${theme.colors.background};
                  color: ${theme.colors.darkorange};
                  display: flex;
                  font-family: sans-serif;
                  height: 100%;
                  justify-content: center;
                  padding: 15px;
              }
          }
      }
  `}
`;
