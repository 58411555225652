/* eslint-disable no-plusplus */
import global from '../../global';
import { GRID } from '../../typings';

/**
 * A function to check if the grid is full
 * @param grid A 9x9 array consisting of values from 0-9
 */
function checkGrid(grid: GRID): boolean {
    for (let i = 0; i < global.gridSize; i++) for (let j = 0; j < global.gridSize; j++) if (grid[i][j] === 0) return false;
    return true;
}

// eslint-disable-next-line import/no-default-export
export default checkGrid;
