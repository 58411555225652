import { useRef, useState } from "react"
import { GameProvider, GameRef } from "../../../providers/GameProvider";
import { sudok } from "../../../models/Games";
import { Sudok } from "../../../components/Sudok";
import { CongratsWindow } from "../../../windows/Congrats";
import { FeedbackWindow } from "../../../windows/Feedback";
import { NextGameWindow } from "../../../windows/NextGame";
import CongratsSvg from '../assets/congrats.svg?react';
import EinsteinSvg from '../assets/einstein.svg?react';
import styles from '../assets/styles.module.css';
import { useParams } from "react-router-dom";

export default () => {
    const sudokRef = useRef<GameRef>(null);
    const [refreshKey, setRefreshKey] = useState<number>(0);
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);

    const getTips = () => {
        if (!sudokRef.current) return;
        sudokRef.current?.tips();
    };

    const resetGame = () => {
        setRefreshKey(refreshKey + 1);
    };

    const colors = {
        primary: sudok.color,
        secondary: sudok.secondaryColor,
        border: sudok.border,
    };

    return (
        <GameProvider
            onTipsClick={getTips}
            colors={colors}
            onReset={resetGame}
            endWindows={[{
                customImage: { Image: CongratsSvg, className: styles.congrats },
                Component: CongratsWindow,
            }, {
                Component: FeedbackWindow,
            }, {
                customImage: { Image: EinsteinSvg, className: styles.einstein },
                Component: NextGameWindow,
            }]}
            tipsEnabled
            instructionEnabled
            background={sudok.background}
            instructionMessage={sudok.instructions?.find((instruction) => instruction.maxLvl >= lvl)?.text}
        >
            <Sudok key={refreshKey} ref={sudokRef} />
        </GameProvider>
    );
};