import * as pictures from '../assets';

export interface Item {
    picture: string;
    title: string;
    funFact?: string;
}

export const items: Item[] = [
    {
        picture: pictures.Abstract1,
        // TODO : Change this one, it is the same as the next one
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la célèbre statue de la Vénus de Milo, datant de l'Antiquité grecque, a été découverte par un fermier sur l'île de Milos en 1820 ?`,
        title: 'Art abstrait',
    },
    { 
        picture: pictures.Abstract2,
        title: 'Art abstrait',
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la célèbre statue de la Vénus de Milo, datant de l'Antiquité grecque, a été découverte par un fermier sur l'île de Milos en 1820 ?` },
    { 
        picture: pictures.Abstract3,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Paul Cézanne est considéré comme un précurseur du cubisme et de l'art moderne, influençant des artistes tels que Picasso et Matisse ?` },
    { 
        picture: pictures.Abstract4,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que Leonardo da Vinci a conçu des plans pour des inventions telles que des machines volantes, des voitures et des ponts, bien avant leur temps ?` },
    { 
        picture: pictures.Abstract5,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "Le Cri" d'Edvard Munch est inspiré d'une expérience personnelle où l'artiste a ressenti une profonde angoisse en contemplant le coucher de soleil ?` },
    { 
        picture: pictures.Abstract6,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du Pop Art, caractérisé par l'utilisation d'images populaires et de techniques de reproduction en série, a émergé dans les années 1950 et 1960 ?` },
    { 
        picture: pictures.Abstract7,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Pablo Picasso a co-fondé le mouvement cubiste avec Georges Braque, marquant ainsi une nouvelle ère dans l'histoire de l'art ?` },
    { 
        picture: pictures.Abstract8,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "La Nuit étoilée" de Vincent van Gogh a été peint alors qu'il était interné dans un asile psychiatrique ?` },
    { 
        picture: pictures.Abstract9,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre italien Raphaël était surnommé "le prince des peintres" en raison de son talent exceptionnel et de son influence sur l'art de la Renaissance ?` },
    { 
        picture: pictures.Abstract10,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la célèbre Mona Lisa de Léonard de Vinci est considérée comme l'une des œuvres d'art les plus précieuses et les plus célèbres au monde ?` },
    { 
        picture: pictures.Abstract11,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Salvador Dalí était également un sculpteur, un écrivain et un cinéaste prolifique, célèbre pour ses œuvres surréalistes et ses extravagances ?` },
    { 
        picture: pictures.Abstract12,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le tableau "Le Déjeuner sur l'herbe" d'Édouard Manet a choqué le public lors de son exposition en 1863 en raison de sa représentation audacieuse de la nudité féminine ?` },
    { 
        picture: pictures.Abstract13,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre sculpteur français Auguste Rodin a été critiqué pour son œuvre "Le Baiser" lors de sa première présentation, mais est maintenant considéré comme l'un de ses chefs-d'œuvre les plus emblématiques ?` },
    { 
        picture: pictures.Abstract14,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du surréalisme, inspiré par les théories psychanalytiques de Sigmund Freud, visait à explorer le monde de l'inconscient à travers des œuvres d'art ?` },
    { 
        picture: pictures.Abstract15,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "Les Demoiselles d'Avignon" de Pablo Picasso a été un tournant majeur dans le développement de l'art moderne et du cubisme ?` },
    { 
        picture: pictures.Abstract16,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre néerlandais Johannes Vermeer a créé certaines des œuvres les plus énigmatiques et les plus captivantes de l'art du XVIIe siècle, notamment "La Jeune Fille à la perle" ?` },
    { 
        picture: pictures.Abstract17,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la statue de David de Michel-Ange, une icône de la Renaissance italienne, mesure plus de 5 mètres de haut et a été sculptée dans un seul bloc de marbre ?` },
    { 
        picture: pictures.Abstract18,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Édouard Manet était un précurseur de l'impressionnisme et un innovateur dans son approche de la représentation de la vie urbaine et de la société moderne ?` },
    { 
        picture: pictures.Abstract19,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique de l'Art nouveau, caractérisé par des motifs organiques et des lignes fluides, a eu une influence majeure sur l'architecture, le design et les arts décoratifs à la fin du XIX` },
    { 
        picture: pictures.Abstract20,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Joan Miró était également un sculpteur et un céramiste, célèbre pour ses œuvres ludiques et colorées inspirées de l'art abstrait ?` },
    { 
        picture: pictures.Abstract21,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du réalisme, émergé au XIXe siècle, visait à représenter la réalité quotidienne avec une précision et un détail photographiques ?` },
    { 
        picture: pictures.Abstract22,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre espagnol Diego Velázquez était également un talentueux portraitiste de la cour royale, célèbre pour ses représentations réalistes et psychologiquement profondes ?` },
    { 
        picture: pictures.Abstract23,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du romantisme, né à la fin du XVIIIe siècle, mettait l'accent sur l'expression des émotions, de l'imagination et de la nature sauvage ?` },
    { 
        picture: pictures.Abstract24,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre français Pierre-Auguste Renoir était un membre influent du mouvement impressionniste, connu pour ses scènes de la vie quotidienne et ses portraits lumineux ?` },
    { 
        picture: pictures.Abstract25,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le sculpteur italien Donatello était l'un des premiers artistes à redécouvrir les techniques de la sculpture en bronze de l'Antiquité romaine ?` },
    { 
        picture: pictures.Abstract26,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du futurisme, fondé en Italie au début du XXe siècle, célébrait la vitesse, la technologie et la modernité dans l'art ?` },
    { 
        picture: pictures.Abstract27,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre hollandais Piet Mondrian était un pionnier de l'art abstrait, connu pour ses compositions géométriques et ses couleurs primaires ?` },
    { 
        picture: pictures.Abstract28,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du dadaïsme, né pendant la Première Guerre mondiale, rejetait les conventions artistiques traditionnelles au profit de l'absurde et de la provocation ?` },
    { 
        picture: pictures.Abstract29,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre américain Jackson Pollock était célèbre pour sa technique de "dripping", consistant à projeter de la peinture sur la toile avec des mouvements spontanés et énergiques ?` },
    { 
        picture: pictures.Abstract30,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du symbolisme, émergé à la fin du XIXe siècle, explorait des thèmes ésotériques, mystiques et fantastiques dans l'art ?` },
    { 
        picture: pictures.Abstract31,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Henri Matisse était un chef de file du mouvement fauviste, caractérisé par l'utilisation expressive de la couleur et la simplification des formes ?` },
    { 
        picture: pictures.Abstract32,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du constructivisme, né en Russie après la Révolution d'Octobre, promouvait l'art comme un moyen de transformer la société et de construire un avenir meilleur ?` },
    { 
        picture: pictures.Abstract33,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Édouard Manet était un précurseur de l'impressionnisme et un innovateur dans son approche de la représentation de la vie urbaine et de la société moderne ?` },
    { 
        picture: pictures.Abstract34,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique de l'Art nouveau, caractérisé par des motifs organiques et des lignes fluides, a eu une influence majeure sur l'architecture, le design et les arts décoratifs à la fin du XIXe siècle ?` },
    { 
        picture: pictures.Abstract35,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Joan Miró était également un sculpteur et un céramiste, célèbre pour ses œuvres ludiques et colorées inspirées de l'art abstrait ?` },
    { 
        picture: pictures.Abstract36,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du réalisme, émergé au XIXe siècle, visait à représenter la réalité quotidienne avec une précision et un détail photographiques ?` },
    { 
        picture: pictures.Abstract37,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre espagnol Diego Velázquez était également un talentueux portraitiste de la cour royale, célèbre pour ses représentations réalistes et psychologiquement profondes ?` },
    { 
        picture: pictures.Abstract38,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du romantisme, né à la fin du XVIIIe siècle, mettait l'accent sur l'expression des émotions, de l'imagination et de la nature sauvage ?` },
    { 
        picture: pictures.Abstract39,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre français Pierre-Auguste Renoir était un membre influent du mouvement impressionniste, connu pour ses scènes de la vie quotidienne et ses portraits lumineux ?` },
    { 
        picture: pictures.Abstract40,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le sculpteur italien Donatello était l'un des premiers artistes à redécouvrir les techniques de la sculpture en bronze de l'Antiquité romaine ?` },
    { 
        picture: pictures.Abstract41,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du futurisme, fondé en Italie au début du XXe siècle, célébrait la vitesse, la technologie et la modernité dans l'art ?` },
    { 
        picture: pictures.Abstract42,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre hollandais Piet Mondrian était un pionnier de l'art abstrait, connu pour ses compositions géométriques et ses couleurs primaires ?` },
    { 
        picture: pictures.Abstract43,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du dadaïsme, né pendant la Première Guerre mondiale, rejetait les conventions artistiques traditionnelles au profit de l'absurde et de la provocation ?` },
    { 
        picture: pictures.Abstract44,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre américain Jackson Pollock était célèbre pour sa technique de "dripping", consistant à projeter de la peinture sur la toile avec des mouvements spontanés et énergiques ?` },
    { 
        picture: pictures.Abstract45,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du symbolisme, émergé à la fin du XIXe siècle, explorait des thèmes ésotériques, mystiques et fantastiques dans l'art ?` },
    { 
        picture: pictures.Abstract46,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Henri Matisse était un chef de file du mouvement fauviste, caractérisé par l'utilisation expressive de la couleur et la simplification des formes ?` },
    { 
        picture: pictures.Abstract47,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du constructivisme, né en Russie après la Révolution d'Octobre, promouvait l'art comme un moyen de transformer la société et de construire un avenir meilleur ?` },
    { 
        picture: pictures.Abstract48,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Francisco Goya était reconnu pour sa capacité à capturer la nature humaine dans ses œuvres, allant de la satire politique à la réflexion sur la condition humaine ?` },
    { 
        picture: pictures.Abstract49,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du surréalisme, inspiré par les théories psychanalytiques de Sigmund Freud, visait à explorer le monde de l'inconscient à travers des œuvres d'art ?` },
    { 
        picture: pictures.Abstract50,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "Les Demoiselles d'Avignon" de Pablo Picasso a été un tournant majeur dans le développement de l'art moderne et du cubisme ?` },
    { 
        picture: pictures.Abstract51,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre néerlandais Johannes Vermeer a créé certaines des œuvres les plus énigmatiques et les plus captivantes de l'art du XVIIe siècle, notamment "La Jeune Fille à la perle" ?` },
    { 
        picture: pictures.Abstract52,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la statue de David de Michel-Ange, une icône de la Renaissance italienne, mesure plus de 5 mètres de haut et a été sculptée dans un seul bloc de marbre ?` },
    { 
        picture: pictures.Abstract53,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Édouard Manet était un précurseur de l'impressionnisme et un innovateur dans son approche de la représentation de la vie urbaine et de la société moderne ?` },
    { 
        picture: pictures.Abstract54,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique de l'Art nouveau, caractérisé par des motifs organiques et des lignes fluides, a eu une influence majeure sur l'architecture, le design et les arts décoratifs à la fin du XIXe siècle ?` },
    { 
        picture: pictures.Abstract55,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Joan Miró était également un sculpteur et un céramiste, célèbre pour ses œuvres ludiques et colorées inspirées de l'art abstrait ?` },
    { 
        picture: pictures.Abstract56,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du réalisme, émergé au XIXe siècle, visait à représenter la réalité quotidienne avec une précision et un détail photographiques ?` },
    { 
        picture: pictures.Abstract57,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre espagnol Diego Velázquez était également un talentueux portraitiste de la cour royale, célèbre pour ses représentations réalistes et psychologiquement profondes ?` },
    { 
        picture: pictures.Abstract58,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du romantisme, né à la fin du XVIIIe siècle, mettait l'accent sur l'expression des émotions, de l'imagination et de la nature sauvage ?` },
    { 
        picture: pictures.Abstract59,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre français Pierre-Auguste Renoir était un membre influent du mouvement impressionniste, connu pour ses scènes de la vie quotidienne et ses portraits lumineux ?` },
    { 
        picture: pictures.Abstract60,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le sculpteur italien Donatello était l'un des premiers artistes à redécouvrir les techniques de la sculpture en bronze de l'Antiquité romaine ?` },
    { 
        picture: pictures.Abstract61,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du futurisme, fondé en Italie au début du XXe siècle, célébrait la vitesse, la technologie et la modernité dans l'art ?` },
    { 
        picture: pictures.Abstract62,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre hollandais Piet Mondrian était un pionnier de l'art abstrait, connu pour ses compositions géométriques et ses couleurs primaires ?` },
    { 
        picture: pictures.Abstract63,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du dadaïsme, né pendant la Première Guerre mondiale, rejetait les conventions artistiques traditionnelles au profit de l'absurde et de la provocation ?` },
    { 
        picture: pictures.Abstract64,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre américain Jackson Pollock était célèbre pour sa technique de "dripping", consistant à projeter de la peinture sur la toile avec des mouvements spontanés et énergiques ?` },
    { 
        picture: pictures.Abstract65,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du symbolisme, émergé à la fin du XIXe siècle, explorait des thèmes ésotériques, mystiques et fantastiques dans l'art ?` },
    { 
        picture: pictures.Abstract66,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Henri Matisse était un chef de file du mouvement fauviste, caractérisé par l'utilisation expressive de la couleur et la simplification des formes ?` },
    { 
        picture: pictures.Abstract67,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du constructivisme, né en Russie après la Révolution d'Octobre, promouvait l'art comme un moyen de transformer la société et de construire un avenir meilleur ?` },
    { 
        picture: pictures.Abstract68,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Francisco Goya était reconnu pour sa capacité à capturer la nature humaine dans ses œuvres, allant de la satire politique à la réflexion sur la condition humaine ?` },
    { 
        picture: pictures.Abstract69,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du surréalisme, inspiré par les théories psychanalytiques de Sigmund Freud, visait à explorer le monde de l'inconscient à travers des œuvres d'art ?` },
    { 
        picture: pictures.Abstract70,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "Les Demoiselles d'Avignon" de Pablo Picasso a été un tournant majeur dans le développement de l'art moderne et du cubisme ?` },
    { 
        picture: pictures.Abstract71,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre néerlandais Johannes Vermeer a créé certaines des œuvres les plus énigmatiques et les plus captivantes de l'art du XVIIe siècle, notamment "La Jeune Fille à la perle" ?` },
    { 
        picture: pictures.Abstract72,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la statue de David de Michel-Ange, une icône de la Renaissance italienne, mesure plus de 5 mètres de haut et a été sculptée dans un seul bloc de marbre ?` },
    { 
        picture: pictures.Abstract73,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Édouard Manet était un précurseur de l'impressionnisme et un innovateur dans son approche de la représentation de la vie urbaine et de la société moderne ?` },
    { 
        picture: pictures.Abstract74,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique de l'Art nouveau, caractérisé par des motifs organiques et des lignes fluides, a eu une influence majeure sur l'architecture, le design et les arts décoratifs à la fin du XIXe siècle ?` },
    { 
        picture: pictures.Abstract75,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Joan Miró était également un sculpteur et un céramiste, célèbre pour ses œuvres ludiques et colorées inspirées de l'art abstrait ?` },
    { 
        picture: pictures.Abstract76,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du réalisme, émergé au XIXe siècle, visait à représenter la réalité quotidienne avec une précision et un détail photographiques ?` },
    { 
        picture: pictures.Abstract77,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre espagnol Diego Velázquez était également un talentueux portraitiste de la cour royale, célèbre pour ses représentations réalistes et psychologiquement profondes ?` },
    { 
        picture: pictures.Abstract78,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du romantisme, né à la fin du XVIIIe siècle, mettait l'accent sur l'expression des émotions, de l'imagination et de la nature sauvage ?` },
    { 
        picture: pictures.Abstract79,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre français Pierre-Auguste Renoir était un membre influent du mouvement impressionniste, connu pour ses scènes de la vie quotidienne et ses portraits lumineux ?` },
    { 
        picture: pictures.Abstract80,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le sculpteur italien Donatello était l'un des premiers artistes à redécouvrir les techniques de la sculpture en bronze de l'Antiquité romaine ?` },
    { 
        picture: pictures.Abstract81,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le mouvement artistique du futurisme, fondé en Italie au début du XXe siècle, célébrait la vitesse, la technologie et la modernité dans l'art ?` },
    { 
        picture: pictures.Abstract82,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre peintre hollandais Piet Mondrian était un pionnier de l'art abstrait, connu pour ses compositions géométriques et ses couleurs primaires ?` },
    { 
        picture: pictures.Abstract83,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que Vincent van Gogh, célèbre pour ses peintures expressionnistes, n'a vendu qu'un seul tableau de son vivant, mais est aujourd'hui considéré comme l'un des artistes les plus influents de tous les temps ?` },
    { 
        picture: pictures.Abstract84,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, connaissez-vous l'histoire de la Joconde de Léonard de Vinci ? Elle a été volée au Louvre en 1911, ce qui a fait sensation dans le monde entier, mais a été retrouvée deux ans plus tard en Italie` },
    { 
        picture: pictures.Abstract85,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que pendant des siècles, la statue de la Vénus de Milo, datant de l'Antiquité grecque, a été considérée comme un symbole de la beauté et de la perfection féminines, malgré la disparition de ses bras` },
    { 
        picture: pictures.Abstract86,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre tableau "Le Cri" d'Edvard Munch est souvent interprété comme une représentation de l'angoisse existentielle, mais peu savent que l'artiste a créé plusieurs versions de cette œuvre` },
    { 
        picture: pictures.Abstract87,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'une des peintures les plus célèbres de Gustav Klimt, "Le Baiser", est imprégnée de symbolisme et représente l'amour romantique dans un style caractéristique de l'Art nouveau` },
    { 
        picture: pictures.Abstract88,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que la sculpture de la Victoire de Samothrace, exposée au Louvre, est l'un des exemples les plus impressionnants de l'art hellénistique, représentant la déesse Niké, symbole de la victoire` },
    { 
        picture: pictures.Abstract89,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le célèbre artiste américain Andy Warhol a révolutionné l'art avec ses œuvres emblématiques de la culture pop, telles que ses sérigraphies de soupes Campbell` },
    { 
        picture: pictures.Abstract90,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste surréaliste Salvador Dalí était connu pour son imagination débordante et ses techniques picturales uniques, telles que la technique du "paranoïaque-critique"` },
    { 
        picture: pictures.Abstract91,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'une des caractéristiques les plus fascinantes de l'œuvre de René Magritte est son utilisation subversive d'objets familiers dans des contextes surréalistes, remettant en question notre perception de la réalité` },
    { 
        picture: pictures.Abstract92,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste contemporain Banksy est célèbre pour ses œuvres politiquement chargées et son anonymat, ce qui ajoute une dimension mystérieuse à son art urbain provocateur` },
    { 
        picture: pictures.Abstract93,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que Didier Triglia, artiste français, a créé une série de peintures en utilisant du vin comme pigment, explorant ainsi l'interaction entre l'art et la culture viticole` },
    { 
        picture: pictures.Abstract94,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste japonais Tatsuo Horiuchi a utilisé Microsoft Excel pour créer des paysages magnifiques, prouvant que l'art peut transcender les limites des logiciels informatiques` },
    { 
        picture: pictures.Abstract95,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste sud-coréen Kim Dong Yoo a développé une technique unique en peignant avec des bulles de savon, créant des œuvres éphémères mais captivantes` },
    { 
        picture: pictures.Abstract96,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste chinois Ai Weiwei a utilisé des milliers de gilets de sauvetage récupérés sur les plages grecques pour créer une installation monumentale, mettant en lumière la crise des réfugiés` },
    { 
        picture: pictures.Abstract97,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le sculpteur britannique Andy Goldsworthy utilise des matériaux naturels tels que des feuilles, des pierres et des branches pour créer des œuvres d'art éphémères dans la nature` },
    { 
        picture: pictures.Abstract98,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste français JR est célèbre pour ses installations photographiques monumentales, telles que le projet "Inside Out", qui donne une voix visuelle à des communautés du monde entier` },
    { 
        picture: pictures.Abstract99,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre américain Chuck Close crée des portraits photoréalistes époustouflants en utilisant une grille et des techniques de peinture minutieuses` },
    { 
        picture: pictures.Abstract100,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste brésilien Vik Muniz utilise souvent des matériaux non conventionnels tels que du sucre, du chocolat ou des déchets pour créer des œuvres d'art étonnantes` },
    { 
        picture: pictures.Abstract101,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste allemand Gerhard Richter est connu pour ses peintures abstraites et réalistes, explorant les limites de la perception et de la représentation` },
    { 
        picture: pictures.Abstract102,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le photographe américain Ansel Adams a capturé la majesté des parcs nationaux américains avec une précision et une beauté qui ont inspiré des générations d'artistes et de spectateurs` },
    { 
        picture: pictures.Abstract103,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste japonaise Yayoi Kusama est célèbre pour ses installations artistiques hallucinantes, notamment ses chambres à pois et ses sculptures immersives, qui transportent les spectateurs dans un monde de rêves et de couleurs` },
    { 
        picture: pictures.Abstract104,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre italien Giuseppe Arcimboldo a créé des portraits étonnants en utilisant des fruits, des légumes et des objets naturels, illustrant ainsi la fusion entre l'homme et la nature` },
    { 
        picture: pictures.Abstract105,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste sud-africain William Kentridge utilise souvent des techniques de dessin au fusain et d'animation pour créer des œuvres poignantes explorant des thèmes tels que l'histoire, la politique et la mémoire collective` },
    { 
        picture: pictures.Abstract106,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américaine Kara Walker est connue pour ses silhouettes découpées en papier noir, qui abordent des questions de race, de genre et d'identité dans l'Amérique contemporaine` },
    { 
        picture: pictures.Abstract107,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Salvador Dalí était fasciné par les théories de la relativité d'Albert Einstein, ce qui se reflète dans ses œuvres, où les objets semblent fondre et se déformer dans des paysages surréalistes` },
    { 
        picture: pictures.Abstract108,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Jean-Michel Basquiat, issu du mouvement graffiti, est devenu célèbre pour ses peintures vibrantes et ses dessins impulsifs, exprimant des thèmes de race, de classe et d'identité urbaine` },
    { 
        picture: pictures.Abstract109,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste britannique Damien Hirst est connu pour ses œuvres provocatrices, telles que ses sculptures d'animaux dans du formol et ses tableaux parsemés de papillons morts, interrogeant les notions de vie, de mort et de vanité` },
    { 
        picture: pictures.Abstract110,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre mexicain Frida Kahlo a utilisé son art pour explorer sa propre identité et ses souffrances physiques, créant des autoportraits emblématiques qui continuent d'inspirer et de captiver les spectateurs du monde entier` },
    { 
        picture: pictures.Abstract111,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste chilien Roberto Matta était associé au mouvement surréaliste et était connu pour ses paysages imaginaires et ses formes organiques, explorant les frontières de la réalité et de l'inconscient` },
    { 
        picture: pictures.Abstract112,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste néerlandais MC Escher est célèbre pour ses dessins impossibles et ses motifs répétitifs, défiant les lois de la perspective et de la géométrie pour créer des illusions optiques fascinantes` },
    { 
        picture: pictures.Abstract113,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Keith Haring est célèbre pour ses dessins au trait audacieux et ses figures stylisées, qui abordent des thèmes tels que l'activisme social, la sexualité et la lutte contre le sida` },
    { 
        picture: pictures.Abstract114,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste japonais Yoko Ono est reconnue pour ses performances artistiques provocatrices et ses œuvres conceptuelles, qui défient les conventions et interrogent les frontières de l'art` },
    { 
        picture: pictures.Abstract115,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre russe Wassily Kandinsky est considéré comme l'un des pionniers de l'art abstrait, explorant la relation entre la couleur, la forme et la musique dans ses œuvres` },
    { 
        picture: pictures.Abstract116,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Louise Bourgeois est connue pour ses sculptures évocatrices et ses installations monumentales, explorant des thèmes tels que la féminité, la famille et la mémoire` },
    { 
        picture: pictures.Abstract117,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre allemand Gerhard Richter utilise des techniques variées, allant de l'abstraction à la photoréalisme, pour explorer les possibilités de la peinture dans le monde contemporain` },
    { 
        picture: pictures.Abstract118,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste britannique Tracey Emin est célèbre pour ses œuvres confessionnelles et provocatrices, qui explorent des thèmes personnels tels que l'amour, la perte et la sexualité` },
    { 
        picture: pictures.Abstract119,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre belge René Magritte est connu pour ses images énigmatiques et ses jeux de mots visuels, qui remettent en question la nature de la représentation et de la réalité` },
    { 
        picture: pictures.Abstract120,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américaine Cindy Sherman utilise la photographie pour créer des autoportraits inventifs et souvent dérangeants, explorant des thèmes tels que l'identité, le genre et la représentation de soi` },
    { 
        picture: pictures.Abstract121,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste allemand Anselm Kiefer crée des œuvres monumentales qui explorent les thèmes de la mémoire collective, de l'histoire et de l'identité nationale` },
    { 
        picture: pictures.Abstract122,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre italien Amedeo Modigliani est célèbre pour ses portraits allongés et stylisés, capturant l'essence et la grâce de ses sujets avec une simplicité saisissante` },
    { 
        picture: pictures.Abstract123,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste russe Kazimir Malevich est célèbre pour avoir créé le mouvement artistique du "suprématisme", caractérisé par l'utilisation de formes géométriques et de couleurs primaires pour exprimer des idées philosophiques` },
    { 
        picture: pictures.Abstract124,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Paul Cézanne est considéré comme l'un des précurseurs de l'art moderne, explorant la géométrie et la structure dans ses paysages et natures mortes révolutionnaires` },
    { 
        picture: pictures.Abstract125,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Jackson Pollock était connu pour sa technique de "dripping", dans laquelle il projetaient de la peinture sur de grandes toiles, créant des compositions chaotiques et dynamiques` },
    { 
        picture: pictures.Abstract126,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre allemand Caspar David Friedrich est célèbre pour ses paysages romantiques, imprégnés d'une profonde spiritualité et d'une fascination pour la nature sauvage` },
    { 
        picture: pictures.Abstract127,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américaine Georgia O'Keeffe est connue pour ses peintures de fleurs géantes, qui explorent la beauté et la sensualité de la nature à travers des formes organiques abstraites` },
    { 
        picture: pictures.Abstract128,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Henri Rousseau, surnommé le "Douanier Rousseau", est célèbre pour ses jungles luxuriantes et ses scènes exotiques, malgré son manque d'expérience dans les voyages` },
    { 
        picture: pictures.Abstract129,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Robert Rauschenberg a exploré les frontières entre l'art et la vie quotidienne, créant des "combines" qui mêlent peinture, sculpture et objets trouvés` },
    { 
        picture: pictures.Abstract130,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste mexicaine Frida Kahlo a puisé dans sa propre expérience de la douleur physique et émotionnelle pour créer des autoportraits saisissants et émotionnels, devenant une figure emblématique du féminisme et de l'art latino-américain` },
    { 
        picture: pictures.Abstract131,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre néerlandais Piet Mondrian est célèbre pour ses compositions géométriques et ses lignes noires, qui reflètent sa quête de l'harmonie et de l'équilibre dans l'art abstrait` },
    { 
        picture: pictures.Abstract132,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Niki de Saint Phalle est connue pour ses sculptures monumentales de femmes voluptueuses et colorées, qui célèbrent la féminité et la joie de vivre` },
    { 
        picture: pictures.Abstract133,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste italien Michelangelo Merisi da Caravaggio a révolutionné la peinture baroque avec ses contrastes dramatiques de lumière et d'ombre, créant des compositions puissantes et émotionnelles` },
    { 
        picture: pictures.Abstract134,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Edward Hopper est célèbre pour ses peintures de la vie urbaine américaine, capturant la solitude et l'aliénation dans des scènes intemporelles de la vie quotidienne` },
    { 
        picture: pictures.Abstract135,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Berthe Morisot était l'une des rares femmes impressionnistes à exposer avec succès ses œuvres lors des Salons officiels de Paris, contribuant ainsi à l'évolution de l'art moderne` },
    { 
        picture: pictures.Abstract136,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste allemand Joseph Beuys a utilisé des matériaux non conventionnels tels que la graisse, le feutre et le miel pour créer des installations provocatrices qui interrogent la société contemporaine` },
    { 
        picture: pictures.Abstract137,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste russe Marc Chagall était connu pour ses œuvres surréalistes et poétiques, qui célèbrent l'amour, la spiritualité et la tradition juive dans un style distinctif et onirique` },
    { 
        picture: pictures.Abstract138,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Alexander Calder est célèbre pour ses sculptures cinétiques et mobiles, qui capturent le mouvement et l'énergie dans des formes abstraites et équilibrées` },
    { 
        picture: pictures.Abstract139,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Louise Nevelson était connue pour ses sculptures monumentales en bois assemblé, créant des compositions abstraites et texturées qui semblent flotter dans l'espace` },
    { 
        picture: pictures.Abstract140,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre espagnol Joan Miró était célèbre pour ses formes biomorphiques et ses couleurs vives, créant un monde imaginaire et fantastique dans ses peintures, sculptures et céramiques` },
    { 
        picture: pictures.Abstract141,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Mark Rothko était connu pour ses peintures de grands formats composées de blocs de couleur flottants, invitant les spectateurs à une expérience méditative et émotionnelle` },
    { 
        picture: pictures.Abstract142,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre norvégien Edvard Munch est célèbre pour son œuvre emblématique "Le Cri", qui exprime l'angoisse et le désespoir dans un style expressif et tourmenté` },
    { 
        picture: pictures.Abstract143,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américaine Kara Walker crée des silhouettes découpées en papier noir pour explorer les thèmes de la race, du genre et de l'histoire américaine, suscitant souvent des débats et des controverses` },
    { 
        picture: pictures.Abstract144,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste allemand Anselm Kiefer utilise souvent des matériaux bruts tels que la paille, la cendre et le plomb pour créer des peintures et des installations monumentales qui évoquent la mémoire collective et l'histoire allemande` },
    { 
        picture: pictures.Abstract145,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Jasper Johns est célèbre pour ses drapeaux et ses cibles, explorant les thèmes de l'identité nationale et de la perception visuelle à travers des motifs emblématiques` },
    { 
        picture: pictures.Abstract146,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre français Édouard Manet était un pionnier de l'impressionnisme et un provocateur social, défiant les conventions artistiques et politiques de son époque dans ses œuvres radicales` },
    { 
        picture: pictures.Abstract147,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Robert Rauschenberg était connu pour ses "combines" sculpturales, qui mêlent peinture, sculpture et objets trouvés pour créer des compositions éclatantes et multimédias` },
    { 
        picture: pictures.Abstract148,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste mexicaine Frida Kahlo a puisé dans sa propre expérience de la douleur physique et émotionnelle pour créer des autoportraits saisissants et émotionnels, devenant une figure emblématique du féminisme et de l'art latino-américain` },
    { 
        picture: pictures.Abstract149,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre néerlandais Piet Mondrian est célèbre pour ses compositions géométriques et ses lignes noires, qui reflètent sa quête de l'harmonie et de l'équilibre dans l'art abstrait` },
    { 
        picture: pictures.Abstract150,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Niki de Saint Phalle est connue pour ses sculptures monumentales de femmes voluptueuses et colorées, qui célèbrent la féminité et la joie de vivre` },
    { 
        picture: pictures.Abstract151,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Keith Haring est célèbre pour ses dessins au trait audacieux et ses figures stylisées, qui abordent des thèmes tels que l'activisme social, la sexualité et la lutte contre le sida` },
    { 
        picture: pictures.Abstract152,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que le peintre italien Michelangelo Merisi da Caravaggio a révolutionné la peinture baroque avec ses contrastes dramatiques de lumière et d'ombre, créant des compositions puissantes et émotionnelles` },
    { 
        picture: pictures.Abstract153,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Edward Hopper est célèbre pour ses peintures de la vie urbaine américaine, capturant la solitude et l'aliénation dans des scènes intemporelles de la vie quotidienne` },
    { 
        picture: pictures.Abstract154,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste française Berthe Morisot était l'une des rares femmes impressionnistes à exposer avec succès ses œuvres lors des Salons officiels de Paris, contribuant ainsi à l'évolution de l'art moderne` },
    { 
        picture: pictures.Abstract155,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste allemand Joseph Beuys a utilisé des matériaux non conventionnels tels que la graisse, le feutre et le miel pour créer des installations provocatrices qui interrogent la société contemporaine` },
    { 
        picture: pictures.Abstract156,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste russe Marc Chagall était connu pour ses œuvres surréalistes et poétiques, qui célèbrent l'amour, la spiritualité et la tradition juive dans un style distinctif et onirique` },
    { 
        picture: pictures.Abstract157,
        title: 'Art abstrait', 
        funFact: `En contemplant cette œuvre abstraite, nous nous connectons à l'art dans toute sa richesse et sa diversité. D'ailleurs, saviez-vous que l'artiste américain Alexander Calder est célèbre pour ses sculptures cinétiques et mobiles, qui capturent le mouvement et l'énergie dans des formes abstraites et équilibrées`
    },
];