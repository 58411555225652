import firestore, { Timestamp } from 'firebase/firestore';
import { MediaInformation } from './MediaInformation';
import { Roles } from './Roles';

export class StatTimeUseApp {
    userId?: string | undefined;

    startTime?: Timestamp | undefined;

    timeUp?: number | undefined;

    constructor(userId?: string, startTime?: Timestamp, timeUp?: number) {
        this.userId = userId;
        this.startTime = startTime;
        this.timeUp = timeUp;
    }
}

export const StatTimeUseAppConverter: firestore.FirestoreDataConverter<StatTimeUseApp> = {
    toFirestore(StatTimeUseApp) {
        return {
            userId: StatTimeUseApp.userId,
            startTime: StatTimeUseApp.startTime,
            timeUp: StatTimeUseApp.timeUp
        };
    },
    fromFirestore(snapshot, options): StatTimeUseApp {
        const data = snapshot.data(options);
        return new StatTimeUseApp(data.userId, data.startTime, data.timeUp);
    }
};
