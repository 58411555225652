import styled, { css } from 'styled-components';
import { NUMBERS } from '../../typings';

import Button from './button';
import global from '../../global';
import { useMemo } from 'react';
import useWindowDimensions from '../../../../utilities/useWindowDimensions';

const Container = styled.div<{ gridSize: number, isPortrait: boolean }>`
${({ gridSize, isPortrait }) => css`
    display: grid;
    grid-template-columns: repeat(${isPortrait && gridSize === 4 ? 4 : gridSize === 4 ? 2 : 3}, 1fr);
    grid-template-rows: repeat(${isPortrait && gridSize === 4 ? 1 : gridSize === 4 ? 2 : 3}, 1fr);
    gap: 10px;
    width: 100%;
    aspect-ratio: ${isPortrait ? 0 : 1};
`}`;

interface Props {
    onClick: () => void;
}
export function Numbers({ onClick }: Props): JSX.Element {
    const numbers = global.gridSize === 4 ? [1, 2, 3, 4] : [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const { height, width } = useWindowDimensions();
    const isPortrait = useMemo(() => {
        if (height && width) {
            return height > width;
        }
        return false;
    }, [height, width]);

    return (
        <Container isPortrait={isPortrait} gridSize={global.gridSize} onClick={onClick}>
            {numbers.map((value) => (
                <Button key={value} value={value} />
            ))}
        </Container>
    )
};
