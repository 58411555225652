import { useContext, useEffect, useState, useRef } from 'react';
import AlbumComponent from '../components/AlbumComponent';
import { Loader } from '../components/Loader';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { useMedia, useOneMedia } from '../stores/Media';
import Funfact from './games/Funfact';
import { MediaItem } from '../models/Media';
import { MenuProvider } from '../providers/MenuProvider';
import { lookupMedia } from '../stores/Stats';
import { ModalSendAllMedia } from '../components/ModalSendAllMedia';

const Album = () => {
  const userDataProp: UserDataProp | null = useContext(UserContext);
  const { data, loading, error } = useMedia(userDataProp?.token || '');
  const [mediaId, setMediaId] = useState<string>('');
  const { data: selectedMedia, loading: loadingMedia, error: errorMedia } = useOneMedia(mediaId, userDataProp?.token || '');
  const [isAdminModalOpen, setIsAdminModalOpen] = useState(false);
  const startTimeRef = useRef<number | null>(null);

  const recordLookupMedia = (id: string) => {
    if (startTimeRef.current !== null && userDataProp?.token) {
      const watchTime = Math.round((Date.now() - startTimeRef.current) / 1000);
      lookupMedia(userDataProp.token, { idMedia: id, watchTime })
        .then(response => {
          console.log('Media lookup response:', response);
        })
        .catch(error => {
          console.error('Error looking up media:', error);
        });
    }
  };

  useEffect(() => {
    if (mediaId) {
      startTimeRef.current = Date.now();
    } else {
      recordLookupMedia(mediaId);
      startTimeRef.current = null;
    }
  }, [mediaId, userDataProp?.token]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }

  if (mediaId) {

    if (loadingMedia) {
      return <Loader />;
    }

    if (errorMedia) {
      console.error(errorMedia);
      return <div>Error: {errorMedia.message}</div>;
    }

    const index = data.findIndex((media: MediaItem) => media.id === mediaId);

    const handleCloseMedia = () => {
      recordLookupMedia(mediaId);
      setMediaId('');
    };

    const handleNext = () => {
      if (index < data.length - 1) {
        recordLookupMedia(mediaId);
        setMediaId(data[index + 1].id);
      }
    };

    const handlePrev = () => {
      if (index > 0) {
        recordLookupMedia(mediaId);
        setMediaId(data[index - 1].id);
      }
    };

    return (
      <Funfact
        mode='album'
        type={selectedMedia?.type || 'IMAGE'}
        title={selectedMedia?.title || ''}
        Picture={selectedMedia?.url || ''}
        text={selectedMedia?.anecdote || ''}
        onClose={handleCloseMedia}
        onNext={index < data.length - 1 ? handleNext : undefined}
        onPrev={index > 0 ? handlePrev : undefined}
      />
    );
  }

  const isAdmin = userDataProp?.user?.role === 'ADMIN';

  return (
    <MenuProvider>
      {isAdmin && <ModalSendAllMedia isOpen={isAdminModalOpen} mediaList={data} closeModal={() => setIsAdminModalOpen(false)} />}
      <AlbumComponent media={data} onDisplay={(id) => setMediaId(id)} openAdminModal={isAdmin ? () => setIsAdminModalOpen(true) : undefined} />
    </MenuProvider>
  );
};

export default Album;