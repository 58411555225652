import React from 'react';

import styles from './styles.module.css';
import { ProfileImage } from '../tempComponents/ProfileImage';

import Star from './assets/star.svg?react';
import { t } from 'i18next';
import { Button } from '../../../components/Button/index';

export interface EncouragementProps {
    encouragement?: {
        msg: string;
        image: string;
        firstName: string;
        lastName: string;
    };
    onClose: () => void;
}

const Encouragement: React.FC<EncouragementProps> = ({ encouragement, onClose }) => {
    return (
        <div className={styles.container}>
            <div className={styles.imgAuthor}>
                <Star width={284} height={144} className={styles.star} />
                <ProfileImage
                    width={134}
                    height={134}
                    name={encouragement?.firstName + ' ' + encouragement?.lastName}
                    url={encouragement?.image}
                    className={styles.profileImage}
                />
            </div>
            <h1 className={styles.title}>{encouragement?.firstName}{' '}{t('giveMsg')}</h1>
            <p className={styles.message} >{encouragement?.msg}</p>
            <Button action={onClose} label={t('startGame')} />
        </div>
    );
};

export default Encouragement;