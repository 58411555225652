import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithCredential,
    signOut,
    sendPasswordResetEmail,
    updatePassword,
    UserCredential,
    updateEmail
} from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { fireAuth } from './firebase';
// eslint-disable-next-line import/no-cycle
import { addFamily, getUserByEmail, passwordChanged } from './cloudFirestore';
import { User } from '../models/User';
import { clickAnalytics } from './analyticsFunction';

export const signIn = async (email: string, password: string): Promise<UserCredential> => {
    return signInWithEmailAndPassword(fireAuth, email, password).then((res) => {
        return res;
    });
};

export const forgotPassword = async (email: string): Promise<void> => {
    return sendPasswordResetEmail(fireAuth, email);
};

export const signUp = async (
    email: string,
    password: string,
    code: string,
    endDate: Timestamp,
    startDate: Timestamp,
    establishmentCode: string,
    establishmentName: string,
    patientId: string,
    lovedPassword?: string,
    legalReferent?: boolean | string,
    firstName?: string,
    lastName?: string,
    relationship?: string,
    cguValidate?: boolean,
    isSubscribed?: boolean
): Promise<UserCredential | null | string | void> => {
    return createUserWithEmailAndPassword(fireAuth, email, password).then(async (res) => {
        return addFamily({
            email,
            familyCode: code,
            userId: res.user.uid,
            legalReferent: legalReferent ?? false,
            isChangePasswordRequired: false,
            endDate,
            startDate,
            establishmentCode,
            establishmentName,
            patient: patientId,
            cguValidate: cguValidate ?? true,
            cguValidateDate: Timestamp.now(),
            lovedPassword: lovedPassword ?? '',
            firstName,
            lastName,
            relationship,
            isSubscribed: isSubscribed ?? false
        });
    });
};

export const changePassword = async (
    oldPassword: string,
    newPassword: string,
    user: User
): Promise<{ success: boolean; error: string | undefined }> => {
    // relogin user
    if (user && user.emailId) {
        return signInWithEmailAndPassword(fireAuth, user.emailId, oldPassword)
            .then((res) => {
                const { currentUser } = fireAuth;
                if (!currentUser) return { success: false, error: 'error' };
                return updatePassword(currentUser, newPassword)
                    .then(() => {
                        if (user.userId) passwordChanged(user.userId);
                        return { success: true, error: undefined };
                    })
                    .catch((error) => {
                        return { success: false, error: 'error' };
                    });
            })
            .catch((error) => {
                return { success: false, error: 'wrongPassword' };
            });
    }
    return { success: false, error: 'error ici' };
};

export const changeEmail = async (
    newEmail: string,
    oldEmail: string,
    password: string
): Promise<boolean> => {
    const { currentUser } = fireAuth;
    if (!currentUser) return false;

    return getUserByEmail(newEmail).then((res) => {
        if (res === null) {
            return signInWithEmailAndPassword(fireAuth, oldEmail, password)
                .then((res) => {
                    return updateEmail(currentUser, newEmail)
                        .then(() => {
                            return true;
                        })
                        .catch((error) => {
                            return false;
                        });
                })
                .catch((error) => {
                    return false;
                });
        } else {
            return false;
        }
    });
};

export const logOut = async (user: User | undefined | null): Promise<void> => {
    if (user && user.role) {
        clickAnalytics('logout', user);
    }
    return signOut(fireAuth);
};
