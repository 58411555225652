import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from './Typography';
import seniorImg from '../assets/seniorImg.png';
import proImg from '../assets/proImg.png';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { DisplayUser } from './DisplayUser';
import { User } from '../models/User';
import { Patient } from '../models/Patient';
import { getPatientFromEstablishment, getTherapistUsers } from '../services/cloudFirestore';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem'
    },
    desc: {
        marginBottom: '1.5rem',
        textAlign: 'left',
        alignSelf: 'left',
        width: '100%',
        color: '#333',
        fontSize: '0.875',
        fontWeight: '400'
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '8.75rem',
        padding: '0.5rem',
        borderRadius: '1rem',
        backgroundColor: '#fff',
        boxShadow: '0px 4px 12px 0px rgba(164, 161, 196, 0.74)',
        gap: '0.5rem',
        cursor: 'pointer'
    },
    'item-img': {
        borderRadius: '0.5rem'
    },
    'item-text': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        width: '100%',
        height: '100%'
    },
    h2: {
        color: '#0D224B',
        textAlign: 'left',
        fontSize: '1.25rem',
        fontWeight: '600'
    },
    p: {
        color: '#8B8B8B',
        fontSize: '0.75rem',
        fontWeight: '500',
        lineHeight: '1rem'
    },
    'user-container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'start',
        width: '100%',
        height: '100%',
        gap: '0rem'
    }
};

interface Props {
    step: number;
    setStep: (step: number) => void;
    setStepTitle: (title: string) => void;
}

export function GalaxiePro({ step, setStep, setStepTitle }: Props): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const [displayCat, setDisplayCat] = useState<'pro' | 'senior'>();
    const [userProList, setUserProList] = useState<User[]>();
    const [userSeniorList, setUserSeniorList] = useState<Patient[]>();

    useEffect(() => {
        const getUserLists = async (): Promise<void> => {
            if (user && user.establishmentCode) {
                const userProList = await getTherapistUsers(user.establishmentCode);
                const userSeniorList = await getPatientFromEstablishment(user.establishmentCode);
                setUserProList(userProList);
                setUserSeniorList(userSeniorList ?? []);
            }
        };
        getUserLists();
    }, [user]);

    useEffect(() => {
        if (step === 1) setStepTitle(t('GalaxiePro.title'));
        else if (step === 2 && displayCat === 'pro') setStepTitle(t('GalaxiePro.titlePro'));
        else if (step === 2 && displayCat === 'senior') setStepTitle(t('GalaxiePro.titleSenior'));
    }, [step]);

    return (
        <>
            {step === 1 && (
                <div
                    style={{
                        ...classes['root']
                    }}>
                    <Typography variant="p" sx={{ ...classes['desc'] }}>
                        {t('GalaxieProDesc')}
                    </Typography>
                    <div
                        style={{ ...classes['item'], marginBottom: '2rem'  }}
                        onClick={(): void => {
                            setDisplayCat('pro');
                            setStep(2);
                        }}>
                        <img style={{ ...classes['item-img'] }} src={proImg} alt="pro" />
                        <div style={{ ...classes['item-text'] }}>
                            <Typography variant="h2" sx={{ ...classes['h2'] }}>
                                {t('GalaxiePro.proTitle')}
                            </Typography>
                            <Typography variant="p" sx={{ ...classes['p'] }}>
                                {t('GalaxiePro.proDesc')}
                            </Typography>
                        </div>
                    </div>
                    <div
                        style={{ ...classes['item'] }}
                        onClick={(): void => {
                            setDisplayCat('senior');
                            setStep(2);
                        }}>
                        <img style={{ ...classes['item-img'] }} src={seniorImg} alt="senior" />
                        <div style={{ ...classes['item-text'] }}>
                            <Typography variant="h2" sx={{ ...classes['h2'] }}>
                                {t('GalaxiePro.seniorTitle')}
                            </Typography>
                            <Typography variant="p" sx={{ ...classes['p'] }}>
                                {t('GalaxiePro.seniorDesc')}
                            </Typography>
                        </div>
                    </div>
                </div>
            )}
            {step === 2 && displayCat === 'pro' && (
                <div
                    style={{
                        ...classes['root']
                    }}>
                    <Typography variant="p" sx={{ ...classes['desc'] }}>
                        {t('GalaxieProDesc.pro')}
                    </Typography>
                    <div style={{ ...classes['user-container'] }}>
                        {userProList?.map((thisUser) => (
                            <DisplayUser user={thisUser} />
                        ))}
                    </div>
                </div>
            )}
            {step === 2 && displayCat === 'senior' && (
                <div
                    style={{
                        ...classes['root']
                    }}>
                    <Typography variant="p" sx={{ ...classes['desc'] }}>
                        {t('GalaxieProDesc.senior')}
                    </Typography>
                    <div style={{ ...classes['user-container'] }}>
                        {userSeniorList?.map((thisUser) => (
                            <DisplayUser user={thisUser} />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
