// eslint-disable-next-line
export default {
    colors: {
        // background: 'radial-gradient(#282c34cc, #282c34)',
        // background: '#FFEFD5',
        // black: '#282c34',
        blue: '#dadada',
        white: 'white',
        background: 'radial-gradient(#a0e9fd, #16697a)',
        kobaltblue: '#0F0046',
        corectValue: '#00D97E',
        darkorange: '#FFCF26',
        lightorange: '#FFCF26',
        lightBlue: '#dadada',
        empty: '#A32EFF33',
        active: '#A32EFF'
    },
    transition: '0.3s'
};
