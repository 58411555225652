import React, { CSSProperties, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { logOut } from '../services/firebaseAuth';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { User } from '../models/User';
import { Typography } from './Typography';
import { useHistory } from 'react-router';
import { Share } from '@capacitor/share';
import { Roles } from '../models/Roles';
import { getPatientByFamilyCode } from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { getBeneficiaryMembers } from '../stores/User';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'profile-image': {
        alignSelf: 'center',
        marginBottom: '1rem'
    },
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '95%',
        textAlign: 'center',
        alignSelf: 'center'
    }
};

interface Props {
    user: User | null | undefined;
}

export function AddMember({ user }: Props): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const { height, width } = useWindowDimensions();
    const history = useHistory();
    const [seniorMember, setSeniorMember] = useState<User>();

    useEffect(() => {
        const getFamilyMembers = async (): Promise<void> => {
            if (userDataProp.token) {
                const allSenior = await getBeneficiaryMembers(userDataProp.token);
                setSeniorMember(allSenior[0]);
            }
        };

        getFamilyMembers();
    }, []);

    return (
        <div
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
            <Typography variant="h3" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                {t('FamilyCode')}
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center', alignSelf: 'center' }}>
                {user?.familyCode}
            </Typography>
            <Typography
                variant="p"
                sx={{
                    marginTop: '4rem',
                    marginBottom: '3rem',
                    textAlign: 'center',
                    alignSelf: 'center',
                    color: '#333',
                    fontSize: '0.875',
                    fontWeight: '400'
                }}>
                {t('InviteGoodStarText1')}
                <span style={{ textDecoration: 'underline' }}>{t('InviteGoodStarText2')}</span>
                {t('InviteGoodStarText3')}
            </Typography>
            <Button
                sx={{ ...classes['logout-button'] }}
                // pink
                onClick={async () => {
                    await Share.share({
                        title: "Invitation à rejoindre Stimul'in!",
                        text:
                            user?.role === Roles.SENIOR
                                ? t('InvitationJoinStimulinSenior', {
                                      code: user?.familyCode,
                                      name: user?.firstName
                                  }) ?? ''
                                : t('InvitationJoinStimulinFamily', {
                                      seniorName: seniorMember?.firstName ?? '',
                                      code: user?.familyCode,
                                      name: user?.firstName
                                  }) ?? '',
                        url: `https://appv1.stimulin.fr/register/${user?.familyCode}`
                    });
                }}>
                {t('InviteGoodStar')}
            </Button>
        </div>
    );
}
