import styles from './styles.module.css';

interface ContainerProps {
    children: React.ReactNode;
    onBlur?: boolean;
    className?: string;
    border?: string;
    background?: string;
}

const Layout: React.FC<ContainerProps> = ({ children, onBlur, className, border, background }) => {
    return (
        <div className={[styles.content, className].join(' ')} style={{
            '--border': border,
            '--background': background,
        } as React.CSSProperties}>
            <div className={[styles.blur, onBlur ? styles.onBlur : ''].join(' ')}>
            </div>
            {children}
        </div>
    );
};

export default Layout;