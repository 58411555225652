import React from 'react';

import { VideoFromYt } from '../stores/youtube';
import { Typography } from './Typography';
import { VideoPlayer } from './VideoPlayer';
import { Button } from './Button';
import { t } from 'i18next';

interface VigentteFromYoutubeProps {
    video: VideoFromYt;
    onClick?: (video: VideoFromYt) => void;
}

export function VigentteFromYoutube({ video, onClick }: VigentteFromYoutubeProps): JSX.Element {
    const [playVideo, setPlayVideo] = React.useState(false);
    return (
        <div
            style={{
                width: '100%'
            }}>
            {!playVideo && (
                <img
                    style={{
                        aspectRatio: '16/9',
                        width: '100%',
                        height: 'auto',
                        cursor: 'pointer',
                        objectFit: 'cover'
                    }}
                    src={video.thumbnail}
                    alt={video.title}
                    onClick={() => setPlayVideo(true)}
                />
            )}
            {playVideo && (
                <div
                    style={{
                        aspectRatio: '16:9',
                        width: '100%',
                        height: 'auto'
                    }}>
                    <VideoPlayer
                        sx={{
                            // aspectRatio: '16:9',
                            // width: '100%',
                            // height: 'auto',
                            width: '100%',
                            height: '100%'
                        }}
                        autoPlay
                        url={`https://youtube.com/embed/${video.id}`}
                    />
                </div>
            )}
            <Typography
                sx={{
                    marginTop: '0.5rem',
                    width: '100%',
                    color: '#000',
                    fontSize: '0.875rem',
                    // lineHeight: '1.25rem',
                    fontWeight: '500'
                }}
                dangerouslySetInnerHTML={{
                    __html: video.title
                }}
                children={undefined}
            />
            <Typography
                sx={{
                    width: '100%',
                    color: '#333',
                    fontSize: '0.75rem',
                    fontWeight: '500',
                    lineHeight: '1rem'
                }}
                dangerouslySetInnerHTML={{
                    __html: video.channelTitle
                }}
                children={undefined}
            />
            {onClick && (
                <Button
                    onClick={() => onClick(video)}
                    sx={{
                        marginTop: '1rem',
                        width: '100%',
                        height: 'auto',
                        fontSize: '1.125rem',
                        fontWeight: '500'
                    }}>
                    {t('choiseThisVideo')}
                </Button>
            )}
        </div>
    );
}
