import React, { ReactElement } from 'react';
import { CSSProperties } from 'styled-components';

const classes: { [key: string]: CSSProperties } = {
    'h1-root': {
        fontSize: '2rem',
        fontWeight: '400',
        color: 'var(--Typography-h1-color)',
        margin: '0',
        textAlign: 'center',
        width: '100%',
        fontFamily: 'var(--ion-font-family)'
    },
    'h2-root': {
        fontSize: '1.5rem',
        fontWeight: '400',
        color: 'var(--Typography-h2-color)',
        margin: '0',
        textAlign: 'center',
        width: '100%',
        fontFamily: 'var(--ion-font-family)'
    },
    'h3-root': {
        fontSize: '1.125rem',
        fontWeight: 600,
        color: 'var(--color-primary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        fontFamily: 'var(--ion-font-family)'
    },
    'h4-root': {
        fontSize: '1rem',
        fontWeight: 500,
        color: 'var(--color-primary)',
        margin: '0',
        fontFamily: 'var(--ion-font-family)'
    },
    'h5-root': {
        color: 'red'
    },
    'h6-root': {
        color: 'red'
    },
    'p-root': {
        color: 'var(--color-black)',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5rem',
        margin: '0',
        fontFamily: 'var(--ion-font-family)'
    },
    'span-root': {
        color: 'red'
    }
};

interface Props {
    children: any;
    variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
    sx?: CSSProperties;
    onClick?: () => void;
    dangerouslySetInnerHTML?: { __html: string };
}

export function Typography({
    children,
    variant = 'p',
    sx,
    onClick,
    dangerouslySetInnerHTML
}: Props): JSX.Element {
    const getElement = (): JSX.Element => {
        switch (variant) {
            case 'h1':
                return (
                    <h1
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h1-root'], ...sx }}>
                        {children}
                    </h1>
                );
            case 'h2':
                return (
                    <h2
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h2-root'], ...sx }}
                        dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
                        {children}
                    </h2>
                );
            case 'h3':
                return (
                    <h3
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h3-root'], ...sx }}>
                        {children}
                    </h3>
                );
            case 'h4':
                return (
                    <h4
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h4-root'], ...sx }}>
                        {children}
                    </h4>
                );
            case 'h5':
                return (
                    <h5
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h5-root'], ...sx }}>
                        {children}
                    </h5>
                );
            case 'h6':
                return (
                    <h6
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['h6-root'], ...sx }}>
                        {children}
                    </h6>
                );
            case 'p':
                return (
                    <p
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['p-root'], ...sx }}
                        dangerouslySetInnerHTML={dangerouslySetInnerHTML}>
                        {children}
                    </p>
                );
            case 'span':
                return (
                    <span
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['span-root'], ...sx }}>
                        {children}
                    </span>
                );
            default:
                return (
                    <p
                        onClick={() => (onClick ? onClick() : {})}
                        style={{ ...classes['p-root'], ...sx }}>
                        {children}
                    </p>
                );
        }
    };

    return getElement();
}
