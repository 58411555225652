import { IonContent, isPlatform } from '@ionic/react';
import React, { CSSProperties, useEffect, useState } from 'react';
import { UserDataProp } from '../models/userDataProp';
import { useContext } from 'react';
import { UserContext } from '../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import back from '../assets/Back.svg';
import cross from '../assets/cross.svg';
import { Typography } from './Typography';
import { Capacitor } from '@capacitor/core';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '1rem',
        paddingLeft: '0rem',
        paddingRight: '0rem',
        height: 'fit-content',
        width: '100%',
        top: 0,
        borderBottom: '2px solid #F2F2F2'
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 600,
        color: 'var(--color-primary)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    step: {
        fontSize: '0.75rem',
        fontWeight: 500,
        color: 'var(--color-secondary)',
        width: '100%',
        paddingLeft: '2rem'
    },
    'icon-nav': {
        width: '3rem',
        height: '3rem',
        cursor: 'pointer'
    },
    'title-text': {
        height: '2rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%'
    }
};

interface CreateImportHeaderProps {
    step: number;
    stepMax: number;
    close: () => void;
    prev: () => void;
    title: string;
    nbToSubstractStep?: number;
}

export function CreateImportHeader({ step, close, prev, stepMax, title, nbToSubstractStep = 0 }: CreateImportHeaderProps): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;

    return (
        <div style={{...classes['root'], paddingTop: '1rem',}}>
            <div style={classes['title']}>
                <img src={back} alt="arrowLeft" onClick={prev} style={classes['icon-nav']} />
                <Typography variant="h3" sx={classes['title-text']}>
                    {title}
                </Typography>
                <img src={cross} alt="close" onClick={close} style={classes['icon-nav']} />
            </div>
            {stepMax > 0 && step > 0 && (
                <div style={classes['step']}>{t('Step',{current: step, max : stepMax})}</div>
            )}
        </div>
    );
}
