import { ReactNode, useEffect, useRef } from 'react';
import Cross from './assets/cross.svg?react';
import style from './style.module.css';
import { useHistory } from 'react-router';

interface MenuProps {
    children?: ReactNode;
    onClose?: () => void;
    isOpened?: boolean;
}

const Menu = ({ children, onClose = () => { }, isOpened = false }: MenuProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const mouseDownTarget = useRef<EventTarget | null>(null);

    useEffect(() => {
        document.body.style.overflow = isOpened ? 'hidden' : '';
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpened]);

    const handleClose = () => {
        onClose();
    }

    return (
        <div
            className={`${style.bgd} ${style.side} ${isOpened ? style.opened : ''}`}
            onMouseUp={(event) => {
                if (
                    mouseDownTarget.current &&
                    ref.current &&
                    !ref.current.contains(mouseDownTarget.current as Node) &&
                    !ref.current.contains(event.target as Node)
                ) {
                    onClose(/*event*/);
                }
            }}
            onMouseDown={(event) => {
                mouseDownTarget.current = event.target;
            }}
        >
            <div className={style.window} ref={ref}>
                {/* <div className={style.close} onClick={handleClose}>
                    <Cross />
                </div> */}
                <div className={style.content}>{children}</div>
            </div>
        </div>
    );
};

export default Menu;