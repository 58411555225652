// import dotenv from 'dotenv';
import { getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions } from 'firebase/functions';
import { initializeApp } from 'firebase/app';
import { Capacitor } from '@capacitor/core';
import { env } from '../../env';


// dotenv.config();
export const app = initializeApp(JSON.parse(env.production ? env.VITE_APP_CONFIG_PROD! : env.VITE_APP_CONFIG_DEV!));


if (Capacitor.isNativePlatform()) {
    initializeAuth(app, {
      persistence: indexedDBLocalPersistence
    });
  }

export const fireAuth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
export const analytics = getAnalytics(app);
