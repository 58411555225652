import React, { CSSProperties } from 'react';

const classes: { [key: string]: CSSProperties } = {
    'btn-root': {
        height: '3rem',
        minHeight: '3rem',
        backgroundColor: 'var(--button-bg-color)',
        color: 'var(--color-white)',
        width: '100%',
        border: 'none',
        fontSize: '1.13rem',
        fontFamily: 'var(--ion-font-family)',
    }
};

interface Props {
    children: any;
    rounded?: boolean;
    disabled?: boolean;
    sx?: CSSProperties;
    onClick?: (e?: any) => void;
    pink?: boolean;
    outline?: boolean;
    outlinedDisable?: boolean;
    onMouseOver?: (e?: any) => void;
}

export function Button({
    children,
    rounded,
    disabled = false,
    sx,
    onClick,
    pink,
    outline,
    outlinedDisable,
    onMouseOver
}: Props): JSX.Element {
    return (
        <button
            disabled={disabled}
            onMouseOver={(e) => {
                onMouseOver ? onMouseOver(e) : () => {};
            }}
            onClick={(e) => {
                onClick ? onClick(e) : () => {};
            }}
            style={{
                ...classes['btn-root'],
                borderRadius: rounded ? '20px' : '8px',
                ...(pink ? { backgroundColor: 'var(--button-bg-color-pink)', color: '#FFF' } : {}),
                ...(disabled && !outlinedDisable
                    ? {
                          backgroundColor: 'var(--button-bg-color-disabled)'
                      }
                    : {}),
                ...(outline && pink
                    ? {
                          border: 'solid var(--button-bg-color-pink)',
                          background: '#FFFFFF',
                          color: 'var(--button-bg-color-pink)'
                      }
                    : {}),
                ...(outline && !pink
                    ? {
                            border: 'solid var(--button-bg-color)',
                            background: '#FFFFFF',
                            color: 'var(--button-bg-color)'
                        }
                    : {}),
                ...(outlinedDisable && disabled
                    ? {
                          backgroundColor: 'var(--button-bg-color-outline-disable)',
                          color: pink ? 'var(--button-bg-color-pink)' : 'var(button-text-color)',
                          border: 'solid var(--button-bg-color-pink)',
                          cursor: 'not-allowed'
                      }
                    : {}),
                ...sx
            }}>
            {children}
        </button>
    );
}
