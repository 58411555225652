export enum ExerciseType {
    QUIZ = `SOUVEN'IN`,
    PUZZLE = `PUZZL'IN`,
    MEMORIZ = `MEMOR'IN`,
    SUDOKU = `SUDOK'IN`,
    PONG = `PONG'IN`,
    ORDER = `ORDON'IN`,
    TOURBILLON = `TOURBILLON`,
    MELIMOTS = `MELI-MOTS`,
}
