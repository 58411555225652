import { useState, useEffect } from 'react';

export default function useWindowDimensions() {
    // const hasWindow = typeof window !== 'undefined';

    function getWindowDimensions() {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const orientation = window.orientation;
        return {
            width,
            height,
            orientation
        };
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        // if (hasWindow) {
            function handleResize() {
                setTimeout(() => {
                    setWindowDimensions(getWindowDimensions());
                }, 1000);
                setTimeout(() => {
                    setWindowDimensions(getWindowDimensions());
                }, 100);
                setTimeout(() => {
                    setWindowDimensions(getWindowDimensions());
                }, 500);
                setWindowDimensions(getWindowDimensions());
            }

            window.addEventListener('resize', handleResize);
            window.addEventListener('orientationchange', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        }
        // }
    }, []);

    return windowDimensions;
}