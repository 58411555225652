import React, { useEffect, useMemo } from 'react';
import { XYCoord, useDrop } from 'react-dnd';

import styles from './styles.module.css';
import DraggableCard from '../DraggableCard';
import { DragItem } from '../../models';
import { useParams } from 'react-router-dom';

interface BoxProps {
    card?: DragItem;
    onCardHold: (id: DragItem['id'], position: XYCoord) => void;
    glow?: boolean;
}

const Box: React.FC<BoxProps> = ({ onCardHold, card, glow }) => {
    const { lvlId } = useParams<{ lvlId: string }>();
    const lvl = parseInt(lvlId);
    const ref = React.useRef<HTMLDivElement>(null);
    const boxRef = React.useRef<HTMLDivElement>(null);

    const boxSize = lvl <= 5 ? styles.large : lvl <= 7 ? styles.medium : styles.small;
    const draggableSize = lvl <= 5 ? styles.largeDraggable : lvl <= 7 ? styles.mediumDraggable : styles.smallDraggable;

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'CARD',
        drop(item: DragItem) {
            if (!ref || !boxRef.current) return;
            onCardHold(item.id, { x: boxRef.current.offsetLeft, y: boxRef.current.offsetTop });
            return item;
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }), []);

    drop(ref);

    return (
        <>
            <div ref={boxRef} className={[boxSize, styles.root, isOver ? styles.isOver : '', glow && card?.status !== 'placed' ? styles.glow : ''].join(' ')}>
            <div ref={ref} className={[draggableSize, styles.dragZone].join(' ')} />
                {card ? <DraggableCard card={card} /> : null}
            </div>
        </>
    );
};

export default Box;