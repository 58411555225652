import firestore, { Timestamp } from 'firebase/firestore';
import { FormField } from './FormField';
import { FormData } from './FormData';
import { User } from './User';
import { Status } from './Status';

export const dropdownList = [
    'Animateur-trice',
    'Arrière grand-mère',
    'Arrière grand-père',
    'Arrière-petit-fils',
    'Arrière-petite-fille',
    'Autoportrait',
    'Autre résident / patient',
    'Belle-Fille',
    'Beau-fils',
    'Conjoint(e)',
    'Cousin',
    'cousine',
    'Fille',
    'Fils',
    'Frère',
    'Grand-mère',
    'Grand-père',
    'Mère',
    'Neveu',
    'Nièce',
    'Père',
    'Personnel soignant',
    'Petit-fils',
    'Petite-fille',
    'Soeur',
    'Intervenant',
    'Tuteur/trice'
];
export const dropdownListFamily = [
    'Sa belle-fille / Son beau-fils',
    'Son / Sa conjoint(e)',
    'Son / Sa cousin(ne)',
    'Son / sa petit(e)-fils / fille',
    'Son / Sa professionnel(le) de confiance',
    'Son arrière-petit(e)-fille / fils',
    'Son fils / Sa fille',
    'Son frère/ Sa sœur',
    'Son neveu / Sa nièce',
    'Une autre personne de confiance'
];

const legalOptionList: string[] = ['Oui', 'Non'];

export class Family extends User implements FormData {
    address?: string | undefined;

    pincode?: string | undefined;

    city?: string | undefined;

    legalReferent?: boolean | undefined;

    patient?: string | undefined;

    relationship?: string | undefined;

    establishmentName?: string | undefined;

    establishmentCode?: string | undefined;

    familyCode?: string | undefined;

    startDate?: Timestamp | undefined;

    endDate?: Timestamp | undefined;

    status?: Status | undefined;

    createdDate?: Timestamp | undefined;

    lovedPassword?: string | undefined;

    cguValidate?: string | undefined;

    cguValidateDate?: Timestamp | undefined;

    constructor(
        id?: string,
        role?: 'PATIENT' | 'FAMILY' | 'THERAPIST' | 'ADMIN',
        emailId?: string,
        firstName?: string,
        lastName?: string,
        dob?: Timestamp,
        profilePhoto?: string | File,
        address?: string,
        pincode?: string,
        city?: string,
        legalReferent?: boolean,
        patient?: string,
        relationship?: string,
        establishmentName?: string,
        establishmentCode?: string,
        familyCode?: string,
        startDate?: Timestamp,
        endDate?: Timestamp,
        userId?: string,
        status?: Status,
        isChangePasswordRequired?: boolean,
        createdDate?: Timestamp,
        lovedPassword?: string,
        cguValidate?: string,
        cguValidateDate?: Timestamp
    ) {
        super({
            id,
            role,
            emailId,
            firstName,
            lastName,
            dob,
            profilePhoto,
            isChangePasswordRequired
        });
        this.address = address;
        this.pincode = pincode;
        this.city = city;
        this.legalReferent = legalReferent;
        this.patient = patient;
        this.relationship = relationship;
        this.establishmentName = establishmentName;
        this.establishmentCode = establishmentCode;
        this.familyCode = familyCode;
        this.startDate = startDate;
        this.endDate = endDate;
        this.userId = userId;
        this.status = status;
        this.createdDate = createdDate;
        this.lovedPassword = lovedPassword;
        this.cguValidate = cguValidate;
        this.cguValidateDate = cguValidateDate;
        this.isChangePasswordRequired = isChangePasswordRequired;
    }

    firstStepFormFields: FormField[] = [
        new FormField({ placeholder: 'memberLastName', field: 'lastName' }),
        new FormField({ placeholder: 'emailPlaceholder', field: 'email', type: 'email' }),
        new FormField({ placeholder: 'memberFirstName', field: 'firstName' }),
        new FormField({ placeholder: 'password', field: 'password', type: 'password' }),
        new FormField({ placeholder: 'address', field: 'address', required: false }),
        new FormField({ placeholder: 'dob', field: 'dob', type: 'date', required: false }),
        new FormField({
            placeholder: 'pincode',
            field: 'pincode',
            width: 'HALF',
            required: false
        }),
        new FormField({ placeholder: 'city', field: 'city', width: 'HALF', required: false }),
        new FormField({
            placeholder: 'legalReferent',
            field: 'legalReferent',
            type: 'select',
            options: legalOptionList
        }),
        new FormField({
            placeholder: 'nameEstablishment',
            field: 'establishmentCode',
            type: 'select',
            dropdownOptions: 'establishment'
        }),
        new FormField({
            placeholder: 'relationship',
            field: 'relationship',
            type: 'select',
            options: dropdownList
        }),
        new FormField({
            placeholder: 'patient',
            field: 'patient',
            type: 'select',
            dropdownOptions: 'patient'
        })
    ];

    secondStepFormFields: FormField[] = [
        new FormField({ placeholder: 'lovedPassword', field: 'lovedPassword' }),
        new FormField({ placeholder: 'familyCode', field: 'familyCode' }),
        new FormField({ placeholder: 'startDate', field: 'startDate', type: 'date' }),
        new FormField({ placeholder: 'endDate', field: 'endDate', type: 'date' }),
        new FormField({
            placeholder: 'isChangePasswordRequired',
            field: 'isChangePasswordRequired',
            type: 'select',
            options: ['true', 'false'],
            required: false
        })
    ];
}

export const FamilyConverter: firestore.FirestoreDataConverter<Family> = {
    toFirestore(family) {
        return {
            role: family.role,
            emailId: family.emailId,
            firstName: family.firstName,
            lastName: family.lastName,
            dob: family.dob,
            profilePhoto: family.profilePhoto,
            address: family.address,
            pincode: family.pincode,
            city: family.city,
            legalReferent: family.legalReferent,
            patient: family.patient,
            relationship: family.relationship,
            establishmentName: family.establishmentName,
            establishmentCode: family.establishmentCode,
            familyCode: family.familyCode,
            startDate: family.startDate,
            endDate: family.endDate,
            userId: family.userId,
            status: family.status,
            lovedPassword: family.lovedPassword,
            cguValidate: family.cguValidate,
            cguValidateDate: family.cguValidateDate
        };
    },
    fromFirestore(snapshot, options): Family {
        const data = snapshot.data(options);
        return new Family(
            snapshot.id,
            data.role,
            data.emailId,
            data.firstName,
            data.lastName,
            data.dob,
            data.profilePhoto,
            data.address,
            data.pincode,
            data.city,
            data.legalReferent,
            data.patient,
            data.relationship,
            data.establishmentName,
            data.establishmentCode,
            data.familyCode,
            data.startDate,
            data.endDate,
            data.userId,
            data.status,
            data.createdDate,
            data.lovedPassword,
            data.cguValidate,
            data.cguValidateDate
        );
    }
};
