import { v4 } from 'uuid';
import {
    getDoc,
    doc,
    updateDoc,
    setDoc,
    getDocs,
    collection,
    query,
    where,
    deleteDoc,
    addDoc,
    Timestamp,
    orderBy
} from 'firebase/firestore';
import { User, UserConverter } from '../models/User';
import { db } from './firebase';
import { Therapist, TherapistConverter } from '../models/Therapist';
import { Family, FamilyConverter } from '../models/Family';
import { Patient, PatientConverter } from '../models/Patient';
import { Establishment, EstablishmentConverter } from '../models/Establishment';
import { ExerciseItem, ExerciseItemConverter } from '../models/ExerciseItem';
import { deleteMedia, uploadImage } from './storageFunctions';
import { Roles } from '../models/Roles';
import { Exercise, ExerciseConverter } from '../models/Exercise';
import { ExerciseType } from '../models/ExerciseType';
import { CompletedMemoryExercise } from '../models/CompletedMemoryExercise';
import { Review } from '../models/Review';
import { GraphData } from '../models/GraphData';
import { ExerciseGraphData } from '../models/ExerciseGraphData';
import { PatientExerciseGraphData } from '../models/PatientExerciseGraphData';
import { LibraryData } from '../models/LibraryData';
import { DropdownData } from '../models/DropdownData';
import { Status } from '../models/Status';
import { EstablishmentTableViewData } from '../models/EstablishmentTableViewData';
import { EstablishmentsExportData } from '../models/EstablishmentsExportData';
import {
    codeStorage,
    convertToTitleCase,
    getDate,
    getDateFromTimestamp,
    getImageKitThumbnailFromVideo
} from '../utilities/utils';
import { ExportData } from '../models/ExportData';
// eslint-disable-next-line import/no-cycle
// import { StateInterface } from '../pages/common/AssignExercise';
import { MediaInformation } from '../models/MediaInformation';
import { Video, VideoConverter } from '../models/Video';
import { UsersVideoData } from '../models/UsersVideoData';
import { clickAnalytics } from './analyticsFunction';
import { create, forEach } from 'lodash';
import { SeniorStatistics } from '../models/SeniorStatistics';
import { StatisticsDetails } from '../models/StatisticsDetails';
import { StatTimeUseApp } from '../models/StatTimeUseApp';
import { MediaStatistics } from '../models/MediaStatistics';
import { ReviewStatistics } from '../models/ReviewStatistics';

export const getEstablishmentList = async (): Promise<Establishment[]> => {
    const list: Establishment[] = [];
    const querySnapshot = await getDocs(
        collection(db, 'establishments').withConverter(EstablishmentConverter)
    );
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const getEstablishmentAdminSide = async (id: string): Promise<Establishment | null> => {
    let establishment: Establishment;
    const reff = doc(db, 'establishments', id).withConverter(EstablishmentConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        establishment = docSnap.data();
        return establishment;
    }
    return null;
};

export const getEstablishment = async (id: string): Promise<Establishment | null> => {
    const reff = doc(db, 'establishments', id).withConverter(EstablishmentConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createUserRegistration = async (data: any): Promise<void | string> => {
    let linkFamily = '';
    let linkPatient = '';
    if (typeof data.familyPicture === 'object' && data.familyPicture) {
        linkFamily = await uploadImage(data.familyPicture, 'images');
    }
    if (typeof data.patientPicture === 'object' && data.patientPicture) {
        linkPatient = await uploadImage(data.patientPicture, 'images');
    }
    return addDoc(collection(db, 'userRegistration'), {
        familyPicture: linkFamily ?? '',
        familyName: data.familyName ?? '',
        familyLastName: data.familyLastName ?? '',
        familyDob: data.familyDob ?? '',
        familyEmail: data.familyEmail ?? '',
        familyPassword: data.familyPassword ?? '',
        patientPicture: linkPatient ?? '',
        patientGender: data.patientGender ?? '',
        patientName: data.patientName ?? '',
        patientLastName: data.patientLastName ?? '',
        patientDob: data.patientDob ?? '',
        patientEmail: data.patientEmail ?? '',
        patientAddress: data.patientAddress ?? '',
        patientCompAddr: data.patientCompAddr ?? '',
        patientZipcode: data.patientZipcode ?? '',
        patientCity: data.patientCity ?? '',
        patientKinship: data.patientKinship ?? '',
        patientID: data.patientID ?? '',
        patientPassword: data.patientPassword ?? '',
        patientFamilyCode: data.patientFamilyCode ?? ''
    })
        .then(() => {
            return 'success';
        })
        .catch((error) => {
            return error;
        });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createNewUserInfoInscription = async (data: {
    email: string;
    howKnow: string;
}): Promise<void | string> => {
    return addDoc(collection(db, 'userInfoInscription'), {
        date: new Date(),
        email: data.email,
        howKnow: data.howKnow
    })
        .then(() => {
            return 'success';
        })
        .catch((error) => {
            return error;
        });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createEstablishment = async (data: any): Promise<void | string> => {
    const establishment = await addDoc(collection(db, 'establishments'), {
        establishmentName: data.establishmentName,
        lastName: data.lastName,
        firstName: data.firstName,
        fonction: data.fonction,
        email: data.email,
        mobile: data.mobile,
        dob: data.dob,
        address: data.address,
        pincode: data.pincode,
        city: data.city,
        maxPatients: data.maxPatients,
        maxTherapists: data.maxTherapists,
        maxFamily: data.maxFamily,
        startDate: data.startDate,
        endDate: data.endDate,
        status: Status.ACTIVE
    });
    await updateDoc(doc(db, 'establishments', establishment.id), {
        establishmentCode: establishment.id
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateEstablishmentByAdmin = async (data: any): Promise<void | string> => {
    const dob = getDate(data.dob);
    const endDate = getDate(data.endDate);
    const startDate = getDate(data.startDate);
    await updateDoc(doc(db, 'establishments', data.establishmentCode), {
        address: data.address,
        city: data.city,
        dob,
        email: data.email,
        endDate,
        firstName: data.firstName,
        fonction: data.fonction,
        lastName: data.lastName,
        mobile: data.mobile,
        pincode: data.pincode,
        startDate
    });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updatePatientByAdmin = async (data: any): Promise<void | string> => {
    const dob = getDate(data.dob);
    await updateDoc(doc(db, 'establishments', data.establishmentCode), {
        dob,
        firstName: data.firstName,
        lastName: data.lastName
    });
};

export const addFamily = async (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    patientFirstName?: string,
    patientLastName?: string,
    patientEmail?: string
): Promise<void | string> => {
    return addDoc(collection(db, 'users'), {
        role: 'FAMILY',
        isChangePasswordRequired: data.isChangePasswordRequired ?? 'true',
        emailId: data.email ?? '',
        password: data.password ?? '',
        firstName: data.firstName ?? '',
        lastName: data.lastName ?? '',
        dob: data.dob ?? null,
        address: data.address ?? '',
        pincode: data.pincode ?? '',
        city: data.city ?? '',
        legalReferent: data.legalReferent ?? '',
        patientId: data.patient ?? '',
        relationship: data.relationship ?? '',
        establishmentName: data.establishmentName ?? '',
        establishmentCode: data.establishmentCode ?? '',
        familyCode: data.familyCode ?? '',
        startDate: data.startDate ?? '',
        endDate: data.endDate ?? '',
        createdDate: Timestamp.now(),
        userId: data.userId ?? '',
        status: Status.ACTIVE,
        cguValidate: data.cguValidate ?? false,
        cguValidateDate: data.cguValidateDate ?? '',
        lovedPassword: data.lovedPassword ?? '',
        patientFirstName: patientFirstName ?? '',
        patientLastName: patientLastName ?? '',
        patientEmail:
            patientEmail && patientEmail.slice(-12) === '@stimulin.fr'
                ? patientEmail.replace('@stimulin.fr', '')
                : patientEmail ?? '',
        isSubscribed: data.isSubscribed ?? false
    })
        .then((): string => {
            return 'success';
        })
        .catch((err): void => {
            return err;
        });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addTherapist = async (data: any): Promise<void | string> => {
    await addDoc(collection(db, 'users'), {
        role: 'THERAPIST',
        isChangePasswordRequired: true,
        emailId: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob ? data.dob : '',
        mobile: data.mobile,
        fonction: data.fonction,
        establishmentName: data.establishmentName,
        establishmentCode: data.establishmentCode,
        startDate: data.startDate,
        endDate: data.endDate,
        createdDate: Timestamp.now(),
        status: Status.ACTIVE,
        cguValidate: false,
        cguValidateDate: '',
        patientPassword: data.patientPassword ?? ''
    });
};

export const getUserByEmail = async (email: string): Promise<User | null> => {
    const reff = collection(db, 'users').withConverter(UserConverter);
    const q = query(reff, where('email', '==', email), where('status', '==', Status.ACTIVE));
    const docSnap = await getDocs(q);
    if (docSnap.size > 0) {
        return docSnap.docs[0].data();
    }
    return null;
};

export const getUserByEmailId = async (email: string): Promise<User | null> => {
    const reff = collection(db, 'users').withConverter(UserConverter);
    const q = query(reff, where('emailId', '==', email), where('status', '==', Status.ACTIVE));
    const docSnap = await getDocs(q);
    if (docSnap.size > 0) {
        return docSnap.docs[0].data();
    }
    return null;
};

export const getUserByInfo = async (firstName: string, lastName: string): Promise<User[]> => {
    const list: User[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(FamilyConverter));
    // eslint-disable-next-line consistent-return
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (
            data.firstName?.toLocaleLowerCase() === firstName.toLocaleLowerCase() &&
            data.lastName?.toLocaleLowerCase() === lastName.toLocaleLowerCase() &&
            data.status === Status.ACTIVE
        ) {
            list.push(data);
        }
    });
    return list;
};

export const getUser = async (uid: string): Promise<User | null> => {
    const reff = collection(db, 'users').withConverter(UserConverter);
    const q = query(reff, where('userId', '==', uid), where('status', '==', Status.ACTIVE));
    const docSnap = await getDocs(q);
    if (docSnap.size > 0) {
        return docSnap.docs[0].data();
    }
    return null;
};

export const saveTherapistProfile = async (id: string, data: Therapist): Promise<void | string> => {
    const content = data;
    let link = '';
    if (typeof data.profilePhoto === 'object' && data.profilePhoto && data.establishmentCode) {
        link = await uploadImage(data.profilePhoto, data.establishmentCode);
        content.profilePhoto = link;
    }
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        dob: content.dob,
        emailId: content.emailId,
        firstName: content.firstName,
        lastName: content.lastName,
        fonction: content.fonction,
        establishmentName: content.establishmentName,
        establishmentCode: content.establishmentCode,
        mobile: content.mobile,
        profilePhoto: content.profilePhoto ?? null
    });

    return link;
};

export const passwordChanged = async (id: string): Promise<void> => {
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        isChangePasswordRequired: false
        // cguValidate: true,
        // cguValidateDate: Timestamp.now()
    });
};

export const getTherapistUser = async (uid: string): Promise<Therapist | null> => {
    const reff = doc(db, 'users', await getDocId(uid)).withConverter(TherapistConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

export const saveFamilyProfile = async (id: string, data: Family): Promise<void | string> => {
    const content = data;
    let link = '';

    if (typeof data.profilePhoto === 'object' && data.profilePhoto && data.familyCode) {
        link = await uploadImage(data.profilePhoto, data.familyCode);
        content.profilePhoto = link;
    }
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        emailId: content.emailId,
        firstName: content.firstName,
        lastName: content.lastName,
        familyCode: content.familyCode,
        address: content.address,
        pincode: content.pincode,
        dob: content.dob,
        city: content.city,
        relationship: content.relationship,
        profilePhoto: content.profilePhoto ?? null
    });

    return link;
};

export const savePatient = async (id: string, data: Patient): Promise<void | string> => {
    const content = data;
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        firstName: content.firstName,
        lastName: content.lastName,
        establishmentName: content.establishmentName,
        address: content.address,
        dob: content.dob,
        pincode: content.pincode,
        city: content.city
    });
};

export const patientUpdateEndDate = async (
    id: string,
    endDate: Timestamp
): Promise<void | string> => {
    // const content = data;
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        endDate: endDate
    });
};

export const getFamilyUser = async (uid: string): Promise<Family | null> => {
    const reff = doc(db, 'users', await getDocId(uid)).withConverter(FamilyConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

export const getPatientFromCode = async (code: string): Promise<Patient | null> => {
    const reff = collection(db, 'users').withConverter(PatientConverter);
    const q = query(reff, where('familyCode', '==', code), where('role', '==', 'PATIENT'));
    const docSnap = await getDocs(q);
    if (docSnap.size > 0) {
        return docSnap.docs[0].data();
    }
    return null;
};

export const getFamilyFromCode = async (code: string): Promise<User[] | null> => {
    const reff = collection(db, 'users').withConverter(UserConverter);
    const q = query(reff, where('familyCode', '==', code));
    const docSnap = await getDocs(q);
    // if (docSnap.size > 0) {
    //     return docSnap.docs[0].data();
    // }
    // return null;
    const list: User[] = docSnap.docs.map((doc) => doc.data());
    if (list.length > 0) {
        return list;
    }
    return null;
};

export const getPatientUser = async (uid: string): Promise<Patient | null> => {
    const reff1 = doc(db, 'users', await getDocId(uid));
    const reff = reff1.withConverter(PatientConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

// Function for found the LegalReferent of the patient
// for view-patient when Patient is selected & profile-Settings(Patient)
export const getLegalReferent = async (code: string): Promise<Family | null> => {
    const reff = collection(db, 'users').withConverter(FamilyConverter);
    const q = query(
        reff,
        where('familyCode', '==', code),
        where('role', '==', 'FAMILY'),
        where('legalReferent', '==', 'Oui'),
        orderBy('createdDate', 'asc')
    );
    const docSnap = await getDocs(q);
    if (docSnap.docs[0]) {
        return docSnap.docs[0].data();
    }
    return null;
};

export const getAllFamilly = async (code: string): Promise<Family[]> => {
    const reff = collection(db, 'users').withConverter(FamilyConverter);
    const q = query(
        reff,
        where('familyCode', '==', code),
        where('role', '==', 'FAMILY'),
        orderBy('emailId', 'asc')
    );
    const querySnapshot = await getDocs(q);
    const list: Family[] = [];
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const getPatientByFamilyCode = async (code: string): Promise<User[]> => {
    const reff = collection(db, 'users').withConverter(FamilyConverter);
    const q = query(
        reff,
        where('familyCode', '==', code),
        where('role', '==', 'PATIENT'),
        orderBy('emailId', 'asc')
    );
    const querySnapshot = await getDocs(q);
    const list: User[] = [];
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const saveExerciseItem = async (
    data: ExerciseItem,
    pathStorage: string
): Promise<void | string> => {
    const content = data;
    if (data.exerciseImage && typeof data.exerciseImage === 'object') {
        const link = await uploadImage(data.exerciseImage, pathStorage);
        content.exerciseImage = link;
        if (link === '') {
            return 'error';
        }
    }
    if (data.id) {
        await updateDoc(doc(db, 'exerciseItem', data.id), {
            communityId: content.communityId ?? null,
            authorId: content.authorId,
            authorType: content.authorType,
            title: content.title ?? null,
            answer: content.answer ?? null,
            imageDetails: content.imageDetails
                ? content.imageDetails.map((item) => {
                      return {
                          id: item.id,
                          firstName: item.firstName,
                          lastName: item.lastName,
                          relation: item.relation
                      };
                  })
                : null,
            photoCapturedPlace: content.photoCapturedPlace ?? null,
            photoCapturedOn: content.photoCapturedOn ?? null,
            anecdote: content.anecdote ?? null,
            theme: content.theme ?? null,
            exerciseImage: content.exerciseImage,
            exerciseImageType: content.exerciseImageType,
            createdDate: content.createdDate ?? Timestamp.now(),
            patientList: content.patientList ?? null,
            proList: content.proList ?? null,
            etsList: content.etsList ?? null
        });
        return data.id;
    }
    const uniqueID = v4();
    await setDoc(doc(db, 'exerciseItem', uniqueID), {
        communityId: content.communityId,
        authorId: content.authorId,
        authorType: content.authorType,
        id: uniqueID,
        title: content.title ?? null,
        answer: content.answer ?? null,
        // imageDetails: content.imageDetails,
        imageDetails: content.imageDetails
            ? content.imageDetails.map((item) => ({
                  id: item.id,
                  firstName: item.firstName,
                  lastName: item.lastName,
                  relation: item.relation
              }))
            : null,
        photoCapturedPlace: content.photoCapturedPlace ?? null,
        photoCapturedOn: content.photoCapturedOn ?? null,
        anecdote: content.anecdote ?? null,
        theme: content.theme ?? null,
        exerciseImage: content.exerciseImage,
        exerciseImageType: content.exerciseImageType,
        createdDate: Timestamp.now(),
        patientList: content.patientList ?? null,
        proList: content.proList ?? null,
        etsList: content.etsList ?? null
    });
    return uniqueID;
};

export const getExerciseItemsForMediaAdmin = async (): Promise<ExerciseItem[]> => {
    const list: ExerciseItem[] = [];
    const q = query(collection(db, 'exerciseItem'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q.withConverter(ExerciseItemConverter));
    querySnapshot.forEach((docSnap) => {
        const item = docSnap.data();
        if (item.authorType === Roles.ADMIN) {
            list.push(item);
        }
    });
    return list;
};

export const getExerciseItemsForMedia = async (id: string): Promise<ExerciseItem[]> => {
    const list: ExerciseItem[] = [];
    const q = query(collection(db, 'exerciseItem'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q.withConverter(ExerciseItemConverter));
    querySnapshot.forEach((docSnap) => {
        const item = docSnap.data();
        if (item.communityId === id) {
            list.push(item);
        }
        if (item.patientList && item.patientList.includes(id)) {
            list.push(item);
        }
    });
    return list;
};

export const getFamilyPicturesForMedia = async (
    id: string,
    user_Id: string,
    userId: string,
    userEts: string
): Promise<
    {
        authorType: Roles | undefined;
        createdDate: Timestamp | null | undefined;
        data: ExerciseItem;
    }[]
> => {
    const list: {
        authorType: Roles | undefined;
        createdDate: Timestamp | null | undefined;
        data: ExerciseItem;
    }[] = [];
    const patientQ = query(collection(db, 'exerciseItem'), where('communityId', '==', id));
    const therapistQ = query(
        collection(db, 'exerciseItem'),
        where('authorType', '==', Roles.PRO),
        where('patientList', 'array-contains', user_Id)
    );
    const adminQ = query(collection(db, 'exerciseItem'), where('authorType', '==', Roles.ADMIN));
    const patientQuerySnapshot = await getDocs(patientQ.withConverter(ExerciseItemConverter));
    const therapistQuerySnapshot = await getDocs(therapistQ.withConverter(ExerciseItemConverter));
    const adminQuerySnapshot = await getDocs(adminQ.withConverter(ExerciseItemConverter));

    patientQuerySnapshot.forEach((docSnap) => {
        const item = {
            authorType: docSnap.data().authorType,
            createdDate: docSnap.data().createdDate,
            data: docSnap.data(),
            authorId: docSnap.data().authorId,
            patientList: docSnap.data().patientList
        };
        if (
            item.authorId === userId ||
            item.patientList?.includes(user_Id) ||
            (item.createdDate &&
                item.createdDate < Timestamp.fromDate(new Date('2023-11-15 23:42:00')))
        ) {
            list.push(item);
        }
    });
    therapistQuerySnapshot.forEach((docSnap) => {
        const item = {
            authorType: docSnap.data().authorType,
            createdDate: docSnap.data().createdDate,
            data: docSnap.data(),
            patientList: docSnap.data().patientList
        };
        list.push(item);
    });
    adminQuerySnapshot.forEach((docSnap) => {
        const item = {
            authorType: docSnap.data().authorType,
            createdDate: docSnap.data().createdDate,
            data: docSnap.data()
        };
        // const deleteArray = [
        //     '52746da6-7a6c-495d-8a6e-35570c795d55',
        //     'd77f15f4-250e-44bc-9c0a-54c40859c634',
        //     '90e2d8d2-a560-4539-aaa0-ded9a43ad82c'
        // ];
        // if (item.data.id && !deleteArray.includes(item.data.id)) {
        //     list.push(item);
        // }
        if (item.data.etsList?.includes(userEts)) {
            list.push(item);
        }
    });

    return list;
};

export const getPatientsData = async (): Promise<Patient[]> => {
    const list: Patient[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(PatientConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.SENIOR) {
            list.push(docSnap.data());
        }
    });
    return list;
};

export const saveExercise = async (user: User, data: Exercise): Promise<void | string> => {
    const content = data;
    if (data.id) {
        clickAnalytics(`edit_${content.exerciseType?.toLowerCase()}`, user);
        await updateDoc(doc(db, 'exercise', data.id), {
            patientList: content.patientList ?? null,
            proList: content.proList ?? null,
            exerciseName: content.exerciseName,
            exerciseImages: content.exerciseImages,
            encouragementMessage: content.encouragementMessage ?? null,
            congratulationsMessage: content.congratulationsMessage ?? null,
            rewardPhoto: content.rewardPhoto ?? null,
            rewardVideo: content.rewardVideo ?? null,
            createdDate: content.createdDate ?? Timestamp.now(),
            establishmentList: content.establishmentList ?? null,
            establishmentCode: content.establishmentCode ?? null,
            familyCode: content.familyCode ?? null,
            theme: content.theme ?? null
        });
    } else {
        const uniqueID = v4();
        clickAnalytics(`create_${content.exerciseType?.toLowerCase()}`, user);
        const restponse = await setDoc(doc(db, 'exercise', uniqueID), {
            id: uniqueID,
            patientList: content.patientList ?? null,
            proList: content.proList ?? null,
            exerciseType: content.exerciseType,
            exerciseName: content.exerciseName,
            authorId: user.id,
            authorType: user.role,
            exerciseImages: content.exerciseImages,
            encouragementMessage: content.encouragementMessage ?? null,
            congratulationsMessage: content.congratulationsMessage ?? null,
            rewardPhoto: content.rewardPhoto ?? null,
            rewardVideo: content.rewardVideo ?? null,
            createdDate: Timestamp.now(),
            createdMonth: Timestamp.now().toDate().getMonth(),
            establishmentList: content.establishmentList ?? null,
            establishmentCode: content.establishmentCode ?? null,
            familyCode: content.familyCode ?? null,
            theme: content.theme ?? null
        });
    }
};

export const getExercises = async (): Promise<Exercise[]> => {
    const list: Exercise[] = [];
    const q = query(collection(db, 'exercise'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const getUsers = async (uids: string[]): Promise<User[]> => {
    const list: Exercise[] = [];
    const q = query(
        collection(db, 'exercise'),
        orderBy('createdDate', 'desc'),
        where('authorId', 'in', uids)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const getUserById = async (uid: string): Promise<User | null> => {
    const docRef = doc(db, 'users', await getDocId(uid));
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

export const getExercisesWhitAuthorInfo = async (): Promise<Exercise[]> => {
    const list = await getExercises();
    // const listAuthor: Promise<Exercise>[] = list.map(async (exercise) => {
    //     if (exercise.authorId) {
    //         const author = await getUser(exercise.authorId);
    //         // console.log('author', author);
    //         if (author && author.firstName) {
    //             const tempExercise: Exercise = {
    //                 ...exercise,
    //                 authorName: author.firstName
    //                 // authorPhotoURL: tempData.photoURL
    //             };
    //             console.log('tempExercise', tempExercise);
    //             return tempExercise;
    //         }
    //         return exercise;
    //     }
    //     return exercise;
    // });

    const listToReturn: Exercise[] = [];
    const uids: string[] = [];
    for (const exercise of list) {
        if (exercise.authorId) {
            uids.push(exercise.authorId);
        }
    }
    const users = await getUsers(uids);
    for (const exercise of list) {
        if (exercise.authorId) {
            const user = users.find((u) => u.id === exercise.authorId);
            if (user) {
                const tempExercise: Exercise = {
                    ...exercise,
                    authorName: user.firstName ?? ''
                };
                listToReturn.push(tempExercise);
            } else {
                listToReturn.push(exercise);
            }
        } else {
            listToReturn.push(exercise);
        }
    }
    return await listToReturn;
};

export const getExerciseItem = async (id: string): Promise<ExerciseItem | null> => {
    const reff = doc(db, 'exerciseItem', id).withConverter(ExerciseItemConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

export const saveGamesData = async (data: SeniorStatistics, user: User): Promise<void | string> => {
    // into data.details[0] delte all keys was undefined
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const details: any = {};
    Object.keys(data.details[0]).forEach((key) => {
        if (data.details[0][key as keyof StatisticsDetails] !== undefined) {
            // Use type assertion to tell TypeScript that the key is valid
            details[key as keyof StatisticsDetails] =
                data.details[0][key as keyof StatisticsDetails];
        }
    });
    data.details[0] = details;
    let list: SeniorStatistics[] = [];
    if (user && user.id && (user.role === Roles.SENIOR || user.role === Roles.FAMILY)) {
        const patient = await getPatientUser(user.id);
        if (
            patient?.statistics &&
            patient?.statistics[0] &&
            !JSON.parse(JSON.stringify(patient?.statistics[0])).timeAndLevel
        ) {
            list = list.concat(patient?.statistics);
        }
        const presentGame = list.filter((obj) => {
            return data.exerciseId && obj.exerciseId === data.exerciseId;
        });
        if (presentGame.length > 0) {
            Object.assign(
                // @ts-ignore
                list.find((b) => b.exerciseId === data.exerciseId),
                {
                    details: presentGame[0].details.concat(data.details)
                }
            );
        } else {
            const seniorStatistics = {
                exerciseId: data.exerciseId,
                typeOfExercise: data.typeOfExercise,
                details: data.details
            };
            list.push(seniorStatistics);
        }
        await updateDoc(doc(db, 'users', await getDocId(user.id)), {
            statistics: list
        });
    }
};

export const saveExerciseReview = async (
    exercise: Exercise,
    review: string,
    userId: string,
    exerciseType: ExerciseType
): Promise<void | string> => {
    let list: string[] = [];
    let listOnPatient: string[] = [];
    let tempReviewStatistics: ReviewStatistics[] = [];
    const patient = await getPatientUser(userId);
    let memoryLevel = 0;
    let memorinLevel = 0;
    let puzzleLevel = 0;
    let sudokuLevel = 0;
    let memoryCount = 1;
    let memorinCount = 0;
    let sudokuCount = 1;
    let puzzleCount = 1;
    let memorinCounterLoose = patient?.memorinCounterLoose;
    // const countErrorAndClue = localStorage.getItem('countErrorAndClue');
    if (patient && patient.review) {
        listOnPatient = listOnPatient.concat(patient.review);
    }
    if (patient && patient.reviewStatistics) {
        tempReviewStatistics = tempReviewStatistics.concat(patient.reviewStatistics);
    }
    if (patient?.memoryCounter && exerciseType === ExerciseType.QUIZ) {
        memoryCount = patient.memoryCounter + 1;
    }
    if (patient?.puzzleCounter && exerciseType === ExerciseType.PUZZLE) {
        puzzleCount = patient.puzzleCounter + 1;
    }
    if (patient?.sudokuCounter && exerciseType === ExerciseType.SUDOKU) {
        sudokuCount = patient.sudokuCounter + 1;
    }
    if (
        (patient?.memorinCounter || patient?.memorinCounter === 0) &&
        exerciseType === ExerciseType.MEMORIZ
    ) {
        memorinCount = patient.memorinCounter + 1;
    }

    if (patient?.puzzleDifficultyLevel) {
        puzzleLevel = patient.puzzleDifficultyLevel;
    }
    if (patient?.memoryDifficultyLevel) {
        memoryLevel = patient.memoryDifficultyLevel;
    }
    if (patient?.sudokuDifficultyLevel) {
        sudokuLevel = patient.sudokuDifficultyLevel;
    }
    if (patient?.memorinDifficultyLevel) {
        memorinLevel = patient.memorinDifficultyLevel;
    }

    listOnPatient.push(review);
    if (exercise.id && exercise.exerciseType && review) {
        tempReviewStatistics.push({
            exerciseId: exercise.id,
            exerciseType: exercise.exerciseType,
            review: review as Review,
            date: Timestamp.now()
        });
    }
    // nb of good answer before increasing the difficulty Quiz'in
    if (memoryCount >= 5) {
        memoryCount = 0;
        if (memoryLevel < 6) {
            memoryLevel += 1;
        }
    }
    // nb of good answer before increasing the difficulty Memor'in
    if (memorinCount >= 5) {
        memorinCount = 0;
        if (memorinLevel < 6) {
            memorinLevel += 1;
        }
    }
    // nb of bad answer before decreasing the difficulty Memor'in
    if (memorinCounterLoose && memorinCounterLoose >= 5) {
        memorinCounterLoose = 0;
        if (memorinLevel > 1) {
            memorinLevel -= 1;
        }
    }
    // nb of good answer before increasing the difficulty Puzzl'in
    if (puzzleCount >= 5) {
        puzzleCount = 0;
        if (puzzleLevel < 7) {
            puzzleLevel += 1;
        }
    }
    // nb of good answer before increasing the difficulty Sudoku'in
    if (sudokuCount >= 5) {
        sudokuCount = 0;
        if (sudokuLevel < 7) {
            sudokuLevel += 1;
        }
    }

    if (exerciseType === ExerciseType.QUIZ) {
        await updateDoc(doc(db, 'users', await getDocId(userId)), {
            review: listOnPatient,
            memoryCounter: memoryCount,
            memoryDifficultyLevel: memoryLevel
        });
    } else if (exerciseType === ExerciseType.MEMORIZ) {
        await updateDoc(doc(db, 'users', await getDocId(userId)), {
            review: listOnPatient,
            memorinCounter: memorinCount,
            memorinDifficultyLevel: memorinLevel,
            memorinCounterLoose: memorinCounterLoose
        });
    } else if (exerciseType === ExerciseType.PUZZLE) {
        await updateDoc(doc(db, 'users', await getDocId(userId)), {
            review: listOnPatient,
            puzzleCounter: puzzleCount,
            puzzleDifficultyLevel: puzzleLevel
        });
    } else if (exerciseType === ExerciseType.SUDOKU) {
        await updateDoc(doc(db, 'users', await getDocId(userId)), {
            review: listOnPatient,
            sudokuCounter: sudokuCount,
            sudokuDifficultyLevel: sudokuLevel
        });
    }
    if (tempReviewStatistics) {
        await updateDoc(doc(db, 'users', await getDocId(userId)), {
            reviewStatistics: tempReviewStatistics
        });
    }

    if (exercise.review) {
        list = list.concat(exercise.review);
    }
    list.push(review);
    if (exercise.id) {
        await updateDoc(doc(db, 'exercise', exercise.id), {
            review: list
        });
    }
};

export const getTherapistExercises = async (userId: string): Promise<Exercise[]> => {
    const list: Exercise[] = [];
    const q = query(
        collection(db, 'exercise'),
        where('authorId', '==', userId),
        orderBy('createdDate', 'desc')
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        list.push(docSnap.data());
    });
    return list;
};

export const deleteExerciseCard = async (id: string): Promise<void> => {
    await deleteDoc(doc(db, 'exercise', id));
};

export const deleteExercises = async (idExerciseItem: string, video?: boolean): Promise<void> => {
    if (idExerciseItem) {
        const exercisesList = await getExercises();
        let exercisesContainsId;
        // Check if thumbnails/personal video exist on an exercise
        if (exercisesList && exercisesList.length > 0) {
            if (video) {
                exercisesContainsId = exercisesList.filter((obj) => {
                    return obj.rewardPhoto === idExerciseItem;
                });
            } else {
                exercisesContainsId = exercisesList.filter((obj) => {
                    return obj.exerciseImages?.includes(idExerciseItem);
                });
            }
            // If exercise exist, delete him
            if (exercisesContainsId.length > 0) {
                exercisesContainsId.forEach(async (element) => {
                    if (element && element.id) {
                        await deleteExerciseCard(element.id);
                    }
                });
            }
        }
    }
};

export const deleteExerciseItemCard = async (id: string): Promise<void> => {
    const item = await getExerciseItem(id);
    if (item) {
        // Delete the thumbnails in database and storage
        if (item.exerciseImage !== undefined && typeof item.exerciseImage === 'string') {
            const response = await deleteMedia(item.exerciseImage);
            if (response === 'success') {
                await deleteDoc(doc(db, 'exerciseItem', id));
                await deleteExercises(item.exerciseImage);
            }
        }
    }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const savePatientProfile = async (id: string, data: any): Promise<void | string> => {
    const content = data;
    let link = '';
    if (data.profilePhoto && typeof data.profilePhoto === 'object' && data.familyCode) {
        link = await uploadImage(data.profilePhoto, data.familyCode);
        content.profilePhoto = link;
    }
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        firstName: content.firstName ?? null,
        lastName: content.lastName ?? null,
        dob: content.dob ?? null,
        emailId: content.emailId ?? null,
        profilePhoto: content.profilePhoto ?? null,
        address: content.address ?? null,
        city: content.city ?? null,
        pincode: content.pincode ?? null
    });
    return link;
};

export const updatePatientDifficultyLevel = async (
    memoryDifficultyLevel: number,
    memorinDifficultyLevel: number,
    puzzleDifficultyLevel: number,
    sudokuDifficultyLevel: number,
    pongDifficultyLevel: number,
    reorderDifficultyLevel: number,
    id: string
): Promise<void> => {
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        memoryDifficultyLevel,
        memorinDifficultyLevel,
        puzzleDifficultyLevel,
        sudokuDifficultyLevel,
        pongDifficultyLevel,
        reorderDifficultyLevel
    });
};

export const getDocId = async (user_id: string): Promise<string> => {
    const document = await getUser(user_id);
    if (document == null || document.id == null) {
        return user_id;
    }
    return document.id;
}

export const updatePuzzleAnswer = async (exerciseName: string, id: string): Promise<void> => {
    await updateDoc(doc(db, 'exerciseItem', id), {
        answer: exerciseName
    });
};

export const getAllUserCount = async (): Promise<number[]> => {
    let totalPatients = 0;
    let totalTherapists = 0;
    let totalFamilies = 0;
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(FamilyConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.PRO) {
            totalTherapists += 1;
        }
        if (data.role === Roles.FAMILY) {
            totalFamilies += 1;
        }
        if (data.role === Roles.SENIOR) {
            totalPatients += 1;
        }
    });
    return [totalTherapists, totalFamilies, totalPatients];
};

export const getReview = async (): Promise<number[]> => {
    let disliked = 0;
    let satisfactory = 0;
    let loved = 0;
    const querySnapshot = await getDocs(
        collection(db, 'exercise').withConverter(ExerciseConverter)
    );
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        data.review?.forEach((element: any) => {
            if (element === Review.LOVED) {
                loved += 1;
            }
            if (element === Review.SATISFACTORY) {
                satisfactory += 1;
            }
            if (element === Review.DISLIKED) {
                disliked += 1;
            }
        });
    });
    return [loved, satisfactory, disliked];
};

export const getPatientReview = async (userId: string): Promise<number[]> => {
    let disliked = 0;
    let satisfactory = 0;
    let loved = 0;
    const patient = await getPatientUser(userId);
    if (patient) {
        patient.review?.forEach((element: any) => {
            if (element === Review.LOVED) {
                loved += 1;
            }
            if (element === Review.SATISFACTORY) {
                satisfactory += 1;
            }
            if (element === Review.DISLIKED) {
                disliked += 1;
            }
        });
    }
    return [loved, satisfactory, disliked];
};

// Need rework
export const getCompletedExercises = async (): Promise<number[]> => {
    let completedMemoryExercise = 0;
    let completedPuzzleExercise = 0;
    const querySnapshot = await getPatientsData();
    querySnapshot.forEach((data) => {
        data.completedExerciseList?.forEach((element: { typeOfExercise: any }) => {
            if (element.typeOfExercise === ExerciseType.QUIZ) {
                completedMemoryExercise += 1;
            }
            if (element.typeOfExercise === ExerciseType.PUZZLE) {
                completedPuzzleExercise += 1;
            }
        });
    });
    return [completedMemoryExercise, completedPuzzleExercise];
};

export const getExercise = async (id: string): Promise<Exercise | null> => {
    const reff = doc(db, 'exercise', id).withConverter(ExerciseConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

// Need rework
export const getCompletedTherapistExercises = async (id: string): Promise<number[]> => {
    let completedMemoryExercise = 0;
    let completedMemorinExercise = 0;
    let completedPuzzleExercise = 0;
    const querySnapshot = await getPatientsData();
    querySnapshot.forEach((data) => {
        data.completedExerciseList?.forEach(
            async (element: { completedExerciseId: string; typeOfExercise: any }) => {
                const exercise = await getExercise(element.completedExerciseId);
                if (element.typeOfExercise === ExerciseType.QUIZ && exercise?.authorId === id) {
                    completedMemoryExercise += 1;
                }
                if (element.typeOfExercise === ExerciseType.PUZZLE && exercise?.authorId === id) {
                    completedPuzzleExercise += 1;
                }
                if (element.typeOfExercise === ExerciseType.MEMORIZ && exercise?.authorId === id) {
                    completedMemorinExercise += 1;
                }
            }
        );
    });
    return [completedMemoryExercise, completedPuzzleExercise, completedMemorinExercise];
};

export const getAllUserCountPerMonth = async (): Promise<GraphData> => {
    const date = new Date();
    const backDate = new Date(date.setMonth(date.getMonth() - 12));
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(UserConverter));
    const familyList: number[] = [];
    const patientList: number[] = [];
    const therapistList: number[] = [];
    const familyListCount: number[] = [];
    const patientListCount: number[] = [];
    const therapistListCount: number[] = [];
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.FAMILY) {
            if (data.createdDate && data.createdDate.toDate() > backDate) {
                familyList.push(data.createdDate.toDate().getUTCMonth());
            }
        }
        if (data.role === Roles.SENIOR) {
            if (data.createdDate && data.createdDate.toDate() > backDate) {
                patientList.push(data.createdDate.toDate().getUTCMonth());
            }
        }
        if (data.role === Roles.PRO) {
            if (data.createdDate && data.createdDate.toDate() > backDate) {
                therapistList.push(data.createdDate.toDate().getUTCMonth());
            }
        }
    });
    const countOccurrences = (arr: number[], val: number): number =>
        arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    for (let i = 0; i < 12; i += 1) {
        familyListCount.push(countOccurrences(familyList, i));
        patientListCount.push(countOccurrences(patientList, i));
        therapistListCount.push(countOccurrences(therapistList, i));
    }
    return { patientListCount, familyListCount, therapistListCount };
};

export const getAllExerciseCountPerMonth = async (
    exercise: Exercise[]
): Promise<ExerciseGraphData> => {
    const date = new Date();
    const backDate = new Date(date.setMonth(date.getMonth() - 12));
    const memoryList: number[] = [];
    const memorinList: number[] = [];
    const puzzleList: number[] = [];
    const familyList: number[] = [];
    const therapistList: number[] = [];
    const memoryListCount: number[] = [];
    const memorinListCount: number[] = [];
    const puzzleListCount: number[] = [];
    const familyListCount: number[] = [];
    const therapistListCount: number[] = [];
    exercise.forEach((data) => {
        if (
            data.createdDate &&
            data.createdDate.toDate() > backDate &&
            data.exerciseType === ExerciseType.QUIZ
        ) {
            memoryList.push(data.createdDate.toDate().getUTCMonth());
        }
        if (
            data.createdDate &&
            data.createdDate.toDate() > backDate &&
            data.exerciseType === ExerciseType.PUZZLE
        ) {
            puzzleList.push(data.createdDate.toDate().getUTCMonth());
        }
        if (
            data.createdDate &&
            data.createdDate.toDate() > backDate &&
            data.exerciseType === ExerciseType.MEMORIZ
        ) {
            memorinList.push(data.createdDate.toDate().getUTCMonth());
        }
        if (
            data.createdDate &&
            data.createdDate.toDate() > backDate &&
            data.authorType === Roles.FAMILY
        ) {
            familyList.push(data.createdDate.toDate().getUTCMonth());
        }
        if (
            data.createdDate &&
            data.createdDate.toDate() > backDate &&
            data.authorType === Roles.PRO
        ) {
            therapistList.push(data.createdDate.toDate().getUTCMonth());
        }
    });
    const countOccurrences = (arr: number[], val: number): number =>
        arr.reduce((a, v) => (v === val ? a + 1 : a), 0);
    for (let i = 0; i < 12; i += 1) {
        memoryListCount.push(countOccurrences(memoryList, i));
        memorinListCount.push(countOccurrences(memorinList, i));
        puzzleListCount.push(countOccurrences(puzzleList, i));
        familyListCount.push(countOccurrences(familyList, i));
        therapistListCount.push(countOccurrences(therapistList, i));
    }
    return {
        memoryListCount,
        memorinListCount,
        puzzleListCount,
        familyListCount,
        therapistListCount
    };
};

export const getRewardReceive = async (userId: string): Promise<number> => {
    const exercises = await getExercises();
    const exercisesOfPatient = exercises.filter((obj) => {
        return userId && obj.patientList?.includes(userId);
    });
    let rewardReceive = 0;
    if (exercisesOfPatient.length > 0) {
        exercisesOfPatient.forEach((element) => {
            if (element.rewardVideo !== null) {
                rewardReceive += 1;
            }
            if (element.rewardPhoto !== null) {
                rewardReceive += 1;
            }
        });
        rewardReceive = Math.round((rewardReceive / exercisesOfPatient.length) * 10) / 10;
    }
    return rewardReceive;
};

export const getPatientListExercisesDetails = async (): Promise<PatientExerciseGraphData> => {
    const patients = await getPatientsData();
    let completeExerciseCount = 0;
    let startExerciseCount = 0;
    let puzzleCount = 0;
    let memoryCount = 0;
    let memorinCount = 0;
    let avgPuzzleTime = 0;
    let minPuzzleTime = 0;
    let maxPuzzleTime = 0;
    let avgMemoryTime = 0;
    let minMemoryTime = 0;
    let maxMemoryTime = 0;
    let avgMemorinTime = 0;
    let minMemorinTime = 0;
    let maxMemorinTime = 0;
    const puzzleTimeTaken: number[] = [0];
    const memoryTimeTaken: number[] = [0];
    const memorinTimeTaken: number[] = [0];
    patients.forEach((patient) => {
        if (patient && patient.completedExerciseList) {
            patient.completedExerciseList.forEach((item: { typeOfExercise: any }) => {
                if (item.typeOfExercise === ExerciseType.PUZZLE) {
                    puzzleCount += 1;
                    completeExerciseCount += 1;
                } else if (item.typeOfExercise === ExerciseType.QUIZ) {
                    memoryCount += 1;
                    completeExerciseCount += 1;
                } else if (item.typeOfExercise === ExerciseType.MEMORIZ) {
                    memorinCount += 1;
                    completeExerciseCount += 1;
                } else {
                    startExerciseCount += 1;
                }
            });
            const average = (arr: number[]): number => arr.reduce((p, c) => p + c, 0) / arr.length;
            if (puzzleTimeTaken.length > 0) {
                maxPuzzleTime = Math.max(...puzzleTimeTaken);
                minPuzzleTime = Math.min(...puzzleTimeTaken);
                avgPuzzleTime = Math.round(average(puzzleTimeTaken));
            }
            if (memoryTimeTaken.length > 0) {
                maxMemoryTime = Math.max(...memoryTimeTaken);
                minMemoryTime = Math.min(...memoryTimeTaken);
                avgMemoryTime = Math.round(average(memoryTimeTaken));
            }
            if (memorinTimeTaken.length > 0) {
                maxMemorinTime = Math.max(...memorinTimeTaken);
                minMemorinTime = Math.min(...memorinTimeTaken);
                avgMemorinTime = Math.round(average(memorinTimeTaken));
            }
        }
    });
    return {
        completeExerciseCount,
        startExerciseCount,
        puzzleCount,
        memoryCount,
        memorinCount,
        avgPuzzleTime,
        minPuzzleTime,
        maxPuzzleTime,
        avgMemoryTime,
        minMemoryTime,
        maxMemoryTime,
        avgMemorinTime,
        minMemorinTime,
        maxMemorinTime,
        avgClueCount: 0,
        avgErrorCount: 0
    };
};

// Get all the exercise patient have for Therapist
export const getPatientExercises = async (patient: Patient): Promise<Exercise[]> => {
    let list: Exercise[] = [];
    const exercises = await getExercises();
    if (patient && patient.id) {
        list = exercises.filter((obj) => {
            return patient.id && obj.patientList?.includes(patient.id);
        });
    }
    return list;
};

export const getEstablishmentListDropdown = async (): Promise<DropdownData[]> => {
    const list: DropdownData[] = [];
    const querySnapshot = await getDocs(
        collection(db, 'establishments').withConverter(EstablishmentConverter)
    );
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data && data.establishmentName && data.establishmentCode) {
            list.push({
                name: data.establishmentName,
                value: data.establishmentCode,
                familyCode: undefined
            });
        }
    });
    return list;
};

export const getAllExerciseItems = async (): Promise<LibraryData[]> => {
    const list: LibraryData[] = [];
    const q = query(collection(db, 'exerciseItem'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q.withConverter(ExerciseItemConverter));
    querySnapshot.forEach((docSnap) => {
        const item = docSnap.data();
        if (
            item.theme &&
            item.exerciseImage &&
            item.exerciseImageType &&
            typeof item.exerciseImage === 'string'
        ) {
            list.push({
                theme: item.theme,
                image: item.exerciseImage,
                imageType: item.exerciseImageType
            });
        }
    });
    return list;
};

export const getPatientDropdownList = async (): Promise<DropdownData[]> => {
    const list: DropdownData[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(PatientConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.SENIOR && data.id) {
            list.push({
                name: `${data.firstName} ${data.lastName}`,
                value: data.id,
                familyCode: data.familyCode
            });
        }
    });
    return list;
};

export const getFamilyList = async (): Promise<Family[]> => {
    const list: Family[] = [];
    const patientList = await getPatientDropdownList();
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(FamilyConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.FAMILY) {
            const patientName = patientList.find((item) => item.familyCode === data.familyCode);
            data.patient = patientName?.name;
            list.push(data);
        }
    });
    return list;
};

export const getThearpistList = async (): Promise<Therapist[]> => {
    const list: Therapist[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(TherapistConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.PRO) {
            list.push(data);
        }
    });
    return list;
};

export const getPatientList = async (): Promise<Patient[]> => {
    const list: Patient[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(PatientConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.SENIOR) {
            list.push(data);
        }
    });
    return list;
};

export const getAdminExerciseItems = async (): Promise<LibraryData[]> => {
    const list: LibraryData[] = [];
    const querySnapshot = await getDocs(
        collection(db, 'exerciseItem').withConverter(ExerciseItemConverter)
    );
    querySnapshot.forEach((docSnap) => {
        const item = docSnap.data();
        if (
            item.authorType === Roles.ADMIN &&
            item.theme &&
            item.exerciseImage &&
            item.exerciseImageType &&
            typeof item.exerciseImage === 'string'
        ) {
            list.push({
                theme: item.theme,
                image: item.exerciseImage,
                imageType: item.exerciseImageType
            });
        }
    });
    return list;
};

export const updateUserStatus = async (id: string, data: Status): Promise<void | string> => {
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        status: data
    });
};

export const updateUserIsSubscribed = async (
    userId: string,
    data: boolean
): Promise<void | string> => {
    await updateDoc(doc(db, 'users', await getDocId(userId)), {
        isSubscribed: data
    });
};

export const updateUserNeedDelete = async (
    userId: string,
    data: boolean
): Promise<void | string> => {
    await updateDoc(doc(db, 'users', await getDocId(userId)), {
        needDelete: data
    });
};

export const updateEstablishmentStatus = async (
    id: string,
    data: Status
): Promise<void | string> => {
    await updateDoc(doc(db, 'establishments', id), {
        status: data
    });
};

export const getTherapistUsers = async (establishmentCode: string): Promise<Therapist[]> => {
    const list: Therapist[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(TherapistConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.PRO && data.establishmentCode === establishmentCode) {
            list.push(data);
        }
    });
    return list;
};

export const getFamilyUsers = async (establishmentCode: string): Promise<Family[]> => {
    const list: Family[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(FamilyConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.FAMILY && data.establishmentCode === establishmentCode) {
            list.push(data);
        }
    });
    return list;
};

export const getPatientUsers = async (establishmentCode: string): Promise<Patient[]> => {
    const list: Patient[] = [];
    const querySnapshot = await getDocs(collection(db, 'users').withConverter(TherapistConverter));
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.role === Roles.SENIOR && data.establishmentCode === establishmentCode) {
            list.push(data);
        }
    });
    return list;
};

export const getUsersFromEstablishmentList = async (): Promise<EstablishmentTableViewData[]> => {
    const establishment: Establishment[] = await getEstablishmentList();
    const list: EstablishmentTableViewData[] = [];
    await Promise.all(
        establishment.map(async (item) => {
            if (item.establishmentCode && item.id) {
                const therapist: Therapist[] = await getTherapistUsers(item.establishmentCode);
                const family: Family[] = await getFamilyUsers(item.establishmentCode);
                const patient: Patient[] = await getPatientUsers(item.establishmentCode);
                list.push({
                    id: item.id,
                    establishmentName: item.establishmentName ?? '',
                    patientsCount: patient.length ?? 0,
                    therapistsCount: therapist.length ?? 0,
                    famillesCount: family.length ?? 0,
                    exportCsv: 'EXPORTEREXPORTER'
                });
            }
        })
    );
    return list;
};

export const saveAdminProfile = async (id: string, data: User): Promise<void | string> => {
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        firstName: data.firstName,
        lastName: data.lastName
    });
};

export const getEstablishmentExportData = async (
    item: Establishment
): Promise<EstablishmentsExportData[]> => {
    const list: EstablishmentsExportData[] = [];
    if (item.establishmentCode && item.id) {
        const therapist: Therapist[] = await getTherapistUsers(item.establishmentCode);
        const family: Family[] = await getFamilyUsers(item.establishmentCode);
        const patient: Patient[] = await getPatientUsers(item.establishmentCode);
        therapist.forEach((therapistData) => {
            list.push({
                id: therapistData.id ?? 'NC',
                establishmentName: item.establishmentName ?? 'NC',
                lastName: therapistData.lastName ?? 'NC',
                firstName: therapistData.firstName ?? 'NC',
                typeOfUser: therapistData.role ? convertToTitleCase(therapistData.role) : 'NC',
                gender: 'NC',
                levelOfStudy: 'NC',
                socioProfessionalCategory: 'NC',
                dob: therapistData.dob ? getDateFromTimestamp(therapistData.dob) : 'NC',
                relationshipOrFunction: therapistData.fonction ?? 'NC',
                familyOrLegalReferent: 'NC',
                establishmentCode: therapistData.establishmentCode ?? 'NC',
                emailId: therapistData.emailId ?? 'NC',
                mobileNumber: therapistData.mobile ?? 'NC',
                startDate: therapistData.startDate
                    ? getDateFromTimestamp(therapistData.startDate)
                    : 'NC',
                endDate: therapistData.endDate ? getDateFromTimestamp(therapistData.endDate) : 'NC',
                patientsMaxCount: item.maxPatients ?? 'NC',
                therapistsMaxCount: item.maxTherapists ?? 'NC',
                famillesMaxCount: item.maxFamily ?? 'NC',
                status: therapistData.status ? convertToTitleCase(therapistData.status) : 'NC',
                address: item.address ?? 'NC',
                postalCode: item.pincode ?? 'NC',
                city: item.city ?? 'NC',
                cguValidate: therapistData.cguValidate ? 'Oui' : 'Non',
                cguValidateDate: therapistData.cguValidateDate
                    ? getDateFromTimestamp(therapistData.cguValidateDate)
                    : 'NC',
                createdDate: therapistData.createdDate
                    ? getDateFromTimestamp(therapistData.createdDate)
                    : 'NC'
            });
        });
        family.forEach((familyData) => {
            list.push({
                id: familyData.id ?? 'NC',
                establishmentName: item.establishmentName ?? 'NC',
                lastName: familyData.lastName ?? 'NC',
                firstName: familyData.firstName ?? 'NC',
                typeOfUser: familyData.role ? convertToTitleCase(familyData.role) : 'NC',
                gender: 'NC',
                levelOfStudy: 'NC',
                socioProfessionalCategory: 'NC',
                dob: familyData.dob ? getDateFromTimestamp(familyData.dob) : 'NC',
                relationshipOrFunction: familyData.relationship ?? 'NC',
                familyOrLegalReferent: familyData.legalReferent ? 'Oui' : 'Non',
                establishmentCode: familyData.familyCode ?? 'NC',
                emailId: familyData.emailId ?? 'NC',
                mobileNumber: 'NC',
                startDate: familyData.startDate ? getDateFromTimestamp(familyData.startDate) : 'NC',
                endDate: familyData.endDate ? getDateFromTimestamp(familyData.endDate) : 'NC',
                patientsMaxCount: item.maxPatients ?? 'NC',
                therapistsMaxCount: item.maxTherapists ?? 'NC',
                famillesMaxCount: item.maxFamily ?? 'NC',
                status: familyData.status ? convertToTitleCase(familyData.status) : 'NC',
                address: familyData.address ?? 'NC',
                postalCode: familyData.pincode ?? 'NC',
                city: familyData.city ?? 'NC',
                cguValidate: familyData.cguValidate ? 'Oui' : 'Non',
                cguValidateDate: familyData.cguValidateDate
                    ? getDateFromTimestamp(familyData.cguValidateDate)
                    : 'NC',
                createdDate: familyData.createdDate
                    ? getDateFromTimestamp(familyData.createdDate)
                    : 'NC'
            });
        });
        patient.forEach((patientData) => {
            list.push({
                id: patientData.id ?? 'NC',
                establishmentName: item.establishmentName ?? 'NC',
                lastName: patientData.lastName ?? 'NC',
                firstName: patientData.firstName ?? 'NC',
                typeOfUser: patientData.role ? convertToTitleCase(patientData.role) : 'NC',
                gender: patientData.sex ?? 'NC',
                levelOfStudy: patientData.level ?? 'NC',
                socioProfessionalCategory: patientData.category ?? 'NC',
                dob: patientData.dob ? getDateFromTimestamp(patientData.dob) : 'NC',
                relationshipOrFunction: 'NC',
                familyOrLegalReferent: patientData.legalReferent ? 'Oui' : 'Non',
                establishmentCode: patientData.familyCode ?? 'NC',
                emailId: patientData.emailId ?? 'NC',
                mobileNumber: 'NC',
                startDate: patientData.startDate
                    ? getDateFromTimestamp(patientData.startDate)
                    : 'NC',
                endDate: patientData.endDate ? getDateFromTimestamp(patientData.endDate) : 'NC',
                patientsMaxCount: item.maxPatients ?? 'NC',
                therapistsMaxCount: item.maxTherapists ?? 'NC',
                famillesMaxCount: item.maxFamily ?? 'NC',
                status: patientData.status ? convertToTitleCase(patientData.status) : 'NC',
                address: patientData.address ?? 'NC',
                postalCode: patientData.pincode ?? 'NC',
                city: patientData.city ?? 'NC',
                cguValidate: 'NC',
                cguValidateDate: 'NC',
                createdDate: patientData.createdDate
                    ? getDateFromTimestamp(patientData.createdDate)
                    : 'NC'
            });
        });
    }
    return list;
};

export const getEstablishmentsExportData = async (): Promise<EstablishmentsExportData[]> => {
    const establishment: Establishment[] = await getEstablishmentList();
    let list: EstablishmentsExportData[] = [];
    await Promise.all(
        establishment.map(async (item) => {
            list = list.concat(await getEstablishmentExportData(item));
        })
    );
    return list;
};

export const getTherapistsExportData = async (): Promise<EstablishmentsExportData[]> => {
    const establishments = await getEstablishmentList();
    const therapist: Therapist[] = await getThearpistList();
    const list: EstablishmentsExportData[] = [];
    therapist.forEach((therapistData) => {
        const item: Establishment | undefined = establishments.find(
            (establishment) => establishment.establishmentCode === therapistData.establishmentCode
        );
        list.push({
            id: therapistData.id ?? 'NC',
            establishmentName: therapistData.establishmentName ?? 'NC',
            lastName: therapistData.lastName ?? 'NC',
            firstName: therapistData.firstName ?? 'NC',
            typeOfUser: therapistData.role ? convertToTitleCase(therapistData.role) : 'NC',
            gender: 'NC',
            levelOfStudy: 'NC',
            socioProfessionalCategory: 'NC',
            dob: therapistData.dob ? getDateFromTimestamp(therapistData.dob) : 'NC',
            relationshipOrFunction: therapistData.fonction ?? 'NC',
            familyOrLegalReferent: 'NC',
            establishmentCode: therapistData.establishmentCode ?? 'NC',
            emailId: therapistData.emailId ?? 'NC',
            mobileNumber: therapistData.mobile ?? 'NC',
            startDate: therapistData.startDate
                ? getDateFromTimestamp(therapistData.startDate)
                : 'NC',
            endDate: therapistData.endDate ? getDateFromTimestamp(therapistData.endDate) : 'NC',
            patientsMaxCount: (item && item.maxPatients) ?? 'NC',
            therapistsMaxCount: (item && item.maxTherapists) ?? 'NC',
            famillesMaxCount: (item && item.maxFamily) ?? 'NC',
            status: therapistData.status ? convertToTitleCase(therapistData.status) : 'NC',
            address: (item && item.address) ?? 'NC',
            postalCode: (item && item.pincode) ?? 'NC',
            city: (item && item.city) ?? 'NC',
            cguValidate: therapistData.cguValidate ? 'Oui' : 'Non',
            cguValidateDate: therapistData.cguValidateDate
                ? getDateFromTimestamp(therapistData.cguValidateDate)
                : 'NC',
            createdDate: therapistData.createdDate
                ? getDateFromTimestamp(therapistData.createdDate)
                : 'NC'
        });
    });
    return list;
};

export const getFamilyExportData = async (): Promise<EstablishmentsExportData[]> => {
    const establishments = await getEstablishmentList();
    const family: Family[] = await getFamilyList();
    const list: EstablishmentsExportData[] = [];
    family.forEach((familyData) => {
        const item: Establishment | undefined = establishments.find(
            (establishment) => establishment.establishmentCode === familyData.establishmentCode
        );
        list.push({
            id: familyData.id ?? 'NC',
            establishmentName: familyData.establishmentName ?? 'NC',
            lastName: familyData.lastName ?? 'NC',
            firstName: familyData.firstName ?? 'NC',
            typeOfUser: familyData.role ? convertToTitleCase(familyData.role) : 'NC',
            gender: 'NC',
            levelOfStudy: 'NC',
            socioProfessionalCategory: 'NC',
            dob: familyData.dob ? getDateFromTimestamp(familyData.dob) : 'NC',
            relationshipOrFunction: familyData.relationship ?? 'NC',
            familyOrLegalReferent: familyData.legalReferent ? 'Oui' : 'Non',
            establishmentCode: familyData.familyCode ?? 'NC',
            emailId: familyData.emailId ?? 'NC',
            mobileNumber: 'NC',
            createdDate: familyData.createdDate
                ? getDateFromTimestamp(familyData.createdDate)
                : 'NC',
            startDate: familyData.startDate ? getDateFromTimestamp(familyData.startDate) : 'NC',
            endDate: familyData.endDate ? getDateFromTimestamp(familyData.endDate) : 'NC',
            patientsMaxCount: (item && item.maxPatients) ?? 'NC',
            therapistsMaxCount: (item && item.maxTherapists) ?? 'NC',
            famillesMaxCount: (item && item.maxFamily) ?? 'NC',
            status: familyData.status ? convertToTitleCase(familyData.status) : 'NC',
            address: familyData.address ?? 'NC',
            postalCode: familyData.pincode ?? 'NC',
            city: familyData.city ?? 'NC',
            cguValidate: familyData.cguValidate ? 'Oui' : 'Non',
            cguValidateDate: familyData.cguValidateDate
                ? getDateFromTimestamp(familyData.cguValidateDate)
                : 'NC'
        });
    });
    return list;
};

export const getPatientExportData = async (): Promise<EstablishmentsExportData[]> => {
    const establishments = await getEstablishmentList();
    const patient: Patient[] = await getPatientList();
    const list: EstablishmentsExportData[] = [];
    patient.forEach((patientData) => {
        const item: Establishment | undefined = establishments.find(
            (establishment) => establishment.establishmentCode === patientData.establishmentCode
        );
        list.push({
            id: patientData.id ?? 'NC',
            establishmentName: patientData.establishmentName ?? 'NC',
            lastName: patientData.lastName ?? 'NC',
            firstName: patientData.firstName ?? 'NC',
            typeOfUser: patientData.role ? convertToTitleCase(patientData.role) : 'NC',
            gender: patientData.sex ?? 'NC',
            levelOfStudy: patientData.level ?? 'NC',
            socioProfessionalCategory: patientData.category ?? 'NC',
            dob: patientData.dob ? getDateFromTimestamp(patientData.dob) : 'NC',
            relationshipOrFunction: 'NC',
            familyOrLegalReferent: patientData.legalReferent ? 'Oui' : 'Non',
            establishmentCode: patientData.familyCode ?? 'NC',
            emailId: patientData.emailId ?? 'NC',
            mobileNumber: 'NC',
            startDate: patientData.startDate ? getDateFromTimestamp(patientData.startDate) : 'NC',
            endDate: patientData.endDate ? getDateFromTimestamp(patientData.endDate) : 'NC',
            patientsMaxCount: (item && item.maxPatients) ?? 'NC',
            therapistsMaxCount: (item && item.maxTherapists) ?? 'NC',
            famillesMaxCount: (item && item.maxFamily) ?? 'NC',
            status: patientData.status ? convertToTitleCase(patientData.status) : 'NC',
            address: patientData.address ?? 'NC',
            postalCode: patientData.pincode ?? 'NC',
            city: patientData.city ?? 'NC',
            cguValidate: 'NC',
            cguValidateDate: 'NC',
            createdDate: patientData.createdDate
                ? getDateFromTimestamp(patientData.createdDate)
                : 'NC'
        });
    });
    return list;
};

export const getEstablishmentExportDataById = async (
    id: string
): Promise<EstablishmentsExportData[]> => {
    const establishment = await getEstablishment(id);
    if (establishment) {
        return getEstablishmentExportData(establishment);
    }
    return [];
};

export const getEstablishmentExport = async (
    data: EstablishmentTableViewData[]
): Promise<ExportData[]> => {
    const list: ExportData[] = [];
    await Promise.all(
        data.map(async (item) => {
            const content = await getEstablishmentExportDataById(item.id);
            list.push({
                id: item.id,
                establishmentData: content
            });
        })
    );
    return list;
};

export const deactiveUserAccount = async (id: string): Promise<void> => {
    await updateDoc(doc(db, 'users', await getDocId(id)), {
        status: Status.INACTIVE
    });
};

// export const getEstablishmentPatients = async (
//     establishments: Establishment[]
// ): Promise<StateInterface> => {
//     const list: StateInterface = {
//         patientsArray: [],
//         patientIdArray: []
//     };
//     const patients = await getPatientList();
//     establishments.forEach((establishment) => {
//         const patientList = patients.filter((obj) => {
//             return obj.establishmentCode === establishment.establishmentCode;
//         });
//         list.patientsArray = list.patientsArray.concat(patientList);
//         patientList.forEach((item) => {
//             list.patientIdArray.push(item.id);
//         });
//     });
//     return list;
// };

export const getPatientExerciseList = async (user: User): Promise<Exercise[]> => {
    const list: Exercise[] = [];
    const q = query(collection(db, 'exercise'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (docSnap) => {
        const data = docSnap.data();
        if (
            (data.patientList && data.patientList.includes(user.id)) ||
            (
                data.authorType === Roles.ADMIN &&
                (data.authorId === "Uxm6MgV2rce96gARLIQUf1pFSLL2" || data.authorId === "zyHCgiK1YGXSNwe6T0OJksojSzg1")
            )
            // (data.establishmentList &&
            //     data.authorType === Roles.ADMIN &&
            //     data.establishmentList.includes(user.establishmentCode))
        ) {
            list.push(data);
        } else if (
            data.createdDate < Timestamp.fromDate(new Date('2023-11-08 23:42:00')) &&
            (data.authorType === Roles.FAMILY || data.authorType === Roles.SENIOR) &&
            data?.familyCode === user.familyCode
        ) {
            // if (data.authorType === Roles.FAMILY || data.authorType === Roles.SENIOR) {
            // const family = await getFamilyUser(data.authorId);
            // if (data?.familyCode === user.familyCode) {
            list.push(data);
            // }
            // }
        }
    });
    return list;
};

export const getNumberOfExercises = async (
    user: User
): Promise<{
    allGames: number;
    [ExerciseType.QUIZ]: number;
    [ExerciseType.MEMORIZ]: number;
    [ExerciseType.PUZZLE]: number;
    [ExerciseType.SUDOKU]: number;
    [ExerciseType.ORDER]: number;
    [ExerciseType.PONG]: number;
    [ExerciseType.TOURBILLON]: number;
    [ExerciseType.MELIMOTS]: number;
}> => {
    const data = {
        allGames: 0,
        [ExerciseType.QUIZ]: 0,
        [ExerciseType.MEMORIZ]: 0,
        [ExerciseType.PUZZLE]: 0,
        [ExerciseType.SUDOKU]: 0,
        [ExerciseType.ORDER]: 0,
        [ExerciseType.PONG]: 0,
        [ExerciseType.TOURBILLON]: 0,
        [ExerciseType.MELIMOTS]: 0
    };

    await getPatientExerciseList(user).then((exercises) => {
        exercises.forEach((exercise) => {
            if (exercise.exerciseType) {
                data.allGames += 1;
                data[exercise.exerciseType] += 1;
            }
        });
    });

    return data;
};

export const getPatientFromEstablishment = async (code: string): Promise<Patient[] | null> => {
    const reff = collection(db, 'users').withConverter(PatientConverter);
    const q = query(
        reff,
        where('establishmentCode', '==', code),
        where('role', '==', 'PATIENT'),
        where('status', '==', 'ACTIVE')
    );
    const docSnap = await getDocs(q);
    const list: Patient[] = [];
    if (docSnap.docs.length > 0) {
        docSnap.docs.forEach((item) => {
            const data = item.data();
            if (data.role === Roles.SENIOR) {
                list.push(data);
            }
        });
        return list;
    }
    return null;
};

export const getProFromEstablishment = async (code: string): Promise<User[] | null> => {
    const reff = collection(db, 'users').withConverter(UserConverter);
    const q = query(
        reff,
        where('establishmentCode', '==', code),
        where('role', '==', 'THERAPIST'),
        where('status', '==', 'ACTIVE')
    );
    const docSnap = await getDocs(q);
    const list: User[] = docSnap.docs.map((item) => item.data());
    if (list.length > 0) {
        return list;
    }
    return null;
};

export const getPersonalVideos = async (
    familyCode: string,
    establishmentCode: string,
    videoCollection: string,
    userId: string,
    userId2?: string
): Promise<
    | {
          authorType: Roles | undefined;
          data: MediaInformation | undefined;
          createdDate: Timestamp | undefined;
          id: string | undefined;
      }[]
    | null
> => {
    const list: {
        id: string | undefined;
        authorType: Roles | undefined;
        data: MediaInformation | undefined;
        createdDate: Timestamp | undefined;
    }[] = [];

    const patientQ = query(
        collection(db, videoCollection),
        where('authorType', '==', Roles.FAMILY),
        where('communityId', '==', familyCode)
    );
    const therapistQ = query(
        collection(db, videoCollection),
        where('authorType', '==', Roles.PRO),
        where('communityId', '==', establishmentCode)
    );
    const adminQ = query(collection(db, videoCollection), where('authorType', '==', Roles.ADMIN));
    const patientQuerySnapshot = await getDocs(patientQ.withConverter(VideoConverter));
    const therapistQuerySnapshot = await getDocs(therapistQ.withConverter(VideoConverter));
    const adminQuerySnapshot = await getDocs(adminQ.withConverter(VideoConverter));

    patientQuerySnapshot.forEach((docSnap) => {
        docSnap.data().mediaInformation?.forEach((mediaData) => {
            const item = {
                id: docSnap.id,
                authorType: docSnap.data().authorType,
                authorId: mediaData.authorId,
                data: mediaData,
                createdDate: mediaData.createdDate,
                patientList: mediaData.patientList
            };
            if (
                item &&
                item.authorType &&
                item.data &&
                ((item.createdDate &&
                    item.createdDate < Timestamp.fromDate(new Date('2023-11-15 23:42:00'))) ||
                    item.patientList?.includes(userId) ||
                    item.authorId === userId || item.authorId === userId2)
            ) {
                list.push(item);
            }
        });
    });
    therapistQuerySnapshot.forEach((docSnap) => {
        docSnap.data().mediaInformation?.forEach((mediaData) => {
            const item = {
                id: docSnap.id,
                authorType: docSnap.data().authorType,
                authorId: mediaData.authorId,
                data: mediaData,
                createdDate: mediaData.createdDate,
                patientList: mediaData.patientList
            };
            if (
                item &&
                item.authorType &&
                item.data &&
                (item.patientList?.includes(userId) || item.authorId === userId)
            ) {
                list.push(item);
            }
        });
    });
    adminQuerySnapshot.forEach((docSnap) => {
        docSnap.data().mediaInformation?.forEach((mediaData) => {
            const item = {
                id: docSnap.id,
                authorType: docSnap.data().authorType,
                authorId: mediaData.authorId,
                data: mediaData,
                createdDate: mediaData.createdDate
            };
            if (
                item &&
                item.authorType &&
                item.data &&
                item.data.etsList?.includes(establishmentCode)
            ) {
                list.push(item);
            }
        });
    });

    return list;
};

export const getPersonalVideo = async (
    id: string,
    secondId?: string
): Promise<MediaInformation[] | null> => {
    let video: Video;
    let videoSecond: Video;
    let list: MediaInformation[] = [];
    if (secondId) {
        const reffSecond = doc(db, 'video', secondId).withConverter(VideoConverter);
        const docSnapSecond = await getDoc(reffSecond);
        if (docSnapSecond.exists()) {
            videoSecond = docSnapSecond.data();
            if (videoSecond && videoSecond.mediaInformation) {
                videoSecond.mediaInformation.forEach((element) => {
                    if (element.patientList && element.patientList?.includes(id)) {
                        list.push(element);
                    }
                });
            }
        }
    }
    const reff = doc(db, 'video', id).withConverter(VideoConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        video = docSnap.data();
        if (video && video.mediaInformation) {
            if (list && list.length > 0) {
                list = list.concat(video.mediaInformation);
            } else {
                list = video.mediaInformation;
            }
            return list;
        }
    } else if (!docSnap.exists() && list.length > 0) {
        return list;
    }
    return null;
};

export const addPersonalVideo = async (
    data: UsersVideoData,
    videoUrl: File | string,
    pathStorage?: string // attention pas d'upload si undefined
): Promise<void | string> => {
    let list: MediaInformation[] = [];
    if (data.communityId && videoUrl && data.authorId) {
        const videoList = await getPersonalVideo(data.communityId);
        const link =
            pathStorage && typeof videoUrl !== 'string'
                ? await uploadImage(videoUrl, pathStorage, true)
                : videoUrl;
        if (link !== '') {
            const listToPush = {
                title: data.title ?? '',
                videoUrl: link,
                createdDate: data.createdDate ?? Timestamp.now(),
                authorId: data.authorId === Roles.SENIOR ? Roles.FAMILY : data.authorId,
                patientList: data.patientList ?? [],
                proList: data.proList ?? [],
                etsList: data.etsList ?? [],
                thumbnail: data.thumbnail ?? ''
            };
            if (videoList === null) {
                list.push(listToPush);
                return setDoc(doc(db, 'video', data.communityId), {
                    communityId: data.communityId,
                    authorType: data.role === Roles.SENIOR ? Roles.FAMILY : data.role,
                    mediaInformation: list
                })
                    .then((): string => {
                        return 'success';
                    })
                    .catch((err): void => {
                        return err;
                    });
            }
            if (videoList !== null && videoList) {
                if (videoList.length > 0) {
                    list = list.concat(videoList);
                    list.push(listToPush);
                } else {
                    list.push(listToPush);
                }
                return updateDoc(doc(db, 'video', data.communityId), {
                    mediaInformation: list
                })
                    .then((): string => {
                        return 'success';
                    })
                    .catch((err): void => {
                        return err;
                    });
            }
        }
    }
    return '';
};

export const deletePersonalVideo = async (
    id: string,
    videoUrl: string,
    deleteVideoFile = true
): Promise<void | string> => {
    let list: MediaInformation[] = [];
    if (id && videoUrl) {
        const videoList = await getPersonalVideo(id);
        if (videoList !== null && videoList && videoList.length > 0) {
            list = list.concat(videoList);
            list = list.filter((obj) => {
                return obj.videoUrl !== videoUrl;
            });
            const response = deleteVideoFile ? await deleteMedia(videoUrl) : 'success';
            if (response === 'success') {
                await updateDoc(doc(db, 'video', id), {
                    mediaInformation: list
                });
                await deleteExercises(videoUrl, true);
            }
        }
    }
};

export const getPersonalYoutubeVideoFromTherapist = async (
    establishmentId: string,
    familyCode: string
): Promise<MediaInformation[] | null> => {
    let video: Video;
    const list: MediaInformation[] = [];
    if (establishmentId && familyCode) {
        const reff = doc(db, 'youtubeVideo', establishmentId).withConverter(VideoConverter);
        const docSnap = await getDoc(reff);
        if (docSnap.exists()) {
            video = docSnap.data();
            if (video && video.mediaInformation) {
                video.mediaInformation.forEach((element) => {
                    if (element.patientList && element.patientList?.includes(familyCode)) {
                        list.push(element);
                    }
                });
            }
        }
    }

    return list;
};

// Make only one function getVideo with param youtube or not
export const getPersonalYoutubeVideo = async (
    id: string,
    secondId?: string
): Promise<MediaInformation[] | null> => {
    let video: Video;
    let videoSecond: Video;
    let list: MediaInformation[] = [];
    if (secondId) {
        const reffSecond = doc(db, 'youtubeVideo', secondId).withConverter(VideoConverter);
        const docSnapSecond = await getDoc(reffSecond);
        if (docSnapSecond.exists()) {
            videoSecond = docSnapSecond.data();
            if (videoSecond && videoSecond.mediaInformation) {
                videoSecond.mediaInformation.forEach((element) => {
                    if (element.patientList && element.patientList?.includes(id)) {
                        list.push(element);
                    }
                });
            }
        }
    }
    const reff = doc(db, 'youtubeVideo', id).withConverter(VideoConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        video = docSnap.data();
        if (video && video.mediaInformation) {
            if (list && list.length > 0) {
                list = list.concat(video.mediaInformation);
            } else {
                list = video.mediaInformation;
            }
            return list;
        }
    } else if (!docSnap.exists() && list.length > 0) {
        return list;
    }
    return null;
};

export const addPersonalYoutubeVideo = async (
    data: UsersVideoData,
    videoUrl: string
): Promise<void | string> => {
    let list: MediaInformation[] = [];
    if (data.communityId && videoUrl) {
        const videoList = await getPersonalYoutubeVideo(data.communityId);
        const listToPush = {
            title: data.title ?? '',
            videoUrl,
            createdDate: data.createdDate ?? Timestamp.now(),
            authorId: data.authorId,
            patientList: data.patientList ?? [],
            proList: data.proList ?? [],
            etsList: data.etsList ?? []
        };
        if (videoList === null) {
            list.push(listToPush);
            return setDoc(doc(db, 'youtubeVideo', data.communityId), {
                communityId: data.communityId,
                authorType: data.role === Roles.SENIOR ? Roles.FAMILY : data.role,
                mediaInformation: list
            })
                .then((): string => {
                    return 'success';
                })
                .catch((err): void => {
                    return err;
                });
        }
        if (videoList !== null) {
            if (videoList.length > 0) {
                list = list.concat(videoList);
                list.push(listToPush);
            } else {
                list.push(listToPush);
            }
            return updateDoc(doc(db, 'youtubeVideo', data.communityId), {
                mediaInformation: list
            })
                .then((): string => {
                    return 'success';
                })
                .catch((err): void => {
                    return err;
                });
        }
    }
    return '';
};

export const deletePersonalYoutubeVideo = async (
    id: string,
    videoUrl: string
): Promise<void | string> => {
    let list: MediaInformation[] = [];
    if (id && videoUrl) {
        const videoList = await getPersonalYoutubeVideo(id);
        if (videoList !== null && videoList.length > 0) {
            list = list.concat(videoList);
            list = list.filter((obj) => {
                return obj.videoUrl !== videoUrl;
            });
            await updateDoc(doc(db, 'youtubeVideo', id), {
                mediaInformation: list
            });
        }
    }
};

export const getExerciseItemsByAutor = async (
    userId: string,
    id: string,
    etsId: string
): Promise<ExerciseItem[]> => {
    const list: ExerciseItem[] = [];
    const q = query(collection(db, 'exerciseItem'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q.withConverter(ExerciseItemConverter));
    querySnapshot.forEach((docSnap) => {
        const item = docSnap.data();
        if (
            item.authorId === userId || item.authorId === id ||
            item.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(id) ||
            item.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(userId) ||
            item.etsList?.includes(etsId)
        ) {
            list.push(item);
        }
    });
    return list;
};
export const getPersonalVideosByAutor = async (
    userId: string,
    etsId: string,
    id: string
): Promise<MediaInformation[]> => {
    const list: any[] = [];
    const reff = doc(db, 'video', etsId).withConverter(VideoConverter);
    const docSnap = await getDoc(reff);
    if (docSnap.exists()) {
        const videoList = docSnap.data();
        if (videoList && videoList.mediaInformation) {
            videoList.mediaInformation.forEach((element) => {
                if (
                    element.authorId === userId ||
                    element.authorId === id ||
                    element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(id) ||
                    element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(userId) ||
                    element.etsList?.includes(etsId)
                ) {
                    list.push({ ...element, id: videoList.communityId });
                }
            });
        }
    }
    const reff2 = doc(db, 'youtubeVideo', etsId).withConverter(VideoConverter);
    const docSnap2 = await getDoc(reff2);
    if (docSnap2.exists()) {
        const videoList = docSnap2.data();
        if (videoList && videoList.mediaInformation) {
            videoList.mediaInformation.forEach((element) => {
                if (
                    element.authorId === userId ||
                    element.authorId === id ||
                    element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(id) ||
                    element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(userId) ||
                    element.etsList?.includes(etsId)
                ) {
                    list.push({ ...element, id: videoList.communityId });
                }
            });
        }
    }
    return list;
};

// export const updateNeedViewOnboarding = async (userId: string, value: boolean): Promise<void> => {
//     await updateDoc(doc(db, 'users', userId), {
//         needViewOnboarding: value
//     });
// };

export const getGamesCreatedByUser = async (userId: string): Promise<Exercise[]> => {
    const list: Exercise[] = [];
    const q = query(collection(db, 'exercise'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.authorId === userId) {
            list.push(data);
        }
    });
    return list;
};

export const getExerciseItemCreatedByUser = async (userId: string): Promise<Exercise[]> => {
    const list: Exercise[] = [];
    const q = query(collection(db, 'exerciseItem'), orderBy('createdDate', 'desc'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        if (data.authorId === userId) {
            list.push(data);
        }
    });
    return list;
};

export const getVideoYoutubeCreatedByUser = async (
    userId: string
): Promise<{ authorId: string; createdDate: Timestamp }[]> => {
    const list: { authorId: string; createdDate: Timestamp }[] = [];
    const q = query(collection(db, 'youtubeVideo'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        data.mediaInformation?.forEach((element: { authorId: string; createdDate: Timestamp }) => {
            if (element.authorId === userId) {
                list.push(element);
            }
        });
    });
    return list;
};

export const getVideoPersonalCreatedByUser = async (
    userId: string
): Promise<{ authorId: string; createdDate: Timestamp }[]> => {
    const list: { authorId: string; createdDate: Timestamp }[] = [];
    const q = query(collection(db, 'video'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        data.mediaInformation?.forEach((element: { authorId: string; createdDate: Timestamp }) => {
            if (element.authorId === userId) {
                list.push(element);
            }
        });
    });
    return list;
};

// export const getVideoYoutbeCreatedByUser2 = async (
//     userId: string
// ): Promise<MediaInformation[]> => {
//     const list: any[] = [];
//     const reff = doc(db, 'video', etsId).withConverter(VideoConverter);
//     const docSnap = await getDoc(reff);
//     if (docSnap.exists()) {
//         const videoList = docSnap.data();
//         if (videoList && videoList.mediaInformation) {
//             videoList.mediaInformation.forEach((element) => {
//                 if (
//                     element.authorId === userId ||
//                     element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(id)
//                 ) {
//                     list.push({ ...element, id: videoList.communityId });
//                 }
//             });
//         }
//     }
//     const reff2 = doc(db, 'youtubeVideo', etsId).withConverter(VideoConverter);
//     const docSnap2 = await getDoc(reff2);
//     if (docSnap2.exists()) {
//         const videoList = docSnap2.data();
//         if (videoList && videoList.mediaInformation) {
//             videoList.mediaInformation.forEach((element) => {
//                 if (
//                     element.authorId === userId ||
//                     element.proList?.map((obj) => obj && Object.keys(obj)[0]).includes(id)
//                 ) {
//                     list.push({ ...element, id: videoList.communityId });
//                 }
//             });
//         }
//     }
//     return list;
// };

export const addStatTimeUseApp = async (stat: StatTimeUseApp): Promise<void> => {
    // const content = data;
    await addDoc(collection(db, 'statTimeUseApp'), {
        userId: stat.userId,
        startTime: stat.startTime,
        timeUp: stat.timeUp
    });
};

export const getStatTimeUseAppByUser = async (userId: string): Promise<StatTimeUseApp[]> => {
    // const content = data;
    const list: StatTimeUseApp[] = [];
    const q = query(collection(db, 'statTimeUseApp'), where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        list.push(data);
    });
    // order list by startTime
    list.sort((a, b) => {
        if (!a.startTime || !b.startTime) return 0;
        return a.startTime.toMillis() - b.startTime.toMillis();
    });
    return list;
};

// for all videoPersonal in db load video and create thumbnail
export const loadAllVideoPersonal = async (): Promise<void> => {
    const q = query(collection(db, 'video'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((docSnap) => {
        const data = docSnap.data();
        // console.log('here ???',data.mediaInformation)
        // if (data.communityId === '4HKPAT' || true) {
        if (false) {
            data.mediaInformation?.forEach(
                async (
                    element: { videoUrl: string; thumbnail: string; authorId: string },
                    index: number
                ) => {
                    if (element.videoUrl && (element.thumbnail === '' || !element.thumbnail)) {
                        // console.log('on passe ici', element.thumbnail, index)
                        const lien = getImageKitThumbnailFromVideo(element.videoUrl, 1024, 1024);
                        console.log('lien', lien);
                        const image = await fetch(lien);
                        if (image.status === 200) {
                            const blob = await image.blob();
                            const file = new File([blob], 'image.png', {
                                type: 'image/png'
                            });
                            console.log('file', file);
                            const thisUser = await getUser(element.authorId);
                            // console.log('thisUser', thisUser);
                            if (thisUser) {
                                const storage = codeStorage(thisUser);
                                if (storage) {
                                    // console.log(storage);
                                    const link = await uploadImage(file, storage);
                                    // link = 'test';
                                    console.log(link);
                                    data.mediaInformation[index].thumbnail = link;
                                    // data.mediaInformation[index].authorId = data.mediaInformation[index].authorId === undefined ? '' : data.mediaInformation[index].authorId;
                                    // console.log(data);
                                    console.log('Save !!! ');
                                    await updateDoc(
                                        doc(
                                            db,
                                            'video',
                                            storage === 'admin'
                                                ? 'zyHCgiK1YGXSNwe6T0OJksojSzg1'
                                                : storage
                                        ),
                                        {
                                            mediaInformation: data.mediaInformation
                                        }
                                    );
                                }
                            }
                        }
                    }
                }
            );
        }
    });
};

export const getAllUser = async (): Promise<Patient[]> => {
    const userToExclude = [
        '',
        'guigonraphael@gmail.com',
        'hello+amelie.poulain@stimulin.fr',
        'laura.ferron2@gmail.com',
        'hello+madeleine.dupont@stimulin.fr',
        'hello+jacques.martin@stimulin.fr',
        'hello+caroline.robin@stimulin.fr',
        'hello+testamel@stimulin.fr',
        'raphael+test@stimulin.fr',
        'raphael+test@stimulin.fr',
        'raphael+test@stimulin.fr',
        'hello+christina.dupont@stimulin.fr',
        'hello+paula.dupont@stimulin.fr',
        'hello+denis.martin@stimulin.fr',
        'hello+iris.soigne@stimulin.fr',
        'admin@stimulin.fr',
        'raphael@stimulin.fr'
    ];
    const list: Patient[] = [];
    const reff = collection(db, 'users').withConverter(PatientConverter);
    const q = query(
        reff,
        // where('role', '==', 'PATIENT'),
        where('status', '==', 'ACTIVE')
    );
    const docSnap = await getDocs(q);
    if (docSnap.docs.length > 0) {
        docSnap.docs.forEach((item) => {
            const data = item.data();
            if (data.emailId && !userToExclude.includes(data.emailId)) {
                list.push(data);
            }
        });
        return list;
    }
    return list;
};
