import React, { useContext, useEffect } from 'react';
import { t } from 'i18next';
import { ConnectionStatus, Network } from '@capacitor/network';
import { useHistory } from 'react-router';
import { env } from '../../../env';
import auth0 from 'auth0-js';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import './login.css';
import { LoginForm } from './LoginForm';
import { SingUpForm } from './SingUpForm';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { InfoModal } from './InfoModal';
import { checkEmail } from '../../stores/User';
import { validateEmail } from '../../utilities/utils';

export function Login(): JSX.Element {
    const history = useHistory();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const [displayPage, setDisplayPage] = React.useState<'login' | 'singup' | 'forgot'>('login');
    const [displayInfoModal, setDisplayInfoModal] = React.useState(false);
    const [msgError, setMsgError] = React.useState<string | null>();
    const [msgForgotPassword, setMsgForgotPassword] = React.useState<string | null>();

    useEffect(() => {
        if (msgForgotPassword !== '' && msgForgotPassword !== null) {
            setTimeout(() => {
                setMsgForgotPassword('')
            }, 8000);
        }
    }, [msgForgotPassword]);

    const Authentication = new auth0.Authentication({
        domain: env.AUTH0_DOMAIN,
        clientID: env.AUTH0_CLIENT_ID,
        responseType: 'code'
    });
    const webAuth = new auth0.WebAuth({
        domain: env.AUTH0_DOMAIN,
        clientID: env.AUTH0_CLIENT_ID,
        responseType: 'token'
    });

    const networkCheck = (status: ConnectionStatus) => {
        if (!status.connected) {
            setMsgError(t('Aucun accès à internet détecté. Veuillez vérifier votre connexion.'));
            return false;
        }
        setMsgError('');
        return true;
    }

    useEffect(() => {
        Network.getStatus().then((status) => {
            networkCheck(status);
        });
        Network.addListener('networkStatusChange', (status) => {
            networkCheck(status);
        });
    }, []);

    const login = (email: string, pass: string) => {
        setMsgError('');
        if (!email || !pass) {
            setMsgError(t('login.error.allFields'));
            return;
        }
        Authentication.login(
            {
                realm: 'Username-Password-Authentication',
                username: email,
                password: pass,
                scope: 'openid profile email',
                audience: env.AUTH0_AUDIENCE
            },
            function (err, authResult) {
                if (err) {
                    setMsgError(t('login.error.wrongPasswordOrEmail'));
                    // checkEmail(email).then((res) => {
                    //     if (res) {
                    //     } else {
                    //         setMsgError('Erreur email incorrect');
                    //     }
                    // });
                } else {
                    // console.log('Refresh code:', authResult);
                    localStorage.setItem('access_token', authResult.accessToken);
                    userDataProp?.updateUser?.({
                        token: authResult.accessToken,
                        user: null
                    });
                    history.push('/home');
                }
            }
        );
    };
    const register = async (email: string, pass: string, codeBe: string, cgu: boolean) => {
        setMsgError('');
        // check if all fields are filled
        if (!email || !pass || !codeBe) {
            setMsgError(t('login.error.allFields'));
            return;
        }
        // check if email is valid
        if (!validateEmail(email)) {
            setMsgError(t('login.error.email'));
            return;
        }
        // check if cgu is checked
        if (!cgu) {
            setMsgError(t('login.error.cgu'));
            return;
        }
        // check if codebe is valid
        if (codeBe.length !== 6) {
            setMsgError(t('login.error.codebe'));
            return;
        }
        // check if password is valid
        if (pass.length < 6) {
            setMsgError(t('login.error.password'));
            return;
        }

        // check if codebe is in db
        var data = await fetch(
            env.API_URL + "/validate-codebe?codebe=" + codeBe
        )
            .then((res) => res.json())
            .then((data) => {
                if (!data) {
                    setMsgError(t('login.error.codebe'));
                    return false;
                } else {
                    return true;
                }
            });
        if (!data) return;

        webAuth.signup(
            {
                connection: 'Username-Password-Authentication',
                email: email,
                password: pass,
                userMetadata: { codebe: codeBe },
                scope: 'openid profile email',
                // audience: env.AUTH0_AUDIENCE
            },
            function (err, authResult) {
                if (err) {
                    setMsgError(t('login.error.emailAlreadyExist'));
                } else {
                    login(email, pass);
                }
            }
        );
    };

    const forgotPassword = (email: string) => {
        setMsgError('');
        if (!email) {
            setMsgError(t('login.error.emailEmpty'));
            return;
        }
        if (!validateEmail(email)) {
            setMsgError(t('login.error.email'));
            return;
        }
        webAuth.changePassword(
            {
                connection: 'Username-Password-Authentication',
                email: email
            },
            function (err, authResult) {
                // if (err) {
                //     setMsgError(t('login.error.emailNotFound'));
                // } else {
                //     setMsgForgotPassword(t('login.forgotPassword.email'));
                // }
            }
        );
    };

    return (
        <>
            <div className="login-container">
                <div className="login-box">
                    <div className="login-header">
                        <svg
                            height="5rem"
                            viewBox="0 0 20225 22971"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M16840.9 481.134C16929.8 290.466 17201 290.466 17289.9 481.134L18032.6 2072.44C18057.2 2125.18 18099.6 2167.58 18152.4 2192.19L19743.7 2934.86C19934.3 3023.84 19934.3 3294.96 19743.7 3383.95L18152.4 4126.61C18099.6 4151.23 18057.2 4193.62 18032.6 4246.36L17289.9 5837.67C17201 6028.34 16929.8 6028.34 16840.9 5837.67L16098.2 4246.36C16073.6 4193.62 16031.2 4151.23 15978.4 4126.61L14387.1 3383.95C14196.5 3294.96 14196.5 3023.84 14387.1 2934.86L15978.4 2192.19C16031.2 2167.58 16073.6 2125.18 16098.2 2072.44L16840.9 481.134Z"
                                fill="#E5007B"
                            />
                            <path
                                d="M14610 17197.2C14610 18824.2 13997 20139.2 12771.2 21142.2C11545.3 22122.9 9907.13 22613.2 7856.61 22613.2C6831.35 22613.2 5806.09 22490.7 4780.83 22245.5C3777.86 22000.3 2964.34 21677.1 2340.27 21275.9V17698.7C2630.02 17966.1 2975.48 18211.3 3376.67 18434.2C3800.15 18634.8 4279.35 18824.2 4814.27 19002.5C5260.03 19136.3 5716.94 19247.7 6184.99 19336.9C6653.05 19403.7 7132.25 19437.2 7622.59 19437.2C8670.14 19437.2 9483.66 19281.1 10063.2 18969.1C10642.6 18634.8 10932.4 18166.7 10932.4 17564.9C10932.4 17074.6 10720.7 16628.8 10297.2 16227.6C9873.7 15826.5 8948.74 15325 7522.29 14723.2C6452.45 14255.1 5661.22 13876.2 5148.59 13586.5C4658.25 13296.7 4234.77 12995.8 3878.16 12683.8C3298.66 12193.5 2864.04 11647.4 2574.29 11045.6C2306.84 10421.6 2173.11 9730.62 2173.11 8972.82C2173.11 7345.77 2763.75 6053.05 3945.02 5094.66C5148.59 4136.26 6786.78 3657.07 8859.59 3657.07C9840.27 3657.07 10787.5 3779.65 11701.3 4024.82C12615.2 4247.71 13361.8 4537.45 13941.3 4894.06V8237.3C13138.9 7791.54 12280.8 7446.07 11367 7200.9C10475.5 6955.73 9606.24 6833.14 8759.29 6833.14C7823.18 6833.14 7098.81 7000.31 6586.18 7334.63C6095.84 7668.95 5850.67 8125.86 5850.67 8705.36C5850.67 9195.7 6062.41 9641.47 6485.89 10042.7C6909.36 10421.6 7834.33 10911.9 9260.77 11513.7C10330.6 11959.4 11110.7 12327.2 11601 12616.9C12113.7 12906.7 12548.3 13207.6 12904.9 13519.6C13484.4 14010 13907.9 14556 14175.3 15157.8C14465.1 15759.6 14610 16439.4 14610 17197.2Z"
                                fill="#0F0046"
                            />
                        </svg>
                    </div>
                    {msgError && <div id="error-message">{msgError}</div>}
                    {msgForgotPassword && <div id="forgot-message">{msgForgotPassword}</div>}
                    {displayPage === 'login' && <LoginForm login={login} setDisplayPage={setDisplayPage} />}
                    {displayPage === 'singup' && <SingUpForm register={register} setDisplayPage={setDisplayPage} setDisplayInfoModal={setDisplayInfoModal} />}
                    {displayPage === 'forgot' && <ForgotPasswordForm setDisplayPage={setDisplayPage} forgotPassword={forgotPassword} />}
                </div>
            </div>
            {displayInfoModal && <InfoModal setDisplayInfoModal={setDisplayInfoModal} />}

            {/* <button
                onClick={() => request()}>
                TEST
            </button>
            {displayLoginButton && (
                <Button
                    pink
                    sx={{
                        position: 'relative',
                        width: '85%',
                        maxWidth: '300px',
                        textAlign: 'center',
                        alignSelf: 'center',
                        marginTop: 'auto',
                        marginBottom: 'auto'
                    }}
                    onClick={login}>
                    {t('connect')}
                </Button>
            )} */}
        </>
    );
}
