import { useEffect } from 'react';
import GamesComponent from '../components/GamesComponent';
import { MenuProvider } from '../providers/MenuProvider';
import { ScreenOrientation } from '@capacitor/screen-orientation';

const Games = () => {

  useEffect(() => {
    try {
        ScreenOrientation.unlock();
    } catch (error) {
        console.error(error);
    }
}, []);

  return (
    <MenuProvider>
      <GamesComponent />
    </MenuProvider>
  );
};

export default Games;