import React from 'react';

import Icon from './clock.svg?react';

import styles from './styles.module.css';

interface ClockProps {
    time: number;
    className?: string;
    color?: string;
}

const Clock: React.FC<ClockProps> = ({ time, className, color }) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedTime = `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;

    return (
        <div className={[styles.root, className].join(' ')} style={{
            '--color': color,
        } as React.CSSProperties}>
            <Icon className={styles.icon} />
            <div className={styles.text}>{formattedTime}</div>
        </div>
    );
};

export default Clock;