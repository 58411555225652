import React, { CSSProperties, useState, useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { logOut } from '../services/firebaseAuth';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { User } from '../models/User';
import { Typography } from './Typography';
import { useHistory } from 'react-router';
import greenCheck from '../assets/greenCheck.png';
import { Share } from '@capacitor/share';
import { Roles } from '../models/Roles';
// import { getPatientByFamilyCode, updateUserNeedDelete } from '../services/cloudFirestore';
import { UserDataProp } from '../models/userDataProp';
import { UserContext, propData } from '../providers/UserProvider';
import { deleteUser, getBeneficiaryMembers } from '../stores/User';
import { PopupModal } from './PopupModal';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: 'fit-content',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'profile-image': {
        alignSelf: 'center',
        marginBottom: '1rem'
    },
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '95%',
        textAlign: 'center',
        alignSelf: 'center'
    }
};

interface Props {
    step: number;
    setStep: (step: number) => void;
}

export function DeleteAccount({ step, setStep }: Props): JSX.Element {
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = useMemo(() => userDataProp?.user, [userDataProp]);
    const history = useHistory();
    const [openModal, setOpenModal] = useState(false);
    const { height, width } = useWindowDimensions();
    const isPortrait = useMemo(() => {
        if (height && width) {
            return height > width;
        }
        return false;
    }, [height, width]);

    return user?.needDelete ? (
        <div
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%',
                padding: width && width < 768 ?'1rem 8px' : '1rem 32px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                
                // alignItems: 'center',
                // gap: '2rem',
                // overflowY: 'auto',
                // padding: '1rem 1rem'
            }}>
            <Typography
                variant="h3"
                sx={{
                    color: '#E5007B',
                    fontSize: width && width < 768 ? '1.2rem' : '1.5rem',
                    fontWeight: '700',
                    textAlign: 'center'
                }}>
                {user?.isSubscribed
                    ? t('modalUnsubscribe.validation.title')
                    : t('modalDelete.validation.title')}
            </Typography>
            <img src={greenCheck} alt="green check" style={{ height: '5rem' }} />
            <Typography
                variant="p"
                sx={{
                    color: '#002F55',
                    fontSize: width && width < 768 ? '1rem' : '1.27rem',
                    fontWeight: '500',
                    textAlign: 'center'
                }}>
                {t('modalDelete.validation.desc1')}
                <br />
                {t('modalDelete.validation.desc2')}
                <br />
                <br />
                {t('modalDelete.validation.desc3')}
                <a href="mailto:hello@stimulin.fr">hello@stimulin.fr</a>
                {'. '}
                {t('modalDelete.validation.desc4')}
                <br />
                <br />
                {t('modalDelete.validation.desc5')}
            </Typography>
            <Button
                pink
                onClick={() => {
                    history.goBack();
                }}>
                {t('modalUnsubscribe.validation.button')}
            </Button>
        </div>
    ) : (
        <div
            style={{
                ...classes['root'],
                width: width && width < 768 ? '95%' : '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
            }}>
            <>
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: t('deleteAccountMessage')
                    }}
                    children={undefined}
                    variant="p"
                    sx={{
                        marginTop: '0px',
                        marginBottom: '1rem',
                        textAlign: 'left',
                        alignSelf: 'center',
                        color: '#333',
                        fontSize: '0.875',
                        fontWeight: '400'
                    }} />
                <Button
                    pink
                    disabled={user?.needDelete}
                    onClick={async () => {
                        setOpenModal(true);
                    }}>
                    {!user?.needDelete && t('DeleteAccount')}
                    {user?.needDelete && t('AccountDeleted')}
                </Button>
            </>
            <PopupModal
                closeModal={() => setOpenModal(false)}
                isOpen={openModal}
                children={
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: '#E5007B',
                                textAlign: 'center',
                                fontSize: '0.875rem',
                                fontWeight: '700',
                                lineHeight: '1.25rem',
                                padding: '0 0.5rem 1.5rem 0.5rem'
                            }}>
                            {t('modalDeleteAccount')}
                        </Typography>

                        <Button
                            pink
                            onClick={async () => {
                                if (user?.userId) {
                                    await deleteUser(userDataProp.token ?? '')
                                        .then(() => {
                                            userDataProp.updateUser && userDataProp.updateUser({
                                                ...userDataProp,
                                                user: { ...user, needDelete: true }
                                            });
                                            setOpenModal(false);
                                            // setOpenValidationModal(true);
                                        })
                                        .catch((error) => { });
                                }
                            }}
                            sx={{
                                marginTop: '1.5rem'
                            }}>
                            {t('modalUnsubscribe.validate')}
                        </Button>
                        <Button
                            onClick={() => setOpenModal(false)}
                            sx={{
                                marginTop: '1.5rem',
                                cursor: 'pointer'
                            }}>
                            {t('modalUnsubscribe.cancel')}
                        </Button>
                    </>
                }
                sxRoot={{
                    top: '0',
                    left: isPortrait ? '0px' : '55%',
                    width: 'auto',
                    height: '100%',
                    padding: '1rem 32px'
                }}
                width="90%"
                height="auto"
            />
        </div >)
}

