/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable curly */
/* eslint-disable @typescript-eslint/naming-convention */
import React, { Children, FC, useCallback, useEffect } from 'react';
// import useMousetrap from 'react-hook-mousetrap';

import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import { createGrid, fillBlock, IReducer, selectBlock } from '../../store';
import { BLOCK_COORDS, GRID, INDEX, N, NUMBERS } from '../../typings';

import Block from './block';
import { Container, Row } from './styles';

interface IState {
    selectedBlock?: BLOCK_COORDS;
    selectedValue: N;
    solvedGrid?: GRID;
}

const Grid: FC<{ gridSize: number }> = ({ gridSize }) => {
    const state = useSelector<IReducer, IState>(({ selectedBlock, solvedGrid, workingGrid }) => ({
        selectedBlock,
        selectedValue:
            workingGrid && selectedBlock ? workingGrid?.[selectedBlock?.[0]]?.[selectedBlock?.[1]] : 0,
        solvedGrid
    }), (prev, next) => prev.selectedBlock === next.selectedBlock && prev.selectedValue === next.selectedValue
    );
    const dispatch = useDispatch<Dispatch<AnyAction>>();

    // UseCallback dispatches the createGrid
    const create = useCallback(() => dispatch(createGrid(gridSize)), [dispatch]);

    useEffect(() => {
        if (!state.solvedGrid) create();
    }, [create, state.solvedGrid]);

    return (
        <Container>
            {Children.toArray(
                [...Array(gridSize)].map((_, rowIndex) => (
                    <Row gridSize={gridSize}>
                        {Children.toArray(
                            [...Array(gridSize)].map((_, colIndex) => (
                                <Block colIndex={colIndex as INDEX} rowIndex={rowIndex as INDEX} />
                            ))
                        )}
                    </Row>
                ))
            )}
        </Container>
    );
};

// eslint-disable-next-line import/no-default-export
export default Grid;
