import React, { CSSProperties, useEffect, useState } from 'react';
import { signUp } from '../../services/firebaseAuth';
import { useHistory, useParams } from 'react-router-dom';
import { Typography } from '../../components/Typography';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import openedEye from '../../assets/openedEye.svg';
import line from '../../assets/line.png';
import closeIcon from '../../assets/closeIcon.png';
import desktopImg from '../../assets/desktopImg.png';
import desktop from '../../assets/desktop.svg';
import closedEye from '../../assets/closedEye.svg';
import person from '../../assets/person.svg';
import group from '../../assets/group.svg';
import { CheckBox } from '../../components/CheckBox';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { SeniorInfo } from '../../components/SeniorInfo';
import { Patient } from '../../models/Patient';
import { validateEmail } from '../../utilities/utils';
import { getUserByEmailId } from '../../services/cloudFirestore';

const classes: { [key: string]: CSSProperties } = {
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        height: '100%',
        overflowY: 'auto',
        margin: '1rem 0.5rem'
    },
    h1: {
        fontWeight: '700',
        paddingTop: '2rem',
        paddingBottom: '1rem',
        textAlign: 'left',
        width: '100%'
    },
    input: {
        paddingBottom: '0.5rem',
        width: '100%'
    },
    'connexion-button': {
        width: '100%',
        marginTop: '1.5rem',
        fontWeight: '600'
    },
    'existing-account': {
        paddingTop: '1rem',
        width: '100%',
        textAlign: 'center'
    },
    'policies-root': {
        width: 'auto',
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'row',
        padding: '0.5rem',
        alignItems: 'center'
    },
    policies: {
        width: 'auto',
        textAlign: 'start'
    },
    'policies-checkBox': {
        cursor: 'pointer',
        alignSelf: 'center',
        marginRight: '0.5rem'
    },
    'register-description': {
        paddingBottom: '1.5rem',
        paddingLeft: '0.8rem',
        fontSize: '1rem',
        fontWeight: '400',
        width: '100%',
        textAlign: 'left',
        color: '#999999'
    }
};

export function Register(): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const { code } = useParams<{ code: string }>();
    const [checkSenior, setCheckSenior] = useState(false);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const { height, width } = useWindowDimensions();
    const [checked, setCheckedValue] = useState<boolean>(false);
    const [values, setValues] = useState({
        familyCode: code ? code : '',
        email: '',
        password: '',
        showPassword: false,
        prop: ''
    });

    useEffect(() => {
        setValues({
            ...values,
            familyCode: code ? code : ''
        });
    }, [code]);

    const onRegister = (patient: Patient): void => {
        setError(false);
        if (
            values.email &&
            values.password &&
            values.familyCode &&
            patient &&
            patient.startDate &&
            patient.endDate &&
            patient.establishmentCode &&
            patient.establishmentName &&
            patient.id
        ) {
            signUp(
                values.email,
                values.password,
                values.familyCode,
                patient.endDate,
                patient.startDate,
                patient.establishmentCode,
                patient.establishmentName,
                patient.id
            )
                .then(() => {
                    history.replace('/profile');
                })
                .catch((err): void => {
                    setError(true);
                    setErrorText(t('SignUpError.email') ?? '');
                    setCheckSenior(false);
                });
        }
    };

    const onError = (code: string): void => {
        if (code === 'PATIENT_NOT_FOUND') {
            setErrorText(t('SignUpErrorWrongCode') ?? '');
            setError(true);
        } else if (code === 'WRONG_CODE') {
            setError(false);
            setCheckedValue(false);
            setValues({
                ...values,
                showPassword: false,
                email: '',
                password: '',
                familyCode: ''
            });
        }

        setCheckSenior(false);
    };

    useEffect(() => {
        setError(false);
    }, [values.email, values.password, values.familyCode]);

    const getSignup = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'right',
                    // position: 'absolute',
                    // top: '0',
                    // left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: '#fff'
                    // zIndex: 1000
                }}>
                <div
                    style={{
                        width: width && height && width > height ? '50%' : '0',
                        height: '100%',
                        backgroundImage: `url(${desktop})`,
                        backgroundSize: 'cover',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        // position: 'fixed',
                        top: '0',
                        left: '0'
                    }}>
                    <img
                        src={desktopImg}
                        alt="desktop"
                        style={{ width: '90%', height: 'auto', backgroundColor: '#F0F6FD' }}
                    />
                </div>
                <div
                    style={{
                        ...classes['root'],
                        width: width && height && width > height ? '50%' : '100%',
                        height: '100%',
                        ...{
                            padding:
                                width && width < 800
                                    ? '2rem'
                                    : width && width < 1000
                                    ? '2rem 4rem'
                                    : '2rem 6rem'
                        }
                    }}>
                    <img
                        src={closeIcon}
                        alt="close"
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                            height: '2rem',
                            width: 'auto',
                            cursor: 'pointer'
                        }}
                        onClick={(): void => {
                            history.push('/login/me');
                        }}
                    />
                    <Typography variant="h1" sx={{ ...classes['h1'] }}>
                        {t('RegisterTitle')}
                    </Typography>
                    <Typography sx={{ ...classes['register-description'] }}>
                        {t('GotFamilyCode')}
                    </Typography>
                    <form
                        style={{ width: '100%' }}
                        onSubmit={(): void => setCheckSenior(true)}
                        onKeyDown={(event): void => {
                            if (event.key === 'Enter') {
                                event.preventDefault();
                                event.stopPropagation();
                                setCheckSenior(true);
                            }
                        }}>
                        <Input
                            sxRoot={{ ...classes['input'] }}
                            label={t('EnterFamilyCode') ?? ''}
                            placeholder={'ex: 3UdS5d'}
                            value={values.familyCode}
                            onChange={(value: string) => {
                                setValues({
                                    ...values,
                                    familyCode: value?.trim() ?? ''
                                });
                            }}
                            imgIntoInput={group}></Input>
                        <Input
                            sxRoot={{ ...classes['input'] }}
                            label={t('EnterEmailRegister') ?? ''}
                            placeholder={t('userExample') ?? ''}
                            value={values.email}
                            onChange={(value: string) => {
                                setValues({
                                    ...values,
                                    email: value?.trim() ?? ''
                                });
                            }}
                            imgIntoInput={person}></Input>
                        <Input
                            sxRoot={{ ...classes['input'] }}
                            label={t('enterPasswordRegister') ?? ''}
                            placeholder={t('passwordFiller') ?? ''}
                            type={values.showPassword ? '' : 'password'}
                            value={values.password}
                            onChange={(value: string) => {
                                setValues({
                                    ...values,
                                    password: value?.trim() ?? ''
                                });
                            }}
                            imgIntoInput={values.showPassword ? openedEye : closedEye}
                            btnClick={(): void => {
                                setValues({
                                    ...values,
                                    showPassword: !values.showPassword
                                });
                            }}></Input>
                        <div style={{ ...classes['policies-root'] }}>
                            <CheckBox
                                sx={{ ...classes['policies-checkBox'] }}
                                checked={checked}
                                setChecked={(): void => {
                                    setCheckedValue(!checked);
                                }}
                            />
                            <Typography sx={{ ...classes['policies'] }}>
                                <span>{t('ByLoginIn')}</span>
                                <span style={{ textDecoration: 'underline' }}>
                                    <a
                                        href="https://appv1.stimulin.fr/cgu.html"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="secondary">
                                        {t('generalConditions')}
                                    </a>
                                </span>
                                <span>{t('and')}</span>
                                <span style={{ textDecoration: 'underline' }}>
                                    <a
                                        href="https://appv1.stimulin.fr/politique-de-confidentialite.html"
                                        target="_blank"
                                        rel="noreferrer"
                                        color="secondary">
                                        {t('privacyPolicy')}
                                    </a>
                                </span>
                                <span>{t("Stimul'inApp")}</span>
                            </Typography>
                        </div>
                    </form>

                    {error ? (
                        <Typography variant="p" sx={{ color: '#FF4842' }}>
                            {errorText}
                        </Typography>
                    ) : null}
                    <Button
                        pink
                        // outlinedDisable={true}
                        disabled={
                            !checked ||
                            values.familyCode === '' ||
                            values.email === '' ||
                            values.password === ''
                        }
                        sx={{ ...classes['connexion-button'] }}
                        onClick={async (): Promise<void> => {
                            if ( !validateEmail(values.email) ) {
                                setError(true);
                                setErrorText(t('SignUpError.notEmail') ?? '');
                                return;
                            } else if ( values.password.length < 6 ) {
                                setError(true);
                                setErrorText(t('SignUpError.password') ?? '');
                                return;
                            }
                            const tempUser = await getUserByEmailId(values.email);
                            if (tempUser !== null) {
                                setError(true);
                                setErrorText(t('SignUpError.email') ?? '');
                                return;
                            } else {
                                setCheckSenior(true);
                            }
                        }}>
                        {t('registerActivate')}
                    </Button>
                    {/* <Typography sx={{ ...classes['existing-account'] }}>
                    <span>{t('AlreadyRegistered') + ' '}</span>
                    <span
                        onClick={(): void => {
                            history.push('/login');
                        }}
                        style={{
                            cursor: 'pointer',
                            color: '#3880ff',
                            textDecoration: 'underline'
                        }}>
                        {t('connect')}
                    </span>
                </Typography> */}
                    <Typography
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                            fontSize: '0.8125rem',
                            fontWeight: '600',
                            color: '#6C6C6C',
                            marginTop: '1.5rem'
                        }}
                        variant="p">
                        <img src={line} alt="line" />
                        <span>{t('haveAccount')}</span>
                        <img src={line} alt="line" />
                    </Typography>
                    <Button
                        outline
                        sx={{ ...classes['connexion-button'] }}
                        onClick={(): void => {
                            history.push('/login/me');
                        }}>
                        {t('connect')}
                    </Button>
                </div>
            </div>
        );
    };

    return (
        <>
            {checkSenior ? (
                <SeniorInfo code={values.familyCode!} onSuccess={onRegister} onError={onError} />
            ) : (
                getSignup()
            )}
        </>
    );
}
