import { withTranslation, WithTranslation } from "react-i18next";

import style from "./style.module.css";
import { Header } from "../Header";
import GameHeader from "../GameHeader";
import EinsteinSpeaking from "../EinsteinSpeaking";
import NewsGrid from "../NewsGrid";
import NavBar from "../NavBar";
import { Game } from "../../models/Game";
import HomeHeader from "../HomeHeader";
import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import { MediaItem } from "../../models/Media";

interface NewsComponentProps extends WithTranslation {
    data: (Game | MediaItem)[];
    onDisplay: (id: string) => void;
};

const NewsComponent = ({ t, data, onDisplay }: NewsComponentProps) => {
    const userDataProp = useContext(UserContext);

    return (
        <div className={style.root}>
            <HomeHeader />
            <EinsteinSpeaking icon='news'>
                <span>{t('{{firstName}}, vous avez reçu', { firstName: userDataProp?.user?.firstName })}</span>
                <span style={{ color: '#E5007B' }}>{t(' 8 nouveautés')}</span>
            </EinsteinSpeaking>
            <NewsGrid data={data} onDisplay={(id) => onDisplay(id)} />
            <NavBar />
        </div>
    );
};

export default withTranslation()(NewsComponent);