import {
    CSSProperties,
    useEffect,
    useRef,
    useState,
    useContext,
    useCallback,
} from 'react';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { Typography } from '../../components/Typography';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import Cropper from 'react-easy-crop';
import { getCroppedImg } from '../../utilities/cropImage';
import { config } from '../../../config';
import {
    codeStorage,
    getCommunityId,
    getImageKitUrlFrom,
    getRole,
    youtube_parser
} from '../../utilities/utils';
import { Button } from '../../components/Button';
import { Slider } from '../../components/Slider';
import zoomIn from '../../assets/zoom_in_24px.svg';
import zoomOut from '../../assets/zoom_out_24px.svg';
import ringLoader from '../../assets/rings.svg';
import search from '../../assets/search.svg';
import { forEach } from 'lodash';
import { ItemList } from '../../components/ItemList';
import { Input } from '../../components/Input';
import { ExerciseItem } from '../../models/ExerciseItem';
import { Roles } from '../../models/Roles';
import { uploadImage, uploadImageV2 } from '../../services/storageFunctions';
import {
    addPersonalVideo,
    addPersonalYoutubeVideo,
    deletePersonalVideo,
    deletePersonalYoutubeVideo,
    getEstablishmentList,
    getExerciseItem,
    getFamilyFromCode,
    getPatientFromEstablishment,
    getPersonalVideo,
    getPersonalYoutubeVideo,
    getProFromEstablishment,
    getUser,
    saveExerciseItem
} from '../../services/cloudFirestore';
import { useHistory } from 'react-router';
import { UsersVideoData } from '../../models/UsersVideoData';
import { ImageIdentificationData } from '../../models/ImageIdentificationData';
import { Select } from '../../components/Select';
import { dropdownListFamily } from '../../models/Family';
import { TextField } from '../../components/TextField';
import { Timestamp } from 'firebase/firestore';
import { DisplayUser } from '../../components/DisplayUser';
import { User } from '../../models/User';
import { Patient } from '../../models/Patient';
import { ShareRulesModal } from '../../components/ShareRulesModal';
import { Loader } from '../../components/Loader';
import { DisplayEts } from '../../components/DisplayEts';
import { Establishment } from '../../models/Establishment';
import { getVideoFromId, getVideoListFromTitle, VideoFromYt } from '../../stores/youtube';
import { VigentteFromYoutube } from '../../components/VigentteFromYoutube';
import React from 'react';
import { createMedia } from '../../stores/Media';

const classes: { [key: string]: CSSProperties } = {
    'root-step': {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem'
    },
    'cropper-container': {
        position: 'relative',
        width: 'calc(100% - 2rem)',
        // marginTop: '1rem',
        height: '50%',
        overflow: 'hidden',
        maxHeight: '22rem',
        maxWidth: '22rem'
    },
    'btn-slider': {
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        backgroundColor: 'transparent',
        border: 'none',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem'
    },
    'slider-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50vh',
        maxWidth: '100%',
        padding: '0 1rem'
    },
    slider: {
        margin: '0 1rem'
    },
    'page-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: 'fit-content',
        width: '100%',
        gap: '0.5rem'
    },
    page: {
        height: '1rem',
        width: '1rem',
        borderRadius: '50%',
        backgroundColor: '#E7E7E7'
    },
    'btn-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: 'fit-content',
        width: '100%',
        gap: '1rem',
        padding: '0 1rem',
        position: 'absolute',
        bottom: '1rem'
    },
    'btn-prev': {
        backgroundColor: 'var(--main-grey-color)',
        color: 'var(--main-text-color)'
    },
    'loader-crop': {
        position: 'absolute',
        top: '0',
        backgroundColor: '#00000050',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    'step-desc': {
        color: '#33333380',
        padding: '1rem',
        fontSize: '1rem',
        fontWeight: '500'
    },
    'step-desc-yt': {
        color: '#33333380',
        fontSize: '0.8rem',
        textAlign: 'center'
    },
    hr: {
        width: '100%',
        height: '1px',
        backgroundColor: '#EFEFEF',
        border: 'none'
    },
    'lst-img-container': {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexDirection: 'row',
        height: 'fit-content',
        width: '100%',
        gap: '0.5rem',
        paddingLeft: '1rem',
        paddingRight: '0.5rem',
        flexWrap: 'wrap',
        position: 'relative'
    },
    'lst-img-item': {
        width: 'calc(100% / 3 - 0.5rem)',
        height: 'auto',
        borderRadius: '0.5rem',
        backgroundColor: '#E7E7E7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative'
    },
    'item-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: 'fit-content',
        width: '100%',
        padding: '0 1rem'
    },
    'img-title-list': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        height: 'fit-content',
        width: '100%',
        gap: '0.5rem'
    },
    'img-element': {
        height: '4rem',
        width: 'auto',
        borderRadius: '0.25rem'
    }
};

interface ImportMediaProps {
    type: 'picture' | 'personal-video' | 'youtube-video';
    step: number;
    setStep: (step: number) => void;
    setStepMax: (stepMax: number) => void;
    // gameAsCreated: (newGame: Exercise) => void;
    setTitle: (title: string) => void;
    mediaToEdit?:
    | { id: string; videoUrl: string | undefined; thumbnail: string | undefined }
    | undefined;
    setMediaToEdit?: (
        value:
            | { id: string; videoUrl: string | undefined; thumbnail: string | undefined }
            | undefined
    ) => void;
    close: () => void;
    updateListPicture: () => void;
}

export function ImportMedia({
    type,
    step,
    setStep,
    setStepMax,
    setTitle,
    mediaToEdit,
    setMediaToEdit,
    close,
    updateListPicture
}: ImportMediaProps): JSX.Element {
    const history = useHistory();
    const { t } = useTranslation();
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const user = userDataProp?.user;
    const { height, width } = useWindowDimensions();
    const inputFile = useRef<HTMLInputElement>(null);
    const inputFileVideo = useRef<HTMLInputElement>(null);
    const [media, setMedia] = useState<File[]>([]);
    const [video, setVideo] = useState<File | string>();
    // const video = useMemo(() => {
    //     console.log(inputFileVideo.current?.files?.[0]);
    //     return tempVideo;
    // }, [tempVideo]);
    // const [crop, setCrop] = useState({ x: 0, y: 0 });
    // const [zoom, setZoom] = useState(1);
    const [indexImport, setIndexImport] = useState(0);
    const [loaderCrop, setLoaderCrop] = useState(true);
    const [step2Page, setStep2Page] = useState<
        null | 'addTitle' | 'addPeople' | 'addPlace' | 'addDesc' | 'addDate'
    >(null);
    const [searchYt, setSearchYt] = useState('');
    const [linkYt, setLinkYt] = useState('');
    const [titleYt, setTitleYt] = useState('');
    const [titleVideo, setTitleVideo] = useState('');
    const [disableBack, setDisableBack] = useState(false);
    const [seniorsList, setSeniorsList] = useState<Patient[]>([]);
    const [needLoader, setNeedLoader] = useState(false);
    const [seniorsListSelected, setSeniorsListSelected] = useState<string[]>([]);
    const [familyList, setFamilyList] = useState<User[]>([]);
    const [familyListSelected, setFamilyListSelected] = useState<string[]>([]);
    const [proList, setProList] = useState<User[]>([]);
    const [proListSelected, setProListSelected] = useState<{ [key: string]: 'read' | 'write' }[]>(
        []
    );
    const [etsList, setEtsList] = useState<Establishment[]>([]);
    const [etsListSelected, setEtsListSelected] = useState<string[]>([]);
    const [tumbnailVideoPersonal, setTumbnailVideoPersonal] = useState<File | string>();
    const [resultVideoYt, setResultVideoYt] = useState<VideoFromYt[]>([]);
    const [nextPageToken, setNextPageToken] = useState<string>();
    const [selectedVideoYt, setSelectedVideoYt] = useState<VideoFromYt | undefined>();
    const [startTimeYt, setStartTimeYt] = useState('000:00');
    const [endTimeYt, setEndTimeYt] = useState('000:00');

    const [mediaCropped, setMediaCropped] = useState<
        {
            id: string | null;
            file: string;
            croppedImage: { url: string; file: Blob };
            crop: { x: number; y: number };
            zoom: number;
            croppedAreaPixels: { width: number; height: number; x: number; y: number };
            title: string | null;
            people: string | null;
            imageDetails: ImageIdentificationData[];
            place: string | null;
            desc: string | null;
            date: Date | null;
            theme: string | null;
            createdDate: Timestamp | null;
        }[]
    >([]);
    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const triggerVideoUpload = (): void => {
        if (inputFileVideo && inputFileVideo.current) {
            inputFileVideo.current.click();
        }
    };
    const [right, setRight] = useState<'write' | 'read' | null>(null);

    useEffect(() => {
        const thisStepMax = type === 'picture' ? 6 : 4;
        // console.log('right', right, step, thisStepMax);
        if (right === 'read' && thisStepMax) {
            if (step === thisStepMax - 2) {
                close();
            }
            setStep(thisStepMax - 1);
        }
        // if (
        //     user?.role === Roles.PRO &&
        //     thisStepMax &&
        //     step === thisStepMax - 1 &&
        //     proListSelected.length === 0
        // ) {
        //     setStep(step - 1);
        // }
    }, [right, step]);

    useEffect(() => {
        if (user) {
            const getFamily = async (): Promise<void> => {
                const family = await getFamilyFromCode(user.familyCode ?? '');
                if (family) {
                    //  delete this user
                    const index = family.findIndex((item) => item.id === user.id);
                    if (index !== -1) {
                        family.splice(index, 1);
                    }
                    setFamilyList(family);
                }
            };
            getFamily();
        }
        if (user && user.role === Roles.PRO) {
            const getSenior = async (): Promise<void> => {
                const seniors = await getPatientFromEstablishment(user.establishmentCode ?? '');
                if (seniors) {
                    setSeniorsList(seniors);
                }
            };
            const getPro = async (): Promise<void> => {
                const pro = await getProFromEstablishment(user.establishmentCode ?? '');
                if (pro) {
                    //  delete this user
                    const index = pro.findIndex((item) => item.id === user.id || item.id === user.userId);
                    if (index !== -1) {
                        pro.splice(index, 1);
                    }
                    setProList(pro);
                }
            };
            getSenior();
            getPro();
        }
        if (user && user.role === Roles.ADMIN) {
            const getEts = async (): Promise<void> => {
                const ets = await getEstablishmentList();
                if (ets) {
                    setEtsList(ets);
                }
            };
            getEts();
        }
    }, [user]);

    const [mediaCreator, setMediaCreator] = useState<User | null>();

    useEffect(() => {
        if (
            user &&
            user.id &&
            user.role === Roles.PRO &&
            mediaCreator &&
            mediaCreator.id !== user.id
        ) {
            const pro = proList;
            const index = pro.findIndex((item) => item.id === mediaCreator.id);
            if (index !== -1) {
                pro.splice(index, 1);
            }
            setProList(pro);
        }
    }, [mediaCreator]);
    const [mediaPictureUrlToEdit, setMediaPictureUrlToEdit] = useState<string | undefined>();
    useEffect(() => {
        if (type === 'picture' && step === 0 && mediaToEdit && mediaToEdit.id !== '') {
            // get element from firebase
            getExerciseItem(mediaToEdit.id).then(async (res) => {
                if (res) {
                    if (res.exerciseImage && typeof res.exerciseImage === 'string' && res.id) {
                        // const date = res.photoCapturedOn ? new Date(getDateFromTimestamp(res.photoCapturedOn)) : null;
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        const temp = res.photoCapturedOn as unknown as Timestamp;
                        const date = res.photoCapturedOn ? new Date(temp.seconds * 1000) : null;
                        setMediaCropped([
                            {
                                id: res.id,
                                file: res.exerciseImage,
                                croppedImage: { url: '', file: new Blob() },
                                crop: { x: 0, y: 0 },
                                zoom: 1,
                                croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
                                title: res.answer ?? null,
                                people: res.patientList?.join(', ') ?? null,
                                imageDetails: res.imageDetails ?? null,
                                place: res.photoCapturedPlace ?? null,
                                desc: res.anecdote ?? null,
                                date: date ?? null, //here
                                theme: res.theme ?? null,
                                createdDate: res.createdDate ?? null
                            }
                        ]);
                        setMediaPictureUrlToEdit(res.exerciseImage);
                        if (user?.role === Roles.ADMIN && res.etsList) {
                            const tempEtsList: string[] = [];
                            res.etsList.forEach((item) => {
                                if (item) tempEtsList.push(item);
                            });
                            setEtsListSelected(tempEtsList);
                        }
                        if (res.patientList) {
                            if (user?.role === Roles.PRO) {
                                setSeniorsListSelected(
                                    (res.patientList.filter(
                                        (item) => item !== user?.id
                                    ) as string[]) ?? []
                                );
                                const tempProList: { [key: string]: 'read' | 'write' }[] = [];
                                res.proList &&
                                    res.proList.forEach((item) => {
                                        if (item)
                                            tempProList.push({
                                                [Object.keys(item)[0]]: Object.values(item)[0]
                                            });
                                    });
                                if (user && tempProList) {
                                    tempProList.forEach((pro) => {
                                        if (pro && Object.keys(pro)[0] === user.id) {
                                            setRight(Object.values(pro)[0]);
                                        }
                                    });
                                    await getUser(res.authorId ?? '').then((result) => {
                                        setMediaCreator(result);
                                    });
                                }
                                setProListSelected(tempProList);
                            } else if (user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) {
                                setFamilyListSelected(
                                    (res.patientList.filter(
                                        (item) => item !== user?.id
                                    ) as string[]) ?? []
                                );
                            }
                        }
                        setStep(step + 1);
                        setDisableBack(true);
                        if (setMediaToEdit) setMediaToEdit(undefined);
                    }
                }
            });
            // setMedia()
            // setStep(step + 1);
        }
        if (
            type === 'youtube-video' &&
            step === 0 &&
            mediaToEdit &&
            mediaToEdit.id &&
            mediaToEdit.id !== '' &&
            mediaToEdit.videoUrl &&
            mediaToEdit.videoUrl !== ''
        ) {
            // get element from firebase
            getPersonalYoutubeVideo(mediaToEdit.id).then((res) => {
                if (res) {
                    forEach(res, async (value) => {
                        if (value.videoUrl === mediaToEdit.videoUrl) {
                            if (value.videoUrl === mediaToEdit.videoUrl) {
                                const urlId = youtube_parser(mediaToEdit.videoUrl ?? '');
                                if (urlId && typeof urlId === 'string') {
                                    getVideoFromId(urlId).then((result) => {
                                        if (result !== 'error') {
                                            setSelectedVideoYt({
                                                ...result,
                                                title: value.title ?? result.title
                                            });
                                            setStep(1);
                                        }
                                    });
                                }
                                if (
                                    mediaToEdit.videoUrl &&
                                    mediaToEdit.videoUrl.includes('start=')
                                ) {
                                    const tempStart = mediaToEdit.videoUrl
                                        .split('start=')[1]
                                        .split('&')[0];
                                    const tempStartMin = Math.floor(Number(tempStart) / 60);
                                    const tempStartSec = Number(tempStart) % 60;
                                    setStartTimeYt(
                                        `${tempStartMin < 10 ? `00${tempStartMin}` : tempStartMin < 100 ? `0${tempStartMin}` : tempStartMin}:${tempStartSec < 10 ? `0${tempStartSec}` : tempStartSec
                                        }`
                                    );
                                }
                                if (mediaToEdit.videoUrl && mediaToEdit.videoUrl.includes('end=')) {
                                    const tempEnd = mediaToEdit.videoUrl.split('end=')[1];
                                    const tempEndMin = Math.floor(Number(tempEnd) / 60);
                                    const tempEndSec = Number(tempEnd) % 60;
                                    setEndTimeYt(
                                        `${tempEndMin < 10 ? `00${tempEndMin}` : tempEndMin < 100 ? `0${tempEndMin}` : tempEndMin}:${tempEndSec < 10 ? `0${tempEndSec}` : tempEndSec
                                        }`
                                    );
                                }

                                if (value.videoUrl === mediaToEdit.videoUrl) {
                                    setLinkYt(value.videoUrl ?? '');
                                    setTitleYt(value.title ?? '');
                                    if (user?.role === Roles.ADMIN && value.etsList) {
                                        const tempEtsList: string[] = [];
                                        value.etsList.forEach((item) => {
                                            if (item) tempEtsList.push(item);
                                        });
                                        setEtsListSelected(tempEtsList);
                                    }
                                    if (user?.role === Roles.PRO) {
                                        const tempPatientList: string[] = [];
                                        value?.patientList &&
                                            value?.patientList.forEach((item) => {
                                                if (item) tempPatientList.push(item);
                                            });
                                        const tempProList: { [key: string]: 'read' | 'write' }[] =
                                            [];
                                        value.proList &&
                                            value.proList.forEach((item) => {
                                                if (item)
                                                    tempProList.push({
                                                        [Object.keys(item)[0]]:
                                                            Object.values(item)[0]
                                                    });
                                            });
                                        if (user && tempProList) {
                                            tempProList.forEach((pro) => {
                                                if (pro && Object.keys(pro)[0] === user.id) {
                                                    setRight(Object.values(pro)[0]);
                                                }
                                            });
                                            await getUser(value.authorId ?? '').then((result) => {
                                                setMediaCreator(result);
                                            });
                                        }
                                        setProListSelected(tempProList);
                                        setSeniorsListSelected(tempPatientList);
                                    } else if (
                                        user?.role === Roles.FAMILY ||
                                        user?.role === Roles.SENIOR
                                    ) {
                                        setFamilyListSelected(
                                            (value?.patientList?.filter(
                                                (item) => item !== user?.id
                                            ) as string[]) ?? []
                                        );
                                    }
                                    setDisableBack(true);
                                }
                            }
                        }
                    });
                }
            });
            // if (setMediaToEdit) setMediaToEdit(undefined);
        }
        if (
            type === 'personal-video' &&
            step === 0 &&
            mediaToEdit &&
            mediaToEdit.id &&
            mediaToEdit.id !== '' &&
            mediaToEdit.videoUrl &&
            mediaToEdit.videoUrl !== ''
        ) {
            getPersonalVideo(mediaToEdit.id).then((res) => {
                if (res) {
                    forEach(res, async (value) => {
                        if (value.videoUrl === mediaToEdit.videoUrl) {
                            setTitleVideo(value.title ?? '');
                            setVideo(mediaToEdit.videoUrl);
                            setTumbnailVideoPersonal(mediaToEdit.thumbnail ?? undefined);
                            if (user?.role === Roles.ADMIN && value.etsList) {
                                const tempEtsList: string[] = [];
                                value.etsList.forEach((item) => {
                                    if (item) tempEtsList.push(item);
                                });
                                setEtsListSelected(tempEtsList);
                            }
                            if (user?.role === Roles.PRO) {
                                const tempPatientList: string[] = [];
                                value?.patientList &&
                                    value?.patientList.forEach((item) => {
                                        if (item) tempPatientList.push(item);
                                    });
                                const tempProList: { [key: string]: 'read' | 'write' }[] = [];
                                value.proList &&
                                    value.proList.forEach((item) => {
                                        if (item)
                                            tempProList.push({
                                                [Object.keys(item)[0]]: Object.values(item)[0]
                                            });
                                    });
                                if (user && tempProList) {
                                    tempProList.forEach((pro) => {
                                        if (pro && Object.keys(pro)[0] === user.id) {
                                            setRight(Object.values(pro)[0]);
                                            const index = tempProList.findIndex(
                                                (item) => Object.keys(item)[0] === user?.id
                                            );
                                            if (index !== -1) {
                                                tempProList.splice(index, 1);
                                            }
                                        }
                                    });
                                    await getUser(value.authorId ?? '').then((result) => {
                                        setMediaCreator(result);
                                    });
                                }
                                setProListSelected(tempProList);
                                setSeniorsListSelected(tempPatientList);
                            } else if (user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) {
                                setFamilyListSelected(
                                    (value?.patientList?.filter(
                                        (item) => item !== user?.id
                                    ) as string[]) ?? []
                                );
                            }
                            setDisableBack(true);
                        }
                    });
                }
            });
        }
        if (type === 'picture' && step === 0 && (!mediaToEdit || mediaToEdit.id === '')) {
            triggerImageUpload();
        }
        if (type === 'personal-video' && step === 0 && (!mediaToEdit || mediaToEdit.id === ''))
            triggerVideoUpload();
    }, [step]);

    useEffect(() => {
        if (media.length > 0) {
            setMediaCropped(
                media.map((file) => {
                    return {
                        id: null,
                        file: URL.createObjectURL(file),
                        croppedImage: { url: '', file: new Blob() },
                        crop: { x: 0, y: 0 },
                        zoom: 1,
                        croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
                        title: null,
                        people: null,
                        imageDetails: new Array<ImageIdentificationData>(
                            new ImageIdentificationData(1, '', '', '')
                        ),
                        place: null,
                        desc: null,
                        date: null,
                        theme: null,
                        createdDate: null
                    };
                })
            );
            setStep(1);
        }
    }, [media]);

    useEffect(() => {
        if (
            user?.role === Roles.PRO &&
            step === 4 &&
            type === 'picture' &&
            proListSelected.length === 0
        )
            setStep(3);
        if (
            user?.role === Roles.PRO &&
            ((step === 2 && type === 'personal-video') ||
                (step === 3 && type === 'youtube-video')) &&
            proListSelected.length === 0
        ) {
            setStep(type === 'youtube-video' ? 2 : 1);
        }

        switch (step) {
            case 0:
                setTitle(t(`import-${type}.title-1`));
                setStepMax(
                    type === 'picture'
                        ? user?.role === Roles.PRO
                            ? 6
                            : 4
                        : user?.role === Roles.PRO
                            ? type === 'youtube-video'
                                ? 5
                                : 4
                            : type === 'youtube-video'
                                ? 3
                                : 2
                );
                break;
            case 1:
                setTitle(t(`import-${type}.title-2`));
                setStepMax(
                    type === 'picture'
                        ? user?.role === Roles.PRO
                            ? 6
                            : 4
                        : user?.role === Roles.PRO
                            ? type === 'youtube-video'
                                ? 5
                                : 4
                            : type === 'youtube-video'
                                ? 3
                                : 2
                );
                break;
            case 2:
                setTitle(t(`import-${type}.title-3`));
                setStepMax(
                    type === 'picture'
                        ? user?.role === Roles.PRO
                            ? 6
                            : 4
                        : user?.role === Roles.PRO
                            ? type === 'youtube-video'
                                ? 5
                                : 4
                            : type === 'youtube-video'
                                ? 3
                                : 2
                );
                break;
            case 3:
                switch (step2Page) {
                    case 'addTitle':
                        setTitle(t('importMedia.addTitle'));
                        setStepMax(0);
                        break;
                    case 'addPeople':
                        setTitle(t('importMedia.addPeople'));
                        setStepMax(0);
                        break;
                    case 'addPlace':
                        setTitle(t('importMedia.addPlace'));
                        setStepMax(0);
                        break;
                    case 'addDate':
                        setTitle(t('importMedia.addDate'));
                        setStepMax(0);
                        break;
                    case 'addDesc':
                        setTitle(t('importMedia.addDesc'));
                        setStepMax(0);
                        break;
                    case null:
                        setTitle(t('importMedia.share'));
                        setStepMax(
                            type === 'picture'
                                ? user?.role === Roles.PRO
                                    ? 6
                                    : 4
                                : user?.role === Roles.PRO
                                    ? 4
                                    : type === 'youtube-video'
                                        ? 3
                                        : 2
                        );
                        break;
                }
                break;
            default:
                setTitle(t('importMedia.title-1'));
                setStepMax(
                    type === 'picture'
                        ? user?.role === Roles.PRO
                            ? 6
                            : 4
                        : user?.role === Roles.PRO
                            ? type === 'youtube-video'
                                ? 5
                                : 4
                            : type === 'youtube-video'
                                ? 3
                                : 2
                );
                break;
        }
    }, [step]);

    useEffect(() => {
        if (selectedVideoYt) {
            const tempStart =
                Number(startTimeYt.split(':')[0]) * 60 + Number(startTimeYt.split(':')[1]);
            const tempEnd = Number(endTimeYt.split(':')[0]) * 60 + Number(endTimeYt.split(':')[1]);
            setLinkYt(
                `https://youtube.com/embed/${selectedVideoYt.id}?start=${tempStart}${endTimeYt !== '000:00' ? `&end=${tempEnd}` : ''
                }`
            );
        }
    }, [startTimeYt, endTimeYt]);

    const updatePicture = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            if (config.upload.LIMIT_IMPOT_MEDIA === -1 || event.target.files.length <= config.upload.LIMIT_IMPOT_MEDIA) {
                // const value = event.target.files[0];
                const value = Array.from(event.target.files).map((file) => {
                    return file;
                });
                // add "&& value.size <= 10000000" to restore size limit
                if (value !== undefined && setMedia) {
                    setMedia(value);
                    setImage(URL.createObjectURL(value[0]));
                }
            } else {
                // display modal error size limite
                // setDisplayErrorLimit(true);
            }
        }
    };
    const updateVideo = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.files) {
            const value = event.target.files[0];
            if (value !== undefined) {
                setVideo(value);
            }
        }
    };

    const [image, setImage] = useState<string>();
    const [result, setResult] = useState<string>();

    const onCropComplete = useCallback(
        async (_croppedArea: any, croppedAreaPixels: any) => {
            setMediaCropped((prevMediaCropped) => {
                const newMediaCropped = [...prevMediaCropped];
                newMediaCropped[indexImport].croppedAreaPixels = croppedAreaPixels;
                return newMediaCropped;
            });
        },
        [image, indexImport]
    );

    const nextImage = async (): Promise<void> => {
        if (indexImport + 1 < media.length) {
            setImage(URL.createObjectURL(media[indexImport]));
            setIndexImport(indexImport + 1);
        } else {
            const newMediaCropped = mediaCropped.map(async (thisMediaCropped, index) => {
                const croppedImageData = await getCroppedImg(
                    getImageKitUrlFrom(thisMediaCropped.file ?? ''),
                    thisMediaCropped.croppedAreaPixels
                );
                const newObj = {
                    id: thisMediaCropped.id,
                    file: thisMediaCropped.file,
                    croppedImage: await getCroppedImg(
                        getImageKitUrlFrom(thisMediaCropped.file),
                        thisMediaCropped.croppedAreaPixels
                    ),
                    croppedAreaPixels: thisMediaCropped.croppedAreaPixels,
                    crop: thisMediaCropped.crop,
                    zoom: 1,
                    title: thisMediaCropped.title,
                    people: thisMediaCropped.people,
                    imageDetails: thisMediaCropped.imageDetails,
                    place: thisMediaCropped.place,
                    desc: thisMediaCropped.desc,
                    date: thisMediaCropped.date,
                    theme: thisMediaCropped.theme,
                    createdDate: thisMediaCropped.createdDate
                };
                return newObj;
            });
            setMediaCropped(await Promise.all(newMediaCropped));
            setStep(2);
        }
    };

    const prevImage = async (): Promise<void> => {
        if (indexImport > 0) {
            setImage(URL.createObjectURL(media[indexImport - 1]));
            setIndexImport(indexImport - 1);
        } else {
            setStep(0);
        }
    };

    let totalSaved = 0;
    const sendPicute = async (): Promise<void> => {
        setNeedLoader(true);
        const thisUser = mediaCreator ? mediaCreator : user;
        const thisProListSelected = proListSelected;
        if (right === 'write') {
            thisProListSelected.push({ [user?.id ?? '']: 'write' });
        } else if (right === 'read') {
            thisProListSelected.push({ [user?.id ?? '']: 'read' });
        }
        // into thisProListSelected, delete same id as user
        const tempProListSelected: { [key: string]: 'read' | 'write' }[] = [];
        thisProListSelected.forEach((item) => {
            tempProListSelected
                .map((thisItem) => Object.keys(thisItem)[0])
                .indexOf(Object.keys(item)[0]) === -1 && tempProListSelected.push(item);
        });
        if (mediaCropped.length > 0) {
            mediaCropped.forEach(async (thisMediaCropped, index) => {
                thisMediaCropped.imageDetails.map((item: ImageIdentificationData, indexItem) => {
                    if (
                        !(
                            (item.firstName && item.firstName !== '') ||
                            (item.lastName && item.lastName !== '') ||
                            (item.relation && item.relation !== '')
                        )
                    ) {
                        thisMediaCropped.imageDetails.splice(indexItem, 1);
                    }
                });
                let thisId = 0;
                const thisImageDetails: ImageIdentificationData[] =
                    thisMediaCropped.imageDetails.map((item: ImageIdentificationData) => {
                        thisId = thisId + 1;
                        return new ImageIdentificationData(
                            thisId,
                            item.firstName ?? '',
                            item.lastName ?? '',
                            item.relation ?? ''
                        );
                    });
                const data: ExerciseItem = {
                    id: thisMediaCropped.id ?? undefined,
                    answer: thisMediaCropped.title ?? undefined,
                    imageDetails: thisImageDetails,
                    photoCapturedPlace: thisMediaCropped.place ?? undefined,
                    photoCapturedOn: thisMediaCropped.date ?? undefined,
                    anecdote: thisMediaCropped.desc ?? undefined,
                    theme: thisMediaCropped.theme ?? 'test',
                    exerciseImage:
                        right === 'read'
                            ? mediaPictureUrlToEdit
                            : new File([thisMediaCropped.croppedImage.file], 'name.webp'),
                    exerciseImageType: 'image/jpeg',
                    title: '',
                    createdDate: thisMediaCropped.createdDate ?? undefined,
                    patientList:
                        thisUser?.role === Roles.PRO ? seniorsListSelected : familyListSelected,
                    proList: tempProListSelected ?? [],
                    etsList: thisUser?.role === Roles.ADMIN ? etsListSelected : []
                };
                if (thisUser && thisUser.id) {
                    if (thisUser.role === Roles.FAMILY || thisUser.role === Roles.SENIOR) {
                        if (thisUser.familyCode) {
                            (data.authorType =
                                thisUser.role === Roles.FAMILY ? Roles.FAMILY : Roles.SENIOR),
                                (data.communityId = thisUser.familyCode),
                                (data.authorId = thisUser.userId);
                        }
                    }
                    if (thisUser.role === Roles.PRO) {
                        if (thisUser.establishmentCode) {
                            (data.authorType = Roles.PRO),
                                (data.communityId = thisUser.establishmentCode),
                                (data.authorId = thisUser.userId);
                        }
                    }
                    if (thisUser.role === Roles.ADMIN) {
                        (data.authorType = Roles.ADMIN),
                            (data.communityId = thisUser.id),
                            (data.authorId = thisUser.userId);
                    }
                }
                if (thisUser) {
                    const url = await uploadImage(data.exerciseImage as File, data.title ?? '');
                    console.log('data', data, url);
                    await createMedia(userDataProp?.token ?? '', {
                        ...data,
                        url: url,
                        type: 'IMAGE',
                        image_details: data.imageDetails
                    }).then((res: any) => {
                        close();
                    });
                }
            });
        }
    };

    const uploadVideoYt = async (): Promise<void> => {
        setNeedLoader(true);
        if (!linkYt) return;
        const thisUser = mediaCreator ? mediaCreator : user;
        const thisProListSelected = proListSelected;
        if (right === 'write') {
            thisProListSelected.push({ [user?.id ?? '']: 'write' });
        } else if (right === 'read') {
            thisProListSelected.push({ [user?.id ?? '']: 'read' });
        }
        const tempProListSelected: { [key: string]: 'read' | 'write' }[] = [];
        thisProListSelected.forEach((item) => {
            tempProListSelected
                .map((thisItem) => Object.keys(thisItem)[0])
                .indexOf(Object.keys(item)[0]) === -1 && tempProListSelected.push(item);
        });
        // @ts-ignore
        const temp: UsersVideoData = {
            authorId: thisUser?.userId,
            title: titleYt,
            communityId: getCommunityId(thisUser) ?? undefined,
            role: getRole(thisUser) ?? undefined,
            patientList:
                thisUser?.role === Roles.PRO ? seniorsListSelected : familyListSelected ?? [],
            proList: tempProListSelected ?? [],
            etsList: thisUser?.role === Roles.ADMIN ? etsListSelected : []
        };
        if (mediaToEdit?.id && mediaToEdit?.videoUrl) {
            await deletePersonalYoutubeVideo(mediaToEdit?.id, mediaToEdit?.videoUrl);
        }
        await createMedia(userDataProp?.token ?? '', {
            ...temp,
            url: linkYt,
            type: 'YOUTUBE',
            image_details: []
        }).then((res: any) => {
            updateListPicture();
            close();
        });
        // await addPersonalYoutubeVideo(temp, linkYt).then((res) => {
        //     if (res) {
        //         // history.go(0);
        //         updateListPicture();
        //         close();
        //     }
        // });
    };

    const [videoPerso, setVideoPerso] = useState<File>();
    const uploadVideoPersonal = async (): Promise<void> => {
        let link = typeof tumbnailVideoPersonal === 'string' ? tumbnailVideoPersonal : '';
        if (user && tumbnailVideoPersonal) {
            const storage = codeStorage(user);
            if (storage && typeof tumbnailVideoPersonal !== 'string') {
                link = await uploadImage(tumbnailVideoPersonal, storage);
            }
        }

        setNeedLoader(true);
        const thisUser = mediaCreator ? mediaCreator : user;
        const thisProListSelected = proListSelected;
        if (right === 'write') {
            thisProListSelected.push({ [user?.id ?? '']: 'write' });
        } else if (right === 'read') {
            thisProListSelected.push({ [user?.id ?? '']: 'read' });
        }
        const tempProListSelected: { [key: string]: 'read' | 'write' }[] = [];
        thisProListSelected.forEach((item) => {
            tempProListSelected
                .map((thisItem) => Object.keys(thisItem)[0])
                .indexOf(Object.keys(item)[0]) === -1 && tempProListSelected.push(item);
        });
        const temp: UsersVideoData = {
            authorId: thisUser?.userId,
            title: titleVideo,
            communityId: getCommunityId(thisUser) ?? undefined,
            role: getRole(thisUser) ?? undefined,
            thumbnail: link,
            patientList:
                thisUser?.role === Roles.PRO ? seniorsListSelected : familyListSelected ?? [],
            proList: tempProListSelected ?? [],
            etsList: thisUser?.role === Roles.ADMIN ? etsListSelected : []
        };
        if (mediaToEdit?.id && mediaToEdit?.videoUrl) {
            await deletePersonalVideo(mediaToEdit?.id, mediaToEdit?.videoUrl, false);
            await addPersonalVideo(temp, mediaToEdit?.videoUrl);
            await createMedia(userDataProp?.token ?? '', {
                ...temp,
                url: mediaToEdit?.videoUrl,
                type: 'VIDEO',
                image_details: []
            }).then((res: any) => {
                updateListPicture();
                close();
            });
        } else if (thisUser) {
            const storage = codeStorage(thisUser);
            if (inputFileVideo.current && inputFileVideo.current.files && storage) {
                await addPersonalVideo(temp, inputFileVideo.current.files[0], storage)
                    .then(async (res) => {
                        if (res) {
                            await createMedia(userDataProp?.token ?? '', {
                                ...temp,
                                url: mediaToEdit?.videoUrl,
                                type: 'VIDEO',
                                image_details: []
                            }).then((res: any) => {
                                updateListPicture();
                                close();
                            });
                            updateListPicture();
                            close();
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        }
    };

    const [viewShareRules, setViewShareRules] = useState<boolean>(false);
    const knowMore = (): JSX.Element => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '1rem',
                    width: '100%'
                }}
                onClick={() => setViewShareRules(true)}>
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '1.8rem',
                        margin: '1rem',
                        width: '1.8rem',
                        borderRadius: '50%',
                        backgroundColor: '#E5007B',
                        color: '#FFFFFF',
                        cursor: 'pointer'
                    }}>
                    i
                </div>
                <div
                    style={{
                        color: '#fff',
                        backgroundColor: '#0D224B',
                        borderRadius: '0.5rem',
                        height: '2.125rem',
                        fontSize: '0.875rem',
                        fontWeight: '500',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '0 1rem',
                        cursor: 'pointer'
                    }}>
                    {t('knowMore')}
                </div>
            </div>
        );
    };

    const [displayMediaIndex, setDisplayMediaIndex] = useState(0);
    const AddPeopleComponent = (): JSX.Element => {
        return (
            <>
                {mediaCropped.map(
                    (
                        thisMediaCropped: {
                            imageDetails: any;
                            croppedImage: { url: string | undefined };
                        },
                        index: number
                    ) => (
                        <div
                            style={{
                                display: displayMediaIndex === index ? 'flex' : 'none',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'start',
                                height: '100%',
                                width: '100%',
                                gap: '1rem'
                            }}>
                            <img
                                style={{
                                    width: '60%',
                                    marginBottom: '1rem'
                                }}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                            {thisMediaCropped.imageDetails &&
                                thisMediaCropped.imageDetails.map(
                                    (item: ImageIdentificationData, indexItem: number) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'start',
                                                height: '100%',
                                                width: '100%',
                                                gap: '1rem'
                                            }}>
                                            <Input
                                                value={item.firstName ?? ''}
                                                onChange={(value): void => {
                                                    setMediaCropped((prev) => {
                                                        const newMediaCropped = [...prev];
                                                        newMediaCropped[index].imageDetails[
                                                            indexItem
                                                        ].firstName = value;
                                                        return newMediaCropped;
                                                    });
                                                }}
                                                placeholder={t('Firstname') ?? ''}
                                            />
                                            <Input
                                                value={item.lastName ?? ''}
                                                onChange={(value): void => {
                                                    setMediaCropped((prev) => {
                                                        const newMediaCropped = [...prev];
                                                        newMediaCropped[index].imageDetails[
                                                            indexItem
                                                        ].lastName = value;
                                                        return newMediaCropped;
                                                    });
                                                }}
                                                placeholder={t('Lastname') ?? ''}
                                            />
                                            <Select
                                                value={item.relation ?? ''}
                                                onChange={(value): void => {
                                                    setMediaCropped((prev) => {
                                                        const newMediaCropped = [...prev];
                                                        newMediaCropped[index].imageDetails[
                                                            indexItem
                                                        ].relation = value.target.value;
                                                        return newMediaCropped;
                                                    });
                                                }}
                                                sx={{
                                                    border: '1px solid var(--textarea-border-color)'
                                                }}
                                                opts={[
                                                    { value: '', label: t('Link') ?? '' },
                                                    ...dropdownListFamily.map((item) => ({
                                                        value: item,
                                                        label: item
                                                    }))
                                                ]}
                                            />
                                            <hr
                                                style={{
                                                    width: '100%',
                                                    height: '2px',
                                                    backgroundColor: 'var(--textarea-border-color)',
                                                    border: 'none'
                                                }}
                                            />
                                        </div>
                                    )
                                )}
                            {/* button to add imageDetails */}
                            <Button
                                sx={{
                                    width: '100%',
                                    height: '3rem',
                                    backgroundColor: '#E8E8E8',
                                    color: '#000000'
                                }}
                                onClick={() => {
                                    setMediaCropped((prev) => {
                                        const newMediaCropped = [...prev];
                                        newMediaCropped[index].imageDetails.push(
                                            new ImageIdentificationData(
                                                newMediaCropped[index].imageDetails.length + 1,
                                                '',
                                                '',
                                                ''
                                            )
                                        );
                                        return newMediaCropped;
                                    });
                                }}>
                                {t('addPeople')}
                            </Button>
                        </div>
                    )
                )}
                <div style={classes['btn-container']}>
                    <Button
                        sx={classes['btn-prev']}
                        onClick={() => {
                            if (displayMediaIndex === 0) {
                                setStep(2);
                                setStep2Page(null);
                            } else {
                                setDisplayMediaIndex(displayMediaIndex - 1);
                            }
                        }}>
                        {t('prev')}
                    </Button>
                    <Button
                        pink
                        sx={classes['btn-next']}
                        onClick={() => {
                            if (displayMediaIndex === mediaCropped.length - 1) {
                                setStep(2);
                                setStep2Page(null);
                                setDisplayMediaIndex(0);
                            } else {
                                setDisplayMediaIndex(displayMediaIndex + 1);
                            }
                        }}>
                        {t('next')}
                    </Button>
                </div>
            </>
        );
    };

    return type === 'picture' ? (
        <>
            {needLoader && <Loader sx={{ zIndex: '1000' }} />}
            <ShareRulesModal
                isOpen={viewShareRules}
                closeModal={() => setViewShareRules(false)}
                type="media"
            />
            {step === 0 && (
                <div style={{ ...classes['root-step'], padding: '40px 32px' }}>
                    <Button
                        sx={{ minHeight: '3rem' }}
                        onClick={(): void => {
                            if (inputFile && inputFile.current) {
                                inputFile.current.click();
                            }
                        }}>
                        {inputFile.current &&
                            inputFile.current.files &&
                            inputFile.current.files?.length >= 1
                            ? t('importNew') ?? ''
                            : t('import') ?? ''}
                    </Button>
                    <input
                        ref={inputFile}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        multiple
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void =>
                            updatePicture(event)
                        }
                    />
                </div>
            )}
            {step === 1 && (
                <div style={{ ...classes['root-step'] }}>
                    <Typography sx={{ width: '100%', padding: '0.5rem 2rem' }}>
                        {t('pictureOnPicture', {
                            actual: indexImport + 1,
                            total: mediaCropped.length
                        })}
                    </Typography>
                    <div
                        style={{
                            ...classes['cropper-container'],
                            ...(height && width && height > width
                                ? { height: 'calc(100vw - 2rem)' }
                                : { height: 'calc(45vw - 2rem)' })
                        }}>
                        {mediaCropped.map((media, index) => {
                            return (
                                <>
                                    <Cropper
                                        image={mediaCropped[indexImport].file}
                                        crop={mediaCropped[indexImport].crop}
                                        zoom={mediaCropped[indexImport].zoom}
                                        minZoom={0.5}
                                        restrictPosition={false}
                                        aspect={1}
                                        onCropChange={(crop: any): void => {
                                            setMediaCropped((prev) => {
                                                const newMediaCropped = [...prev];
                                                newMediaCropped[indexImport].crop = crop;
                                                return newMediaCropped;
                                            });
                                        }}
                                        onCropComplete={onCropComplete}
                                        onMediaLoaded={(): void => setLoaderCrop(false)}
                                        onZoomChange={(zoom: number): void => {
                                            setMediaCropped((prev) => {
                                                const newMediaCropped = [...prev];
                                                newMediaCropped[indexImport].zoom = zoom;
                                                return newMediaCropped;
                                            });
                                        }}
                                    />
                                </>
                            );
                        })}
                        ;
                        {loaderCrop && (
                            <div style={classes['loader-crop']}>
                                {/* <IonLoading  message="Loading..." spinner="circles" />
                                            testtttest */}
                                {/* <IonInfiniteScroll>
                                                <IonInfiniteScrollContent
                                                    loadingText={t('loading') ?? ''}
                                                    loadingSpinner="bubbles"
                                                />
                                            </IonInfiniteScroll> */}
                                <img src={ringLoader} alt="loader" style={{ height: '6rem' }} />
                            </div>
                        )}
                    </div>
                    <div style={classes['slider-container']}>
                        <Button
                            sx={classes['btn-slider']}
                            onClick={(): void => {
                                setMediaCropped((prev) => {
                                    const newMediaCropped = [...prev];
                                    newMediaCropped[indexImport].zoom =
                                        newMediaCropped[indexImport].zoom - 0.2 >= 0.5
                                            ? newMediaCropped[indexImport].zoom - 0.2
                                            : 0.5;
                                    return newMediaCropped;
                                });
                            }}>
                            <img src={zoomOut} alt="zoom out" />
                        </Button>
                        <Slider
                            sx={classes['slider']}
                            value={mediaCropped[indexImport].zoom}
                            min={0.5}
                            max={3}
                            step={0.1}
                            ariaLabel="Zoom"
                            onChange={(e: any, thisZoom: number): void => {
                                setMediaCropped((prev) => {
                                    const newMediaCropped = [...prev];
                                    newMediaCropped[indexImport].zoom = thisZoom;
                                    return newMediaCropped;
                                });
                            }}
                        />
                        <Button
                            sx={classes['btn-slider']}
                            onClick={(): void => {
                                setMediaCropped((prev) => {
                                    const newMediaCropped = [...prev];
                                    newMediaCropped[indexImport].zoom =
                                        newMediaCropped[indexImport].zoom + 0.2 <= 3
                                            ? newMediaCropped[indexImport].zoom + 0.2
                                            : 3;
                                    return newMediaCropped;
                                });
                            }}>
                            <img src={zoomIn} alt="zoom in" />
                        </Button>
                    </div>
                    <div style={classes['page-container']}>
                        {media.map((file, index) => (
                            <div
                                style={{
                                    ...classes['page'],
                                    ...(index === indexImport ? { backgroundColor: '#121212' } : {})
                                }}
                                key={index}
                            />
                        ))}
                    </div>
                    <div style={{ height: '5rem', width: '100%' }} />
                    <div style={classes['btn-container']}>
                        <Button
                            sx={classes['btn-prev']}
                            onClick={() => {
                                if (disableBack) close();
                                else {
                                    setLoaderCrop(true);
                                    prevImage();
                                }
                            }}>
                            {t('prev')}
                        </Button>
                        <Button
                            pink
                            sx={classes['btn-next']}
                            onClick={() => {
                                setLoaderCrop(true);
                                nextImage();
                            }}>
                            {t('next')}
                        </Button>
                    </div>
                </div>
            )}
            {step === 2 && (
                <div style={{ ...classes['root-step'] }}>
                    <Typography sx={classes['step-desc']}>{t('personalisePictures')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <div style={classes['lst-img-container']}>
                        {mediaCropped.map((thisMediaCropped, index) => (
                            <img
                                style={classes['lst-img-item']}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                        ))}
                    </div>
                    <hr style={classes['hr']} />
                    <div style={classes['item-container']}>
                        <ItemList
                            title={t('addImgtitle')}
                            onClick={(): void => {
                                setStep2Page('addTitle');
                                setStep(3);
                            }}
                        />
                        <ItemList
                            title={t('addImgPeople')}
                            onClick={(): void => {
                                setStep2Page('addPeople');
                                setStep(3);
                            }}
                        />
                        <ItemList
                            title={t('addImgPlace')}
                            onClick={(): void => {
                                setStep2Page('addPlace');
                                setStep(3);
                            }}
                        />
                        <ItemList
                            title={t('addImgDate')}
                            onClick={(): void => {
                                setStep2Page('addDate');
                                setStep(3);
                            }}
                        />
                        <ItemList
                            title={t('addImgDesc')}
                            onClick={(): void => {
                                setStep2Page('addDesc');
                                setStep(3);
                            }}
                        />
                        <div style={{ height: '5rem', width: '100%' }} />
                    </div>
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)'
                            // margin: '0 0.5rem'
                        }}
                        // sendPicute()
                        onClick={() => {
                            setStep(3);
                            setStep2Page(null);
                        }}>
                        {t('Continue') ?? ''}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === 'addTitle' && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem' }}>
                    {mediaCropped.map((thisMediaCropped, index) => (
                        <div style={classes['img-title-list']}>
                            <img
                                style={classes['img-element']}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                            <Input
                                value={mediaCropped[index].title ?? ''}
                                onChange={(value): void => {
                                    setMediaCropped((prev) => {
                                        const newMediaCropped = [...prev];
                                        newMediaCropped[index].title = value;
                                        return newMediaCropped;
                                    });
                                }}
                                maxLength={30}
                                placeholder={t('Title') ?? ''}
                            />
                        </div>
                    ))}
                    <Button
                        pink
                        sx={{ margin: '1rem' }}
                        onClick={() => {
                            setStep(2);
                            setStep2Page(null);
                        }}>
                        {t('Validate') ?? ''}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === 'addPeople' && (
                <div
                    style={{ ...classes['root-step'], padding: '2rem 1rem', marginBottom: '5rem' }}>
                    {AddPeopleComponent()}
                </div>
            )}
            {step === 3 && step2Page === 'addPlace' && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem' }}>
                    {mediaCropped.map((thisMediaCropped, index) => (
                        <div style={classes['img-title-list']}>
                            <img
                                style={classes['img-element']}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                            <Input
                                value={mediaCropped[index].place ?? ''}
                                onChange={(value): void => {
                                    setMediaCropped((prev) => {
                                        const newMediaCropped = [...prev];
                                        newMediaCropped[index].place = value;
                                        return newMediaCropped;
                                    });
                                }}
                                placeholder={t('Place') ?? ''}
                            />
                        </div>
                    ))}
                    <Button
                        pink
                        sx={{ margin: '1rem' }}
                        onClick={() => {
                            setStep(2);
                            setStep2Page(null);
                        }}>
                        {t('Validate') ?? ''}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === 'addDate' && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem' }}>
                    {mediaCropped.map((thisMediaCropped, index) => (
                        <div style={classes['img-title-list']}>
                            <img
                                style={classes['img-element']}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                            <Input
                                type="date"
                                value={mediaCropped[index].date?.toISOString().substring(0, 10)}
                                onChange={(value): void => {
                                    setMediaCropped((prev) => {
                                        const newMediaCropped = [...prev];
                                        newMediaCropped[index].date = new Date(value);
                                        return newMediaCropped;
                                    });
                                }}
                                placeholder={t('Place') ?? ''}
                            />
                        </div>
                    ))}
                    <Button
                        pink
                        sx={{ margin: '1rem' }}
                        onClick={() => {
                            setStep(2);
                            setStep2Page(null);
                        }}>
                        {t('Validate') ?? ''}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === 'addDesc' && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem' }}>
                    {mediaCropped.map((thisMediaCropped, index) => (
                        <div style={classes['img-title-list']}>
                            <img
                                style={classes['img-element']}
                                src={thisMediaCropped.croppedImage.url}
                                alt="result"
                            />
                            <TextField
                                sx={{ width: '100%' }}
                                value={mediaCropped[index].desc ?? ''}
                                onChange={(value): void => {
                                    setMediaCropped((prev) => {
                                        const newMediaCropped = [...prev];
                                        newMediaCropped[index].desc = value;
                                        return newMediaCropped;
                                    });
                                }}
                                placeholder={t('Description') ?? ''}
                            // multiline
                            // rows={4}
                            />
                        </div>
                    ))}
                    <Button
                        pink
                        sx={{ margin: '1rem' }}
                        onClick={() => {
                            setStep(2);
                            setStep2Page(null);
                        }}>
                        {t('Validate') ?? ''}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === null && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDescToPro.pro')}</Typography>
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToPro.pro') ?? ''}
                        checked={proListSelected.length === proList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                const temp: { [key: string]: 'read' | 'write' }[] = [];
                                proList.forEach((item) => {
                                    temp.push({ [item.id ?? '']: 'read' });
                                });
                                setProListSelected(temp);
                            } else {
                                setProListSelected([]);
                            }
                        }}
                    />
                    {proList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={proListSelected
                                .map((item) => Object.keys(item)[0])
                                .includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setProListSelected([
                                        ...proListSelected,
                                        { [senior.id ?? '']: 'read' }
                                    ]);
                                } else {
                                    setProListSelected(
                                        proListSelected.filter(
                                            (item) => Object.keys(item)[0] !== senior.id
                                        )
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => (proListSelected.length === 0 ? setStep(5) : setStep(4))}>
                        {proListSelected.length > 0 ? t('Continue') : t('notNowThanks')}
                    </Button>
                </div>
            )}
            {step === 4 && step2Page === null && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={{ ...classes['step-desc'], width: '100%' }}>
                        {t('sendMediaDescToProRight')}
                    </Typography>
                    {knowMore()}
                    <DisplayUser
                        selectPermission
                        selectAll
                        selectAllTitle={t('sendAllToProRight') ?? ''}
                        selectValue={
                            proListSelected.filter((item) => Object.values(item)[0] === 'write')
                                .length === proListSelected.length
                                ? 'write'
                                : 'read'
                        }
                        onChange={(str): void => {
                            const temp: { [key: string]: 'read' | 'write' }[] = [];
                            proListSelected.forEach((item) => {
                                const itemKey = Object.keys(item)[0];
                                temp.push({ [itemKey]: str === 'write' ? 'write' : 'read' });
                            });
                            setProListSelected(temp);
                        }}
                    />
                    {proListSelected
                        .map((senior) => proList.find((item) => item.id === Object.keys(senior)[0]))
                        .map(
                            (senior) =>
                                senior &&
                                senior.id !== user?.id && (
                                    <DisplayUser
                                        user={senior}
                                        selectPermission
                                        selectValue={
                                            Object.values(
                                                proListSelected[
                                                proListSelected.findIndex(
                                                    (item) =>
                                                        Object.keys(item)[0] === senior?.id
                                                )
                                                ]
                                            )[0]
                                        }
                                        onChange={(str: string): void => {
                                            const temp: { [key: string]: 'read' | 'write' }[] =
                                                proListSelected;
                                            const index = temp.findIndex(
                                                (item) => Object.keys(item)[0] === senior?.id
                                            );
                                            if (index !== -1) {
                                                temp[index] = {
                                                    [Object.keys(temp[index])[0] ?? '']:
                                                        str === 'write' ? 'write' : 'read'
                                                };
                                            }
                                            setProListSelected([...temp]);
                                        }}
                                    />
                                )
                        )}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => setStep(5)}>
                        {t('Continue')}
                    </Button>
                </div>
            )}
            {step === 5 && step2Page === null && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDesc.family')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllSenior.pro') ?? ''}
                        checked={seniorsListSelected.length === seniorsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setSeniorsListSelected(
                                    seniorsList.map((senior) => senior.id ?? '')
                                );
                            } else {
                                setSeniorsListSelected([]);
                            }
                        }}
                    />
                    {seniorsList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={seniorsListSelected.includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setSeniorsListSelected([
                                        ...seniorsListSelected,
                                        senior.id ?? ''
                                    ]);
                                } else {
                                    setSeniorsListSelected(
                                        seniorsListSelected.filter((id) => id !== senior.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => sendPicute()}>
                        {t('Send')}
                    </Button>
                </div>
            )}

            {step === 3 && step2Page === null && user?.role === Roles.ADMIN && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <DisplayEts
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToEts.admin') ?? ''}
                        checked={etsListSelected.length === etsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setEtsListSelected(etsList.map((ets) => ets.id ?? ''));
                            } else {
                                setEtsListSelected([]);
                            }
                        }}
                    />
                    {etsList.map((ets) => (
                        <DisplayEts
                            checkbox={true}
                            ets={ets}
                            checked={etsListSelected.includes(ets.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setEtsListSelected([...etsListSelected, ets.id ?? '']);
                                } else {
                                    setEtsListSelected(
                                        etsListSelected.filter((id) => id !== ets.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => sendPicute()}>
                        {t('Continue')}
                    </Button>
                </div>
            )}

            {step === 3 &&
                step2Page === null &&
                (user?.role === Roles.SENIOR || user?.role === Roles.FAMILY) && (
                    <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                        <Typography
                            variant="h2"
                            sx={{
                                width: '100%',
                                color: '#1C0D4B',
                                fontWeight: '600',
                                textAlign: 'left'
                            }}>
                            {t('importMedia.shareMedia')}
                        </Typography>
                        <Typography sx={classes['step-desc']}>
                            {t('sendMediaDesc.family')}
                        </Typography>
                        {/* <hr style={classes['hr']} /> */}
                        <DisplayUser
                            checkbox={true}
                            selectAll
                            selectAllTitle={t('sendAllSenior.family') ?? ''}
                            checked={familyListSelected.length === familyList.length}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setFamilyListSelected(
                                        familyList.map((senior) => senior.id ?? '')
                                    );
                                } else {
                                    setFamilyListSelected([]);
                                }
                            }}
                        />
                        {familyList.map((senior) => (
                            <DisplayUser
                                checkbox={true}
                                user={senior}
                                checked={familyListSelected.includes(senior.id ?? '')}
                                onCheck={(checked): void => {
                                    if (checked === true) {
                                        setFamilyListSelected([
                                            ...familyListSelected,
                                            senior.id ?? ''
                                        ]);
                                    } else {
                                        setFamilyListSelected(
                                            familyListSelected.filter((id) => id !== senior.id)
                                        );
                                    }
                                }}
                            />
                        ))}
                        <Button
                            pink
                            sx={{
                                position: 'absolute',
                                bottom: '1rem',
                                width: 'calc(100% - 2rem)',
                                zIndex: 100
                                // margin: '0 0.5rem'
                            }}
                            onClick={() => sendPicute()}>
                            {t('Send')}
                        </Button>
                    </div>
                )}
        </>
    ) : type === 'personal-video' ? (
        <>
            {needLoader && <Loader sx={{ zIndex: '1000' }} />}
            <ShareRulesModal
                isOpen={viewShareRules}
                closeModal={() => setViewShareRules(false)}
                type="media"
            />
            <input
                ref={inputFileVideo}
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                multiple={false}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>): void => updateVideo(event)
                    // console.log('g')
                }
            />
            {step === 0 && (
                <div style={{ ...classes['root-step'], padding: '40px 32px' }}>
                    <Typography variant="h3">{t('SelectVideo')}</Typography>
                    <Typography sx={classes['step-desc-yt']}>
                        {t('SelectVideoPersoDesc')}
                    </Typography>
                    <Button
                        sx={{ minHeight: '3rem' }}
                        disabled={disableBack}
                        onClick={(): void => {
                            if (inputFileVideo && inputFileVideo.current) {
                                inputFileVideo.current.click();
                            }
                        }}>
                        {inputFileVideo.current &&
                            inputFileVideo.current.files &&
                            inputFileVideo.current.files?.length >= 1
                            ? t('importNew') ?? ''
                            : t('import') ?? ''}
                    </Button>
                    {video && (
                        <>
                            <canvas
                                // ref={canvasImg}
                                style={{
                                    // width: '100%',
                                    height: 'auto',
                                    maxHeight: '30vh',
                                    background: '#E8E8E8',
                                    aspectRatio: '1/1'
                                }}
                                width="1024"
                                height="1024"
                                id={`canvas-create`}
                            />
                            <video
                                style={{
                                    width: '100%',
                                    height: 'auto',
                                    maxHeight: '30vh',
                                    background: '#E8E8E8',
                                    display: 'none'
                                }}
                                controls
                                src={
                                    typeof video === 'string'
                                        ? `${video}#t=0.01`
                                        : `${URL.createObjectURL(video)}#t=0.01`
                                }
                                onCanPlay={(e) => {
                                    const thisVideo = e.currentTarget;
                                    // thisVideo.play();
                                    const canvas = document.getElementById(
                                        `canvas-create`
                                    ) as HTMLCanvasElement;
                                    const ctx = canvas.getContext('2d');
                                    if (ctx && !tumbnailVideoPersonal) {
                                        // addapt video img to canvas size, with crop if needed
                                        const ratio = thisVideo.videoWidth / thisVideo.videoHeight;
                                        const canvasRatio = canvas.width / canvas.height;
                                        let width = thisVideo.videoWidth;
                                        let height = thisVideo.videoHeight;
                                        let x = 0;
                                        let y = 0;
                                        if (ratio > canvasRatio) {
                                            width = thisVideo.videoHeight * canvasRatio;
                                            x = (thisVideo.videoWidth - width) / 2;
                                        } else {
                                            height = thisVideo.videoWidth / canvasRatio;
                                            y = (thisVideo.videoHeight - height) / 2;
                                        }
                                        ctx.drawImage(
                                            thisVideo,
                                            x,
                                            y,
                                            width,
                                            height,
                                            0,
                                            0,
                                            1024,
                                            1024
                                        );

                                        // get picture from ctx to save it
                                        canvas.toBlob(
                                            (blob) => {
                                                // @ts-ignore
                                                const file = new File([blob], 'image.png', {
                                                    type: 'image/png'
                                                });
                                                // download file
                                                setTumbnailVideoPersonal(file);
                                            },
                                            'image/png',
                                            1
                                        );
                                    } else if (
                                        ctx &&
                                        tumbnailVideoPersonal &&
                                        typeof tumbnailVideoPersonal === 'string'
                                    ) {
                                        // set tumbnailVideoPersonal to canvas
                                        const img = new Image();
                                        img.src = tumbnailVideoPersonal;
                                        img.onload = () => {
                                            ctx.drawImage(img, 0, 0, 1024, 1024);
                                        };
                                    }
                                }}
                            />
                        </>
                    )}
                    <Input
                        value={titleVideo}
                        onChange={(value): void => {
                            setTitleVideo(value);
                        }}
                        label={t('EnterTittle') ?? ''}
                        desc={t('max40char') ?? ''}
                        maxLength={40}
                        placeholder={t('exDenis') ?? ''}
                    />
                    <Button
                        pink
                        disabled={
                            (!inputFileVideo ||
                                !inputFileVideo.current ||
                                !inputFileVideo.current.files ||
                                !inputFileVideo.current.files[0]) &&
                            !disableBack
                        }
                        sx={{ minHeight: '3rem' }}
                        onClick={() => setStep(1)}>
                        {/* onClick={() => uploadVideoPersonal()}> */}
                        {t('Validate') ?? ''}
                    </Button>
                </div>
            )}
            {step === 1 && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDescToPro.pro')}</Typography>
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToPro.pro') ?? ''}
                        checked={proListSelected.length === proList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                const temp: { [key: string]: 'read' | 'write' }[] = [];
                                proList.forEach((item) => {
                                    temp.push({ [item.id ?? '']: 'read' });
                                });
                                setProListSelected(temp);
                            } else {
                                setProListSelected([]);
                            }
                        }}
                    />
                    {proList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={proListSelected
                                .map((item) => Object.keys(item)[0])
                                .includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setProListSelected([
                                        ...proListSelected,
                                        { [senior.id ?? '']: 'read' }
                                    ]);
                                } else {
                                    setProListSelected(
                                        proListSelected.filter(
                                            (item) => Object.keys(item)[0] !== senior.id
                                        )
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => (proListSelected.length === 0 ? setStep(3) : setStep(2))}>
                        {proListSelected.length > 0 ? t('Continue') : t('notNowThanks')}
                    </Button>
                </div>
            )}
            {step === 2 && step2Page === null && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={{ ...classes['step-desc'], width: '100%' }}>
                        {t('sendMediaDescToProRight')}
                    </Typography>
                    {knowMore()}
                    <DisplayUser
                        selectPermission
                        selectAll
                        selectAllTitle={t('sendAllToProRight') ?? ''}
                        selectValue={
                            proListSelected.filter((item) => Object.values(item)[0] === 'write')
                                .length === proListSelected.length
                                ? 'write'
                                : 'read'
                        }
                        onChange={(str): void => {
                            const temp: { [key: string]: 'read' | 'write' }[] = [];
                            proListSelected.forEach((item) => {
                                const itemKey = Object.keys(item)[0];
                                temp.push({ [itemKey]: str === 'write' ? 'write' : 'read' });
                            });
                            setProListSelected(temp);
                        }}
                    />
                    {proListSelected
                        .map((senior) => proList.find((item) => item.id === Object.keys(senior)[0]))
                        .map(
                            (senior) =>
                                senior &&
                                senior.id !== user?.id && (
                                    <DisplayUser
                                        user={senior}
                                        selectPermission
                                        selectValue={
                                            proListSelected.filter(
                                                (item) =>
                                                    Object.keys(item)[0] === senior?.id &&
                                                    Object.values(item)[0] === 'write'
                                            ).length === 1
                                                ? 'write'
                                                : 'read'
                                        }
                                        onChange={(str: string): void => {
                                            const temp: { [key: string]: 'read' | 'write' }[] =
                                                proListSelected;
                                            const index = temp.findIndex(
                                                (item) => Object.keys(item)[0] === senior?.id
                                            );
                                            if (index !== -1) {
                                                temp[index] = {
                                                    [Object.keys(temp[index])[0] ?? '']:
                                                        str === 'write' ? 'write' : 'read'
                                                };
                                            }
                                            setProListSelected([...temp]);
                                        }}
                                    />
                                )
                        )}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => setStep(3)}>
                        {t('Continue')}
                    </Button>
                </div>
            )}
            {step === 3 && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDesc.family')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllSenior.pro') ?? ''}
                        checked={seniorsListSelected.length === seniorsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setSeniorsListSelected(
                                    seniorsList.map((senior) => senior.id ?? '')
                                );
                            } else {
                                setSeniorsListSelected([]);
                            }
                        }}
                    />
                    {seniorsList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={seniorsListSelected.includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setSeniorsListSelected([
                                        ...seniorsListSelected,
                                        senior.id ?? ''
                                    ]);
                                } else {
                                    setSeniorsListSelected(
                                        seniorsListSelected.filter((id) => id !== senior.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoPersonal()}>
                        {t('Send')}
                    </Button>
                </div>
            )}

            {step === 1 && user?.role === Roles.ADMIN && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <DisplayEts
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToEts.admin') ?? ''}
                        checked={etsListSelected.length === etsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setEtsListSelected(etsList.map((ets) => ets.id ?? ''));
                            } else {
                                setEtsListSelected([]);
                            }
                        }}
                    />
                    {etsList.map((ets) => (
                        <DisplayEts
                            checkbox={true}
                            ets={ets}
                            checked={etsListSelected.includes(ets.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setEtsListSelected([...etsListSelected, ets.id ?? '']);
                                } else {
                                    setEtsListSelected(
                                        etsListSelected.filter((id) => id !== ets.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoPersonal()}>
                        {t('Continue')}
                    </Button>
                </div>
            )}

            {step === 1 && (user?.role === Roles.SENIOR || user?.role === Roles.FAMILY) && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDesc.family')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllSenior.family') ?? ''}
                        checked={familyListSelected.length === familyList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setFamilyListSelected(familyList.map((senior) => senior.id ?? ''));
                            } else {
                                setFamilyListSelected([]);
                            }
                        }}
                    />
                    {familyList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={familyListSelected.includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setFamilyListSelected([...familyListSelected, senior.id ?? '']);
                                } else {
                                    setFamilyListSelected(
                                        familyListSelected.filter((id) => id !== senior.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoPersonal()}>
                        {t('Send')}
                    </Button>
                </div>
            )}
        </>
    ) : (
        <>
            {needLoader && <Loader sx={{ zIndex: '1000' }} />}
            <ShareRulesModal
                isOpen={viewShareRules}
                closeModal={() => setViewShareRules(false)}
                type="media"
            />
            {step === 0 && (
                <div style={{ ...classes['root-step'], padding: '20px 32px', gap: '3rem' }}>
                    <Typography variant="h3">{t('SelectVideo')}</Typography>
                    {/* <Typography sx={classes['step-desc-yt']}>{t('SelectVideoDesc')}</Typography> */}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.5rem',
                            width: '100%'
                        }}>
                        <Input
                            value={searchYt}
                            label={t('shearchKeyWord') ?? ''}
                            desc={t('shearYtDesc') ?? ''}
                            placeholder={t('shearchOnYt') ?? ''}
                            imgIntoInput={search}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    getVideoListFromTitle(searchYt).then((res) => {
                                        if (res !== 'error') {
                                            setResultVideoYt(res.lst);
                                            setNextPageToken(res.nextPageToken);
                                        }
                                    });
                                }
                            }}
                            onChange={(value): void => setSearchYt(value)}
                            btnClick={() => {
                                getVideoListFromTitle(searchYt).then((res) => {
                                    if (res !== 'error') {
                                        setResultVideoYt(res.lst);
                                        setNextPageToken(res.nextPageToken);
                                    }
                                    // if 0 result, show 0 result
                                });
                            }}
                        />
                        <Button
                            sx={{ minHeight: '3rem' }}
                            disabled={disableBack}
                            onClick={(): void => {
                                getVideoListFromTitle(searchYt).then((res) => {
                                    if (res !== 'error') {
                                        setResultVideoYt(res.lst);
                                        setNextPageToken(res.nextPageToken);
                                    }
                                });
                            }}>
                            {t('shearch')}
                        </Button>
                    </div>
                    {resultVideoYt.length === 0 ? (
                        <>
                            <Input
                                value={linkYt}
                                onChange={(value): void => setLinkYt(value)}
                                label={t('EnterLink') ?? ''}
                                placeholder={t('EnterLink.placeholder') ?? ''}
                                disabled={disableBack}
                            />
                            <Button
                                disabled={!linkYt}
                                sx={{ minHeight: '3rem' }}
                                onClick={() => {
                                    const tempUrlId = youtube_parser(linkYt);
                                    if (tempUrlId && typeof tempUrlId === 'string') {
                                        getVideoFromId(tempUrlId).then((res) => {
                                            if (res !== 'error') {
                                                setSelectedVideoYt(res);
                                                setTitleYt(
                                                    res.title.length <= 40
                                                        ? res.title
                                                        : res.title.slice(0, 37) + '...'
                                                );
                                                setStartTimeYt('000:00');
                                                setStep(1);
                                            }
                                        });
                                    }
                                }}>
                                {t('next') ?? ''}
                            </Button>
                        </>
                    ) : (
                        <>
                            {resultVideoYt.map((video) => (
                                <VigentteFromYoutube
                                    video={video}
                                    onClick={() => {
                                        setLinkYt(`https://youtube.com/embed/${video.id}`);
                                        setSelectedVideoYt(video);
                                        setTitleYt(
                                            video.title.length <= 40
                                                ? video.title
                                                : video.title.slice(0, 37) + '...'
                                        );
                                        setStartTimeYt('000:00');
                                        setStep(1);
                                        // const videoElement = document.createElement('video');
                                        // videoElement.src = `https://youtube.com/embed/${video.id}`;
                                        // console.log("ici", videoElement.duration.toString());
                                        // videoElement.oncanplaythrough = () => {
                                        //     setEndTimeYt(videoElement.duration.toString());
                                        //     console.log("here", videoElement.duration.toString());
                                        // };
                                    }}
                                />
                            ))}
                            <Button
                                pink
                                onClick={
                                    (): void => {
                                        getVideoListFromTitle(searchYt, nextPageToken).then((res) => {
                                            if (res !== 'error') {
                                                setResultVideoYt([...resultVideoYt, ...res.lst]);
                                                setNextPageToken(res.nextPageToken);
                                            }
                                        });
                                    }
                                }>
                                {t('showMore') ?? ''}
                            </Button>
                        </>
                    )}
                    {/* <Button
                        pink
                        disabled={!linkYt}
                        sx={{ minHeight: '3rem' }}
                        onClick={() => setStep(1)}>
                        {t('next') ?? ''}
                    </Button> */}
                </div>
            )}

            {step === 1 && selectedVideoYt && linkYt && (
                <div style={{ ...classes['root-step'], padding: '20px 32px', gap: '1rem' }}>
                    <Typography
                        variant="h3"
                        sx={{
                            textAlign: 'center'
                        }}>
                        {t('SelectedVideo')}
                    </Typography>
                    <Typography
                        variant="h3"
                        sx={{
                            color: '#33333380',
                            fontWeight: '500',
                            fontSize: '1rem',
                            textAlign: 'left',
                            justifyContent: 'flex-start'
                        }}>
                        {'- '}
                        {t('Option')}
                        {' -'}
                    </Typography>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '1.5rem',
                            width: '100%'
                        }}>
                        <Input
                            value={titleYt}
                            label={t('SelectedVideo.title') ?? ''}
                            placeholder={t('SelectedVideo.placeholder') ?? ''}
                            imgIntoInput={search}
                            maxLength={40}
                            onChange={(value): void => setTitleYt(value)}
                        />
                        <VigentteFromYoutube video={selectedVideoYt} />
                        <Button
                            sx={{ minHeight: '3rem' }}
                            onClick={() => {
                                setStep(0);
                                setLinkYt('');
                                setTitleYt('');
                                setSelectedVideoYt(undefined);
                            }}>
                            {t('SelectedVideo.change') ?? ''}
                        </Button>
                        <Input
                            type="time" // set time into min:sec
                            value={startTimeYt}
                            label={t('SelectedVideo.startTime') ?? ''}
                            // placeholder={t('SelectedVideo.placeholder') ?? ''}
                            imgIntoInput={search}
                            maxLength={40}
                            onChange={(value): void => setStartTimeYt(value)}
                            format="###:##"
                        />
                        <Input
                            type="time"
                            value={endTimeYt}
                            label={t('SelectedVideo.endTime') ?? ''}
                            // placeholder={t('SelectedVideo.placeholder') ?? ''}
                            imgIntoInput={search}
                            maxLength={40}
                            onChange={(value): void => setEndTimeYt(value)}
                            format="###:##"
                        />
                        <Button pink sx={{ minHeight: '3rem' }} onClick={() => setStep(2)}>
                            {t('next') ?? ''}
                        </Button>
                    </div>
                </div>
            )}

            {step === 2 && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDescToPro.pro')}</Typography>
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToPro.pro') ?? ''}
                        checked={proListSelected.length === proList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                const temp: { [key: string]: 'read' | 'write' }[] = [];
                                proList.forEach((item) => {
                                    temp.push({ [item.id ?? '']: 'read' });
                                });
                                setProListSelected(temp);
                            } else {
                                setProListSelected([]);
                            }
                        }}
                    />
                    {proList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={proListSelected
                                .map((item) => Object.keys(item)[0])
                                .includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setProListSelected([
                                        ...proListSelected,
                                        { [senior.id ?? '']: 'read' }
                                    ]);
                                } else {
                                    setProListSelected(
                                        proListSelected.filter(
                                            (item) => Object.keys(item)[0] !== senior.id
                                        )
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => (proListSelected.length === 0 ? setStep(4) : setStep(3))}>
                        {proListSelected.length > 0 ? t('Continue') : t('notNowThanks')}
                    </Button>
                </div>
            )}
            {step === 3 && step2Page === null && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={{ ...classes['step-desc'], width: '100%' }}>
                        {t('sendMediaDescToProRight')}
                    </Typography>
                    {knowMore()}
                    <DisplayUser
                        selectPermission
                        selectAll
                        selectAllTitle={t('sendAllToProRight') ?? ''}
                        selectValue={
                            proListSelected.filter((item) => Object.values(item)[0] === 'write')
                                .length === proListSelected.length
                                ? 'write'
                                : 'read'
                        }
                        onChange={(str): void => {
                            const temp: { [key: string]: 'read' | 'write' }[] = [];
                            proListSelected.forEach((item) => {
                                const itemKey = Object.keys(item)[0];
                                temp.push({ [itemKey]: str === 'write' ? 'write' : 'read' });
                            });
                            setProListSelected(temp);
                        }}
                    />
                    {proListSelected
                        .map((senior) => proList.find((item) => item.id === Object.keys(senior)[0]))
                        .map(
                            (senior) =>
                                senior &&
                                senior.id !== user?.id && (
                                    <DisplayUser
                                        user={senior}
                                        selectPermission
                                        selectValue={
                                            proListSelected.filter(
                                                (item) =>
                                                    Object.keys(item)[0] === senior?.id &&
                                                    Object.values(item)[0] === 'write'
                                            ).length === 1
                                                ? 'write'
                                                : 'read'
                                        }
                                        onChange={(str: string): void => {
                                            const temp: { [key: string]: 'read' | 'write' }[] =
                                                proListSelected;
                                            const index = temp.findIndex(
                                                (item) => Object.keys(item)[0] === senior?.id
                                            );
                                            if (index !== -1) {
                                                temp[index] = {
                                                    [Object.keys(temp[index])[0] ?? '']:
                                                        str === 'write' ? 'write' : 'read'
                                                };
                                            }
                                            setProListSelected([...temp]);
                                        }}
                                    />
                                )
                        )}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => setStep(4)}>
                        {t('Continue')}
                    </Button>
                </div>
            )}
            {step === 4 && user?.role === Roles.PRO && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDesc.family')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllSenior.pro') ?? ''}
                        checked={seniorsListSelected.length === seniorsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setSeniorsListSelected(
                                    seniorsList.map((senior) => senior.id ?? '')
                                );
                            } else {
                                setSeniorsListSelected([]);
                            }
                        }}
                    />
                    {seniorsList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={seniorsListSelected.includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setSeniorsListSelected([
                                        ...seniorsListSelected,
                                        senior.id ?? ''
                                    ]);
                                } else {
                                    setSeniorsListSelected(
                                        seniorsListSelected.filter((id) => id !== senior.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoYt()}>
                        {t('Send')}
                    </Button>
                </div>
            )}

            {step === 2 && user?.role === Roles.ADMIN && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <DisplayEts
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllToEts.admin') ?? ''}
                        checked={etsListSelected.length === etsList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setEtsListSelected(etsList.map((ets) => ets.id ?? ''));
                            } else {
                                setEtsListSelected([]);
                            }
                        }}
                    />
                    {etsList.map((ets) => (
                        <DisplayEts
                            checkbox={true}
                            ets={ets}
                            checked={etsListSelected.includes(ets.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setEtsListSelected([...etsListSelected, ets.id ?? '']);
                                } else {
                                    setEtsListSelected(
                                        etsListSelected.filter((id) => id !== ets.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoYt()}>
                        {t('Continue')}
                    </Button>
                </div>
            )}

            {step === 2 && (user?.role === Roles.SENIOR || user?.role === Roles.FAMILY) && (
                <div style={{ ...classes['root-step'], padding: '2rem 1rem 4rem' }}>
                    <Typography
                        variant="h2"
                        sx={{
                            width: '100%',
                            color: '#1C0D4B',
                            fontWeight: '600',
                            textAlign: 'left'
                        }}>
                        {t('importMedia.shareMedia')}
                    </Typography>
                    <Typography sx={classes['step-desc']}>{t('sendMediaDesc.family')}</Typography>
                    {/* <hr style={classes['hr']} /> */}
                    <DisplayUser
                        checkbox={true}
                        selectAll
                        selectAllTitle={t('sendAllSenior.family') ?? ''}
                        checked={familyListSelected.length === familyList.length}
                        onCheck={(checked): void => {
                            if (checked === true) {
                                setFamilyListSelected(familyList.map((senior) => senior.id ?? ''));
                            } else {
                                setFamilyListSelected([]);
                            }
                        }}
                    />
                    {familyList.map((senior) => (
                        <DisplayUser
                            checkbox={true}
                            user={senior}
                            checked={familyListSelected.includes(senior.id ?? '')}
                            onCheck={(checked): void => {
                                if (checked === true) {
                                    setFamilyListSelected([...familyListSelected, senior.id ?? '']);
                                } else {
                                    setFamilyListSelected(
                                        familyListSelected.filter((id) => id !== senior.id)
                                    );
                                }
                            }}
                        />
                    ))}
                    <Button
                        pink
                        sx={{
                            position: 'absolute',
                            bottom: '1rem',
                            width: 'calc(100% - 2rem)',
                            zIndex: 100
                            // margin: '0 0.5rem'
                        }}
                        onClick={() => uploadVideoYt()}>
                        {t('Send')}
                    </Button>
                </div>
            )}
        </>
    );
}
