import { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import useDetectKeyboardOpen from 'use-detect-keyboard-open';

import Gift from './assets/gift.svg?react';
import House from './assets/house.svg?react';
import Medias from './assets/medias.svg?react';
import Puzzle from './assets/puzzle.svg?react';
import NavItem from '../NavItem';

import style from './style.module.css';

const determineActiveTab = (url: string) => {
    const computedUrl = url?.toLowerCase() || '';

    if (computedUrl.includes('/home')) {
        return 'HOME';
    }
    if (computedUrl.includes('/news')) {
        return 'NEWS';
    }
    if (computedUrl.includes('/games')) {
        return 'GAMES';
    }
    if (computedUrl.includes('/album')) {
        return 'MEDIAS';
    }
    return 'HOME';
};

interface NavBarProps extends WithTranslation { };

const NavBar = ({ t }: NavBarProps) => {
    const location = useLocation();
    const activeTab = determineActiveTab(location?.pathname);
    const isKeyboardOpen = useDetectKeyboardOpen();

    const useWindowWidth = () => {
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);

        useEffect(() => {
            const handleResize = () => setWindowWidth(window.innerWidth);
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }, []);

        return windowWidth;
    };

    const windowWidth = useWindowWidth();

    return (
        <>
            <nav className={`${style.nav} ${isKeyboardOpen ? style.isKeyboardOpen : ''}`}>
                <div className={style.items}>
                    <NavItem
                        className={style.navItem}
                        action={`/home`}
                        Icon={House}
                        title={t('Accueil')}
                        active={activeTab === 'HOME'}
                    />
                    <NavItem
                        className={style.navItem}
                        action={`/news`}
                        Icon={Gift}
                        title={windowWidth < 992 ? t('Nouveau') : t('Nouveautés')}
                        active={activeTab === 'NEWS'}
                    />
                    <NavItem
                        className={style.navItem}
                        action={`/games`}
                        Icon={Puzzle}
                        title={t('Jeux')}
                        active={activeTab === 'GAMES'}
                    />
                    <NavItem
                        className={style.navItem}
                        action={`/album`}
                        Icon={Medias}
                        title={windowWidth < 992 ? t('Médias') : t('Médiathèque')}
                        active={activeTab === 'MEDIAS'}
                    />
                </div>
            </nav>
                <div className={style.safeArea} />
        </>
    );
};

export default withTranslation()(NavBar);
