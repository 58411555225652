import React from 'react';
import iconPersonalVideo from '../../assets/iconPersonalVideo.svg';
import iconYoutubeVideo from '../../assets/iconYoutubeVideo.svg';
import { Typography } from '../Typography';
import { t } from 'i18next';

interface Props {
    time: string;
    type: 'personal' | 'youtube';
}

export function CardWatchTime({ time, type }: Props): JSX.Element {
    return (
        <div
            style={{
                width: '100%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.5rem',
                gap: '0.5rem',
                padding: '0.5rem',
                backgroundColor: type === 'personal' ? '#6B00AD1A' : '#FF43431A'
            }}>
            {type === 'personal' && (
                <img
                    src={iconPersonalVideo}
                    alt="iconPersonalVideo"
                    style={{
                        width: '1.25rem',
                        height: 'auto'
                    }}
                />
            )}
            {type === 'youtube' && (
                <img
                    src={iconYoutubeVideo}
                    alt="iconYoutubeVideo"
                    style={{
                        width: '1.25rem',
                        height: 'auto'
                    }}
                />
            )}
            <Typography
                variant="p"
                sx={{
                    color: '#0D224B',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    width: '100%',
                    lineHeight: '1.125rem'
                }}
                dangerouslySetInnerHTML={{
                    __html:
                        type === 'personal'
                            ? t('stats.watchTimePersonal', { time: time })
                            : t('stats.watchTimeYoutube', { time: time })
                }}
                children={undefined}></Typography>
        </div>
    );
}
