import React from 'react';

import styles from './styles.module.css';
import Heading from '../Heading';
import Einstein from './einstein.svg?react';
import Media from './media.svg?react';
import News from './news.svg?react';

interface Props {
    icon: 'einstein' | 'media' | 'news';
    children: React.ReactNode;
}

const EinsteinSpeaking: React.FC<Props> = ({ children, icon }) => {
    const Icon = {
        einstein: Einstein,
        media: Media,
        news: News,
    }[icon];

    return (
        <div className={styles.root}>
            <Icon className={styles.einstein} />
            <Heading center className={styles.text}>{children}</Heading>
        </div>
    );
};

export default EinsteinSpeaking;