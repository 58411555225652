import React from 'react';

import Einstein from './assets/einstein.svg?react';
import Arrow from './assets/arrow.svg?react';
import Button from '../../../components/Button/index';
import Next from './assets/next.svg?react';
import { useTranslation } from 'react-i18next';
import GameHeader from '../../../components/GameHeader';
import { VideoPlayer } from '../../../components/VideoPlayer';

import styles from './styles.module.css';
export interface FunfactProps {
    title: string;
    Picture?: React.FC<React.SVGProps<SVGSVGElement>> | string;
    color?: string;
    text?: string;
    onClose: () => void;
    mode?: 'album' | 'game';
    type?: 'IMAGE' | 'VIDEO' | 'YOUTUBE';
    onNext?: () => void;
    onPrev?: () => void;
}

const Funfact: React.FC<FunfactProps> = ({ text, title, color, Picture, onClose, mode = 'game', type = 'IMAGE', onNext, onPrev }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            {mode === 'album' && <GameHeader quitAction title={title} rtnAction={() => onClose()} />}
            <div className={styles.container}>
                {mode === 'album' && onNext && <Arrow className={[styles.arrow, styles.right].join(' ')} onClick={onNext} />}
                {Picture ?
                    <div className={styles.pictureContainer}>
                        {type === 'VIDEO' || type === 'YOUTUBE' ? <VideoPlayer sx={{ height: 'auto' }} url={Picture as string} />
                            : typeof Picture === 'string' ?
                                <div className={styles.picture} style={{ backgroundImage: `url(${Picture})` }} />
                                : <Picture
                                    className={styles.picture}
                                    style={{ '--fill-color': 'var(--color-primary)' } as React.CSSProperties}
                                />
                        }
                        {mode === 'game' && <Button
                            className={[styles.button, styles.floating].join(' ')}
                            label={t('Passer à la suite')}
                            action={onClose}
                        />}
                    </div>
                    : <>
                        <Button
                            className={[styles.button, styles.floating].join(' ')}
                            label={t('Passer à la suite')}
                            action={onClose}
                        />
                    </>
                }
                {text && <div className={[styles.textContainer, !Picture ? styles.textContainerPlain : ''].join(' ')}>
                    <Einstein
                        className={styles.einstein}
                        style={{ '--fill-color': color || 'var(--color-primary)' } as React.CSSProperties}
                    />
                    <div className={styles.text}>
                        {text}
                    </div>
                </div>}
                {mode === 'album' && onPrev && <Arrow className={[styles.arrow, !text ? styles.white : ''].join(' ')} onClick={onPrev} />}
                {mode === 'album' && (onNext || onPrev) &&
                    <div className={styles.mobileButton}>
                        {onPrev && <Button
                            className={styles.prevButton}
                            label={t('Précédent')}
                            theme='SECONDARY'
                            action={onPrev}
                            Icon={Next}
                        />}
                        {onNext && <Button
                            className={styles.nextButton}
                            label={t('Suivant')}
                            theme='PRIMARY'
                            action={onNext}
                            Icon={Next}
                        />}
                    </div>
                }
                {text && <div className={styles.gradient} />}
            </div>
        </div>
    );
};

export default Funfact;