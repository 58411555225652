/* eslint-disable import/no-default-export */
/* eslint-disable import/no-useless-path-segments */
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';

// import { Button } from '../../components';
import { Box, Button, Typography, styled } from '@mui/material';
import { createGrid } from '../../store';
import { Container } from './styles/index';
import { DeactiveModall } from '../ContainerModal';

import styles from './styles.module.css';
import { useGame } from '../../../../contexts/GameContext';

const ButtonContainer = styled(Box)({
    '@media (max-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column'
    },
    '@media (min-width: 598px)': {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row'
    }
});
const ModalButtons = styled(Button)(() => ({
    '@media (max-width: 598px)': {
        width: '100%',
        margin: '2% 0%'
    },
    '@media (min-width: 598px)': {
        width: '30%'
    }
}));

const NewGameButton: FC<{ gridSize: number }> = ({ gridSize }) => {
    const dispatch = useDispatch<Dispatch<Action>>();
    const { resetTimer } = useGame();
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const createNewGrid = useCallback(() => {
        // if (window.confirm('Are you sure you want to start a new game?')) dispatch(createGrid());
        dispatch(createGrid(gridSize));
        resetTimer();
    }, [dispatch]);

    return (
        // <Container>
        //     <Button onClick={createNewGrid}>{t('NewGame')}</Button>
        // </Container>
        <>
            <DeactiveModall
                isModelOpen={openModal}
                setModelOpen={setOpenModal}
                body={
                    <div
                        style={{
                            width: '90%',
                            margin: 'auto'
                            // display: openModal ? 'block' : 'none'
                        }}>
                        <Typography
                            variant="h3"
                            color="#374653"
                            sx={{ textAlign: 'center', fontSize: '1.75rem', fontWeight: '700' }}>
                            {t('Souhaitez-vous vraiment changer de grille ?')}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#374653"
                            sx={{ textAlign: 'center', fontSize: '1.25rem', fontWeight: '500' }}>
                            {t('L’avancement actuel ne sera pas enregistré.')}
                        </Typography>
                        <ButtonContainer
                            mt={3}
                            mb={6}
                            sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ModalButtons
                                variant="outlined"
                                sx={{
                                    border: '1px solid #374653',
                                    boxShadow: '0px 0px 0px 2px rgba(92, 102, 112, 0.6)',
                                    borderRadius: '12px',
                                    width: '30%',
                                    marginRight: '5%'
                                }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                onClick={(): void => setOpenModal(false)}>
                                <Typography
                                    variant="subtitle1"
                                    color="#374653"
                                    sx={{ textTransform: 'none' }}>
                                    {t('toCancel')}
                                </Typography>
                            </ModalButtons>
                            <ModalButtons
                                variant="outlined"
                                sx={{
                                    border: '1px solid #8B004B',
                                    boxShadow: '0px 0px 0px 2px rgba(208, 0, 112, 0.4)',
                                    borderRadius: '12px',
                                    width: '30%'
                                }}
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                onClick={() => {
                                    setOpenModal(false);
                                    createNewGrid();
                                }}>
                                <Typography
                                    variant="subtitle1"
                                    color="#D00070"
                                    sx={{ textTransform: 'none' }}>
                                    {t('yes')}
                                </Typography>
                            </ModalButtons>
                        </ButtonContainer>
                    </div>
                }
                circleIcon
                onCancel={(): void => {
                    setOpenModal(false);
                }}
            />
            <div onClick={() => setOpenModal(true)}
                className={styles.newGameButton}
            >
                {t('Nouvelle grille')}
            </div>
        </>
    );
};

export default NewGameButton;
