import Trophee from './assets/trophee.svg?react';

import styles from './styles.module.css';
import Heading from '../../components/Heading';
import { useHistory } from 'react-router';

export const NextGameWindow = ({ onClose, onReset }: { onClose: () => void, onReset?: () => void }) => {
    const history = useHistory();

    const handleOnClick = (path: string) => {
        history.push(path);
        onClose();
    };

    return (
        <div className={styles.root}>
            <div className={styles.tropheeHalo} />
            <Trophee className={styles.trophee} />
            <div className={styles.ctaContainer}>
                <div className={styles.actions} onClick={() => onReset && onReset()}>
                    <div className={styles.cta}>
                        <Heading center className={styles.label}>Refaire ce jeu</Heading>
                    </div>
                </div>
                <div className={styles.actions} onClick={() => handleOnClick('/games')}>
                    <div className={[styles.cta, styles.outlined].join(' ')}>
                        <Heading center className={styles.label}>Revenir à la liste des jeux</Heading>
                    </div>
                </div>
            </div>
            <div className={styles.actions} onClick={() => handleOnClick('/home')}>
                <Heading className={styles.close}>Faire une pause</Heading>
            </div>
        </div>
    );
}