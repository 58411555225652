import React, { useEffect } from 'react';
import statEvolMoreRevese from '../../assets/statEvolMoreRevese.svg';
import statEvolMore from '../../assets/statEvolMore.svg';
import statEvolLessRevese from '../../assets/statEvolLessRevese.svg';
import statEvolLess from '../../assets/statEvolLess.svg';
import statEvolLike from '../../assets/statEvolLike.svg';
import { Typography } from '../Typography';
import { t } from 'i18next';

interface Props {
    evolNb: string;
    reverse?: boolean;
}

export function StatEvol({ evolNb, reverse }: Props): JSX.Element {
    const [typeChange, setTypeChange] = React.useState<'up' | 'down' | 'equal'>('equal');

    useEffect(() => {
        if (reverse && evolNb.includes('+')) {
            setTypeChange('down');
        } else if (reverse && evolNb.includes('-')) {
            setTypeChange('up');
        } else if (evolNb.includes('+')) {
            setTypeChange('up');
        } else if (evolNb.includes('-')) {
            setTypeChange('down');
        } else {
            setTypeChange('equal');
        }
    }, [evolNb, reverse]);
    return (
        <div
            style={{
                width: '100%',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '0.5rem',
                gap: '0.5rem',
                padding: '0.5rem',
                backgroundColor: typeChange === 'up'
                ? '#F0F8E5'
                : typeChange === 'down'
                ? '#FFE9DD'
                : '#FFFEDF'
            }}>
            {typeChange === 'equal' && (
                <img
                    src={statEvolLike}
                    alt="statEvolMore"
                    style={{
                        width: '3rem',
                        height: '3rem'
                    }}
                />
            )}
            {typeChange === 'down' && (
                <img
                    src={ reverse ? statEvolMoreRevese : statEvolLess}
                    alt="statEvolLess"
                    style={{
                        width: '3rem',
                        height: '3rem'
                    }}
                />
            )}
            {typeChange === 'up' && (
                <img
                    src={reverse ? statEvolLessRevese : statEvolMore}
                    alt="statEvolMore"
                    style={{
                        width: '3rem',
                        height: '3rem'
                    }}
                />
            )}
            <Typography
                variant="p"
                sx={{
                    color: '#0D224B',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    width: '100%',
                    lineHeight: '1.125rem'
                }}
                dangerouslySetInnerHTML={{
                    __html: evolNb.includes('+')
                        ? t('stats.progression+', { p: evolNb })
                        : evolNb.includes('-')
                        ? t('stats.progression-', { p: evolNb })
                        : t('stats.progression0')
                }}
                children={undefined}></Typography>
        </div>
    );
}
