export interface Word {
    word: string;
    letters: string[];
    funFact?: string;
};

export interface DragItem {
    id: number
    top: number
    left: number
    letter: string
    handIdx?: number
    zIndex: number
    status: 'idle' | 'correct' | 'wrong' | 'glow' | 'placed'
}

export const levels: Record<number, Word[]> = {
    1: [
        { word: 'Air', letters: ['A', 'I', 'R'], funFact: `Le mélange de gaz qui forme l'atmosphère terrestre et que nous respirons. Saviez-vous que l'air est composé d'environ 78% d'azote, 21% d'oxygène et 1% d'autres gaz, dont le dioxyde de carbone et l'argon ?` },
        { word: 'Ami', letters: ['A', 'M', 'I'], funFact: `Une personne avec qui on entretient une relation d'affection et de confiance. Saviez-vous que les études montrent que les personnes ayant des amitiés solides ont tendance à être plus heureuses et en meilleure santé ?` },
        { word: 'Art', letters: ['A', 'R', 'T'], funFact: `L'expression créative de l'imagination, souvent sous forme de peinture, sculpture, musique, etc. Saviez-vous que la Joconde de Léonard de Vinci est l'un des tableaux les plus célèbres au monde, exposé au Musée du Louvre à Paris ?` },
        { word: 'Axe', letters: ['A', 'X', 'E'], funFact: `Une ligne de référence autour de laquelle quelque chose tourne ou est centré. Saviez-vous que la Terre tourne autour de son axe une fois par jour, créant ainsi le cycle du jour et de la nuit ?` },
        { word: 'Bar', letters: ['B', 'A', 'R'], funFact: `Un établissement où l'on sert des boissons alcoolisées. Saviez-vous que le plus vieux bar en activité du monde est le Sean's Bar en Irlande, qui existe depuis l'an 900 ?` },
        { word: 'Bas', letters: ['B', 'A', 'S'], funFact: `La partie inférieure de quelque chose ; aussi un vêtement porté sur les jambes. Saviez-vous que les bas en soie étaient considérés comme un symbole de luxe et d'élégance au 16ème siècle en Europe ?` },
        { word: 'Bec', letters: ['B', 'E', 'C'], funFact: `La partie dure et pointue de la bouche d'un oiseau. Saviez-vous que le bec du toucan est proportionnellement l'un des plus grands de tous les oiseaux, utilisé pour attraper des fruits et réguler la température corporelle ?` },
        { word: 'Bel', letters: ['B', 'E', 'L'], funFact: `Beau, agréable à regarder ou à écouter. Saviez-vous que le Taj Mahal, un mausolée en marbre blanc situé en Inde, est souvent considéré comme l'un des plus beaux édifices du monde ?` },
        { word: 'Beu', letters: ['B', 'E', 'U'], funFact: `Le bruit que font certains animaux, comme les moutons ou les chèvres. Saviez-vous que le bêlement d'une chèvre peut être entendu jusqu'à un kilomètre de distance ?` },
        { word: 'Bol', letters: ['B', 'O', 'L'], funFact: `Un récipient rond et profond utilisé pour manger ou servir de la nourriture. Saviez-vous que le mot "bol" est également utilisé dans le jargon du sport pour désigner un match très compétitif et intense ?` },
        { word: 'Box', letters: ['B', 'O', 'X'], funFact: `Un espace confiné ou un contenant ; aussi une forme de sport de combat. Saviez-vous que la boxe est l'un des sports les plus anciens, remontant à plus de 3 000 ans en Égypte ancienne ?` },
        { word: 'Bus', letters: ['B', 'U', 'S'], funFact: `Un véhicule de transport en commun. Saviez-vous que le premier bus à impériale a été mis en service à Londres en 1829 par George Shillibeer, offrant une alternative au transport en calèche ?`, },
        { word: 'But', letters: ['B', 'U', 'T'], funFact: `L'objectif à atteindre dans un jeu ou une compétition. Saviez-vous que le but le plus rapide jamais marqué lors d'un match de football professionnel a été inscrit par Hakan Şükür, joueur turc, en seulement 11 secondes ?` },
        { word: 'Cal', letters: ['C', 'A', 'L'], funFact: `Une unité de mesure utilisée pour exprimer la chaleur ou l'énergie. Saviez-vous que le mot "cal" est une abréviation de "calorie", une unité de mesure de l'énergie utilisée dans la nutrition et la physique ?` },
        { word: 'Cap', letters: ['C', 'A', 'P'], funFact: `Un vêtement couvrant la tête, souvent avec une visière ; aussi un point de repère sur la côte maritime. Saviez-vous que le Cap de Bonne-Espérance en Afrique du Sud était autrefois appelé le Cap des Tempêtes en raison de ses eaux dangereuses ?` },
        { word: 'Car', letters: ['C', 'A', 'R'], funFact: `Un véhicule de transport collectif ; aussi une conjonction causale. Saviez-vous que le premier service régulier d'autocar a été lancé en France en 1828 par Stanislas Baudry, reliant Nantes à Saint-Nazaire ?` },
        { word: 'Cas', letters: ['C', 'A', 'S'], funFact: `Une circonstance particulière ou une situation. Saviez-vous que "cas d'école" est une expression utilisée pour décrire une situation typique, souvent utilisée dans l'enseignement ?` },
        { word: 'Cru', letters: ['C', 'R', 'U'], funFact: `Non cuit, souvent utilisé pour décrire des aliments. Saviez-vous que certains restaurants proposent des plats à base de cuisine crue, tels que des sushis ou des carpaccios, pour les amateurs de saveurs authentiques ?` },
        { word: 'Dos', letters: ['D', 'O', 'S'], funFact: `La partie postérieure du corps humain ou d'un animal. Saviez-vous que les dauphins ont une nageoire dorsale distinctive qui les aide à stabiliser leur mouvement dans l'eau ?` },
        { word: 'Duo', letters: ['D', 'U', 'O'], funFact: `Un groupe de deux personnes travaillant ou se produisant ensemble. Saviez-vous que de nombreux duos musicaux célèbres, comme Simon & Garfunkel ou Daft Punk, ont connu un grand succès grâce à leur collaboration artistique ?` },
        { word: 'Eau', letters: ['E', 'A', 'U'], funFact: `Une substance liquide, transparente, inodore et insipide, essentielle à la vie. Saviez-vous que l'eau représente environ 60% du poids corporel d'un adulte humain ?` },
        { word: 'Fer', letters: ['F', 'E', 'R'], funFact: `Un métal commun, élément chimique de symbole Fe. Saviez-vous que le fer est l'un des éléments les plus abondants sur Terre, et qu'il constitue le noyau interne de notre planète ?` },
        { word: 'Feu', letters: ['F', 'E', 'U'], funFact: `La combustion rapide de matériaux, produisant chaleur, lumière et fumée. Saviez-vous que la découverte du feu par les premiers humains a été un tournant majeur dans l'évolution, permettant la cuisson des aliments et la protection contre les prédateurs ?` },
        { word: 'Fin', letters: ['F', 'I', 'N'], funFact: `La conclusion ou la terminaison de quelque chose. Saviez-vous que le mot "fin" est utilisé à la fois en français et en anglais pour indiquer la fin d'une histoire ou d'un film ?` },
        { word: 'Fou', letters: ['F', 'O', 'U'], funFact: `Une personne atteinte de folie ou un terme utilisé pour décrire quelque chose d'irrationnel. Saviez-vous que "fou" est aussi une pièce du jeu d'échecs, appelée "bishop" en anglais, se déplaçant en diagonale ?` },
        { word: 'Gaz', letters: ['G', 'A', 'Z'], funFact: `Un élément chimique présent dans l'atmosphère, tel que l'oxygène ou l'azote. Saviez-vous que le gaz naturel est principalement composé de méthane et est utilisé comme source d'énergie dans de nombreux foyers et industries à travers le monde ?` },
        { word: 'Gel', letters: ['G', 'E', 'L'], funFact: `L'état solide de l'eau à basse température. Ou un produit utilisé pour coiffer les cheveux et leur donner une apparence fixe. Saviez-vous que le gel pour les cheveux a été popularisé dans les années 1920 avec l'apparition du style de cheveux "à la gomina" ?` },
        { word: 'Hic', letters: ['H', 'I', 'C'], funFact: `Un léger problème ou une difficulté. Saviez-vous que l'expression "hic et nunc" est une locution latine signifiant "ici et maintenant", utilisée pour désigner le moment présent ?` },
        { word: 'Jam', letters: ['J', 'A', 'M'], funFact: `Séance musicale improvisée basée sur des standards de jazz à laquelle peuvent se joindre différents musiciens. Saviez-vous que les musiciens de jazz improvisent souvent en jouant ensemble dans une séance de "jam session" ?` },
        { word: 'Jet', letters: ['J', 'E', 'T'], funFact: `Un avion à réaction ou un flux rapide de liquide ou de gaz. Saviez-vous que le premier avion à réaction commercial, le De Havilland Comet, a effectué son vol inaugural en 1952 ?` },
        { word: 'Jeu', letters: ['J', 'E', 'U'], funFact: `Une activité récréative ou un divertissement. Saviez-vous que le jeu d'échecs est l'un des jeux de stratégie les plus anciens et les plus populaires au monde, pratiqué par des millions de personnes ?` },
        { word: 'Lac', letters: ['L', 'A', 'C'], funFact: `Une grande étendue d'eau entourée de terres. Saviez-vous que le lac Baïkal en Sibérie est le lac le plus profond du monde, avec une profondeur maximale de 1 642 mètres ?`, },
        { word: 'Les', letters: ['L', 'E', 'S'], funFact: `Article défini pluriel en français. Saviez-vous que "Les Misérables" est un célèbre roman de Victor Hugo, adapté en comédie musicale et en plusieurs films ?` },
        { word: 'Lis', letters: ['L', 'I', 'S'], funFact: `La deuxième personne du singulier de l'indicatif présent du verbe "lire". Saviez-vous que les Égyptiens de l'Antiquité utilisaient des hiéroglyphes pour écrire, gravant souvent des images de fleurs de lotus et de papyrus, qui symbolisaient la vie et la renaissance ?` },
        { word: 'Lit', letters: ['L', 'I', 'T'], funFact: `Un meuble utilisé pour dormir, généralement équipé d'un matelas et d'un sommier. Saviez-vous que le lit le plus grand du monde mesure 26,5 mètres de long et a été construit en 2011 lors du festival annuel de la bière de la ville de Farnham, en Angleterre ?` },
        { word: 'Lot', letters: ['L', 'O', 'T'], funFact: `Un ensemble d'objets ou une part de quelque chose. Saviez-vous que le Lot est également un département en France, connu pour ses paysages pittoresques et ses sites historiques ?` },
        { word: 'Lot', letters: ['L', 'O', 'T'], funFact: `Une part, un ensemble ou un destin. Saviez-vous que le terme "lot" est souvent utilisé dans les ventes aux enchères pour désigner un groupe d'articles vendus ensemble ?` },
        { word: 'Mer', letters: ['M', 'E', 'R'], funFact: `Une grande étendue d'eau salée qui couvre la majeure partie de la surface terrestre. Saviez-vous que la mer Morte, située entre Israël et la Jordanie, est l'un des plans d'eau les plus salés au monde, ce qui permet aux baigneurs de flotter facilement ?` },
        { word: 'Mie', letters: ['M', 'I', 'E'], funFact: `La partie centrale et molle du pain. Saviez-vous que la mie de pain est souvent considérée comme la meilleure partie, en particulier dans les pains frais et moelleux ?` },
        { word: 'Mot', letters: ['M', 'O', 'T'], funFact: `Un ensemble de lettres ou de sons qui a une signification particulière et qui est utilisé pour communiquer. Saviez-vous que le mot le plus long en anglais est "pneumonoultramicroscopicsilicovolcanoconiosis", qui fait référence à une maladie pulmonaire causée par l'inhalation de poussière de silice ?` },
        { word: 'Mur', letters: ['M', 'U', 'R'], funFact: `Une structure verticale qui divise ou soutient. Saviez-vous que le Mur de Berlin, construit en 1961 pour diviser l'Est et l'Ouest de la ville, est devenu un symbole de la guerre froide avant d'être démoli en 1989 ?` },
        { word: 'Net', letters: ['N', 'E', 'T'], funFact: `Propre et clair ; aussi une abréviation pour "Internet". Saviez-vous que le terme "net" peut également signifier "clair et précis", comme dans l'expression "réponse nette et précise" ?` },
        { word: 'Nid', letters: ['N', 'I', 'D'], funFact: `Le domicile d'un oiseau ou d'un insecte. Ou une petite quantité de quelque chose. Saviez-vous que l'expression "un nid de guêpes" est souvent utilisée pour décrire une situation délicate ou dangereuse qui peut devenir incontrôlable si elle est dérangée ?` },
        { word: 'Non', letters: ['N', 'O', 'N'], funFact: `Une négation ou un refus. Saviez-vous que le mot "non" est l'un des premiers mots que les enfants apprennent à utiliser pour exprimer leur désaccord ?` },
        { word: 'Nou', letters: ['N', 'O', 'U'], funFact: `Une forme de verbe "nouer". Saviez-vous que les nœuds marins, tels que le nœud de chaise ou le nœud de cabestan, sont utilisés depuis des siècles par les marins pour attacher des cordes et sécuriser des objets à bord des navires ?` },
        { word: 'Oie', letters: ['O', 'I', 'E'], funFact: `Un grand oiseau aquatique, souvent domestiqué pour sa viande et ses plumes. Saviez-vous que les oies sauvages migrent sur de longues distances, souvent en formations en V pour économiser de l'énergie ?` },
        { word: 'Oui', letters: ['O', 'U', 'I'], funFact: `Une affirmation ou une approbation. Saviez-vous que dire "oui" à de nouvelles expériences peut ouvrir des portes et vous permettre de découvrir des aspects de la vie que vous n'auriez jamais imaginés ? Dire "oui" peut être bénéfique pour la santé mentale, car cela encourage une attitude positive et ouvre de nouvelles possibilités ?` },
        { word: 'Pas', letters: ['P', 'A', 'S'], funFact: `Un mouvement d'un pied devant l'autre. Saviez-vous que le pas de danse "moonwalk" popularisé par Michael Jackson est devenu une icône de la culture pop dans les années 1980 ?` },
        { word: 'Pic', letters: ['P', 'I', 'C'], funFact: `Une pointe ou un sommet ; aussi un outil utilisé pour creuser. Saviez-vous que le Mont Everest est le plus haut pic du monde, culminant à 8 848 mètres au-dessus du niveau de la mer ?` },
        { word: 'Pif', letters: ['P', 'I', 'F'], funFact: `Le nez. Saviez-vous que l'expression "au pif" signifie faire quelque chose au hasard ou sans réfléchir ?` },
        { word: 'Pou', letters: ['P', 'O', 'U'], funFact: `Un insecte parasite qui infeste souvent les cheveux ou la peau des humains et des animaux. Saviez-vous que les poux ont existé depuis des millions d'années et ont été retrouvés dans des momies égyptiennes datant de 3 000 ans ?` },
        { word: 'Put', letters: ['P', 'U', 'T'], funFact: `Le participe passé du verbe "put". Saviez-vous que "put" est souvent utilisé dans le langage financier pour décrire une option de vente, permettant à un investisseur de vendre un actif à un prix spécifié à une date ultérieure ?` },
        { word: 'Rat', letters: ['R', 'A', 'T'], funFact: `Un petit rongeur omnivore, souvent considéré comme nuisible. Saviez-vous que les rats sont des animaux très intelligents et sociaux, capables de résoudre des problèmes complexes et de faire preuve de compassion envers leurs congénères ?` },
        { word: 'Riz', letters: ['R', 'I', 'Z'], funFact: `Une céréale cultivée principalement dans les régions tropicales et subtropicales. Saviez-vous que le riz est l'aliment de base pour plus de la moitié de la population mondiale, avec plus de 40 000 variétés différentes cultivées à travers le monde ?` },
        { word: 'Roi', letters: ['R', 'O', 'I'], funFact: `Un souverain masculin d'un royaume. Saviez-vous que Louis XIV de France, connu sous le nom de Roi Soleil, a régné pendant 72 ans, ce qui en fait l'un des plus longs règnes de l'histoire européenne ?` },
        { word: 'Rue', letters: ['R', 'U', 'E'], funFact: `Une voie publique bordée de bâtiments dans une ville ou un village. Saviez-vous que la rue Lombard à San Francisco est célèbre pour être la rue la plus sinueuse du monde ? La rue la plus étroite du monde se trouve à Reutlingen, en Allemagne, et mesure seulement 31 centimètres de large à son point le plus étroit.`, },
        { word: 'Sac', letters: ['S', 'A', 'C'], funFact: `Un contenant souple utilisé pour transporter des objets. Saviez-vous que le sac à main le plus cher au monde est le sac "Mouawad 1001 Nuits Diamond Purse", incrusté de 4 517 diamants et valant plus de 3,8 millions de dollars ? Le sac est également une ancienne unité de mesure utilisée pour peser des marchandises telles que le grain, le sucre ou le café dans les commerces. Le mot "sac" est également utilisé dans le jargon militaire pour désigner une mission difficile ou dangereuse.` },
        { word: 'Sec', letters: ['S', 'E', 'C'], funFact: `Qui ne contient pas d'humidité. Saviez-vous que le désert d'Atacama au Chili est l'endroit le plus sec sur Terre, avec des endroits n'ayant jamais enregistré de précipitations ?` },
        { word: 'Sel', letters: ['S', 'E', 'L'], funFact: `Un composé chimique couramment utilisé pour assaisonner les aliments. Saviez-vous que le mot "salarium" en latin, qui signifie "salaire", est dérivé de "sel", car dans l'Antiquité les soldats romains étaient souvent payés en sel ?` },
        { word: 'Sol', letters: ['S', 'O', 'L'], funFact: `La surface de la Terre. Saviez-vous que le sol contient des milliards de micro-organismes par mètre carré, jouant un rôle crucial dans la décomposition des matières organiques et le cycle des nutriments ?` },
        { word: 'Son', letters: ['S', 'O', 'N'], funFact: `Les vibrations perçues par l'oreille comme un son. Saviez-vous que les animaux utilisent souvent le son pour communiquer entre eux, que ce soit pour trouver un partenaire, avertir d'un danger ou marquer leur territoire ?` },
        { word: 'Tas', letters: ['T', 'A', 'S'], funFact: `Une pile ou un amas d'objets. Saviez-vous que l'expression "faire un tas" peut également signifier économiser de l'argent ou accumuler des ressources ?` },
        { word: 'Thé', letters: ['T', 'H', 'É'], funFact: `Une boisson chaude ou froide infusée à partir des feuilles de thé. Saviez-vous que le thé est la deuxième boisson la plus consommée au monde après l'eau, et qu'il existe des centaines de variétés de thé avec des saveurs et des arômes uniques ?` },
        { word: 'Vin', letters: ['V', 'I', 'N'], funFact: `Une boisson alcoolisée produite à partir de raisins fermentés. Saviez-vous que le vin le plus ancien jamais découvert a été trouvé dans une cave en Arménie et date d'environ 6 000 ans ?`, },
        { word: 'Vue', letters: ['V', 'U', 'E'], funFact: `La capacité de percevoir l'environnement par les yeux. Saviez-vous que les aigles ont une vision environ 4 à 8 fois plus nette que celle des humains, leur permettant de repérer des proies à des kilomètres de distance ?` },
        { word: 'Zip', letters: ['Z', 'I', 'P'], funFact: `Un dispositif de fermeture à glissière ; aussi un fichier compressé en informatique. Saviez-vous que la fermeture éclair a été inventée par Whitcomb Judson en 1893, mais n'a gagné en popularité qu'avec les améliorations apportées par Gideon Sundback en 1913 ?` },
    ],
    2: [{
        word: 'Moto',
        letters: ['M', 'O', 'T', 'O'],
        funFact: `Un véhicule à deux roues propulsé par un moteur. Saviez-vous que la première moto à essence a été inventée par l'ingénieur allemand Gottlieb Daimler en 1885? Cette moto, surnommée la "Reitwagen" (ou "machine à chevaucher"), était équipée d'un moteur à combustion interne.`,
    }, {
        word: 'Lune',
        letters: ['L', 'U', 'N', 'E'],
        funFact: `Le satellite naturel de la Terre, en orbite autour de celle-ci. Saviez-vous que la Lune s'éloigne progressivement de la Terre à raison d'environ 3,8 centimètres par an? Cela signifie qu'il y a des millions d'années, la Lune était beaucoup plus proche de notre planète et semblait donc beaucoup plus grande dans le ciel.`,
    }, {
        word: 'Vent',
        letters: ['V', 'E', 'N', 'T'],
        funFact: `Le déplacement de l'air d'une région à une autre, généralement causé par des différences de pression atmosphérique. Saviez-vous que le vent le plus fort jamais enregistré sur Terre a soufflé à 408 km/h (253 mph) le 10 avril 1996 sur le mont Washington, dans le New Hampshire, aux États-Unis?`,
    }, {
        word: 'Port',
        letters: ['P', 'O', 'R', 'T'],
        funFact: `Un endroit sur la côte où les navires peuvent accoster pour charger et décharger des marchandises ou des passagers. Saviez-vous que le port de Shanghai en Chine est actuellement le plus grand port du monde en termes de volume de marchandises? Il a dépassé le port de Singapour en 2010.`,
    }, {
        word: 'Rire',
        letters: ['R', 'I', 'R', 'E'],
        funFact: `Une expression joyeuse du visage et des sons produits par la gorge souvent en réponse à quelque chose de comique ou de plaisant. Saviez-vous que selon les recherches, le rire peut réduire le stress, améliorer la fonction cardiovasculaire et renforcer le système immunitaire? Il est souvent considéré comme un élément clé du bien-être émotionnel et physique.`,
    }],
    3: [{
        word: 'Chien',
        letters: ['C', 'H', 'I', 'E', 'N'],
        funFact: `Un mammifère domestique courant, souvent gardien ou compagnon de l'homme. Saviez-vous que le chien est souvent considéré comme le meilleur ami de l'homme en raison de sa loyauté et de sa nature sociable? Cette relation entre l'homme et le chien remonte à des milliers d'années.`,
    }, {
        word: 'Pomme',
        letters: ['P', 'O', 'M', 'M', 'E'],
        funFact: `Le fruit du pommier, généralement rouge, vert ou jaune, avec une peau lisse et une chair croquante et juteuse. Saviez-vous que la pomme est l'un des fruits les plus consommés au monde? Il existe des milliers de variétés de pommes, chacune avec son propre goût, texture et couleur.`,
    }, {
        word: 'Livre',
        letters: ['L', 'I', 'V', 'R', 'E'],
        funFact: `Un ensemble de feuilles de papier, parchemin ou autres matériaux reliées ensemble, souvent imprimées ou manuscrites, et formant un volume. Saviez-vous que le livre imprimé a été inventé en Chine par Bi Sheng vers 1040? Cela a révolutionné la diffusion des connaissances et des idées à travers le monde.`,
    }, {
        word: 'Sucre',
        letters: ['S', 'U', 'C', 'R', 'E'],
        funFact: `Un glucide cristallin doux, soluble dans l'eau, extrait de diverses plantes, principalement de la canne à sucre et de la betterave à sucre, et utilisé dans la préparation des aliments et des boissons. Saviez-vous que le sucre était autrefois considéré comme un produit de luxe réservé aux riches? Il était si précieux qu'il était parfois enfermé dans des coffres-forts.`,
    }, {
        word: 'Radio',
        letters: ['R', 'A', 'D', 'I', 'O'],
        funFact: `Un moyen de communication utilisant des ondes électromagnétiques pour transmettre des informations, généralement sous forme de sons. Saviez-vous que la première transmission radio a été réalisée par Guglielmo Marconi en 1895? Il a réussi à envoyer un signal radio sur une distance d'environ 1,5 kilomètre (environ 1 mile).`
    }, {
        word: 'Atome',
        letters: ['A', 'T', 'O', 'M', 'E'],
        funFact: `Savais-tu que l'atome est en grande partie composée de vide ? Si tu agrandissais un atome à la taille d'un stade de football, le noyau serait juste une petite bille au centre, et les électrons tourneraient autour à des kilomètres de distance. C'est un monde microscopique incroyablement spacieux et mystérieux !`,
    }, {
        word: 'Echec',
        letters: ['E', 'C', 'H', 'E', 'C'],
        funFact: `Savais-tu que le plus long match d'échecs de l'histoire a duré 20 heures et 15 minutes ? C'était lors d'une partie entre Ivan Nikolić et Goran Arsović en 1989. Ils ont finalement fait match nul.`,
    }, {
        word: 'Lampe',
        letters: ['L', 'A', 'M', 'P', 'E'],
        funFact: `Savais-tu que la première lampe électrique à incandescence a été inventée par Thomas Edison en 1879 ? Cependant, le modèle de lampe le plus durable est la Centennial Light, située à Livermore, en Californie, qui brûle depuis 1901. C'est la plus ancienne lampe électrique encore en fonctionnement et elle a été reconnue par le Livre Guinness des records. Imagine combien de conversations et de moments elle a illuminés au fil des ans !`,
    }, {
        word: 'Avion',
        letters: ['A', 'V', 'I', 'O', 'N'],
        funFact: `Le vol commercial le plus long sans escale est opéré par Singapore Airlines entre Singapour et Newark, New Jersey. Ce vol traverse une distance de plus de 15 000 kilomètres et dure environ 18 heures et 30 minutes. C'est un véritable marathon dans les airs, mais les passagers ont droit à un confort de première classe pour rendre le voyage plus agréable !`,
    }],
    4: [{
        word: 'Banane',
        letters: ['B', 'A', 'N', 'A', 'N', 'E'],
        funFact: `Le fruit du bananier, avec une peau jaune et une chair douce et crémeuse. Saviez-vous que la banane est le quatrième produit alimentaire le plus important au monde après le riz, le blé et le lait? Elle est cultivée dans plus de 130 pays.`,
    }, {
        word: 'Diable',
        letters: ['D', 'I', 'A', 'B', 'L', 'E'],
        funFact: `Dans de nombreuses traditions religieuses et mythologiques, une figure maléfique associée au mal ou au diable. Saviez-vous que dans la mythologie grecque, le dieu Pan, souvent représenté avec des cornes et des pieds de bouc, est parfois considéré comme une incarnation du diable dans la tradition chrétienne ?`,
    }, {
        word: 'Soleil',
        letters: ['S', 'O', 'L', 'E', 'I', 'L'],
        funFact: `L'étoile au centre de notre système solaire, autour de laquelle la Terre et d'autres planètes orbitent. Saviez-vous que le Soleil représente environ 99,8% de la masse totale de notre système solaire? C'est une énorme sphère de gaz chaud qui produit de la lumière et de la chaleur.`,
    }, {
        word: 'Orange',
        letters: ['O', 'R', 'A', 'N', 'G', 'E'],
        funFact: `Le fruit de l'oranger, avec une peau épaisse et rugueuse et une chair juteuse et sucrée. Saviez-vous que le nom de la couleur "orange" provient du nom du fruit? Auparavant, la couleur était simplement décrite comme une nuance de rouge ou de jaune.`,
    }, {
        word: 'Écoles',
        letters: ['É', 'C', 'O', 'L', 'E', 'S'],
        funFact: `Une institution où les enfants et les jeunes adultes reçoivent une éducation formelle.Saviez-vous que la plus ancienne école encore en fonctionnement est l'école King's School à Canterbury, en Angleterre? Fondée en 597, cette école a une histoire qui remonte à plus de 1400 ans.`
    }, {
        word: 'Hockey',
        letters: ['H', 'O', 'C', 'K', 'E', 'Y'],
        funFact: `Savais-tu que le hockey sur glace est né en Angleterre au début du XIXe siècle ? Les premières règles officielles ont été établies en 1879 à Montréal, au Canada. Mais voici le fait amusant : le plus long match de hockey sur glace a duré 176 minutes et 30 secondes ! C'était en 1936 entre les équipes de Port Arthur et de Fort William en Ontario, Canada.`,
    }],
    5: [{
        word: 'Bonheur',
        letters: ['B', 'O', 'N', 'H', 'E', 'U', 'R'],
        funFact: `Un état de bien-être et de satisfaction personnelle. Saviez-vous que selon des études, le bonheur est souvent associé à des interactions sociales positives, à des expériences enrichissantes et à un sentiment de réalisation personnelle plutôt qu'à des possessions matérielles?`,
    }, {
        word: 'Chanson',
        letters: ['C', 'H', 'A', 'N', 'S', 'O', 'N'],
        funFact: `Une composition musicale généralement chantée par un ou plusieurs chanteurs. Saviez-vous que la chanson la plus vendue de tous les temps est "White Christmas" de Bing Crosby? Sortie en 1942, elle a vendu plus de 50 millions d'exemplaires dans le monde entier.`,
    }, {
        word: 'Nuageux',
        letters: ['N', 'U', 'A', 'G', 'E', 'U', 'X'],
        funFact: `Un état de temps où le ciel est couvert de nuages. Saviez-vous que les nuages ​​sont formés de minuscules gouttelettes d'eau ou de cristaux de glace en suspension dans l'atmosphère? Leur forme et leur type dépendent de facteurs tels que l'humidité, la température et l'altitude.`,
    }, {
        word: 'Biscuit',
        letters: ['B', 'I', 'S', 'C', 'U', 'I', 'T'],
        funFact: `Un petit gâteau sec et friable, souvent sucré et parfois aromatisé. Saviez-vous que le mot "biscuit" vient du latin "bis coctus", qui signifie "cuit deux fois"? Traditionnellement, les biscuits étaient cuits deux fois pour les rendre plus croustillants et durables.`,
    }, {
        word: 'Journal',
        letters: ['J', 'O', 'U', 'R', 'N', 'A', 'L'],
        funFact: `Une publication périodique contenant des nouvelles, des commentaires, des articles et d'autres informations. Saviez-vous que le premier journal imprimé régulièrement était la ""Gazette"" publiée en Angleterre en 1665? Depuis lors, les journaux sont devenus une source majeure d'information dans le monde entier.`,
    }],
    6: [{
        word: 'Écologie',
        letters: ['É', 'C', 'O', 'L', 'O', 'G', 'I', 'E'],
        funFact: `La branche de la biologie qui étudie les interactions entre les organismes et leur environnement. Saviez-vous que le terme "écologie" a été introduit en 1866 par le biologiste allemand Ernst Haeckel? Il l'a dérivé du grec "oikos", qui signifie "maison" ou "foyer", et "logie", qui signifie "étude de".`,
    }, {
        word: 'Coquille',
        letters: ['C', 'O', 'Q', 'U', 'I', 'L', 'L', 'E'],
        funFact: `La coquille extérieure d'un œuf ou le squelette externe d'un mollusque. Saviez-vous que les coquilles d'œufs sont principalement composées de carbonate de calcium? Elles sont relativement solides mais peuvent être fragiles en fonction de l'espèce d'oiseau.`,
    }, {
        word: 'Paysanne',
        letters: ['P', 'A', 'Y', 'S', 'A', 'N', 'N', 'E'],
        funFact: `Une femme travaillant dans l'agriculture, généralement dans un cadre rural. Saviez-vous que les paysans et paysannes ont joué un rôle essentiel dans l'histoire de l'agriculture? Leurs connaissances traditionnelles ont souvent été transmises de génération en génération.`,
    }, {
        word: 'Chausson',
        letters: ['C', 'H', 'A', 'U', 'S', 'S', 'O', 'N'],
        funFact: `Un petit soulier ou une chaussure légère, souvent portée à l'intérieur. Saviez-vous que les chaussons sont parfois appelés "pantoufles"? Ce terme provient du français "pantoufle", qui désignait à l'origine une chaussure souple sans talon portée à l'intérieur.`,
    }, {
        word: 'Amalgame',
        letters: ['A', 'M', 'A', 'L', 'G', 'A', 'M', 'E'],
        funFact: `Une combinaison ou un mélange de différentes choses. Saviez-vous que l'amalgame dentaire, utilisé pour remplir les cavités, est composé principalement de mercure et d'autres métaux comme l'argent, le zinc et le cuivre?`
    }, {
        word: 'Peinture',
        letters: ['P', 'E', 'I', 'N', 'T', 'U', 'R', 'E'],
        funFact: `L'une des peintures les plus célèbres au monde, la Mona Lisa de Leonardo da Vinci, a été volée au Louvre en 1911. Cela a déclenché une chasse à l'homme internationale. Elle a été retrouvée deux ans plus tard en Italie. Cependant, avant son retour au musée, elle a fait une tournée mondiale, attirant encore plus d'attention et de fascination, ce qui contribua à accroître sa renommée.`,
    }, {
        word: 'Histoire',
        letters: ['H', 'I', 'S', 'T', 'O', 'I', 'R', 'E'],
        funFact: `Le Panthéon de Rome, construit entre 118 et 125 après J.-C., est célèbre pour son impressionnante coupole de béton non renforcé, qui est la plus grande au monde de cette époque. Ce qui rend encore plus fascinant, c'est que le diamètre du dôme est égal à la hauteur du bâtiment, créant une symétrie parfaite. De plus, le Panthéon est l'un des rares bâtiments de l'Antiquité à avoir été continuellement en usage depuis son achèvement, servant désormais de lieu de culte chrétien.`,
    }, {
        word: 'Montagne',
        letters: ['M', 'O', 'N', 'T', 'A', 'G', 'N', 'E'],
        funFact: `Savais-tu que l'Everest continue de grandir chaque année ? En raison de l'activité tectonique, il gagne environ 4 millimètres de hauteur par an. Cela signifie que chaque année, il devient un peu plus haut que le plus haut sommet du monde !`,
    }],
    7: [{
        word: 'Parapluie',
        letters: ['P', 'A', 'R', 'A', 'P', 'L', 'U', 'I', 'E'],
        funFact: `Un dispositif portatif utilisé pour se protéger de la pluie ou du soleil, composé d'un auvent maintenu par des baleines ou des tiges pliables fixées à un manche. Saviez-vous que les premiers parapluies étaient utilisés il y a des milliers d'années en Égypte, en Chine et dans d'autres régions du monde? Ils étaient souvent fabriqués à partir de feuilles de palmier ou de papier huilé.`,
    }, {
        word: 'Téléphone',
        letters: ['T', 'É', 'L', 'É', 'P', 'H', 'O', 'N', 'E'],
        funFact: `Un appareil de communication électronique permettant de transmettre la voix ou d'autres données à distance. Saviez-vous que le premier brevet pour un appareil téléphonique a été déposé par l'inventeur américain Alexander Graham Bell en 1876? Cela a marqué le début de l'ère des télécommunications.`,
    }, {
        word: 'Universel',
        letters: ['U', 'N', 'I', 'V', 'E', 'R', 'S', 'E', 'L'],
        funFact: `Qui concerne ou s'applique à tout le monde ou à tout ce qui existe. Saviez-vous que le concept d'universalité a été discuté par de nombreux philosophes au fil des siècles? Des penseurs comme Platon et Kant ont exploré l'idée d'idées ou de principes universels.`,
    }, {
        word: 'Tradition',
        letters: ['T', 'R', 'A', 'D', 'I', 'T', 'I', 'O', 'N'],
        funFact: `Une pratique, une croyance ou un événement transmis de génération en génération. Saviez-vous que la tradition du repas de Noël remonte à l'Antiquité romaine? Les Saturnales, une fête en l'honneur du dieu Saturne, étaient célébrées avec des festins et des échanges de cadeaux, ce qui a contribué à l'évolution des célébrations de Noël telles que nous les connaissons aujourd'hui.`,
    }, {
        word: 'Inattendu',
        letters: ['I', 'N', 'A', 'T', 'T', 'E', 'N', 'D', 'U'],
        funFact: `Qui survient de manière imprévue ou surprenante. Saviez-vous que la découverte du radium par Marie Curie en 1898 a été totalement inattendue? Elle a remarqué que des sels d'uranium émettaient une lumière fluorescente, un phénomène inconnu jusque-là, ce qui a finalement conduit à la découverte du radium.`,
    }],
    8: [{
        word: 'Architecte',
        letters: ['A', 'R', 'C', 'H', 'I', 'T', 'E', 'C', 'T', 'E'],
        funFact: `Une personne qualifiée pour concevoir des bâtiments et superviser leur construction. Saviez-vous que l'un des architectes les plus célèbres de tous les temps était Léonard de Vinci? Bien qu'il soit plus connu pour ses travaux en peinture et en science, de Vinci a également conçu des plans pour de nombreux bâtiments et structures innovantes.`,
    }, {
        word: 'Résolution',
        letters: ['R', 'É', 'S', 'O', 'L', 'U', 'T', 'I', 'O', 'N'],
        funFact: `Une décision formelle prise lors d'une réunion ou d'une assemblée. Saviez-vous que la tradition des résolutions du Nouvel An remonte à l'ancienne Babylone, il y a plus de 4000 ans? Les Babyloniens célébraient le nouvel an en promettant de rembourser leurs dettes et de rendre les objets empruntés.`,
    }, {
        word: 'Électrique',
        letters: ['É', 'L', 'E', 'C', 'T', 'R', 'I', 'Q', 'U', 'E'],
        funFact: `Relatif à l'électricité ou fonctionnant à l'électricité. Saviez-vous que la première utilisation commerciale de l'électricité remonte à 1879 lorsque Thomas Edison a mis en service la première centrale électrique à New York? Cela a marqué le début de l'ère de l'électricité à grande échelle.`,
    }, {
        word: 'Ressources',
        letters: ['R', 'E', 'S', 'S', 'O', 'U', 'R', 'C', 'E', 'S'],
        funFact: `Les moyens disponibles pour être utilisés ou exploités dans le but de produire quelque chose. Saviez-vous que certaines ressources naturelles, comme le pétrole et le charbon, ont été formées il y a des millions d'années à partir de la matière organique en décomposition? Leur utilisation massive a des implications majeures pour l'environnement et l'économie mondiale.`,
    }, {
        word: 'Atmosphère',
        letters: ['A', 'T', 'M', 'O', 'S', 'P', 'H', 'È', 'R', 'E'],
        funFact: `La couche de gaz entourant la Terre ou toute autre planète. Saviez-vous que l'atmosphère de la Terre est composée principalement de azote (78%) et de dioxygène (21%) avec des traces d'autres gaz? Cette composition est essentielle à la vie telle que nous la connaissons.`,
    }],
    9: [{
        word: 'Fantastique',
        letters: ['F', 'A', 'N', 'T', 'A', 'S', 'T', 'I', 'Q', 'U', 'E'],
        funFact: `Qui relève du domaine de l'imagination, de l'imaginaire. Saviez-vous que le genre fantastique a une longue histoire remontant à l'antiquité? Les contes de fées, les légendes et les mythes sont souvent peuplés de créatures fantastiques telles que les dragons, les fées et les monstres.`,
    }, {
        word: 'Intéressant',
        letters: ['I', 'N', 'T', 'É', 'R', 'E', 'S', 'S', 'A', 'N', 'T'],
        funFact: `Qui suscite de l'intérêt ou de la curiosité. Saviez-vous que les recherches montrent que l'intérêt est souvent lié à la dopamine, un neurotransmetteur associé au plaisir et à la motivation? C'est pourquoi nous sommes attirés par les choses que nous trouvons intéressantes.`,
    }, {
        word: 'Environnant',
        letters: ['E', 'N', 'V', 'I', 'R', 'O', 'N', 'N', 'A', 'N', 'T'],
        funFact: `Qui entoure ou encadre quelque chose.Saviez-vous que les écosystèmes environnants jouent un rôle crucial dans la survie et la santé des espèces qui y vivent? Les changements dans l'environnement peuvent avoir des répercussions importantes sur la biodiversité et l'équilibre écologique.`,
    }, {
        word: 'Controversé',
        letters: ['C', 'O', 'N', 'T', 'R', 'O', 'V', 'E', 'R', 'S', 'É'],
        funFact: `Qui suscite la controverse ou le débat. Saviez-vous que certains sujets, comme la politique, la religion et la morale, sont souvent considérés comme controversés en raison de leurs implications sociales et éthiques? Les controverses peuvent souvent conduire à des discussions constructives mais aussi à des divisions.`
    }],
    10: [{
        word: 'Exceptionnel',
        letters: ['E', 'X', 'C', 'E', 'P', 'T', 'I', 'O', 'N', 'N', 'E', 'L'],
        funFact: `Qui sort de l'ordinaire, qui est remarquable. Saviez-vous que chaque individu possède des caractéristiques exceptionnelles qui le rendent unique? Reconnaître et valoriser ces qualités individuelles peut contribuer à renforcer la confiance en soi et l'estime de soi.`,
    }, {
        word: 'Intelligence',
        letters: ['I', 'N', 'T', 'E', 'L', 'L', 'I', 'G', 'E', 'N', 'C', 'E'],
        funFact: `La capacité de comprendre, d'apprendre et de s'adapter à de nouvelles situations. Saviez-vous que l'intelligence humaine est un sujet complexe étudié par divers domaines tels que la psychologie, la neuroscience et l'intelligence artificielle? Malgré de nombreuses théories, il n'existe pas de définition unique de l'intelligence.`,
    }, {
        word: 'Actuellement',
        letters: ['A', 'C', 'T', 'U', 'E', 'L', 'L', 'E', 'M', 'E', 'N', 'T'],
        funFact: `À l'heure actuelle, à ce moment précis. Saviez-vous que le terme "actuellement" est souvent utilisé pour signifier "maintenant", mais il est plus précisément utilisé pour faire référence à ce qui se passe actuellement dans une période temporelle plus large? Par exemple, "Actuellement, je travaille sur un projet qui sera terminé dans deux semaines."`,
    }, {
        word: 'Connaissance',
        letters: ['C', 'O', 'N', 'N', 'A', 'I', 'S', 'S', 'A', 'N', 'C', 'E'],
        funFact: `La compréhension ou la conscience acquise par l'expérience, l'apprentissage ou l'observation. Saviez-vous que la connaissance est souvent considérée comme l'une des principales sources de pouvoir? C'est pourquoi l'acquisition de connaissances est valorisée dans de nombreuses sociétés à travers le monde.`,
    }, {
        word: 'Bibliothèque',
        letters: ['B', 'I', 'B', 'L', 'I', 'O', 'T', 'H', 'È', 'Q', 'U', 'E'],
        funFact: `Une bibliothèque est un lieu où sont rassemblés et classés des livres, des documents, des périodiques ou d'autres supports d'information, accessibles au public pour l'étude, la lecture ou la consultation. Saviez-vous que la plus grande bibliothèque du monde est la Bibliothèque du Congrès à Washington, D.C. ? Fondée en 1800, elle abrite plus de 170 millions d'articles, dont des livres, des manuscrits, des cartes, des photographies et même des partitions musicales. Elle est non seulement une mine d'informations pour les chercheurs du monde entier, mais aussi un lieu emblématique de la culture et de l'histoire américaine.`
    }],
}
