import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import Button from '../../Button/index';

interface FigureProps {
    onClick: () => void;
}

const Cta: React.FC<FigureProps> = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.root}>
            <Button 
                theme='SECONDARY'
                label={t(`Revoir l'image`)}
                action={onClick}
            />
        </div>
    );
};

export default Cta;