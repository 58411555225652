import React from 'react';
import styles from './styles.module.css';

import Comma from './assets/comma.svg?react';
import Info from './assets/info.svg?react';
import { Message } from '../Tips';

interface SpeechBubbleProps {
    color: Message['color'];
    message: string;
}

const SpeechBubble: React.FC<SpeechBubbleProps> = ({ message, color }) => {
    let fillColor: string;
    switch (color) {
        case 'wrong':
            fillColor = '#C8AA3A';
            break;
        case 'success':
            fillColor = '#00A473';
            break;
        case 'info':
            fillColor = '#FFFFFF';
            break;
        default:
            fillColor = '#FFFFFF';
            break;
    }

    return (
        <>
            <div className={[styles.box, color ? styles[color] : ''].join(' ')}>
                <Comma
                    style={{
                        '--fill-color': fillColor,
                    } as React.CSSProperties}
                    className={styles.comma}
                />
                <div className={[styles.text, color ? styles[color] : ''].join(' ')}>
                    {color === 'info' && <Info className={styles.infoLogo} />}
                    <pre>{message}</pre>
                </div>
            </div>
        </>
    );
};

export default SpeechBubble;