import { useRef } from 'react';
import { useLongPress } from 'use-long-press';
import memoryCardBack from './assets/memoryCardBack.svg'
import { CardObj } from './index';
import click from '../../assets/click.png';
import memorinCorrect from './assets/correct.svg'
import memorinIncorrect from './assets/incorrect.svg'
import './MemorizCard.css';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { getImageKitUrlFrom } from '../../utilities/utils';

interface MemorinCardProps {
    card: CardObj;
    handleClick: (cardIndex: number, card: CardObj) => void;
    cardIndex: number;
    lvl: number;
}

export function MemorinCard({
    card,
    handleClick,
    cardIndex,
    lvl,
}: MemorinCardProps): JSX.Element {
    const imageContainer = useRef<HTMLInputElement>(null);
    const { width } = useWindowDimensions();

    const bindLongPress = useLongPress(
        (event) => {
            if (!card.found) {
                handleClick(cardIndex, card);
            }
        },
        {
            threshold: 1000
        }
    );

    console.log('card', lvl);

    return (
        <div
            className={`card-parent ${lvl > 1 ? 'small' : 'regular'}`}
            style={{
                display: 'inline-block',
                margin: '0',
                padding: '0'
            }}
            {...bindLongPress()}
            onClick={(): void => {
                if (!card.found) {
                    handleClick(cardIndex, card);
                }
            }}>
            <div
                ref={imageContainer}
                className={`card-container ${(card.flipped || card.found) ? 'card-container-click' : ''}`}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    display: 'inline-block',
                    overflow: 'hidden',
                    margin: '0',
                    borderRadius: '18px',
                }}>
                <div className='card'>
                    <img
                        className="card-front"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '100%',
                            width: 'auto',
                        }}
                        src={memoryCardBack}
                        alt="MemorinCardBack"
                    />
                    <img
                        className='card-back'
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            maxHeight: '100%',
                            maxWidth: '100%',
                            height: '100%',
                            width: 'auto',
                            borderRadius: '18px',
                            border: card.incorrect
                                ? '6px solid #FFCF26'
                                : card.correct
                                    ? '6px solid #00D97E'
                                    : 'none'
                        }}
                        src={getImageKitUrlFrom(card.image.toString(), 512, 512)}
                        alt="cardFront"
                    />
                    {/* )} */}
                </div>
                {card.found && (
                    <div
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '0%',
                            left: '0%',
                            height: '100%',
                            width: '100%',
                            backgroundColor: 'rgba(255, 255, 255, 0.62)',
                            borderRadius: '18px'
                        }}
                    />
                )}
                {!(card.flipped || card.found) && card.activeClue && (
                    <img
                        className="Animation"
                        src={click}
                        alt="click"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: width && width < 480 ? '35%' : '50%',
                            width: 'auto',
                            transform: 'translate(-26px, -40px) rotate(-45deg)',
                            zIndex: '3'
                        }}
                    />
                )}
                {card.correct && card.flipped && (
                    <img
                        src={memorinCorrect}
                        alt="memorinCorrect"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '50%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
                {card.incorrect && card.flipped && (
                    <img
                        src={memorinIncorrect}
                        alt="memorinIncorrect"
                        style={{
                            display: 'block',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            height: '50%',
                            width: 'auto',
                            transform: 'translate(-50%, -50%)',
                        }}
                    />
                )}
            </div>
        </div>
    );
}
