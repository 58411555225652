import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import style from './style.module.css';
import Chevron from './assets/chevron.svg?react';

interface DropdownProps extends WithTranslation {
    items: { value: string, label: string; icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }[];
    action: (item: { value: string, label: string; icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> }) => void;
};

const Dropdown = ({ t, items, action }: DropdownProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const selectCategory = (category: string) => {
        setSelectedCategory(category);
        const selectedItem = items.find((item) => item.label === category);
        if (selectedItem) {
            action(selectedItem);
        }
        setIsOpen(false);
    };

    return (
        <div className={style.root}>
            <div className={[style.dropdown, isOpen ? style.dropdownIsOpen : ''].join(' ')} onClick={toggleDropdown}>
                <span className={style.label}>{selectedCategory ?? items[0]?.label}</span>
                <Chevron className={isOpen ? style.chevronUp : style.chevronDown} />
            </div>
            {isOpen && (
                <ul className={style.menu}>
                    {items.map((item) => (
                        <li
                            key={item.label}
                            onClick={() => selectCategory(item.label)}
                            className={style.item}
                        >
                            {item.icon && <item.icon className={style.icon} />}
                            <span className={style.label}>{item.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default withTranslation()(Dropdown);
