import { v4 as uuid } from 'uuid';

import { items, Item } from '../models/theme';
import { complexity, option, rotations } from '../interfaces';

export const shuffle = (tab: any[]) => {
    var i, j, tmp;
    for (i = tab.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        tmp = tab[i];
        tab[i] = tab[j];
        tab[j] = tmp;
    }
    return tab;
}

export const getRandomTheme = (): Item => {
    const keys = Object.keys(items) as Array<keyof typeof items>;
    const randomIndex = Math.floor(Math.random() * keys.length);
    const randomKey = keys[randomIndex];
    return items[randomKey] as Item;
}

export const getComplexity = (lvl: number): complexity => {
    switch (lvl) {
        case 1:
            return { lvl, nbOptions: 2, nbRotationsCat1: 1, nbRotationsCat2: 0, nbFlips: 0 };
        case 2:
            return { lvl, nbOptions: 3, nbRotationsCat1: 2, nbRotationsCat2: 0, nbFlips: 0 };
        case 3:
            return { lvl, nbOptions: 4, nbRotationsCat1: 3, nbRotationsCat2: 0, nbFlips: 0 };
        case 4:
            return { lvl, nbOptions: 4, nbRotationsCat1: 2, nbRotationsCat2: 1, nbFlips: 1 };
        case 5:
            return { lvl, nbOptions: 4, nbRotationsCat1: 1, nbRotationsCat2: 2, nbFlips: 2 };
        case 6:
            return { lvl, nbOptions: 5, nbRotationsCat1: 0, nbRotationsCat2: 4, nbFlips: 0 };
        case 7:
            return { lvl, nbOptions: 5, nbRotationsCat1: 0, nbRotationsCat2: 4, nbFlips: 1 };
        case 8:
            return { lvl, nbOptions: 6, nbRotationsCat1: 0, nbRotationsCat2: 5, nbFlips: 2 };
        case 9:
            return { lvl, nbOptions: 7, nbRotationsCat1: 0, nbRotationsCat2: 6, nbFlips: 3 };
        case 10:
            return { lvl, nbOptions: 8, nbRotationsCat1: 0, nbRotationsCat2: 7, nbFlips: 4 };
        default:
            return { lvl, nbOptions: 2, nbRotationsCat1: 1, nbRotationsCat2: 0, nbFlips: 0 };
    }
}

export const generateGameOptions = (complexity: complexity): option[] => {
    const options: option[] = [];
    // Add the first option which represents the correct answer
    options.push({ rotation: 0, flip: false, isCorrect: true, status: 'idle', id: uuid() });

    const rotationsCat1: Array<rotations["Category1"]> = [90, 180, 270];
    const rotationsCat2: Array<rotations["Category2"]> = [45, 90, 135, 180, 225, 270, 315];

    // Add options for category 1 rotations
    for (let i = 0; i < complexity.nbRotationsCat1; i++) {
        let randomRotation = rotationsCat1[Math.floor(Math.random() * rotationsCat1.length)];
        options.push({ rotation: randomRotation, flip: false, isCorrect: false, status: 'idle', id: uuid() });
        rotationsCat1.splice(rotationsCat1.indexOf(randomRotation), 1);
        rotationsCat2.splice(rotationsCat2.indexOf(randomRotation), 1);
    }

    // Add options for category 2 rotations
    for (let i = 0; i < complexity.nbRotationsCat2; i++) {
        let randomRotation = rotationsCat2[Math.floor(Math.random() * rotationsCat2.length)];
        options.push({ rotation: randomRotation, flip: false, isCorrect: false, status: 'idle', id: uuid() });
        rotationsCat2.splice(rotationsCat2.indexOf(randomRotation), 1);
    }

    // Apply flips to the options except the first one
    for (let i = 1; i < options.length; i++) {
        if (complexity.nbFlips > 0) {
            options[i].flip = true;
            complexity.nbFlips--;
        }
    }

    // Shuffle the options
    return shuffle(options);
}

// Reset the status of the option
export const resetOptionsStatus = (id: option["id"], setOptions: React.Dispatch<React.SetStateAction<option[]>>) => {
    setOptions((old) =>
        old.map((opt) => {
            if (opt.id === id) {
                return { ...opt, status: 'idle' } as option;
            }
            return opt;
        })
    );
};