import { memo, useEffect } from 'react'
import { DragSourceMonitor, useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend';

import Card from '../Card';
import { DragItem } from '../../models';

const getStyles = (
    left: number,
    top: number,
    zIndex: number,
    isDragging: boolean,
    holded: boolean
): React.CSSProperties => {
    const transform = !holded ? `translate3d(${left}px, ${top}px, 0)` : '';
    return {
        position: !holded ? 'absolute' : 'relative',
        transform,
        WebkitTransform: transform,
        // IE fallback: hide the real node using CSS when dragging
        // because IE will ignore our custom "empty image" drag preview.
        opacity: isDragging ? 0 : 1,
        height: isDragging ? 0 : '',
        // Initial offset
        top: 0,
        left: 0,
        zIndex: holded ? 1 : zIndex,
    }
}

interface DraggableCardProps {
    card: DragItem;
}

const DraggableCard: React.FC<DraggableCardProps> = memo(({ card }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: 'CARD',
        item: card,
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
        canDrag: () => !(card.status === 'correct' || card.status === 'placed'),
    }), [card]);

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true })
    }, []);

    return (
        <div
            ref={drag}
            style={getStyles(card.left, card.top, card.zIndex, isDragging, card.handIdx !== undefined)}
            role="DraggableCard"
        >
            <Card letter={card.letter} status={card.status} holded={card.handIdx !== undefined} />
        </div>
    )
});

export default DraggableCard;
