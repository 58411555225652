import React from 'react';
import { Capacitor } from '@capacitor/core';

import styles from './styles.module.css';
import InstructionsDesktopSVG from './InstructionsDesktop.svg?react';
import InstructionsMobileSVG from './InstructionsMobile.svg?react';
import Action from '../Action';

interface InstructionsProps {
    onClick: () => void;
}

const Instructions: React.FC<InstructionsProps> = ({ onClick }) => {
    const platform = Capacitor.getPlatform();

    return (
        <Action action={() => onClick()}>
            {platform === 'web'
                ? <InstructionsDesktopSVG className={styles.svg} />
                : <InstructionsMobileSVG className={styles.svg} />}
        </Action>
    );
};

export default Instructions;