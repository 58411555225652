import React, { useState, useEffect, createContext, useContext } from 'react';
import { IonContent, IonProgressBar } from '@ionic/react';
import { useHistory, useLocation } from 'react-router-dom';
// import { Box, CircularProgress } from "@mui/material";
// import firebase from 'firebase/compat';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line import/no-cycle
// import { logOut } from '../services/firebaseAuth';
import { User } from '../models/User';
// eslint-disable-next-line import/no-cycle
// import { getUser } from '../services/cloudFirestore';
// import { fireAuth } from '../services/firebase';
import { UserDataProp } from '../models/userDataProp';
import { Loader } from '../components/Loader';
import { getUser } from '../stores/User';
import { useAuth0 } from '@auth0/auth0-react';
import { App as CapApp } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
export const UserContext = createContext<UserDataProp>({
    user: null,
    updateUser: () => { },
    token: '',
    logout: () => { }
});

interface UserProviderProps {
    children?: React.ReactNode;
}

// export const UserProvider: React.FunctionComponent<UserProviderProps> = ({
//   children,
// }: UserProviderProps): JSX.Element => {
export function UserProvider({ children }: UserProviderProps): JSX.Element {
    const [loaded, setLoaded] = useState<boolean | false>(false);
    const { user, isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const history = useHistory();
    const { pathname } = useLocation();
    const logout = async () => {
        localStorage.removeItem('access_token');
        setPropData({ ...propData, token: '', user: null });
        history.replace('/login');
    }
    const [propData, setPropData] = useState<UserDataProp>({
        user: null,
        updateUser: (thisPropData: UserDataProp) => {
            setPropData(thisPropData);
        },
        token: '',
        logout: logout
    });

    useEffect(() => {
        const tempToken = localStorage.getItem('access_token') || '';
        if (tempToken !== propData.token && tempToken !== '') {
            setPropData({ ...propData, token: tempToken });
        }
        const init = async () => {
            // const localToken = localStorage.getItem('access_token') || '';
            const localToken = propData.token || localStorage.getItem('access_token') || '';
            if (!localToken) {
                // logout();
                // history.replace('/login');
                if (
                    (pathname !== '/register' &&
                        pathname !== '/new-account' &&
                        pathname !== '/new-account-confirm' &&
                        pathname !== '/forgot-password' &&
                        pathname !== '/guide-utilisateurs' &&
                        pathname !== '/login' &&
                        pathname.slice(0, 7) !== '/login/') ||
                    pathname === '/login/me'
                ) {
                    history.replace('/login');
                    setLoaded(true);
                } else {
                    setLoaded(true);
                }
                return;
            }
            const updatedUser: User | null = await getUser(localToken);
            if (!updatedUser && localToken !== '') {
                logout();
                return;
            }
            setPropData({
                ...propData,
                token: localToken,
                user: { ...updatedUser, id: updatedUser?.id?.replace('auth0|', '') || '' }
            });
            setLoaded(true);
            if (updatedUser) {
                if (
                    !updatedUser.firstName ||
                    !updatedUser.lastName ||
                    updatedUser.firstName === '' ||
                    updatedUser.lastName === ''
                ) {
                    history.replace('/profile');
                }
            }
        };
        init();
    }, [propData.token, pathname]);

    return loaded ? (
        <UserContext.Provider value={propData}>{children}</UserContext.Provider>
    ) : (
        <IonContent
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100vw'
            }}>
            <Loader disableBg />
        </IonContent>
    );
}

export const propData = () => useContext(UserContext);
