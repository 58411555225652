import { CSSProperties, FC } from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';

import CardDragPreview from '../CardDragPreview';

import styles from './styles.module.css';

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        }
    }

    let { x, y } = currentOffset

    const transform = `translate(${x}px, ${y}px)`
    return {
        transform,
        WebkitTransform: transform,
    }
}

export const CustomDragLayer: FC = () => {
    const { itemType, isDragging, item, initialOffset, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging(),
        }))

    const renderItem = () => {
        switch (itemType) {
            case 'CARD':
                return <CardDragPreview letter={item.letter} />
            default:
                return null
        }
    }

    if (!isDragging) {
        return null;
    }

    return (
        <div className={styles.layer}>
            <div style={getItemStyles(initialOffset, currentOffset)}>
                {renderItem()}
            </div>
        </div>
    )
}
