import React, { CSSProperties, ReactElement } from 'react';
import '../utilities/utils.css';
const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '0.5rem'
    },
    'btn-root': {
        height: '3rem',
        backgroundColor: 'var(--color-white)',
        color: 'var(--select-text-color)',
        width: '100%',
        border: 'none',
        fontSize: '1.13rem',
        padding: '8px 3rem 8px 2rem',
        gap: '8px',
        borderRadius: '8px'
    },
    'label-root': {
        color: 'var(--color-primary)',
        textAlign: 'left',
        width: '100%',
        paddingLeft: '.5rem',
        fontSize: '1rem',
        fontWeight: '600'
    }
};

interface Props {
    sx?: CSSProperties;
    onChange?: (e: any) => void;
    opts: { value: string | number | undefined; label: string }[];
    value?: string;
    disabled?: boolean;
    label?: string;
    labelStar?: boolean;
    sxLabel?: CSSProperties;
    sxRoot?: CSSProperties;
    defaultValue?: string;
    arrowWhite?: boolean;
    optColorBlue?: boolean;
}

export function Select({
    sx,
    onChange,
    opts,
    value,
    disabled,
    label,
    labelStar,
    sxLabel,
    sxRoot,
    defaultValue,
    arrowWhite,
    optColorBlue
}: Props): JSX.Element {
    return (
        <div style={{ ...classes['div-root'], ...sxRoot }}>
            {label !== undefined && (
                <label style={{ ...classes['label-root'], ...sxLabel }} htmlFor={label}>
                    {label}
                    {labelStar && <span style={{ color: 'var(--color-error)' }}>&nbsp;*</span>}
                </label>
            )}
            <select
                name={label}
                onChange={(e) => {
                    onChange ? onChange(e) : () => {};
                }}
                defaultValue={defaultValue}
                disabled={disabled}
                style={{
                    ...classes['btn-root'],
                    ...{ cursor: disabled ? 'not-allowed' : 'pointer' },
                    ...sx
                }}
                value={value}
                className={arrowWhite ? "select-arrow-white" : "select-arrow"}>
                {opts.map((opt) => (
                    <option key={opt.value} value={opt.value} style={optColorBlue ? {color: 'rgb(13, 34, 75)'} : {}}>
                        {opt.label}
                    </option>
                ))}
            </select>
        </div>
    );
}
