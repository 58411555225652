/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-template */
/* eslint-disable curly */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable react/sort-comp */
/* eslint-disable import/no-cycle */
// import React from 'react';
import React, { useEffect, useRef, useState } from 'react';
import Sketch from 'react-p5';
import type P5 from "p5";
// import MenuComponent from './menu';
// import CongratsComponent from './congrats';
import * as CONSTANTS from './constants';

import cpuImg from './assets/cpu.svg';
import scrolling from './assets/scrolling.png';
import { isPlatform } from '@ionic/core';
import { Loader } from '../Loader';
import { getImageKitUrlFromRounded } from '../../utilities/utils';

interface PongProps {
    cpuMode: boolean;
    scoreNeedToWin: number;
    finishGame: Function;
    ballSpeed: number;
    cpuSpeed: { min: number; max: number };
    userImage: string;
}

const PongComponent: React.FC<PongProps> = ({ cpuMode, finishGame, scoreNeedToWin, ballSpeed, userImage, cpuSpeed: cpu }) => {
    const parentElement = document.getElementById('game-root');
    const [size, setSize] = useState({ width: 0, height: 0 });
    const [state, setState] = useState({ finished: false, goToMenu: false });

    let scoreLeft: number = 0;
    let scoreRight: number = 0;
    let paddleWidth: number = 24;
    let paddleHeight: number = 130;
    let paddleStep: number = size.height / 9;
    let borderOffset: number = 5;
    let diameter: number = 50;

    let xPaddleLeft: number = borderOffset;
    let yPaddleLeft: number = size.height / 2;
    let xPaddleRight: number = size.width - borderOffset - paddleWidth;
    let yPaddleRight: number = size.height / 2;

    let leftServeXpos: number = xPaddleLeft + paddleWidth + diameter / 2;
    let leftServeYpos: number = yPaddleLeft + 0.5 * paddleHeight;
    let rightServeXpos: number = xPaddleRight - diameter / 2;
    let rightServeYpos: number = yPaddleRight + 0.5 * paddleHeight;

    let yBall: number = leftServeYpos;
    let xBall: number = leftServeXpos;
    let xBallSpeed: number = ballSpeed;
    let yBallSpeed: number = ballSpeed;
    let yHand: number = size.height / 2 - 50;
    let handDirection: number = 1;
    const displayHandRef = useRef(true);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            displayHandRef.current = false;
        }, 5000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []);

    let started: boolean = false;
    let firstServe: boolean = true;
    let leftServe: boolean = true;
    let rightServe: boolean = false;

    let canAddScoreLeft: boolean = false;

    let cpuSpeed: number = Math.floor((cpu.min + cpu.max) / 2);
    let diffCpuBall: number = 0;

    const [gameWasFinished, setGameWasFinished] = useState(false);

    const [imgCpu, setImgCpu] = useState<P5.Image>();
    const [imgUser, setImgUser] = useState<P5.Image>();
    const [imgHand, setImgHand] = useState<P5.Image>();

    useEffect(() => {
        if (parentElement) {
            setSize({
                width: parentElement.offsetWidth,
                height: parentElement.offsetHeight,
            });
        }
    }, [parentElement]);

    if (!parentElement || !size.width || !size.height) {
        return <Loader />
    }

    // p5 Canvas Setup
    const setup = (p5: P5, canvasParentRef: Element) => {
        const cnv = p5.createCanvas(size.width, size.height, 'p2d').parent(canvasParentRef);
        cnv.style('display', 'block');
        setImgCpu(p5.loadImage(cpuImg));
        setImgHand(p5.loadImage(scrolling));
        setImgUser(p5.loadImage(getImageKitUrlFromRounded(userImage, 300, 300)));

        // // désactive add point to correct on low speed
        // useEffect(() => {
        //     if (this.started) {
        //         setTimeout(() => {
        //             this.canAddScoreLeft = true;
        //         }, 100);
        //     } else {
        //         this.canAddScoreLeft = false;
        //     }
        // }, [this.started]);
    };

    // p5 Canvas Re-draw method
    const draw = (p5: P5) => {
        if (gameWasFinished) return;
        p5.background(35, 47, 112);

        // stop game if finished
        if (state.finished) {
            setGameWasFinished(true);
            setStarted(false);
            finishGame(scoreLeft, scoreRight);
        }

        // global pause - when not started or serve in progress
        if (started) {
            xBall += xBallSpeed;
            yBall += yBallSpeed;
        }

        // Draw img cpu and user
        if (cpuMode) {
            if (imgCpu) {
                p5.image(
                    imgCpu,
                    isPlatform('mobile') ? size.width * (3 / 4) - 35 : size.width * (3 / 4) - 50,
                    isPlatform('mobile') ? size.height - 90 : size.height - 120,
                    isPlatform('mobile') ? 70 : 100,
                    isPlatform('mobile') ? 70 : 100
                );
            }
            if (imgUser) {
                p5.image(
                    imgUser,
                    isPlatform('mobile') ? size.width * (1 / 4) - 35 : size.width * (1 / 4) - 50,
                    isPlatform('mobile') ? size.height - 90 : size.height - 120,
                    isPlatform('mobile') ? 70 : 100,
                    isPlatform('mobile') ? 70 : 100
                );
            }
        }

        // draw scrolling hand
        if (displayHandRef.current && imgHand) {
            p5.image(imgHand, paddleWidth + 15, yHand, 100, 100);
            yHand += 3 * handDirection;
            if (yHand >= (size.height / 4) * 3 - 100) {
                handDirection = -1;
            }
            if (yHand <= size.height / 4) {
                handDirection = 1;
            }
        }

        // Detect collision with left paddle
        // if hit with upper half of paddle, redirect up, if lower half, redirect down
        if (
            xBall <=
            0 + xPaddleLeft + paddleWidth + borderOffset + diameter / 2 &&
            yBall < yPaddleLeft + paddleHeight &&
            yBall >= yPaddleLeft
        ) {
            if (started && canAddScoreLeft) {
                addPointLeft();
            }
            // xBallSpeed *= -1;
            xBallSpeed = Math.abs(xBallSpeed);
            // if (
            //     yBall >= yPaddleLeft &&
            //     yBall < yPaddleLeft + 0.5 * paddleHeight
            // ) {
            //     // here
            //     yBallSpeed = Math.abs(yBallSpeed) * -1;
            //     xBallSpeed = Math.abs(xBallSpeed);
            // }
            // if (
            //     yBall > yPaddleLeft + 0.5 * paddleHeight &&
            //     yBall <= yPaddleLeft + paddleHeight
            // ) {
            //     // here
            //     yBallSpeed = Math.abs(yBallSpeed);
            //     xBallSpeed = Math.abs(xBallSpeed);
            // }
        }
        // points only if behind left wall
        else if (xBall < diameter / 2) {
            xBallSpeed *= -1;
            addPointRight();
            setStarted(false);
            // put ball for left serve
            xBall = xPaddleLeft + paddleWidth + diameter / 2;
            yBall = yPaddleLeft + 0.5 * paddleHeight;
            leftServe = true;
            resetCPUSpeed();

            // serve after 2 seconds
            setTimeout(() => {
                mobileServe();
            }, 2000);
        }

        // Detect collision with right paddle
        // if hit with upper half of paddle, redirect up, if lower half, redirect down
        if (
            xBall >=
            size.width - borderOffset - paddleWidth - diameter / 2 &&
            yBall <= yPaddleRight + paddleHeight &&
            yBall >= yPaddleRight
        ) {
            xBallSpeed = Math.abs(xBallSpeed) * -1;
            // if (
            //     yBall >= yPaddleRight &&
            //     yBall < yPaddleRight + 0.5 * paddleHeight
            // ) {
            //     yBallSpeed = Math.abs(yBallSpeed) * -1;
            //     xBallSpeed = Math.abs(xBallSpeed) * -1;
            // }
            // if (
            //     yBall > yPaddleRight + 0.5 * paddleHeight &&
            //     yBall <= yPaddleRight + paddleHeight
            // ) {
            //     yBallSpeed = Math.abs(yBallSpeed);
            //     xBallSpeed = Math.abs(xBallSpeed) * -1;
            // }
        }
        // points if behind right wall
        // pause game and do serve position for the lost point user
        else if (xBall + diameter / 2 > size.width) {
            xBallSpeed *= -1;
            addPointLeft();
            setStarted(false);
            // put ball for right serve
            xBall = xPaddleRight - diameter / 2;
            yBall = yPaddleRight + 0.5 * paddleHeight;
            rightServe = true;
            resetCPUSpeed();

            // setTimeout(() => {
            //     mobileServeRight(rightServe);
            // }, 1000);
        }

        bounceTopBottom();

        if (started === false && firstServe === true && gameWasFinished === false) {
            firstServe = false;
            // speed first serve
            setTimeout(() => {
                mobileServe();
            }, 5200);
        }

        // Draw paddle left
        p5.fill(255, 255, 255);
        p5.noStroke();
        p5.rect(xPaddleLeft, yPaddleLeft, paddleWidth, paddleHeight, 10);

        // Draw paddle right
        p5.fill(255, 255, 255);
        p5.noStroke();
        p5.rect(xPaddleRight, yPaddleRight, paddleWidth, paddleHeight, 10);

        drawStaticItems(p5);

        // Draw ball (top layer)
        p5.fill(84, 111, 255);
        p5.stroke(255);
        p5.strokeWeight(4);
        p5.ellipse(xBall, yBall, diameter, diameter);

        cpuShouldAction();
        boundToWindow();
    };

    const setStarted = (newVal: boolean) => {
        started = newVal;
        if (started) {
            setTimeout(() => {
                canAddScoreLeft = true;
            }, 100);
        } else {
            canAddScoreLeft = false;
        }
    };

    const cpuShouldAction = () => {
        if (cpuMode) {
            diffCpuBall = yBall - yPaddleRight;
            if (rightServe) {
                rightServe = false;
                setTimeout(() => cpuServe(), 2000);
            }
            if (started) {
                cpuMove();
            }
        }
    };

    const cpuServe = () => {
        setStarted(true);
        rightServe = false;
    };

    const addDelayToScore = () => {
        canAddScoreLeft = false;
        setTimeout(() => {
            canAddScoreLeft = true;
        }, 100);
    };

    const addPointLeft = () => {
        scoreLeft++;
        addDelayToScore();
        if (scoreLeft === scoreNeedToWin) {
            setState((old) => ({ ...old, finished: true }));
        }
    };

    const addPointRight = () => {
        scoreRight++;
        if (scoreRight === scoreNeedToWin && !cpuMode) {
            setState((old) => ({ ...old, finished: true }));
        }
    };

    // CPU move right paddle
    const cpuMove = () => {
        yPaddleRight +=
            diffCpuBall >= cpuSpeed * 0.7 ? cpuSpeed : cpuSpeed * -1;

        // bound to play window
        if (yPaddleRight <= 0) {
            yPaddleRight = 0;
        }
        if (yPaddleRight + paddleHeight >= size.height) {
            yPaddleRight = size.height - paddleHeight;
        }

        // Randomize CPU speed
        const speedDiff = Math.floor(Math.random() * 10);
        cpuSpeed += Math.random() <= 0.5 ? speedDiff : speedDiff * -1;

        // bound the CPU speed change
        if (cpuSpeed > cpu.max) {
            cpuSpeed = cpu.max;
        }
        if (cpuSpeed < cpu.min) {
            cpuSpeed = cpu.min;
        }
    };

    const resetCPUSpeed = () => {
        if (cpuMode) {
            cpuSpeed = 8;
        }
    };

    const bounceTopBottom = () => {
        // bounce from top and bottom
        if (yBall < diameter / 2 || yBall > size.height - diameter / 2) {
            yBallSpeed *= -1;
        }
    };

    const moveBallDuringRightServe = (moveBallDuringRightServe: boolean) => {
        if (moveBallDuringRightServe) {
            xBall = xPaddleRight - diameter / 2;
            yBall = yPaddleRight + 0.5 * paddleHeight;
        }
    };

    const moveBallDuringLeftServe = (moveBallDuringLeftServe: boolean) => {
        if (moveBallDuringLeftServe) {
            xBall = xPaddleLeft + paddleWidth + diameter / 2;
            yBall = yPaddleLeft + 0.5 * paddleHeight;
        }
    };

    const mobileServe = () => {
        setStarted(true);
        if (leftServe) {
            xBallSpeed = Math.abs(xBallSpeed);
        }
        if (rightServe) {
            xBallSpeed = Math.abs(xBallSpeed) * -1;
        }
        leftServe = false;
        rightServe = false;
    };

    const boundToWindow = () => {
        if (yPaddleLeft <= 0) yPaddleLeft = 0;
        if (yPaddleLeft + paddleHeight >= size.height)
            yPaddleLeft = size.height - paddleHeight;
        if (yPaddleRight <= 0) yPaddleRight = 0;
        if (yPaddleRight + paddleHeight >= size.height)
            yPaddleRight = size.height - paddleHeight;
    };

    const drawStaticItems = (p5: P5) => {
        // Draw middle line
        for (let i = 0; i < size.height; i += 20) {
            if (i % 40 === 0) {
                p5.fill(255, 255, 255);
                p5.noStroke();
                p5.rect(
                    (size.width - paddleWidth) / 2,
                    i - 8,
                    paddleWidth / 4,
                    size.height / 30
                );
            }
        }

        // Draw scores
        p5.textFont('Luciole-Regular', 36);
        p5.fill(96, 245, 255);
        p5.textSize(40);
        p5.text(
            // scoreLeft < 10 ? '0' + scoreLeft : scoreLeft,
            scoreLeft + ' point' + (scoreLeft > 1 ? 's' : ''),
            size.width * (1 / 4) - 110,
            40
        );
        if (!cpuMode) {
            p5.text(
                scoreRight < 10 ? '0' + scoreRight : scoreRight,
                size.width * (3 / 4),
                50
            );
        }

        // // title & menu back text
        // p5.textSize(50);
        // p5.text('IvoPong', (size.width - paddleWidth) / 2 - 100, size.height - 40);
        // p5.textSize(20);
        // p5.text(
        //     'ESC to Menu',
        //     (size.width - paddleWidth) / 2 - 62,
        //     size.height - 20
        // );
    };

    // p5 event on mobile screen tap / desktop click
    const touchStartedSinglePlayer = (t: any) => {
        if (t.pmouseY < 0.5 * t.height) {
            yPaddleLeft -= paddleStep;
        } else {
            yPaddleLeft += paddleStep;
        }
        // boundToWindow();
        mobileServe();
    };

    const mouveWhitMouse = (t: any) => {
        if (
            t.pmouseY < size.height - paddleHeight / 2 &&
            t.pmouseY > paddleHeight / 2
        )
            yPaddleLeft = t.pmouseY - paddleHeight / 2;
        if (t.pmouseY >= size.height - paddleHeight / 2)
            yPaddleLeft = size.height - paddleHeight;
        if (t.pmouseY <= paddleHeight / 2) yPaddleLeft = 0;
        if (leftServe) {
            // put ball for left serve
            xBall = xPaddleLeft + paddleWidth + diameter / 2;
            yBall = yPaddleLeft + 0.5 * paddleHeight;
        }
    };

    // p5 event on mobile screen tap / desktop click
    const touchStartedTwoPlayers = (t: any) => {
        // right
        if (t.pmouseY < 0.5 * t.height && t.pmouseX > 0.5 * t.width) {
            yPaddleRight -= paddleStep;
        }
        if (t.pmouseY > 0.5 * t.height && t.pmouseX > 0.5 * t.width) {
            yPaddleRight += paddleStep;
        }
        // left
        if (t.pmouseY < 0.5 * t.height && t.pmouseX < 0.5 * t.width) {
            yPaddleLeft -= paddleStep;
        }
        if (t.pmouseY > 0.5 * t.height && t.pmouseX < 0.5 * t.width) {
            yPaddleLeft += paddleStep;
        }
        // boundToWindow();
        mobileServe();
    };

    const pause = () => {
        setStarted(!started);
    };

    // p5 event on key press
    const keyPressed = (e: any) => {
        // esc to menu
        if (e.keyCode === CONSTANTS.ESC) {
            pause();
            // if (setDisplayRules) setDisplayRules(true);
        }
        if (e.keyCode === CONSTANTS.SPACEBAR) {
            // space
            setStarted(true);
            if (leftServe) {
                xBallSpeed = Math.abs(xBallSpeed);
            }
            if (rightServe) {
                xBallSpeed = Math.abs(xBallSpeed) * -1;
            }
            leftServe = false;
            rightServe = false;
        }
        // 2nd player keys W (87) and S (83)
        if (!cpuMode) {
            if (e.keyCode === CONSTANTS.UP_ARROW || e.keyCode === CONSTANTS.LEFT_ARROW) {
                yPaddleRight -= paddleStep;
            }
            if (e.keyCode === CONSTANTS.DOWN_ARROW || e.keyCode === CONSTANTS.RIGHT_ARROW) {
                yPaddleRight += paddleStep;
            }
        }

        if (e.keyCode === CONSTANTS.Z_KEY) {
            yPaddleLeft -= paddleStep;
        }
        if (e.keyCode === CONSTANTS.S_KEY) {
            yPaddleLeft += paddleStep;
        }

        moveBallDuringLeftServe(leftServe);
        moveBallDuringRightServe(rightServe);
        // boundToWindow();
    };

    const windowResized = (p5: P5) => {
        if (!parentElement) return;
        size.width = parentElement.offsetWidth;
        size.height = parentElement.offsetHeight;
        p5.resizeCanvas(size.width, size.height);
        xPaddleRight = size.width - borderOffset - paddleWidth;
    };

    return (
        <Sketch
            style={{
                width: '100%',
                height: '100%',
            }}
            setup={setup as any}
            draw={draw as any}
            keyPressed={keyPressed}
            mouseMoved={mouveWhitMouse}
            touchMoved={mouveWhitMouse}
            windowResized={windowResized as any}
        />
    );
}

// eslint-disable-next-line import/no-default-export
export default PongComponent;
