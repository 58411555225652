import style from './style.module.css';

import Action from '../Action';
import { FunctionComponent, SVGProps } from 'react';

interface NavItemProps {
    className?: string;
    action: string;
    Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
    title: string;
    disabled?: boolean;
    active?: boolean;
    hideOnMobile?: boolean;
    hideOnDesktop?: boolean;
}

const NavItem = ({
    className,
    action,
    Icon,
    title,
    disabled = false,
    active = false,
    hideOnMobile = false,
    hideOnDesktop = false,
}: NavItemProps) => {
    const computedClassName = `${style.navItem} ${active ? style.isActive : ''}  ${disabled ? style.isDisabled : ''}
    ${hideOnMobile ? style.hideOnMobile : ''} ${hideOnDesktop ? style.hideOnDesktop : ''} ${className}`;
    return (
        <Action className={computedClassName} action={action}>
            <div className={`${style.iconContainer} ${active ? style.isActive : ''}`}>
                <Icon style={{
                    '--color': active ? 'white' : '',
                } as React.CSSProperties} />
            </div>
            <span className={style.title}>{title}</span>
        </Action>
    );
};

export default NavItem;