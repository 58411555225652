import React from 'react';
import styles from './styles.module.css';
import Box from '../Box';
import { XYCoord } from 'react-dnd';
import { DragItem, Word } from '../../models';

interface HandProps {
    size: number;
    cards: DragItem[];
    onCardHold: (id: DragItem['id'], position: XYCoord, handIdx: DragItem['handIdx']) => void;
    word: Word['letters'];
}

const Hand: React.FC<HandProps> = ({ cards, size, onCardHold, word }) => {

    const handleCardHold = (id: DragItem['id'], position: XYCoord, handIdx: DragItem['handIdx']) => {
        onCardHold(id, position, handIdx);
    };

    return (
        <div className={styles.root}>
            {[...Array(size)].map((_, idx) => (
                <Box
                    key={idx}
                    onCardHold={(id, position) => handleCardHold(id, position, idx)}
                    card={cards.find(card => card.handIdx === idx)}
                    glow={cards.some(card =>
                        card.status === 'glow' && card.handIdx === undefined && card.letter === word[idx]
                    )}
                />
            ))}
        </div>
    );
};

export default Hand;