import React, { CSSProperties, ReactElement } from 'react';
import { Typography } from './Typography';
import cross from '../assets/cross.svg';
import useWindowDimensions from '../utilities/useWindowDimensions';
import infoLogo from '../assets/infoLogo.svg';

const classes: { [key: string]: CSSProperties } = {
    'div-root': {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    },
    modal: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.8rem',
        zIndex: 1001,
        // padding: '10px',
        overflowY: 'auto',
        backgroundColor: '#F5F5F5',
        height: 'fit-content',
        width: 'fit-content'
    },
    children: {
        marginTop: '1rem',
        color: 'var(--main-blue-color)',
        lineHeight: '2rem',
        fontWeight: '600',
        fontSize: '1rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        gap: '2rem'
    }
};

interface ModalProps {
    isOpen: boolean;
    closeModal?: Function;
    title?: string;
    children?: ReactElement;
    maxWidth?: string;
    maxHeight?: string;
    sx?: CSSProperties;
}

export function ModalInfo({
    isOpen,
    closeModal,
    title,
    children,
    maxWidth,
    maxHeight,
    sx
}: ModalProps): JSX.Element {
    const { width, height } = useWindowDimensions();
    return (
        <div style={{ ...classes['div-root'], display: isOpen ? 'flex' : 'none', ...sx }}>
            <div
                style={{
                    ...classes['modal'],
                    overflowY: 'hidden',
                    maxHeight: maxHeight ? maxHeight : '80vh',
                    maxWidth: maxWidth
                        ? maxWidth
                        : height && width && height < width
                        ? '75vw'
                        : '90vw'
                }}>
                {closeModal && (
                    <img
                        src={cross}
                        alt="close"
                        onClick={(): void => closeModal()}
                        style={{
                            position: 'absolute',
                            top: '1rem',
                            right: '1rem',
                            cursor: 'pointer',
                            border: '2px solid #E3E6E8',
                            borderRadius: '50%',
                            height: '3rem',
                            width: '3rem',
                            zIndex: 1002
                        }}
                    />
                )}
                <div
                    style={{
                        position: 'relative',
                        display: 'flex',
                        overflowY: 'auto',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: 'fit-content',
                        minWidth: height && width && height < width ? '75vw' : '80vw',
                        width: 'fit-content',
                        maxWidth: maxWidth
                            ? maxWidth
                            : height && width && height < width
                            ? '75vw'
                            : '90vw',
                        padding: height && width && height < width ? '2rem' : '1rem'
                    }}>
                    <img
                        src={infoLogo}
                        alt="info"
                        style={{
                            height: '1.8rem',
                            width: '1.8rem',
                        }}
                    />
                    {title && (
                        <Typography
                            variant="h2"
                            sx={{
                                marginTop: '1rem',
                                color: '#E5007B',
                                fontWeight: '700',
                                fontSize: '1.5rem'
                            }}
                            dangerouslySetInnerHTML={{ __html: title }}
                            children={undefined}
                        />
                    )}
                    <div style={classes['children']}>{children}</div>
                </div>
            </div>
        </div>
    );
}
