import { IonCheckbox } from '@ionic/react';
import React, { CSSProperties, ReactElement } from 'react';
import '../utilities/utils.css';

const classes: { [key: string]: CSSProperties } = {};

interface Props {
    checked?: boolean;
    sx?: CSSProperties;
    setChecked?: (value: boolean) => void;
    disabled?: boolean;
    green?: boolean;
    pink?: boolean;
    white?: boolean;
}

export function CheckBox({ checked = false, sx, setChecked, disabled, green, pink, white }: Props): JSX.Element {
    return (
        <IonCheckbox
            className={green && checked ? 'checkbox-green' : pink ? 'checkbox-pink' : white ? 'checkbox-white' : ''}
            defaultChecked={checked}
            checked={checked}
            disabled={disabled}
            onIonChange={(e) => {
                setChecked ? setChecked(e.detail.checked) : () => {};
            }}
            style={{ ...sx }}
        />
    );
}
