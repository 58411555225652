import { WithTranslation, withTranslation } from 'react-i18next';

import style from './style.module.css';
import { Profile } from '../home/profile/Profile';
import { CreateImportHeader } from '../../components/CreateImportHeader';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ImportMedia } from '../home/ImportMedia';
import { ChoiseCreateImport } from '../home/ChoiseCreateImport';
import { CreateGames } from '../home/createImport/CreateGames';
import { PatientHomeData } from '../../models/PatientHomeData';
import { Exercise } from '../../models/Exercise';

interface ContextMenuProps extends WithTranslation {
    page?: string;
    onClose: () => void;
    itemToEdit?: PatientHomeData;
    media?: { id: string; videoUrl: string | undefined; thumbnail: string | undefined; type: string };
};

const ContextMenu = ({ t, page, itemToEdit, media, onClose }: ContextMenuProps) => {
    const history = useHistory();
    const [step, setStep] = useState<number>(page === 'MyProfile' ? 0 : 1);
    const [stepTitle, setStepTitle] = useState<string>(t('Iwant') ?? '');
    const [displayProfile, setDisplayProfile] = useState<boolean>([
        'MyProfile',
        'MyStatistics',
        'PersonalInformations',
        'MyFamilyMembers',
        'inspiNotebook',
    ].includes(page ?? ''));
    const [onboarding, setOnboarding] = useState<boolean>(false);
    const [displayImportMedia, setDisplayImportMedia] = useState<boolean>(media !== undefined);
    const [displayCreateGames, setDisplayCreateGames] = useState<boolean>(page === 'create');
    const [displayCreateImportMenu, setDisplayCreateImportMenu] = useState<boolean>(['create', 'import'].includes(page ?? ''));
    const [importMediaType, setImportMediaType] = useState<
        null | 'picture' | 'personal-video' | 'youtube-video'
    >(media?.type as 'picture' | 'personal-video' | 'youtube-video' | null);
    const [gameToEdit, setGameToEdit] = useState<PatientHomeData | undefined>(itemToEdit);
    const [mediaToEdit, setMediaToEdit] = useState<{ id: string; videoUrl: string | undefined; thumbnail: string | undefined; } | undefined>(media);
    const [stepMax, setStepMax] = useState<number>(-1);
    const [displayCongratulationsModal, setDisplayCongratulationsModal] = useState<boolean>(false);
    const [setupAlreadyDone, setSetupAlreadyDone] = useState<boolean>(false);
    const [gameSaved, setGameSaved] = useState<Exercise>();

    const useWindowDimensions = () => {
        const [height, setHeight] = useState<number | null>(null);
        const [width, setWidth] = useState<number | null>(null);

        const updateDimensions = () => {
            setHeight(window.innerHeight);
            setWidth(window.innerWidth);
        };

        useEffect(() => {
            window.addEventListener('resize', updateDimensions);
            updateDimensions();
            return () => window.removeEventListener('resize', updateDimensions);
        }, []);

        return { height, width };
    };

    const { height, width } = useWindowDimensions();

    const setup = () => {
    };

    const refresh = () => {
    };

    const gameAsCreated = (newGame: Exercise): void => {
        setGameSaved(newGame);
        // setStepCreateGames(-1);
        setStep(-1);
        setSetupAlreadyDone(false);
        setup();
        setDisplayCongratulationsModal(true);
    };

    return (
        <>
            {!displayCreateImportMenu && <div>
                <CreateImportHeader
                    step={step}
                    stepMax={0}
                    close={(): void => {
                        setDisplayProfile(false);
                        setStep(-1);
                        onClose();
                    }}
                    prev={(): void => {
                        if (step > 0) {
                            setStep(step - 1);
                        } else {
                            setDisplayProfile(false);
                            setStep(-1);
                            onClose();
                        }
                    }}
                    title={stepTitle}
                />
                {displayProfile && (
                    <Profile
                        initPage={page}
                        step={step}
                        setStep={setStep}
                        setStepTitle={setStepTitle}
                        displayOnboarding={() => setOnboarding(true)}
                    />
                )}
            </div>}
            {displayCreateImportMenu && <div
                style={{
                    transition:
                        height && width && height > width
                            ? 'opacity 0.3s ease-in-out, top 0.3s ease-in-out'
                            : 'opacity 0.3s ease-in-out, left 0.3s ease-in-out',
                    top: displayCreateImportMenu ? 0 : '100%',
                }}
                className={style.createGamePage}
            >
                <CreateImportHeader
                    step={displayCreateGames ? step - 1 : step}
                    stepMax={stepMax}
                    close={(): void => {
                        setDisplayCreateImportMenu(false);
                        setDisplayCreateGames(false);
                        setGameToEdit(undefined);
                        setDisplayImportMedia(false);
                        setMediaToEdit(undefined);
                        setStep(-1);
                        setStepMax(0);
                        onClose();
                    }}
                    prev={(): void => {
                        // setStepCreateGames(stepCreateGames - 1);
                        if (step > 1) {
                            setStep(step - 1);
                        } else if (step === 1) {
                            setDisplayCreateImportMenu(true);
                            setDisplayCreateGames(false);
                            setGameToEdit(undefined);
                            setMediaToEdit(undefined);
                            setDisplayImportMedia(false);
                            setStep(0);
                        } else {
                            onClose();
                        }
                    }}
                    title={stepTitle}
                />
                {displayCreateImportMenu && !displayCreateGames && !displayImportMedia && (
                    <ChoiseCreateImport
                        onSelectGames={() => {
                            setStep(1);
                            setDisplayCreateGames(true);
                            setImportMediaType(null);
                        }}
                        onSelectPicture={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('picture');
                        }}
                        onSelectVideo={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('personal-video');
                        }}
                        onSelectVideoYT={() => {
                            setStep(1);
                            setDisplayImportMedia(true);
                            setImportMediaType('youtube-video');
                        }}
                    />
                )}
                {displayCreateGames && (
                    <CreateGames
                        step={step - 1}
                        gameToEdit={gameToEdit}
                        setStep={(step: number): void => setStep(step + 1)}
                        setStepMax={(stepMax: number): void => setStepMax(stepMax)}
                        gameAsCreated={gameAsCreated}
                        setTitle={(title: string): void => setStepTitle(title)}
                        close={(): void => {
                            setDisplayCreateImportMenu(false);
                            setDisplayCreateGames(false);
                            setGameToEdit(undefined);
                            // setMediaToEdit('');
                            setDisplayImportMedia(false);
                            setStep(-1);
                            setStepMax(0);
                            onClose();
                        }}
                    />
                )}
                {displayImportMedia && (
                    <ImportMedia
                        type={importMediaType ?? 'picture'}
                        step={step - 1}
                        mediaToEdit={mediaToEdit}
                        setMediaToEdit={(
                            value:
                                | {
                                    id: string;
                                    videoUrl: string | undefined;
                                    thumbnail: string | undefined;
                                }
                                | undefined
                        ): void => setMediaToEdit(value)}
                        setStep={(step: number): void => setStep(step + 1)}
                        setStepMax={(stepMax: number): void => setStepMax(stepMax)}
                        // gameAsCreated={gameAsCreated}
                        setTitle={(title: string): void => setStepTitle(title)}
                        close={(): void => {
                            setDisplayCreateImportMenu(false);
                            setDisplayCreateGames(false);
                            setDisplayImportMedia(false);
                            setMediaToEdit(undefined);
                            setStep(-1);
                            setStepMax(0);
                            onClose();
                        }}
                        updateListPicture={refresh}
                    />
                )}
            </div>}
        </>
    )
};

export default withTranslation()(ContextMenu);