import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography } from './Typography';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { Patient } from '../models/Patient';
import logoSmall from '../assets/logo-small.svg';
import familyPeapole from '../assets/familyPeapole.png';
import line from '../assets/line.png';
import ringLoader from '../assets/rings.svg';
import { translateDate } from '../utilities/utils';
import { getPatientFromCode } from '../services/cloudFirestore';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { ProfileImage } from './ProfilImage';

const classes: { [key: string]: CSSProperties } = {
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyItems: 'center',
        alignItems: 'center',
        height: '100%',
        overflowY: 'auto',
        margin: '1rem 0.5rem'
    },
    typography: {
        display: 'flex',
        alignContent: 'space-around',
        alignItems: 'center',
        justifyContent: 'center'
    },
    'welcome-root': {
        width: 'auto',
        maxWidth: '75%',
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '1rem',
        alignItems: 'center'
    },
    'welcome-message': {
        fontSize: '1.75rem',
        fontWeight: '700',
        lineHeight: '1.5'
    },
    'family-code': {
        textAlign: 'center'
    },
    'continue-button': {
        marginTop: '1rem',
        width: '100%'
    },
    'wrong-code-button': {
        width: 'fit-content',
        padding: '0 2rem',
        fontWeight: '500',
    }
};

interface SeniorInfoProps {
    code: string;
    onSuccess: Function;
    onError: Function;
}

export function SeniorInfo({ code, onSuccess, onError }: SeniorInfoProps): JSX.Element {
    const [patient, setPatient] = useState<Patient | null>();
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState('');
    const { t } = useTranslation();
    const { height, width } = useWindowDimensions();

    useEffect(() => {
        getPatientFromCode(code).then((data) => {
            if (data === null) {
                onError('PATIENT_NOT_FOUND');
            }
            setPatient(data);
        });
    }, []);

    useEffect(() => {
        if (patient && patient.dob) {
            setDate(translateDate(patient?.dob?.toDate().toDateString()));
        }
    }, [patient]);

    const getSeniorInfo = (
        <>
            <div style={{ ...classes['welcome-root'] }}>
                <Typography variant="h3" sx={{ ...classes['welcome-message'] }}>
                    {t('Welcome')}
                </Typography>
                <img
                    src={familyPeapole}
                    alt="family"
                    style={{ height: '3.25rem', width: 'auto' }}
                />
                {patient ? (
                    <ProfileImage
                        width={100}
                        height={100}
                        name={`${patient.firstName} ${patient.lastName}`}
                        url={patient.profilePhoto}
                    />
                ) : null}
            </div>
            <div style={{ ...classes['family-code'] }}>
                <Typography sx={{ color: '#374653' }}>{t('InFamilyOf')}</Typography>
                {patient && patient.lastName && (
                    <Typography
                        variant="p"
                        sx={{ color: '#374653', fontWeight: 600, paddingTop: '0.5rem' }}>
                        {`${patient?.firstName} ${patient.lastName}`}
                    </Typography>
                )}
                {date && (
                    <Typography variant="p" sx={{ color: '#374653', fontWeight: 600 }}>
                        {`${t('Born')}${date}`}
                    </Typography>
                )}
                {patient && patient.city && (
                    <Typography variant="p" sx={{ color: '#374653', fontWeight: 600 }}>
                        {`${t('ResidenceAt')}${patient.city}`}
                    </Typography>
                )}
            </div>
            <Button
                pink
                sx={{ ...classes['continue-button'] }}
                onClick={(): void => {
                    setLoading(true);
                    onSuccess(patient);
                }}>
                {t('toJoin')}
            </Button>
            <Typography
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    fontSize: '0.8125rem',
                    fontWeight: '600',
                    color: '#6C6C6C',
                    marginTop: '2rem'
                }}
                variant="p">
                <img src={line} alt="line" />
                <span>{t('WrongCode')}</span>
                <img src={line} alt="line" />
            </Typography>
            <Button
                outline
                sx={{ ...classes['wrong-code-button'], marginTop: '1rem' }}
                onClick={(): void => {
                    onError('WRONG_CODE');
                }}>
                {t('RetryNew')}
            </Button>
        </>
    );

    return (
        <div
            style={{
                ...classes['root'],
                ...{
                    padding:
                        width && width < 480
                            ? '0 5%'
                            : width && width < 768
                            ? '0 12.5%'
                            : width && width < 1024
                            ? '0 20%'
                            : '0 25%'
                }
            }}>
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-start'
                }}>
                <img src={logoSmall} alt="logo" style={{ height: '2.5rem', width: 'auto' }} />
            </div>
            {patient ? (
                getSeniorInfo
            ) : (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                        width: '100vw'
                    }}>
                    <img src={ringLoader} alt="loader" style={{ height: '6rem' }} />
                </div>
            )}
        </div>
    );
}
