import { memo, useEffect, useState } from 'react'

import Card from '../Card';

const styles: React.CSSProperties = {
  display: 'inline-block',
  transform: 'rotate(-7deg)',
  WebkitTransform: 'rotate(-7deg)',
}

export interface CardDragPreviewProps {
  letter: string
}

const CardDragPreview: React.FC<CardDragPreviewProps> = memo(({ letter }) => {
  const [tickTock, setTickTock] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => setTickTock(!tickTock), 500)
    return () => clearInterval(interval)
  }, [tickTock]);

  return (
    <div style={styles}>
      <Card letter={letter} cardPreview status={'idle'} />
    </div>
  );
});

export default CardDragPreview;
