import React, { CSSProperties } from 'react';

const classes: { [key: string]: CSSProperties } = {
    'slider-root': {
        height: '3rem',
        color: 'var(--color-white)',
        width: '100%',
        border: 'none',
        fontSize: '1.13rem',
        // accentColor: 'var(--slider-bg-color)',
        cursor: 'pointer'
    },
    'slider-blue': {
        accentColor: 'var(--slider-bg-color)'
    },
    'slider-pink': {
        accentColor: 'var(--button-bg-color-pink)'
    }
};

interface Props {
    onChange?: (e: any, value: number) => void;
    sx?: CSSProperties;
    min?: number;
    max?: number;
    ariaLabel?: string;
    value?: number;
    step?: number;
    pink?: boolean;
}

export function Slider({
    sx,
    onChange,
    min,
    max,
    ariaLabel,
    value,
    step,
    pink
}: Props): JSX.Element {
    return (
        <input
            type="range"
            min={min}
            max={max}
            aria-label={ariaLabel}
            onChange={(e: any) => onChange && onChange(e, e.target.value)}
            value={value}
            step={step}
            style={{
                ...classes['slider-root'],
                ...(pink ? classes['slider-pink'] : classes['slider-blue']),
                ...sx
            }}
        />
    );
}
