import update from 'immutability-helper';

import { DragItem, levels } from "../models";

export const getRandomWord = (level: number) => {
    const words = levels[level];
    const index = Math.floor(Math.random() * words.length);
    return words[index];
};

export const setGlowStatus = (currentCards: DragItem[], count: number) => {
    let updates: { [key: number]: { $merge: { status: 'glow' } } } = {};
    let indices: number[] = [];

    for (let i = 0; i < count; i++) {
        let idx = Math.floor(Math.random() * currentCards.length);
        while (currentCards[idx].handIdx !== undefined || (currentCards[idx].status === 'glow' || indices.includes(idx))) {
            idx = Math.floor(Math.random() * currentCards.length);
        }
        indices.push(idx);
        updates[idx] = { $merge: { status: 'glow' } };
    }

    return update(currentCards, updates);
};

export const shuffleCards = (currentCards: DragItem[], screenHeight: number, screenWidth: number, cardHeight: number, cardWidth: number) => {
    const placedCards: DragItem[] = [];

    const isOverlapping = (x: number, y: number) => {
        return placedCards.some(placedCard => 
            x < placedCard.left + cardWidth &&
            x + cardWidth > placedCard.left &&
            y < placedCard.top + cardHeight &&
            y + cardHeight > placedCard.top
        );
    };

    const findValidPosition = (): [number, number] => {
        let attempts = 0;
        let x, y;
        do {
            x = Math.random() * (screenWidth - cardWidth);
            y = Math.random() * (screenHeight - cardHeight);
            attempts++;
            if (attempts > 100) {
                console.warn("Impossible to find a non-overlapping position for all cards");
                return [x, y]; // Returns the last position tried
            }
        } while (isOverlapping(x, y));
        return [x, y];
    };

    return currentCards.map(card => {
        const [left, top] = findValidPosition();
        const newCard = { ...card, left, top };
        placedCards.push(newCard);
        return newCard;
    });
};