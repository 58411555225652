import { WithTranslation, withTranslation } from 'react-i18next';
import MediaCard, { MediaCardProps } from '../MediaCard';
import style from './style.module.css';
import Action from '../Action';
import multimedia from './assets/multimedia.png';
import photo from './assets/photo.png';
import video from './assets/video.png';
import youtube from './assets/youtube.png';
import { Button } from '../Button/index';

interface MediaGridProps extends WithTranslation {
    items: MediaCardProps[];
    withAlbum?: boolean;
    onDisplay: (id: string) => void;
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
    type?: string;
}

const MediaGrid = ({ t, items, withAlbum, onDisplay, onEdit, onDelete, type }: MediaGridProps) => {
    const albumImg = type === 'ALL' ? multimedia : type === 'IMAGE' ? photo : type === 'VIDEO' ? video : youtube;
    const albumTitle = type === 'ALL' ? 'multimédia' : type === 'IMAGE' ? 'photos' : type === 'VIDEO' ? 'vidéos' : 'Youtube';

    return (
        <div className={style.root}>
            {withAlbum && (
                <Action action={() => onDisplay(items[0].id)} className={style.album} style={{ backgroundImage: `url(${albumImg})` }}>
                    <span className={[style.albumTitle, type === 'VIDEO' ? style.white : ''].join(' ')}>{t('Mon album {{type}}', { type: albumTitle })}</span>
                    <Button className={style.albumButton} label={t('Consulter')} />
                </Action>
            )}
            {items.map((item) => (
                <MediaCard key={item.id} {...item} action={() => onDisplay(item.id)} onEdit={() => onEdit(item.id)} onDelete={() => onDelete(item.id)} />
            ))}
        </div>
    );
};

export default withTranslation()(MediaGrid);