import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import statEvolMore from '../../assets/statEvolMore.svg';
import statEvolLess from '../../assets/statEvolLess.svg';
import statEvolLike from '../../assets/statEvolLike.svg';
import smileysSatisfait from '../../assets/smileysSatisfait.svg';
import smileysNoOpinion from '../../assets/smileysNoOpinion.svg';
import smileysNotSatisfait from '../../assets/smileysNotSatisfait.svg';
import starBackground from '../../assets/starBackground.svg';
import { Select } from '../Select';

import { SelectTimeRange, SelectTimeline, classes } from '../ProfileStatisticsStyle';
import { lstGamesToUnsort as lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import { getNumberOfExercises } from '../../services/cloudFirestore';
import { Button } from '../Button';
import { Patient } from '../../models/Patient';
import { getDateFromTimestamp2 } from '../../utilities/utils';
import { Review } from '../../models/Review';
import { getStat } from '../../stores/Stats';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { SatisfactionReview } from '../../models/SatisfactionReview';

interface Props {
    // nbReview: { loved: number; satisfactory: number; disliked: number };
    userDataList: Patient[];
}

export function StatsSatisfaction({ userDataList }: Props): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const { t } = useTranslation();
    const [thisSatisfaction, setThisSatisfaction] = useState<
        'satisfait' | 'noOpinion' | 'notSatisfait'
    >();
    const [thisSmiley, setThisSmiley] = useState<string>();
    const [maxListView, setMaxListView] = useState<number>(4);
    const [countExerciceType, setCountExerciceType] = useState<{
        [ExerciseType.QUIZ]: number;
        [ExerciseType.MEMORIZ]: number;
        [ExerciseType.PUZZLE]: number;
        [ExerciseType.SUDOKU]: number;
        [ExerciseType.ORDER]: number;
        [ExerciseType.PONG]: number;
        [ExerciseType.TOURBILLON]: number;
        [ExerciseType.MELIMOTS]: number;
    }>({
        [ExerciseType.QUIZ]: 0,
        [ExerciseType.MEMORIZ]: 0,
        [ExerciseType.PUZZLE]: 0,
        [ExerciseType.SUDOKU]: 0,
        [ExerciseType.ORDER]: 0,
        [ExerciseType.PONG]: 0,
        [ExerciseType.TOURBILLON]: 0,
        [ExerciseType.MELIMOTS]: 0,
    });

    const [exerciceSatisfaction, setExerciceSatisfaction] = useState<{
        [ExerciseType.QUIZ]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.MEMORIZ]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.PUZZLE]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.SUDOKU]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.ORDER]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.PONG]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.TOURBILLON]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
        [ExerciseType.MELIMOTS]: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined;
    }>();
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [startDate, setStartDate] = useState<Date>(
        new Date(
            endDate.getFullYear(),
            endDate.getMonth(),
            new Date().getDate() - 30,
            new Date().getHours(),
            new Date().getMinutes(),
            new Date().getSeconds()
        )
    );
    const [timeRange, setTimeRange] = useState<
        'fromStart' | 'last24h' | 'last7d' | 'last30d' | 'last12m'
    >('last30d');
    const [evolSatisfaction, setEvolSatisfaction] = useState<'+' | '-' | '='>();

    const calcData = async () => {

        const res = await getStat(
            userDataProp?.token || '',
            'game/satisfaction',
            {
                startDate,
                endDate,
                idList: userDataList.map((user) => user.id ?? '')
            }
        ).then((res) => res.data);

        const data = {
            [ExerciseType.QUIZ]: 0,
            [ExerciseType.MEMORIZ]: 0,
            [ExerciseType.PUZZLE]: 0,
            [ExerciseType.SUDOKU]: 0,
            [ExerciseType.ORDER]: 0,
            [ExerciseType.PONG]: 0,
            [ExerciseType.TOURBILLON]: 0,
            [ExerciseType.MELIMOTS]: 0,
            ...res.numberPlayed
        };
        // sort lstGames by the order of the data
        lstGames.sort((a, b) => data[b.type] - data[a.type]);


        setCountExerciceType(data);
        // setExerciceSatisfaction({
        //     [ExerciseType.QUIZ]: res.satisfaction[ExerciseType.QUIZ],
        //     [ExerciseType.MEMORIZ]: res.satisfaction[ExerciseType.MEMORIZ],
        //     [ExerciseType.PUZZLE]: res.satisfaction[ExerciseType.PUZZLE],
        //     [ExerciseType.SUDOKU]: res.satisfaction[ExerciseType.SUDOKU],
        //     [ExerciseType.ORDER]: res.satisfaction[ExerciseType.ORDER],
        //     [ExerciseType.PONG]: res.satisfaction[ExerciseType.PONG],
        //     [ExerciseType.TOURBILLON]: res.satisfaction[ExerciseType.TOURBILLON],
        //     [ExerciseType.MELIMOTS]: res.satisfaction[ExerciseType.MELIMOTS]
        // });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, startDate, endDate]);


    const spanSatisfaction = (
        satisfaction: 'satisfait' | 'noOpinion' | 'notSatisfait' | undefined
    ): JSX.Element => {
        const strText = satisfaction
            ? satisfaction?.charAt(0).toUpperCase() + satisfaction?.slice(1)
            : '';
        const strImg: {
            satisfait: { img: string; color: string };
            noOpinion: { img: string; color: string };
            notSatisfait: { img: string; color: string };
        } = {
            satisfait: { img: smileysSatisfait, color: '#DDFAD3' },
            noOpinion: { img: smileysNoOpinion, color: '#F2F2F2' },
            notSatisfait: { img: smileysNotSatisfait, color: '#FFC1C1' }
        };
        return satisfaction !== undefined ? (
            <span
                style={{
                    borderRadius: '0.5rem',
                    backgroundColor: strImg[satisfaction].color,
                    padding: '0.25rem 0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '0.25rem',
                    fontSize: '0.875rem',
                    fontWeight: '500',
                    color: '#0D224B'
                }}>
                <img
                    src={strImg[satisfaction].img}
                    alt={`smileys${satisfaction?.charAt(0).toUpperCase()}`}
                    style={{ height: '1rem', width: '1rem' }}
                />
                {t(strText)}
            </span>
        ) : (
            <div />
        );
    };

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.satisfactionTitle')}
                </Typography>
                {/* <StatToolkit value={}/> */}
            </div>
            <div style={{ ...classes['stat-card-blue'] }}>
                <div style={{ ...classes['stat-card-blue-staisfaction'] }}>
                    {thisSatisfaction && (
                        <img
                            src={starBackground}
                            alt="starBackground"
                            style={{
                                ...classes['star-bg'],
                                // faire en sorte que l'image prenne toute la place disponible
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                // height: '100%',
                                objectFit: 'cover'
                            }}
                        />
                    )}
                    {thisSatisfaction && (
                        <div
                            style={{
                                height: '3.75rem',
                                width: '3.75rem',
                                backgroundColor: '#FF0089',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 2
                            }}>
                            <img
                                src={thisSmiley}
                                alt="smiley"
                                style={{ height: '1.75rem', width: '1.75rem' }}
                            />
                        </div>
                    )}
                    {thisSatisfaction !== 'noOpinion' &&
                        thisSatisfaction && ( //HERE2
                            <Typography
                                variant="p"
                                sx={{ ...classes['stat-card-blue-title'], zIndex: 2 }}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        userDataList.length === 1
                                            ? t(
                                                `profile.statistics.satisfactionActual.${thisSatisfaction}`,
                                                {
                                                    name:
                                                        userDataList.length === 1 &&
                                                            userDataList[0]
                                                            ? userDataList[0].firstName
                                                            : ''
                                                }
                                            ) || ''
                                            : t(
                                                `profile.statistics.satisfactionActual.all.${thisSatisfaction}`
                                            ) || ''
                                }}
                                children={undefined}
                            />
                        )}
                </div>
                {timeRange !== 'fromStart' && evolSatisfaction && (
                    <div style={{ ...classes['stat-card-evol-container'], zIndex: 2 }}>
                        {/* <img src={statsIconGrows} alt="statsIconGrows" style={{ ...classes['icon'] }} /> */}
                        <img
                            src={
                                evolSatisfaction === '+'
                                    ? statEvolMore
                                    : evolSatisfaction === '-'
                                        ? statEvolLess
                                        : statEvolLike
                            }
                            alt="statEvolLike"
                            style={{ ...classes['icon'] }}
                        />
                        <Typography
                            variant="p"
                            sx={{ ...classes['stat-card-evol-text'] }}
                            dangerouslySetInnerHTML={{
                                __html:
                                    t(`profile.statistics.satisfaction${evolSatisfaction}`) || ''
                            }}
                            children={undefined}></Typography>
                    </div>
                )}
            </div>
            <div
                style={{
                    ...classes['stat-card'],
                    ...classes['stat-card-no-top'],
                    zIndex: 2,
                    gap: '0px'
                }}>
                {/* <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.satisfactionMorePlayGame')}
                </Typography> */}
                <SelectTimeRange
                    sx={{
                        zIndex: 2,
                        marginBottom: '1.5rem'
                    }}
                    defaultValue="last30d"
                    onChange={(value) => {
                        setStartDate(value.startDate);
                        setEndDate(value.endDate);
                        setTimeRange(value.timeRange);
                    }}
                />

                {lstGames.map(
                    (game: { type: ExerciseType; name: string; img: string }, index: number) => {
                        if (index < maxListView) {
                            return (
                                <div key={`gamesList${index}`}>
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                            width: '100%',
                                            gap: '0.5rem',
                                            padding: '0.5rem 0'
                                        }}>
                                        <img
                                            src={game.img}
                                            alt="game"
                                            style={{ ...classes['games-icon'] }}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'start',
                                                justifyContent: 'center',
                                                width: '100%',
                                                gap: '0.125rem'
                                            }}>
                                            <Typography
                                                variant="p"
                                                sx={{ ...classes['stat-card-title'] }}>
                                                {t(game.name)}
                                            </Typography>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    justifyContent: 'start',
                                                    gap: '0.5rem'
                                                }}>
                                                <span
                                                    style={{
                                                        fontSize: '0.875rem',
                                                        fontWeight: '500',
                                                        color: '#0D224B',
                                                        backgroundColor: '#F2F2F2',
                                                        padding: '0.25rem 0.5rem',
                                                        borderRadius: '0.5rem'
                                                    }}>
                                                    {countExerciceType[game.type]}{' '}
                                                    {t(
                                                        `part${countExerciceType[game.type] > 1
                                                            ? 's'
                                                            : ''
                                                        }`
                                                    )}
                                                </span>
                                                {exerciceSatisfaction &&
                                                    exerciceSatisfaction[game.type] &&
                                                    spanSatisfaction(
                                                        exerciceSatisfaction[game.type]
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <hr
                                        style={{
                                            width: '100%',
                                            height: '0.0625rem',
                                            backgroundColor: '#EFEFEF',
                                            border: 'none'
                                            // margin: '0.5rem 0'
                                        }}
                                    />
                                </div>
                            );
                        }
                    }
                )}

                <Button
                    onClick={() => {
                        maxListView <= lstGames.length && setMaxListView(maxListView + 4);
                        maxListView > lstGames.length && setMaxListView(4);
                    }}>
                    {maxListView <= lstGames.length ? t('showMore') : t('showLess')}
                </Button>
            </div>
        </div>
    );
}
