import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { User } from '../../models/User';
import { Typography } from '../Typography';
import statsIconGrows from '../../assets/statsIconGrows.svg';
import clueIcon from '../../assets/clueIcon.svg';
import ErrorIcon from '../../assets/ErrorIcon.svg';
import littleArrow from '../../assets/littleArrow.svg';
import { Select } from '../Select';

import {
    SelectTimeline,
    StatToolkit,
    StatsArray,
    addData,
    calcEvol,
    changetimeLine,
    classes,
    getLabelFromDate
} from '../ProfileStatisticsStyle';
import { lstGames } from '../ProfileStatisticsStyle';
import { ExerciseType } from '../../models/ExerciseType';
import { getNumberOfExercises } from '../../services/cloudFirestore';
import { Button } from '../Button';
// import '../../utilities/utils.css';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Patient } from '../../models/Patient';
import {
    getDateFromTimestamp,
    getDateFromTimestamp2,
    translateMonthByNumber
} from '../../utilities/utils';
import { StatEvol } from './StatEvol';
import { StimbotInfo } from './StimbotInfo';
import { labelsMonth } from '../ProfileStatisticsStyle';
import { UserDataProp } from '../../models/userDataProp';
import { UserContext } from '../../providers/UserProvider';
import { getStat } from '../../stores/Stats';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface Props {
    userDataList: Patient[];
}

export function StatsGamesFinisVsStarted({ userDataList }: Props): JSX.Element {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const { t } = useTranslation();
    const [typeDataView, setTypeDataView] = useState<ExerciseType | 'all'>('all');
    const [nbLvlEvol, setNbLvlEvol] = useState<string>('');
    const [nbGames, setNbGames] = useState<number>(0);

    const [endDate, setEndDate] = useState<Date>(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
    );
    const [startDate, setStartDate] = useState<Date>(
        new Date(endDate.getFullYear(), endDate.getMonth() - 3, 1)
    );
    const [timeScale, setTimeScale] = useState<'day' | 'week' | 'month' | 'year'>('month');

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'bottom' as const,
                labels: {
                    // borderRadius: 20,
                    boxWidth: 30,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    precision: 0,
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                border: { dash: [4, 4] },
                grid: {
                    color: '#D8D8D8'
                },
                min: 0
                // max: 10,
                // ticks: {
                //     stepSize: 1
                // }
            },
            x: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 600,
                        family: 'Luciole-Regular'
                    }
                },
                grid: {
                    display: false,
                    lineWidth: 0
                }
            }
        }
    };

    const [data, setData] = useState<any | undefined>({
        labels: labelsMonth,
        datasets: [
            {
                label: t('gamesStarted'),
                data: [],
                backgroundColor: '#CDF2F4',
                borderRadius: 10
            },
            {
                label: t('gamesFinished'),
                data: [],
                backgroundColor: '#00C7D3',
                borderRadius: 10
            }
        ]
    });

    const calcData = async () => {
        const res = await getStat(
            userDataProp?.token || '',
            'game/play',
            {
                startDate,
                endDate,
                idList: userDataList.map((user) => user.id ?? ''),
                timeScale,
                type: typeDataView
            }
        );

        setNbLvlEvol(calcEvol(res.data.finish, timeScale));

        setNbGames(res.data.finish.reduce((a: any, b: any) => a + b, 0));

        setData({
            ...data,
            labels: getLabelFromDate(startDate, endDate, timeScale),
            datasets: [
                {
                    ...data.datasets[0],
                    data: res.data.start
                },
                {
                    ...data.datasets[1],
                    data: res.data.finish
                }
            ]
        });
    };

    useEffect(() => {
        if (userDataList && userDataList.length > 0) {
            calcData();
        }
    }, [userDataList, typeDataView, startDate, endDate, timeScale]);

    return (
        <div style={{ ...classes['stat-card-container'] }}>
            <div style={{ ...classes['stat-card-title-container'] }}>
                <Typography variant="p" sx={{ ...classes['stat-card-title'] }}>
                    {t('profile.statistics.gamesFinishVsStartedTitle')}
                </Typography>
                <StatToolkit
                    value={nbGames}
                    tooltipText={t('profile.statistics.gamesFinishVsStartedTooltip', {
                        nb: nbGames
                    })}
                />
            </div>
            <div style={{ ...classes['stat-card'] }}>
                <StimbotInfo text={t('profile.statistics.gamesFinishVsStartedInfo')} />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '1rem'
                    }}>
                    <Select
                        sxRoot={{ ...classes['select-white-root'] }}
                        sx={{
                            border: '1px solid #E8E8E8',
                            borderRadius: '1.5rem'
                        }}
                        defaultValue="all"
                        value={typeDataView}
                        opts={[
                            { value: 'all', label: t('all') },
                            ...lstGames.map((game, index) => {
                                return { value: game.type, label: t(game.name) };
                            })
                        ]}
                        onChange={(e) => setTypeDataView(e.target.value as ExerciseType)}
                    />
                    <SelectTimeline
                        onChange={(data: {
                            val: string;
                            startDate: Date;
                            endDate: Date;
                            timeScale: 'day' | 'week' | 'month' | 'year';
                        }) => {
                            setEndDate(data.endDate);
                            setStartDate(data.startDate);
                            setTimeScale(data.timeScale);
                        }}
                    />
                </div>
                {data && <Bar options={options} data={data} />}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1.5rem',
                        width: '100%'
                    }}>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'prev',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        <img src={littleArrow} alt="littleArrow" />
                        {t('prev')}
                    </Button>
                    <Button
                        sx={{ ...classes['white-btn'] }}
                        onClick={() => {
                            changetimeLine(
                                'next',
                                timeScale,
                                endDate,
                                setEndDate,
                                startDate,
                                setStartDate
                            );
                        }}>
                        {t('next')}
                        <img
                            src={littleArrow}
                            alt="littleArrow"
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </Button>
                </div>
                <StatEvol evolNb={nbLvlEvol} />
            </div>
        </div>
    );
}
