import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import App from './App';
import './main.css';
import { env } from '../env';
import { Auth0Provider } from '@auth0/auth0-react';
import { isPlatform } from '@ionic/react';
import { DEVICE } from './config';

const container = document.getElementById('root');
const root = createRoot(container!);

export const returnTo = isPlatform('hybrid')
    ? 'fr.stimulin://home'
    : window.location.origin;

if (env.production) {
    Sentry.init({
        dsn: env.production ? env.SENTRY_DSN_PROD : env.SENTRY_DSN_DEV,
        integrations: [
            browserTracingIntegration({
                tracePropagationTargets: ['localhost', 'https://appv1.stimulin.fr']
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0
    });
}

console.log('DEVICE', DEVICE);

document.documentElement.classList.add(DEVICE.toLowerCase());

root.render(
   // <React.StrictMode>
        <Auth0Provider
            domain={env.AUTH0_DOMAIN}
            clientId={env.AUTH0_CLIENT_ID}
            useRefreshTokens={true}
            useRefreshTokensFallback={false}
            cacheLocation="localstorage"
            authorizationParams={{
                // token_endpoint_auth_method: 'none',
                // application_type: isPlatform('hybrid') ? "Native" : "SPA",
                redirect_uri: returnTo,
                audience: env.AUTH0_AUDIENCE,
            }}>
            <App />
        </Auth0Provider>
    // </React.StrictMode>
);
