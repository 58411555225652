import React from 'react';
import './login.css';
import { t } from 'i18next';

interface ForgotPasswordFormProps {
    setDisplayPage: (t: 'login' | 'singup' | 'forgot') => void;
    forgotPassword: (email: string) => void;
}

export function ForgotPasswordForm({ setDisplayPage, forgotPassword }: ForgotPasswordFormProps): JSX.Element {
    const [email, setEmail] = React.useState('');
    const [emailSent, setEmailSent] = React.useState(false);
    return !emailSent ? (<>
        <h3>{t('forgotPass.title')}</h3>
        <h5>{t('forgotPass.subtitle')}</h5>
        <form id="forgot-password" onSubmit={(e) => e.preventDefault()}>
            <div className="form-group">
                <label htmlFor="name">Email</label>
                <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    className="form-control input"
                    id="forgot-password-email"
                    placeholder="ex : jean.dupont@hotmail.com"
                />
            </div>
            <button
                onClick={() => {
                    forgotPassword(email);
                    setEmailSent(true);
                }}
                type="submit"
                id="forgot-password-btn"
                className="btn btn-primary btn-block">
                Changer mon mot de passe
            </button>
            <button
                onClick={() => setDisplayPage('login')}
                type="button"
                id="forgot-password-back"
                className="btn btn-default btn-block">
                {t('back')}
            </button>
        </form>
    </>) : (<>
        <h3>{t('gorgotPassSent.title')}</h3>
        <h5>{t('gorgotPassSent.subtitle', { email: email })}</h5>
        <button
            onClick={() => setDisplayPage('login')}
            type="button"
            id="forgot-password-back-sent"
            className="btn btn-default btn-block">
            {t('back')}
        </button>
    </>);
}
