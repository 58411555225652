import { FC, ReactNode, useState, useMemo } from "react";

import { WindowContext, WindowContextProps, useWindow } from "../contexts/WindowContext";
import Window from '../components/Window';

const defaultState: WindowContextProps = {
    component: undefined,
    props: {},
    isOpened: false
};

const WindowManager: FC = () => {
    const { isOpened, component: Component, props, closeWindow } = useWindow();

    return (
        <>
            <Window {...props} isOpened={isOpened} onClose={closeWindow}>
                {Component && <Component />}
            </Window>
        </>
    );
};

interface WindowProviderProps {
    children: ReactNode;
}

export const WindowProvider: FC<WindowProviderProps> = ({ children }) => {
    const [openState, setOpenState] = useState(false);
    const [state, setState] = useState<WindowContextProps>(defaultState);

    const actions = useMemo(
        () => ({
            open({ component = undefined, isOpened = false, ...props }: WindowContextProps) {
                setState({
                    component,
                    props,
                    isOpened,
                });
                setOpenState(true);
            },
            close() {
                setOpenState(false);
            },
        }),
        [],
    );
    return (
        <WindowContext.Provider
            value={{
                isOpened: openState,
                component: state.component,
                props: state.props,
                openWindow: (args: WindowContextProps) => actions.open(args),
                closeWindow: actions.close,
                customImage: state.customImage,
            }}
        >
            <WindowManager />
            {children}
        </WindowContext.Provider>
    );
};