import firestore, { Timestamp } from 'firebase/firestore';
import { ImageIdentificationData } from './ImageIdentificationData';
import { Roles } from './Roles';

export class ExerciseItem {
    communityId?: string | undefined;

    authorId?: string | undefined;

    authorType?: Roles | undefined;

    id?: string | undefined;

    title?: string | undefined;

    answer?: string | undefined;

    exerciseImage?: File | string | undefined;

    exerciseImageType?: string | undefined;

    imageDetails: ImageIdentificationData[];

    photoCapturedPlace?: string | undefined;

    photoCapturedOn?: Date | undefined;

    anecdote?: string | undefined;

    theme?: string | undefined;

    createdDate?: Timestamp | null;

    patientList?: (string | undefined)[];

    proList?: ({[key: string]: 'read' | 'write'} | undefined)[];

    etsList?: (string | undefined)[];

    constructor(
        communityId: string,
        authorId: string,
        authorType: Roles,
        id: string,
        title: string,
        answer: string,
        exerciseImage: File | string,
        exerciseImageType: string,
        imageDetails: ImageIdentificationData[],
        photoCapturedPlace: string,
        photoCapturedOn: Date,
        anecdote: string,
        theme: string,
        createdDate: Timestamp,
        patientList: string[],
        proList?: ({[key: string]: 'read' | 'write'} | undefined)[],
        etsList?: string[]
    ) {
        this.communityId = communityId;
        this.authorId = authorId;
        this.authorType = authorType;
        this.id = id;
        this.title = title;
        this.answer = answer;
        this.exerciseImage = exerciseImage;
        this.exerciseImageType = exerciseImageType;
        this.imageDetails = imageDetails;
        this.photoCapturedPlace = photoCapturedPlace;
        this.photoCapturedOn = photoCapturedOn;
        this.anecdote = anecdote;
        this.theme = theme;
        this.createdDate = createdDate;
        this.patientList = patientList;
        this.proList = proList;
        this.etsList = etsList;
    }
}

export const ExerciseItemConverter: firestore.FirestoreDataConverter<ExerciseItem> = {
    toFirestore(exerciseItem) {
        return {
            communityId: exerciseItem.communityId,
            authorId: exerciseItem.authorId,
            authorType: exerciseItem.authorType,
            id: exerciseItem.id,
            title: exerciseItem.title,
            answer: exerciseItem.answer,
            exerciseImage: exerciseItem.exerciseImage,
            exerciseImageType: exerciseItem.exerciseImageType,
            imageDetails: exerciseItem.imageDetails,
            photoCapturedPlace: exerciseItem.photoCapturedPlace,
            photoCapturedOn: exerciseItem.photoCapturedOn,
            anecdote: exerciseItem.anecdote,
            theme: exerciseItem.theme,
            createdDate: exerciseItem.createdDate,
            patientList: exerciseItem.patientList,
            proList: exerciseItem.proList,
            etsList: exerciseItem.etsList
        };
    },
    fromFirestore(snapshot, options): ExerciseItem {
        const data = snapshot.data(options);
        return new ExerciseItem(
            data.communityId,
            data.authorId,
            data.authorType,
            data.id,
            data.title,
            data.answer,
            data.exerciseImage,
            data.exerciseImageType,
            data.imageDetails,
            data.photoCapturedPlace,
            data.photoCapturedOn,
            data.anecdote,
            data.theme,
            data.createdDate,
            data.patientList,
            data.proList,
            data.etsList
        );
    }
};
