import React, { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { sendResetInfoPatient } from '../../services/storageFunctions';
import { getAllFamilly, getUserByEmailId, getUserByInfo } from '../../services/cloudFirestore';
import { validateEmail } from '../../utilities/utils';
import { Roles } from '../../models/Roles';

import magicUnicorns from '../../assets/magicUnicorns.png';
import checkGreen from '../../assets/checkGreen.svg';
import { User } from '../../models/User';
import { Select } from '../../components/Select';
import { Button } from '../../components/Button';
import { Typography } from '../../components/Typography';
import useWindowDimensions from '../../utilities/useWindowDimensions';
import { Header } from '../../components/Header';
import { Input } from '../../components/Input';
import { CheckBox } from '../../components/CheckBox';

const classes: { [key: string]: CSSProperties } = {
    ForgotButton: {
        margin: '10px',
        fontSize: '1.2rem',
        fontWeight: '600',
        // boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
        borderRadius: '12px'

        // '&:hover': {
        //     backgroundColor: '#D00070',
        //     boxShadow: '0px 0px 0px 1.7833px rgba(208, 0, 112, 0.4)',
        //     color: 'white'
        // },
        // '@media (max-width: 650px)': {
        //     width: '100%',
        //     minWidth: '100%'
        // }
    },
    title: {
        fontWeight: '700',
        fontSize: '2rem'
        // display: 'flex',
        // alignContent: 'space-around',
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    'sub-title': {
        textAlign: 'center',
        fontWeight: '500',
        fontSize: '1.5rem',
        lineHeight: '2rem'
    },
    'sub-title-choise': {
        fontWeight: '400',
        fontSize: '1.2rem',
        lineHeight: '1.5rem',
        textAlign: 'left',
        color: '#84919E',
        width: '40%',
        height: '100%'
    },
    container: {
        marginTop: '7%',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        fontFamily: 'Inter',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        flexWrap: 'wrap'
    }
};

export function ForgotPassword(): JSX.Element {
    const { t } = useTranslation();
    const history = useHistory();
    const [typeForgot, setTypeForgot] = useState<'password' | 'email' | 'passwordEmail'>();
    const [step, setStep] = useState(0);
    const { height, width } = useWindowDimensions();

    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [errorEmailOrTwo, setErrorEmailOrTwo] = useState(false);
    const [errorUserNotFound, setErrorUserNotFound] = useState(false);
    const [errorMoreOneUser, setErrorMoreOneUser] = useState(false);
    const [errorSelectMail, setErrorSelectMail] = useState(false);
    const [step2Error1, setStep2Error1] = useState<boolean>(false);
    const [step2Error2, setStep2Error2] = useState<boolean>(false);
    const [step2Error3, setStep2Error3] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [userIsPatient, setUserIsPatient] = useState<boolean>();
    const [familyCode, setFamilyCode] = useState<string>();

    const [emailSending, setEmailSending] = useState<{
        myEmail: string;
        famillyEmail: { email: string; firstName: string };
        otherEmail: string;
    }>({ myEmail: '', famillyEmail: { email: '', firstName: '' }, otherEmail: '' });
    const [emailSendingBool, setEmailSendingBool] = useState<{
        myEmail: boolean;
        famillyEmail: boolean;
        otherEmail: boolean;
    }>({ myEmail: false, famillyEmail: false, otherEmail: false });
    const [arrayFamillyEmail, setArrayFamillyEmail] = useState<
        { email: string; firstName: string }[]
    >([]);
    const [userInfo, setUserInfo] = useState<{
        firstName: string;
        lastName: string;
    }>({ firstName: '', lastName: '' });
    const [user, setUser] = useState<User>();

    const validStep0 = (type: 'password' | 'email' | 'passwordEmail' | undefined): void => {
        setTypeForgot(type);
        if (type === undefined) setStep(0);
        else setStep(1);
    };

    const [sendingEmailList, sendingEmailListBool] = useState<string[]>([]);
    // const [sendingFirstNameFamilly, setSendingFirstNameFamilly] = useState<string>();
    const validStep2 = async (): Promise<void> => {
        let sendingFirstNameFamilly;
        // use temps because useState is async
        let tempStep2Error1 = false;
        let tempStep2Error2 = false;
        let tempStep2Error3 = false;
        sendingEmailListBool([]);
        if (emailSendingBool.myEmail) {
            if (validateEmail(emailSending.myEmail)) {
                sendingEmailList.push(emailSending.myEmail);
            } else {
                setStep2Error1(true);
                tempStep2Error1 = true;
            }
        }
        if (emailSendingBool.famillyEmail) {
            if (validateEmail(emailSending.famillyEmail.email)) {
                sendingEmailList.push(emailSending.famillyEmail.email);
                sendingFirstNameFamilly = emailSending.famillyEmail.firstName;
            } else {
                setStep2Error2(true);
                tempStep2Error2 = true;
            }
        }
        if (emailSendingBool.otherEmail) {
            if (validateEmail(emailSending.otherEmail)) {
                sendingEmailList.push(emailSending.otherEmail);
            } else {
                setStep2Error3(true);
                tempStep2Error3 = true;
            }
        }

        if (
            !tempStep2Error1 &&
            !tempStep2Error2 &&
            !tempStep2Error3 &&
            (emailSendingBool.myEmail ||
                emailSendingBool.famillyEmail ||
                emailSendingBool.otherEmail)
        ) {
            const tempSendingEmailList: string[] = [];
            if (emailSendingBool.myEmail) tempSendingEmailList.push(emailSending.myEmail);
            if (emailSendingBool.famillyEmail) {
                tempSendingEmailList.push(emailSending.famillyEmail.email);
            }

            // sending to user and familly member
            if (typeForgot) {
                sendResetInfoPatient(
                    typeForgot,
                    email,
                    tempSendingEmailList,
                    {
                        firstName: sendingFirstNameFamilly ?? '',
                        firstNamePatient: user && user.firstName ? user.firstName : '',
                        lastNamePatient: user && user.lastName ? user.lastName : ''
                    },
                    emailSendingBool.myEmail ? emailSending.myEmail : ''
                ).then((res) => {
                    if (res) {
                        setStep(-1);
                    }
                });
            }

            // sending to other account
            if (emailSendingBool.otherEmail && typeForgot) {
                sendResetInfoPatient(
                    typeForgot,
                    email,
                    [emailSending.otherEmail],
                    {
                        firstName: '',
                        firstNamePatient: user && user.firstName ? user.firstName : '',
                        lastNamePatient: user && user.lastName ? user.lastName : ''
                    },
                    emailSendingBool.myEmail ? emailSending.myEmail : ''
                ).then((res) => {
                    if (res) {
                        setStep(-1);
                    }
                });
            }
        } else {
            setErrorSelectMail(true);
        }
    };

    useEffect(() => {
        setError(false);
        if (
            step === 2 &&
            !emailSendingBool.myEmail &&
            !emailSendingBool.famillyEmail &&
            !emailSendingBool.otherEmail
        ) {
            let tempMyEmail = '';
            const tempFamillyEmail: { email: string; firstName: string }[] = [];
            const getData = async (): Promise<void> => {
                if (validateEmail(email) && emailSending.myEmail === '') tempMyEmail = email;
                if (familyCode) {
                    const FamillyEmail = await getAllFamilly(familyCode);
                    if (FamillyEmail) {
                        FamillyEmail.forEach((element) => {
                            if (element.emailId && element.firstName) {
                                tempFamillyEmail.push({
                                    email: element.emailId,
                                    firstName: element.firstName
                                });
                            }
                        });
                    }
                }
            };
            getData().then(() => {
                setArrayFamillyEmail(tempFamillyEmail);
                setEmailSending({
                    myEmail: tempMyEmail,
                    famillyEmail: tempFamillyEmail[0],
                    otherEmail: ''
                });
                setEmailSendingBool({
                    myEmail: tempMyEmail !== '',
                    famillyEmail: tempFamillyEmail[0].email !== '',
                    otherEmail: false
                });
            });
        }
    }, [email, step]);

    const onForgotPassword = async (): Promise<void> => {
        // setError(false);
        if (email) {
            setError(false);
            let tempEmail = '';
            if (!validateEmail(email)) tempEmail = `${email}@stimulin.fr`;
            else tempEmail = email;

            const thisUser = await getUserByEmailId(tempEmail);
            if (thisUser) {
                if (thisUser.role === Roles.SENIOR) {
                    setUserIsPatient(true);
                    setFamilyCode(thisUser.familyCode);
                    setUser(thisUser);
                    setStep(2);
                } else {
                    setUserIsPatient(false);
                    setLoading(true);
                    const tempSendingEmailList = [tempEmail];
                    sendResetInfoPatient(
                        'password',
                        tempEmail,
                        tempSendingEmailList,
                        {
                            firstName: thisUser.firstName ?? '',
                            firstNamePatient: thisUser.firstName ?? '',
                            lastNamePatient: thisUser.lastName ?? ''
                        },
                        tempEmail
                    ).then((result) => {
                        if (result) {
                            setStep(-1);
                        }
                    });
                }
            } else {
                setError(true);
            }
        } else {
            setError(true);
        }
    };

    const onForgotEmailOrTwo = async (): Promise<void> => {
        setErrorEmailOrTwo(false);
        setErrorUserNotFound(false);
        if (userInfo.firstName && userInfo.lastName) {
            getUserByInfo(
                userInfo.firstName,
                userInfo.lastName
                // userInfo.dob !== new Date().toISOString().slice(0, 10) ? userInfo.dob : null
            ).then((res) => {
                if (res && res.length === 1 && res[0].emailId) {
                    if (res[0].role !== Roles.SENIOR) {
                        if (typeForgot) {
                            const tempSendingEmailList = [res[0].emailId];
                            sendResetInfoPatient(
                                typeForgot,
                                res[0].emailId,
                                tempSendingEmailList,
                                {
                                    firstName: res[0].firstName ?? '',
                                    firstNamePatient: res[0].firstName ?? '',
                                    lastNamePatient: res[0].lastName ?? ''
                                },
                                res[0].emailId
                            ).then((result) => {
                                if (result) {
                                    setStep(-1);
                                }
                            });
                        }
                    } else {
                        const setingEmail = async (): Promise<void> => {
                            if (res[0].emailId) {
                                await setEmail(res[0].emailId);
                                await setUserIsPatient(true);
                                await setFamilyCode(res[0].familyCode);
                                await setUser(res[0]);
                            }
                        };
                        setingEmail().then(() => {
                            setStep(2);
                        });
                    }
                } else {
                    // setErrorEmailOrTwo(true);
                    if (res && res.length === 0) setErrorUserNotFound(true); // user not found
                    else if (res && res.length > 1) setErrorMoreOneUser(true); // more than one user
                }
            });
        } else {
            setErrorEmailOrTwo(true); // empty input
        }
    };

    useEffect(() => {
        setErrorEmailOrTwo(false);
        setErrorUserNotFound(false);
        setErrorMoreOneUser(false);
    }, [userInfo]);

    return (
        <>
            <Header
                backFunc={(): void => {
                    if (step > 0) setStep(step - 1);
                    else history.goBack();
                }}
            />
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'start',
                    alignItems: 'center',
                    overflowY: 'auto',
                    paddingBottom: '20px'
                }}>
                {/* <Header
                canGoBack
                goBackFunc={(): void => {
                    if (step > 0) setStep(step - 1);
                    else history.goBack();
                }}
            /> */}
                <div style={{ width: width && width < 769 ? '100%' : '80%', padding: '0 5%' }}>
                    {step === -1 && (
                        <div
                            style={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                height: '100vh',
                                width: '100vw',
                                backgroundColor: '#7B838B',
                                zIndex: 1000,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                            <div
                                style={{
                                    width: '75%',
                                    height: '75%',
                                    backgroundColor: 'white',
                                    borderRadius: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    padding: '20px'
                                }}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '30%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <img src={magicUnicorns} alt="magicUnicorns" height="100%" />
                                    <img src={checkGreen} alt="check" />
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '40%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Typography
                                        variant="h3"
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.5 !important',
                                            fontWeight: '700 !important',
                                            color: '#374653'
                                        }}>
                                        {userIsPatient
                                            ? t('emailSentPatient')
                                            : t('emailSent!patient')}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            textAlign: 'center',
                                            fontSize: '1.2rem !important',
                                            fontWeight: '400 !important',
                                            color: '#374653'
                                        }}>
                                        {!userIsPatient && t('checkYourEmail!patient')}
                                        {userIsPatient &&
                                            typeForgot === 'password' &&
                                            t('checkYourEmailPatientPassword')}
                                        {userIsPatient && typeForgot === 'email' && (
                                            <>
                                                {t('checkYourEmailPatientEmail1')}
                                                <br />
                                                {t('checkYourEmailPatientEmail2')}
                                            </>
                                        )}
                                        {userIsPatient &&
                                            typeForgot === 'passwordEmail' &&
                                            t('checkYourEmailPatientPasswordEmail')}
                                    </Typography>
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: '30%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Button
                                        pink
                                        sx={{ ...classes['forgot-big'] }}
                                        onClick={(): void => {
                                            history.push('/login/me');
                                        }}>
                                        {t('Igo')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    )}
                    {step === 0 && (
                        <>
                            <Typography variant="h2" sx={{ ...classes['title'] }}>
                                {t('whatForgot')}
                            </Typography>
                            <div style={{ ...classes['container'] }}>
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 950 ? '100%' : '30%'
                                    }}
                                    onClick={(): void => validStep0('password')}>
                                    {t('forgot.myPassword')}
                                </Button>
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 950 ? '100%' : '30%'
                                    }}
                                    onClick={(): void => validStep0('email')}>
                                    {t('forgot.myEmail')}
                                </Button>
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 950 ? '100%' : '30%'
                                    }}
                                    onClick={(): void => validStep0('passwordEmail')}>
                                    {t('forgot.myPasswordEmail')}
                                </Button>
                            </div>
                        </>
                    )}
                    {step === 1 && typeForgot === 'password' && (
                        <>
                            <Typography variant="h2" sx={{ ...classes['title'] }}>
                                {t('forgotPasswordTitle')}
                            </Typography>
                            <Typography variant="h3" sx={{ ...classes['sub-title'] }}>
                                {t('forgotPasswordText1')}
                                <br />
                                {t('forgotPasswordText2')}
                            </Typography>
                            <div style={{ ...classes['container'], flexDirection: 'column' }}>
                                <Input
                                    // id="email"
                                    sxRoot={{ width: width && width < 648 ? '100%' : '60%' }}
                                    pink
                                    label={t('inputForgotEmail') ?? ''}
                                    labelPlaceholder
                                    type="email"
                                    // outlined
                                    value={email}
                                    error={error}
                                    onChange={(str): void => setEmail(str)}
                                />
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 648 ? '100%' : '60%',
                                        margin: '0',
                                        marginTop: '0.5rem'
                                    }}
                                    onClick={async (): Promise<void> => {
                                        onForgotPassword();
                                    }}>
                                    {t('createNewPassword')}
                                </Button>
                                {error && (
                                    <Typography variant="p" sx={{ color: 'var(--color-error)' }}>
                                        {t('errorForgotPasswordNotFind')}
                                    </Typography>
                                )}
                                <Typography
                                    variant="h2"
                                    sx={{
                                        ...classes['title'],
                                        marginTop: '6%'
                                    }}>
                                    {t('oupsForgotPasswordEmail')}
                                </Typography>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        ...classes['sub-title']
                                    }}>
                                    {t('oupsForgotPasswordEmailDesc')}
                                </Typography>
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 648 ? '100%' : '60%',
                                        margin: '0',
                                        marginTop: '0.5rem'
                                    }}
                                    onClick={(): void => {
                                        validStep0('passwordEmail');
                                    }}>
                                    {t('getPasswordEmail')}
                                </Button>
                                {/* <ForgotButton
                                    big
                                    sx={{ marginTop: '20px !important' }}
                                    onClick={(): void => {
                                        validStep0('passwordEmail');
                                    }}>
                                    {t('getPasswordEmail')}
                                </ForgotButton> */}
                            </div>
                        </>
                    )}
                    {step === 1 && typeForgot !== 'password' && (
                        <>
                            <Typography
                                variant="h2"
                                sx={{
                                    ...classes['title']
                                }}>
                                {t('forgotEmail|EmailPasswordTitle')}
                                <br />
                                {typeForgot === 'passwordEmail'
                                    ? t('forgotEmailPasswordTitle2')
                                    : t('forgotEmailTitle2')}
                            </Typography>
                            <div
                                style={{
                                    ...classes['container']
                                }}>
                                <Input
                                    // id="firstname"
                                    labelPlaceholder
                                    sxRoot={{
                                        marginTop: '1rem',
                                        width: width && width < 648 ? '100%' : '60%'
                                    }}
                                    pink
                                    label={t('inputFirstname') ?? ''}
                                    value={userInfo.firstName}
                                    error={error}
                                    onChange={(str): void =>
                                        setUserInfo({
                                            ...userInfo,
                                            firstName: str
                                        })
                                    }
                                />
                                <Input
                                    // id="lastname"
                                    labelPlaceholder
                                    sxRoot={{
                                        marginTop: '1rem',
                                        width: width && width < 648 ? '100%' : '60%'
                                    }}
                                    pink
                                    label={t('inputLastname') ?? ''}
                                    value={userInfo.lastName}
                                    error={error}
                                    onChange={(str): void =>
                                        setUserInfo({
                                            ...userInfo,
                                            lastName: str
                                        })
                                    }
                                />
                                {/* <Input
                                    // id="dob"
                                    labelPlaceholder
                                    sxRoot={{
                                        marginTop: '1rem',
                                        width: width && width < 648 ? '100%' : '60%'
                                    }}
                                    pink
                                    label={t('inputBirthday') ?? ''}
                                    type="date"
                                    value={userInfo.dob}
                                    error={error}
                                    onChange={(str): void =>
                                        setUserInfo({
                                            ...userInfo,
                                            dob: str
                                        })
                                    }
                                /> */}
                                <Button
                                    pink
                                    outline
                                    sx={{
                                        ...classes['ForgotButton'],
                                        width: width && width < 648 ? '100%' : '60%',
                                        margin: '0',
                                        marginTop: '1rem'
                                    }}
                                    onClick={async (): Promise<void> => {
                                        onForgotEmailOrTwo();
                                    }}>
                                    {typeForgot === 'email' ? (
                                        t('getIdentifiant')
                                    ) : (
                                        <span style={{ lineHeight: '1.3rem' }}>
                                            {t('getIdentifiantPass1')}
                                            <br />
                                            {t('getIdentifiantPass2')}
                                        </span>
                                    )}
                                </Button>
                                {errorEmailOrTwo && (
                                    <Typography
                                        variant="p"
                                        sx={{
                                            color: 'var(--color-error)',
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                        {!userInfo.lastName &&
                                            t('errorForgotEmailOrTwoEmpty.lastName')}
                                        {!userInfo.lastName && <br />}
                                        {!userInfo.firstName &&
                                            t('errorForgotEmailOrTwoEmpty.firstName')}
                                    </Typography>
                                )}
                                {errorUserNotFound && (
                                    <Typography
                                        variant="p"
                                        sx={{
                                            color: 'var(--color-error)',
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                        {t('errorForgotEmailOrTwoUserNotFound')}
                                    </Typography>
                                )}
                                {errorMoreOneUser && (
                                    <Typography
                                        variant="p"
                                        sx={{
                                            color: 'var(--color-error)',
                                            width: '100%',
                                            textAlign: 'center'
                                        }}>
                                        {t('errorForgotEmailOrTwoMoreOneUser')}
                                    </Typography>
                                )}
                            </div>
                        </>
                    )}
                    {step === 2 && ( // userIsPatient &&
                        <>
                            <Typography variant="h2" sx={{ ...classes['title'] }}>
                                {t('forgotPasswordPatient')}
                            </Typography>
                            <div style={{ ...classes['container'] }}>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: width && width < 648 ? 'column' : 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '4%'
                                    }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            ...classes['sub-title-choise'],
                                            width: width && width < 648 ? '100%' : '40%'
                                        }}>
                                        {t('forgotPasswordPatient.choise1')}
                                    </Typography>
                                    <div
                                        style={{
                                            width: width && width < 648 ? '100%' : '60%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                        <Input
                                            sxRoot={{
                                                width: '85%',
                                                marginTop: '0px'
                                            }}
                                            sx={{ backgroundColor: '#fff' }}
                                            pink
                                            type="email"
                                            value={emailSending?.myEmail}
                                            // error={step2Error1}
                                            onChange={(str): void => {
                                                setEmailSending({
                                                    ...emailSending,
                                                    myEmail: str
                                                });
                                                if (str === '') {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        myEmail: false
                                                    });
                                                } else {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        myEmail: true
                                                    });
                                                }
                                            }}
                                        />
                                        <div
                                            style={{
                                                width: '15%',
                                                height: '100% !important',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <CheckBox
                                                green
                                                checked={emailSendingBool.myEmail}
                                                setChecked={(value): void => {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        myEmail: !emailSendingBool.myEmail
                                                    });
                                                }}
                                                sx={{
                                                    transform: 'scale(2)' // default 2.5
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {step2Error1 && (
                                    <Typography sx={{ width: '100%', color: 'var(--color-error)' }}>
                                        {t('errorIsNotEmail')}
                                    </Typography>
                                )}
                                <div
                                    style={{
                                        width: '100%',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: width && width < 648 ? 'column' : 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '4%'
                                    }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            ...classes['sub-title-choise'],
                                            width: width && width < 648 ? '100%' : '40%'
                                        }}>
                                        {t('forgotPasswordPatient.choise2')}
                                    </Typography>
                                    <div
                                        style={{
                                            width: width && width < 648 ? '100%' : '60%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                        <Select
                                            sx={{
                                                border: '1px solid var(--textarea-border-color)'
                                            }}
                                            defaultValue={
                                                arrayFamillyEmail[0] && arrayFamillyEmail[0].email
                                                    ? arrayFamillyEmail[0].email
                                                    : ''
                                            }
                                            sxRoot={{
                                                width: '85%',
                                                marginTop: '0px',
                                                textAlign: 'left'
                                                // '@media (max-width: 650px)': {
                                                //     width: '100%',
                                                //     minWidth: '100%'
                                                // }
                                            }}
                                            value={
                                                emailSending.famillyEmail &&
                                                emailSending.famillyEmail.email
                                                    ? emailSending.famillyEmail.email
                                                    : ''
                                            }
                                            opts={arrayFamillyEmail.map((item) => ({
                                                value: item.email,
                                                label: item.email
                                            }))}
                                            onChange={(e): void => {
                                                if (
                                                    e &&
                                                    e.target &&
                                                    e.target.value &&
                                                    typeof e.target.value === 'string'
                                                ) {
                                                    // get first name into arrayFamillyEmail with email
                                                    const firstName = arrayFamillyEmail.find(
                                                        (item) => item === e.target.value
                                                    );
                                                    if (firstName) {
                                                        setEmailSending({
                                                            ...emailSending,
                                                            famillyEmail: {
                                                                email: e.target.value,
                                                                firstName: firstName.firstName
                                                            }
                                                        });
                                                    }
                                                    if (e.target.value === '') {
                                                        setEmailSendingBool({
                                                            ...emailSendingBool,
                                                            famillyEmail: false
                                                        });
                                                    } else {
                                                        setEmailSendingBool({
                                                            ...emailSendingBool,
                                                            famillyEmail: true
                                                        });
                                                    }
                                                }
                                            }}
                                        />
                                        <div
                                            style={{
                                                width: '15%',
                                                height: '100% !important',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <CheckBox
                                                green
                                                checked={emailSendingBool.famillyEmail}
                                                setChecked={(value): void => {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        famillyEmail: !emailSendingBool.famillyEmail
                                                    });
                                                }}
                                                sx={{
                                                    transform: 'scale(2)' // default 2.5
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {step2Error2 && (
                                    <Typography sx={{ width: '100%', color: 'var(--color-error)' }}>
                                        {t('errorIsNotEmail')}
                                    </Typography>
                                )}
                                <div
                                    style={{
                                        width: '100%',
                                        height: 'fit-content',
                                        display: 'flex',
                                        flexDirection: width && width < 648 ? 'column' : 'row',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        marginTop: '4%'
                                    }}>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            ...classes['sub-title-choise'],
                                            width: width && width < 648 ? '100%' : '40%'
                                        }}>
                                        {t('forgotPasswordPatient.choise3')}
                                        <span style={{ fontSize: '0.8rem' }}>
                                            {t('forgotPasswordPatient.choise3.2')}
                                        </span>
                                        {' :'}
                                    </Typography>
                                    <div
                                        style={{
                                            width: width && width < 648 ? '100%' : '60%',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'space-between'
                                        }}>
                                        <Input
                                            sxRoot={{
                                                width: '85%',
                                                marginTop: '0px'
                                            }}
                                            sx={{ backgroundColor: '#fff' }}
                                            pink
                                            type="email"
                                            onChange={(str): void => {
                                                setEmailSending({
                                                    ...emailSending,
                                                    otherEmail: str
                                                });
                                                if (str === '') {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        otherEmail: false
                                                    });
                                                } else {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        otherEmail: true
                                                    });
                                                }
                                            }}
                                            value={emailSending?.otherEmail}
                                        />
                                        <div
                                            style={{
                                                width: '15%',
                                                height: '100% !important',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}>
                                            <CheckBox
                                                green
                                                checked={emailSendingBool.otherEmail}
                                                setChecked={(value): void => {
                                                    setEmailSendingBool({
                                                        ...emailSendingBool,
                                                        otherEmail: !emailSendingBool.otherEmail
                                                    });
                                                }}
                                                sx={{
                                                    transform: 'scale(2)' // default 2.5
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {step2Error3 && (
                                    <Typography sx={{ width: '100%', color: 'var(--color-error)' }}>
                                        {t('errorIsNotEmail')}
                                    </Typography>
                                )}
                                <Button
                                    pink
                                    sx={{
                                        marginTop: '6%'
                                        // height: typeForgot === 'passwordEmail' ? '85px' : '50px'
                                    }}
                                    onClick={(): void => {
                                        validStep2();
                                    }}>
                                    {typeForgot === 'password' && t('getLinkByEmail')}
                                    {typeForgot === 'email' && t('getIdByEmail')}
                                    {typeForgot === 'passwordEmail' && (
                                        <span style={{ lineHeight: '1.3rem' }}>
                                            {t('getIdPassByEmail1')}
                                            <br />
                                            {t('getIdPassByEmail2')}
                                        </span>
                                    )}
                                </Button>
                                {errorSelectMail && (
                                    <Typography sx={{ width: '100%', color: 'var(--color-error)' }}>
                                        {t('errorSelectMail')}
                                    </Typography>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}
