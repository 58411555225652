import React, { CSSProperties, useState, useEffect, useContext, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';
import { changeEmail, logOut } from '../services/firebaseAuth';
import { ProfileImage } from './ProfilImage';
import useWindowDimensions from '../utilities/useWindowDimensions';
import { Patient } from '../models/Patient';
import {
    getFamilyUser,
    getPatientFromCode,
    getPatientUser,
    getTherapistUser,
    getUserByEmail,
    saveFamilyProfile,
    savePatientProfile,
    saveTherapistProfile
} from '../services/cloudFirestore';
import { Input } from './Input';
import { Select } from './Select';
import { UserDataProp } from '../models/userDataProp';
import { UserContext } from '../providers/UserProvider';
import { Typography } from './Typography';
import { Therapist } from '../models/Therapist';
import { Timestamp } from 'firebase/firestore';
import { Family, dropdownListFamily } from '../models/Family';
import editImgIcon from '../assets/editImg.png';
import rings from '../assets/rings.svg';
import { getImageKitUrlFrom } from '../utilities/utils';
import Cropper from 'react-easy-crop';
import { Slider } from './Slider';
import zoomIn from '../assets/zoom_in_24px.svg';
import zoomOut from '../assets/zoom_out_24px.svg';
import unknownUser from '../assets/unknownUser.png';
import { getCroppedImg } from '../utilities/cropImage';
import { useHistory } from 'react-router-dom';
import { Roles } from '../models/Roles';
import { User } from '../models/User';
import { changePassword, saveUser } from '../stores/User';
import { uploadImage } from '../services/storageFunctions';
import { Browser } from '@capacitor/browser';

const classes: { [key: string]: CSSProperties } = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        gap: '1rem',
        padding: '1rem 32px'
    },
    'informations-page-root': {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        alignItems: 'center'
    },
    'profile-image': {
        alignSelf: 'center',
        marginBottom: '0rem'
    },
    'informations-root': {
        display: 'flex',
        position: 'relative',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        flexWrap: 'wrap',
        paddingBottom: '2rem',
        paddingTop: '1rem'
    },
    'logout-button': {
        position: 'relative',
        marginBottom: '1%',
        width: '100%',
        textAlign: 'center',
        alignSelf: 'center'
    },
    'cropper-container': {
        position: 'relative',
        width: 'calc(100% - 2rem)',
        height: '50%',
        overflow: 'hidden',
        maxHeight: '22rem',
        maxWidth: '22rem'
    },
    slider: {
        margin: '0 1rem'
    },
    'slider-container': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '50vh',
        maxWidth: '100%',
        padding: '0 1rem'
    },
    'btn-slider': {
        width: 'fit-content',
        height: 'fit-content',
        display: 'flex',
        backgroundColor: 'transparent',
        border: 'none',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem'
    }
};

interface Props {
    setPhoto: React.Dispatch<React.SetStateAction<string | File | undefined>>;
    photo: string | File | undefined;
    step: number;
    setStep: React.Dispatch<React.SetStateAction<number>>;
}

export function PersonalInformations({ setPhoto, photo, step, setStep }: Props): JSX.Element {
    const connectedUserDataProp: UserDataProp | null = useContext(UserContext);
    const user = connectedUserDataProp?.user;
    const [tmpUser, setTmpUser] = useState<User | null | undefined>(user);
    const { t } = useTranslation();
    const history = useHistory();
    const { height, width } = useWindowDimensions();

    const [imageInUpload, setImageInUpload] = useState<boolean>(false);

    const [newPictureSelected, setNewPictureSelected] = useState<boolean>(false);
    const inputFile = useRef<HTMLInputElement>(null);
    const [croppedDone, setCroppedDone] = useState<boolean>(false);
    const [mediaCropped, setMediaCropped] = useState<{
        file: string;
        croppedImage: { url: string; file: Blob };
        crop: { x: number; y: number };
        zoom: number;
        croppedAreaPixels: { width: number; height: number; x: number; y: number };
        title: string | null;
        people: string | null;
        place: string | null;
        desc: string | null;
        date: Date | null;
        theme: string | null;
    }>();
    const [image, setImage] = useState<File>();
    const [errorSaveInformations, setErrorSaveInformations] = useState<boolean>(false);

    const triggerImageUpload = (): void => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    const handleTmpUserChange = (state: string, value: string | null | Timestamp): void => {
        setErrorSaveInformations(false);
        setTmpUser({ ...tmpUser, [state]: value });
    };

    const updateImage = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        if (event.target.files) {
            setImage(event.target.files[0]);
        }
    };

    useEffect(() => {
        if (image) {
            setMediaCropped({
                file: URL.createObjectURL(image),
                croppedImage: { url: '', file: new Blob() },
                crop: { x: 0, y: 0 },
                zoom: 1,
                croppedAreaPixels: { width: 0, height: 0, x: 0, y: 0 },
                title: null,
                people: null,
                place: null,
                desc: null,
                date: null,
                theme: null
            });
            setNewPictureSelected(true);
            setStep(step + 1);
        }
    }, [image]);

    useEffect(() => {
        if (step === 1 && newPictureSelected === true && user?.profilePhoto) {
            setPhoto(
                typeof user?.profilePhoto !== 'string'
                    ? user?.profilePhoto
                    : getImageKitUrlFrom(user.profilePhoto)
            );
        }
    }, [step]);

    const onCropComplete = useCallback(
        async (_croppedArea: any, croppedAreaPixels: any) => {
            setMediaCropped(() => {
                if (mediaCropped) {
                    const newMediaCropped = mediaCropped;

                    newMediaCropped.croppedAreaPixels = croppedAreaPixels;

                    return newMediaCropped;
                }
                return mediaCropped;
            });
        },
        [photo, mediaCropped]
    );

    useEffect(() => {
        const saveCroppedPicture = async (): Promise<void> => {
            if (user && user.id && mediaCropped?.croppedImage.file && image && image.type) {
                setImageInUpload(true);
                user.profilePhoto = new File([mediaCropped?.croppedImage.file], image?.name, {
                    type: image?.type
                });
                const link = await uploadImage(
                    user.profilePhoto,
                    user?.role === Roles.FAMILY || user?.role === Roles.SENIOR
                        ? user?.familyCode ?? 'images'
                        : user?.establishmentCode ?? 'images'
                );
                if (link) {
                    setTmpUser({ ...tmpUser, profilePhoto: link });
                    saveDetails({ ...tmpUser, profilePhoto: link });
                }
                setImageInUpload(false);
                history.push('/home');
            }
        };

        if (croppedDone === true && mediaCropped) {
            saveCroppedPicture();
            setCroppedDone(false);
        }
    }, [mediaCropped, croppedDone]);

    const validatePicture = async (): Promise<void> => {
        if (mediaCropped) {
            const newMediaCropped = {
                file: mediaCropped.file,
                croppedImage: await getCroppedImg(
                    mediaCropped.file,
                    mediaCropped.croppedAreaPixels
                ),
                croppedAreaPixels: mediaCropped.croppedAreaPixels,
                crop: mediaCropped.crop,
                zoom: 1,
                title: mediaCropped.title,
                people: mediaCropped.people,
                place: mediaCropped.place,
                desc: mediaCropped.desc,
                date: mediaCropped.date,
                theme: mediaCropped.theme
            };
            setMediaCropped(newMediaCropped);
            setCroppedDone(true);
        }
    };

    const saveDetails = async (user: User | null | undefined): Promise<void> => {
        setErrorSaveInformations(false);
        if (user && connectedUserDataProp?.token && tmpUser?.firstName && tmpUser?.lastName) {
            await saveUser(user, connectedUserDataProp.token).then((updatedUser) => {
                if (updatedUser) {
                    setTmpUser(updatedUser);
                    connectedUserDataProp.updateUser &&
                        connectedUserDataProp.updateUser({
                            ...connectedUserDataProp,
                            user: updatedUser
                        });
                }
            });
        } else {
            setErrorSaveInformations(true);
        }
    };

    return (
        <div style={{ ...classes['root'] }}>
            {newPictureSelected && mediaCropped && step === 2 ? (
                <>
                    <div
                        style={{
                            ...classes['cropper-container'],
                            ...(height && width && height > width
                                ? { height: 'calc(100vw - 2rem)' }
                                : { height: 'calc(45vw - 2rem)' })
                        }}>
                        <Cropper
                            image={mediaCropped.file}
                            crop={mediaCropped.crop}
                            zoom={mediaCropped.zoom}
                            minZoom={0.5}
                            restrictPosition={false}
                            aspect={1}
                            onCropChange={(crop: any): void => {
                                setMediaCropped(() => {
                                    const newMediaCropped = { ...mediaCropped };
                                    newMediaCropped.crop = crop;

                                    return newMediaCropped;
                                });
                            }}
                            onCropComplete={onCropComplete}
                            onZoomChange={(zoom: number): void => {
                                setMediaCropped(() => {
                                    const newMediaCropped = { ...mediaCropped };
                                    newMediaCropped.zoom = zoom;

                                    return newMediaCropped;
                                });
                            }}
                        />
                        {imageInUpload && (
                            <img
                                src={rings}
                                alt="loading"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    height: '50%',
                                    width: '50%'
                                }}
                            />
                        )}
                    </div>
                    <div style={classes['slider-container']}>
                        <Button
                            sx={classes['btn-slider']}
                            onClick={(): void => {
                                setMediaCropped(() => {
                                    const newMediaCropped = { ...mediaCropped };
                                    newMediaCropped.zoom =
                                        newMediaCropped.zoom - 0.2 >= 0.5
                                            ? newMediaCropped.zoom - 0.2
                                            : 0.5;

                                    return newMediaCropped;
                                });
                            }}>
                            <img src={zoomOut} alt="zoom out" />
                        </Button>
                        <Slider
                            sx={classes['slider']}
                            value={mediaCropped.zoom}
                            min={0.5}
                            max={3}
                            step={0.1}
                            ariaLabel="Zoom"
                            onChange={(e: any, thisZoom: number): void => {
                                setMediaCropped(() => {
                                    const newMediaCropped = { ...mediaCropped };
                                    newMediaCropped.zoom = thisZoom;

                                    return newMediaCropped;
                                });
                            }}
                        />
                        <Button
                            sx={classes['btn-slider']}
                            onClick={(): void => {
                                setMediaCropped(() => {
                                    const newMediaCropped = { ...mediaCropped };
                                    newMediaCropped.zoom =
                                        newMediaCropped.zoom + 0.2 <= 3
                                            ? newMediaCropped.zoom + 0.2
                                            : 3;

                                    return newMediaCropped;
                                });
                            }}>
                            <img src={zoomIn} alt="zoom in" />
                        </Button>
                    </div>
                    <Button
                        disabled={imageInUpload}
                        onClick={() => validatePicture()}
                        key="validate">
                        {imageInUpload ? t('waitPlz') : t('Validate')}
                    </Button>
                </>
            ) : (
                <div
                    style={{
                        ...classes['informations-page-root'],
                        ...{
                            alignItems: width && width < 768 ? 'flex-start' : 'center',
                            width: width && width < 768 ? '95%' : '100%'
                        }
                    }}>
                    <div style={{ position: 'relative' }}>
                        {(user?.firstName && user?.lastName) || typeof photo === 'string' ? (
                            <ProfileImage
                                key="profile picture"
                                sx={{ ...classes['profile-image'] }}
                                width={80}
                                height={80}
                                name={`${user?.firstName} ${user?.lastName}`}
                                url={
                                    typeof photo !== 'string'
                                        ? photo
                                        : getImageKitUrlFrom(photo, 150, 150)
                                }
                            />
                        ) : (
                            <img
                                src={unknownUser}
                                alt="unknownUser"
                                style={{ height: '80px', width: '80px' }}
                            />
                        )}
                        {user?.id === tmpUser?.id && (
                            <div
                                style={{
                                    width: '2rem',
                                    position: 'absolute',
                                    top: '70%',
                                    right: '0%'
                                }}>
                                <img
                                    src={editImgIcon}
                                    alt="editImgIcon"
                                    onClick={triggerImageUpload}
                                    style={{ cursor: 'pointer' }}
                                />
                                <input
                                    type="file"
                                    ref={inputFile}
                                    onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                    ): Promise<void> => updateImage(event)}
                                    accept="image/*"
                                    multiple={false}
                                    style={{ display: 'none', cursor: 'pointer' }}
                                />
                            </div>
                        )}
                    </div>
                    <Typography
                        variant="h3"
                        sx={{
                            width: width && width < 768 ? '90%' : '86%',
                            margin: '1rem 1rem'
                        }}>
                        {t('PersonalInformations')}
                    </Typography>
                    {/* HERE */}
                    <div
                        style={{
                            ...classes['informations-root'],
                            ...{ flexDirection: 'row' }
                        }}>
                        <Input
                            label={t('Firstname') ?? ''}
                            key="firstname"
                            value={tmpUser?.firstName ?? ''}
                            sxRoot={{
                                margin: '0.5rem 0',
                                width: width && width < 768 ? '100%' : 'calc(50%)',
                                paddingRight: width && width < 768 ? '0' : '0.5rem'
                            }}
                            onChange={(value: string): void =>
                                handleTmpUserChange('firstName', value)
                            }
                        />
                        <Input
                            label={t('Lastname') ?? ''}
                            key="lastname"
                            value={tmpUser?.lastName ?? ''}
                            sxRoot={{
                                margin: '0.5rem 0',
                                width: width && width < 768 ? '100%' : 'calc(50%)',
                                paddingLeft: width && width < 768 ? '0' : '0.5rem'
                            }}
                            onChange={(value: string): void =>
                                handleTmpUserChange('lastName', value)
                            }
                        />
                        <Input
                            label={t('DateOfBirth') ?? ''}
                            key="date of birth"
                            value={
                                tmpUser && tmpUser.dob && typeof tmpUser.dob === 'string'
                                    ? new Date(tmpUser.dob).toISOString().split('T')[0]
                                    : ''
                            }
                            onChange={(value: string): void => handleTmpUserChange('dob', value)}
                            type="date"
                            sxRoot={{
                                margin: '0.5rem 0rem'
                            }}
                        />
                        {(user?.role === Roles.FAMILY || user?.role === Roles.SENIOR) && (
                            <Input
                                label={t('FamilyCode') ?? ''}
                                key="family code"
                                value={user.familyCode ?? ''}
                                disabled
                                sxRoot={{
                                    margin: '0.5rem 0rem'
                                }}
                            />
                        )}
                        {user?.establishmentName !== "Stimul'in" &&
                            user?.establishmentName !== "Stimul'in Part'Lib" &&
                            user?.establishmentName !== 'Vivatech users' && (
                                <Input
                                    label={t('EstablishmentName') ?? ''}
                                    key="establishment name"
                                    value={
                                        user && user.establishmentName
                                            ? user?.establishmentName
                                            : ''
                                    }
                                    disabled
                                    sxRoot={{
                                        margin: '0.5rem 0rem'
                                    }}
                                />
                            )}
                        {/* <Input
                            label={t('Address') ?? ''}
                            key="address"
                            value={tmpUser && tmpUser.address ? tmpUser.address : ''}
                            disabled={
                                user?.id !== connectedUser?.id ||
                                finalizedAccount ||
                                userId !== tmpUser?.id
                            }
                            sxRoot={{
                                margin: '0.5rem 0rem'
                            }}
                            onChange={(value: string): void => {
                                handleTmpUserChange('address', value);
                            }}
                        />
                        <Input
                            label={t('Pincode') ?? ''}
                            key="pincode"
                            value={tmpUser && tmpUser.pincode ? tmpUser?.pincode : ''}
                            disabled={
                                user?.id !== connectedUser?.id ||
                                finalizedAccount ||
                                userId !== tmpUser?.id
                            }
                            sxRoot={{
                                margin: '0.5rem 0',
                                width: width && width < 768 ? '100%' : 'calc(50%)',
                                paddingRight: width && width < 768 ? '0' : '0.5rem'
                            }}
                            onChange={(value: string): void => {
                                handleTmpUserChange('pincode', value);
                            }}
                        />
                        <Input
                            label={t('City') ?? ''}
                            key="city"
                            value={tmpUser && tmpUser.city ? tmpUser?.city : ''}
                            disabled={
                                user?.id !== connectedUser?.id ||
                                finalizedAccount ||
                                userId !== tmpUser?.id
                            }
                            sxRoot={{
                                margin: '0.5rem 0',
                                width: width && width < 768 ? '100%' : 'calc(50%)',
                                paddingLeft: width && width < 768 ? '0' : '0.5rem'
                            }}
                            onChange={(value: string): void => {
                                handleTmpUserChange('city', value);
                            }}
                        /> */}
                        <Input
                            label={t('Email') ?? ''}
                            key="email"
                            type="email"
                            value={tmpUser?.emailId ?? ''}
                            disabled
                            sxRoot={{
                                margin: '0.5rem 0rem'
                            }}
                        />
                    </div>
                    {/* HERE */}
                    {errorSaveInformations && (
                        <Typography
                            variant="p"
                            sx={{
                                // width: width && width < 768 ? '90%' : '86%',
                                margin: '1rem 1rem',
                                textAlign: 'center',
                                color: 'var(--color-error)'
                            }}>
                            {t('errorSaveInformations')}
                        </Typography>
                    )}
                    <Button
                        pink
                        key="save"
                        sx={{
                            position: 'relative',
                            marginBottom: '1rem',
                            width: '100%',
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}
                        onClick={async () => {
                            await saveDetails(tmpUser);
                            history.push('/home');
                        }}>
                        {t('SaveDetails')}
                    </Button>
                    <Button
                        sx={{
                            position: 'relative',
                            marginBottom: '1rem',
                            width: '100%',
                            alignSelf: 'center',
                            textAlign: 'center'
                        }}
                        onClick={async () => {
                            if (connectedUserDataProp.token) {
                                // console.log('tmpUser?.emailId', tmpUser?.emailId);
                                const response = await changePassword(connectedUserDataProp.token);
                                if (response) {
                                    // window.open(response.data.ticket, '_blank');
                                    Browser.open({
                                        url: response.data.ticket,
                                        windowName: '_blank'
                                    });
                                }
                            }
                        }}>
                        {t('ChangePassword')}
                    </Button>
                    {/* <Button
                        key="logout"
                        sx={{
                            ...classes['logout-button']
                        }}
                        pink
                        onClick={() => {
                            logOut(user).then(() => {
                                setTimeout(() => {
                                    history.push('/login/me');
                                }, 100);
                            });
                        }}>
                        {t('LogOut')}
                    </Button> */}
                </div>
            )}
        </div>
    );
}
