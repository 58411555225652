import { createContext, useContext } from "react";
import { FunfactProps } from "../pages/games/Funfact";
import { Message } from "../components/Tips";

export const GameContext = createContext({
    startTimer: () => { },
    stopTimer: () => { },
    resetTimer: () => { },
    addPoints: (points: number) => { },
    resetPoints: () => { },
    writeMessage: (message: Message) => { },
    displayInstruction: () => { },
    displayFunfact: (funfact: FunfactProps) => { },
    closeFunfact: () => { },
    endGame: (props: any) => { },
    showUi: () => { },
    hideUi: () => { },
    time: 0,
    points: undefined as number | undefined,
});

export const useGame = () => useContext(GameContext);