import EinsteinSpeaking from "../EinsteinSpeaking";
import GameHeader from "../GameHeader";
import TabBar from "../TabBar";
import Heart from "./assets/heart.svg?react";
import Palette from "./assets/palette.svg?react";
import Book from "./assets/book.svg?react";
import Coffee from "./assets/coffee.svg?react";
import Tree from "./assets/tree.svg?react";
import Flask from "./assets/flask.svg?react";
import Ball from "./assets/ball.svg?react";
import Plane from "./assets/plane.svg?react";
import style from "./style.module.css";

import { WithTranslation, withTranslation } from "react-i18next";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { tourbillon, pingpong, memory, sudok, puzzle, reordon, melimots, quizz } from "../../models/Games";
import MediaGrid from "../MediaGrid";
import { useMenu } from "../../contexts/MenuContext";
import ContextMenu from "../../pages/ContextMenu";
import { DeleteWindow } from "../../windows/Delete";
import { deleteGame } from "../../stores/Game";
import { useContext, useState } from "react";
import { UserDataProp } from "../../models/userDataProp";
import { UserContext } from "../../providers/UserProvider";

interface GamesThemeProps extends WithTranslation {
    title: string;
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    items: any[];
    path: string;
};

const GamesTheme = ({ t, title, icon, items: initialItems, path }: GamesThemeProps) => {
    const userDataProp: UserDataProp | null = useContext(UserContext);
    const [items, setItems] = useState(initialItems);
    const history = useHistory();
    const { openMenu, closeMenu } = useMenu();
    const tabBarItems = [
        { label: t('Tous mes jeux') },
        { label: t('Favoris'), icon: Heart },
        { label: t('Art et culture'), icon: Palette },
        { label: t('Histoire'), icon: Book },
        { label: t('Quotidien'), icon: Coffee },
        { label: t('Nature'), icon: Tree },
        { label: t('Science'), icon: Flask },
        { label: t('Sport'), icon: Ball },
        { label: t('Voyage'), icon: Plane },
    ];

    // Separate "All games" from other items
    const allGamesItem = tabBarItems.shift();
    // Sort other TabBar items alphabetically
    const sortedTabBarItems = tabBarItems.sort((a, b) => a.label.localeCompare(b.label));
    // Reinsert "All games" in first position
    if (allGamesItem) {
        sortedTabBarItems.unshift(allGamesItem);
    }

    const gameThemeItems = items?.map((item) => ({
        id: item.id,
        title: item.title,
        author: item.authorType === 'ADMIN' ? 'Albert, Stimul\'in' : item.authorName,
        authorImage: item.authorPicture,
        image: item?.media?.[0]?.url,
        action: `${path}/${item.id}/lvl`,
        favorite: false,
        new: false,
        created_date: item.created_date,
        permission: item.permission,
    }))
        // Sort items by created_date
        .sort((a, b) => new Date(b.created_date).getTime() - new Date(a.created_date).getTime());

    const handleEdit = (itemId: string) => {
        const game = items.find((item) => item.id === itemId);
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <ContextMenu
                    page={'create'}
                    onClose={() => closeMenu()}
                    itemToEdit={{
                        exercise: {
                            id: game.id,
                            exerciseType: game.type,
                            exerciseName: game.title,
                            encouragementMessage: game.encouragementMessage,
                            congratulationsMessage: game.congratulationsMessage,
                            mediaList: game.media,
                        },
                        exerciseImage: game?.media?.[0]?.url ?? '',
                    }}
                />,
            });
        }
    };

    const handleDelete = (itemId: string) => {
        if (openMenu && closeMenu) {
            openMenu({
                component: () => <DeleteWindow type={'jeu'} onCancel={closeMenu} onDelete={() => {
                    deleteGame(userDataProp?.token || '', itemId);
                    setItems(prevItems => prevItems.filter(item => item.id !== itemId));
                    closeMenu();
                }} />,
            });
        }
    };

    return (
        <div className={style.root}>
            <GameHeader
                rtnAction={() => history.goBack()}
                quitAction
                title={title}
                Icon={icon}
            />
            <EinsteinSpeaking icon='einstein'>
                <span>{t('Sélectionnez un jeu')}</span>
            </EinsteinSpeaking>
            {/* <TabBar
                items={sortedTabBarItems}
            /> */}
            <MediaGrid
                onDisplay={(itemId) => {
                    history.push(`${path}/${itemId}/lvl`);
                }}
                items={gameThemeItems}
                onEdit={handleEdit}
                onDelete={handleDelete}
            />
        </div>
    );
};

export default withTranslation()(GamesTheme);