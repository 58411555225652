export class ImageIdentificationData {
    id: number;

    firstName: string | null;

    lastName: string | null;

    relation: string | null;

    constructor(id: number, firstName: string, lastName: string, relation: string) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.relation = relation;
    }
}
